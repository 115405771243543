import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { theme } from "../../../utils/theme";
import { AppText, FlexDiv, Loading } from "../../UI";
import styled from "styled-components";
import { PhoenixIcon } from "../../UI/Phoenix";
import { download, file_text, pause, play, rotate, volume_2 } from "../../../images/NewDesign";
import { useDebounce } from "../../../utils/hooks";
import { MyPriorCallNotes } from "../DashboardSideBarSegments";
import { CoachingNotes } from "./CoachingNotes";
import { successToast } from "../../../utils/toast";
import { FetchResult, MutationFunctionOptions, gql, useMutation } from "@apollo/client";
// @ts-ignore
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import { loggedInUser } from "../../../apollo/cache";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { throttle } from "lodash";
import { ModalContext } from "../../../context";
import { downloadResourceCors } from "src/utils/misc";
import { iconSave } from "src/images";

const SEND_REP_FEEDBACK = gql`
  mutation sendReviewCallReportToRep($conference_id: String!) {
    sendReviewCallReportToRep(conference_id: $conference_id)
  }
`;

interface Lead {
  id: string;
  business_name: string;
  channel: string;
  first_name: string;
  last_name: string;
  industry_label: string;
  lead_source_label: string;
  current_lead_type: string;
  call_notes: { created_at: string; notes: string; id: string }[];
}

interface Region {
  id: string;
  start: number;
  end: number;
  data: {
    note: string;
  };
}

interface WaveFormProps {
  url?: string;
  peakData?: number[];
  showCoachingNotes: boolean;
  lead_data: Lead;
  regions?: Region[];
  conference_id?: string;
  refetch_call_report?: () => void;
  addCoachingNote?: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  editCoachingNote?: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  deleteCoachingNote?: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  aiProcessing?: boolean;
  callDuration?: number;
  showTimeline?: boolean;
}

const regionColor = "rgba(255,179,56,0.6)";

const WaveformV2: React.FC<WaveFormProps> = ({
  url,
  peakData,
  showCoachingNotes,
  lead_data,
  regions,
  conference_id,
  refetch_call_report,
  addCoachingNote,
  editCoachingNote,
  deleteCoachingNote,
  aiProcessing,
  callDuration,
  showTimeline,
}) => {
  const { setShowCoachingNotesModal, setCurrentConferenceID, setSaveCallToLibraryModal } = useContext(ModalContext);

  const waveformContainer = useRef<HTMLDivElement | null>(null);
  const waveform = useRef<WaveSurfer | null>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  const [loadingDownload, setLoadingDownload] = useState(false);

  const { aiPhase1 } = useFlags();
  const [didLoad, setDidLoad] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);
  const setCurrentTimeThrottled = useRef(
    throttle((time: number) => {
      setCurrentTime(time);
    }, 250),
  ); // Update the time at most 4 times per second

  useEffect(() => {
    if (!url || !waveformContainer.current) return;

    waveform.current = WaveSurfer.create({
      container: waveformContainer.current as HTMLElement,
      responsive: true,
      normalize: true,

      height: 48,

      barWidth: 2,
      barMinHeight: 2,
      barRadius: 1.5,
      barGap: 4,
      cursorWidth: 1,

      backgroundColor: theme.fill.brand.disabled,

      waveColor: theme.fill.brand.primary,
      progressColor: theme.fill.brand.primary,
      backend: 'MediaElement',
      plugins: [RegionsPlugin.create({})],
    });

    waveform.current.load(url, peakData);

    // this is required to trigger the audio process event.
    waveform.current?.seekTo(0);

    waveform.current.on("ready", waveformOnReady);

    waveform.current.on("audioprocess", (time) => {
      setCurrentTimeThrottled.current(time);
    });

    waveform.current.on("region-updated", () => {
      saveRegions();
    });

    waveform.current.on("region-removed", () => {
      saveRegions();
    });

    waveform.current.on("region-update-end", async (region: any) => {
      if (!addCoachingNote || !editCoachingNote) return;

      if (region.attributes.wavesurferShouldCreate) {
        await addCoachingNote({
          variables: {
            note_id: region.id,
            conference_id: conference_id,
            text: "",
            start: Math.floor(region.start),
            end: Math.floor(region.end),
          },
        });
        refetch_call_report && refetch_call_report();
        saveRegions();
        region.update({
          attributes: {
            wavesurferShouldCreate: false,
          },
        });
      } else {
        await editCoachingNote({
          variables: {
            noteItemId: region.id,
            text: region.data.note,
            start: Math.floor(region.start),
            end: Math.floor(region.end),
          },
        });
        refetch_call_report && refetch_call_report();
        saveRegions();
      }
    });

    return () => waveform.current?.destroy();
  }, [waveformContainer, url]);

  // handles the custom cursor head placement.
  useEffect(() => {
    const waveContainer = waveformContainer.current?.querySelector("wave");
    const cursorContainer = waveformContainer.current?.querySelector("wave > wave");

    if (!waveContainer || !cursorContainer) return;

    const cursorHead = document.createElement("div");
    Object.assign(cursorHead.style, {
      position: "absolute",
      width: "20px",
      height: "12px",
      border: `1px solid ${theme.border.brand.inverse}`,
      borderRadius: "360px",
      backgroundColor: theme.fill.neutral.primary,
      boxShadow: "0px 0px 4px 0px rgba(112, 145, 255, 0.4)",
      top: "-12px",
    });
    waveContainer.appendChild(cursorHead);

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === cursorContainer) {
          cursorHead.style.left = `${(cursorContainer as HTMLElement).offsetWidth - 10}px`;
        }
      });
    });

    resizeObserver.observe(cursorContainer as HTMLElement);

    return () => resizeObserver.disconnect();
  }, [waveformContainer]);

  const waveformOnReady = () => {
    setIsReady(true);

    // drag selection note functionality is being removed in aiPhase1 release.
    // in aiPhase1 notes will no longer be 'range based'. They will only have a start position.
    if (!aiPhase1) {
      regions &&
        regions.forEach((region) => {
          waveform.current?.addRegion({
            ...region,
            color: regionColor,
          });
        });

      if (loggedInUser().role === "ADMIN" || loggedInUser().role === "SM") {
        waveform.current?.enableDragSelection({
          color: regionColor,
          attributes: {
            wavesurferShouldCreate: true,
          },
          data: {
            note: "",
          },
        });
      }
    }
  };

  const saveRegions = () => {
    //save region/annotation state locally to waveform state
    const newRegions = Object.keys(waveform.current?.regions.list)?.map((id) => {
      const region = waveform.current?.regions.list[id];
      return {
        id: region.id,
        start: region.start,
        end: region.end,
        color: region.color,
        attributes: region.attributes,
        data: region.data,
      };
    });

    console.log("New Regions updated", newRegions);
  };

  const [sendReviewCallReportToRep, { loading: repLoading }] = useMutation(SEND_REP_FEEDBACK, {
    onCompleted({ sendReviewCallReportToRep }) {
      console.log("sendReviewCallReportToRep: ", sendReviewCallReportToRep);
      successToast("Feedback sent!");
    },
  });

  const handlePlay = () => {
    if (!waveform.current) return;
    waveform.current.playPause();
    setIsPlaying((prev) => !prev);
  };

  const handleSkipForward = useCallback(() => {
    if (!waveform.current) return;
    const curPos = waveform.current.getCurrentTime();
    const audioDur = waveform.current.getDuration();
    const seekToPos = Math.min(curPos + 30, audioDur) / audioDur;
    if (seekToPos === 1) {
      waveform.current.pause();
      setIsPlaying(false);
    }
    waveform.current.seekTo(seekToPos);
  }, [waveform]);

  const handleSkipBack = useCallback(() => {
    if (!waveform.current) return;
    const curPos = waveform.current.getCurrentTime();
    const audioDur = waveform.current.getDuration();
    const seekToPos = Math.max(0, curPos - 15) / audioDur;
    waveform.current.seekTo(seekToPos);
  }, [waveform]);

  const handleVolumeChange = useCallback(
    (val: number) => {
      if (!waveform.current) return;
      waveform.current.setVolume(val / 100);
    },
    [waveform],
  );

  const handleSpeedChange = useCallback(
    (val: number) => {
      if (!waveform.current) return;
      waveform.current.setPlaybackRate(val);
      setPlaybackSpeed(val);
    },
    [waveform],
  );

  const playRegion = useCallback(
    (regionId: string) => {
      if (!waveform.current) return;
      const region = waveform.current.regions.list[regionId];
      region.play();
      setIsPlaying(true);
    },
    [waveform],
  );

  const addRegion = async (text?: string) => {
    if (!waveform.current || !addCoachingNote) return;

    const results = await addCoachingNote({
      variables: {
        conference_id: conference_id,
        text: !!text ? text : "",
        start: Math.max(Math.floor(waveform.current.getCurrentTime()), 0),
        end: Math.floor(waveform.current.getCurrentTime() + 1),
      },
    });

    waveform.current.addRegion({
      id: results.data.addCoachingNote.id,
      start: Math.max(Math.floor(waveform.current.getCurrentTime()), 0),
      end: Math.floor(waveform.current.getCurrentTime() + 1),
      color: regionColor,
      attributes: {},
      data: {
        note: results.data.addCoachingNote.text ?? "",
      },
    });
    refetch_call_report && refetch_call_report();
    successToast("New note added!");
  };

  const updateNotes = async (regionId: string, note: string) => {
    if (!waveform.current || !editCoachingNote) return;

    await editCoachingNote({
      variables: {
        noteItemId: regionId,
        text: note,
      },
    });
    refetch_call_report && refetch_call_report();

    const region = waveform.current.regions.list[regionId];

    region.update({
      data: {
        note: note,
      },
    });
    successToast("Note updated!");
  };

  const removeRegion = async (regionId: string) => {
    if (!waveform.current || !deleteCoachingNote) return;

    await deleteCoachingNote({
      variables: {
        noteItemId: regionId,
      },
    });
    const region = waveform.current.regions.list[regionId];
    region.remove();
    refetch_call_report && refetch_call_report();
    successToast("Note deleted!");
  };

  return (
    <FlexDiv direction="column">
      <ControlContainer>
        {!!aiPhase1 && (
          <FlexDiv gap={4} align="center" style={{ cursor: "pointer" }} onClick={() => setShowCoachingNotesModal(true)}>
            <PhoenixIcon
              svg={file_text}
              size={16}
              color={theme.icon.brand.default}
              hoverColor={theme.icon.brand.default}
            />
            <AppText
              fontSize={10}
              fontWeight={600}
              lineHeight={16}
              color={theme.buttontext.brand_outline.default}
              uppercase
            >
              Add Coaching Note
            </AppText>
          </FlexDiv>
        )}

        <FlexDiv align="center" style={{ marginRight: "auto", marginLeft: "auto" }}>
          <PlayController
            handlePlay={handlePlay}
            isPlaying={isPlaying}
            handleSkipForward={handleSkipForward}
            handleSkipBack={handleSkipBack}
            aiPhase1={aiPhase1}
            disabled={!isReady}
          />
          <AppText style={{ paddingLeft: "16px" }}>
            {formatTime(currentTime, (callDuration ?? 0) > 3600)} /{" "}
            {formatTime(callDuration ?? 0, (callDuration ?? 0) > 3600)}
          </AppText>
          {!isReady && (
            <div style={{ marginLeft: "32px" }}>
              <Loading />
            </div>
          )}
        </FlexDiv>

        <FlexDiv direction="column" justify="space-between" align="flex-end" style={{ height: "52px" }}>
          <FlexDiv gap={8} align="center">
            {!!url && (
              <>
                <PhoenixIcon
                  svg={iconSave}
                  alt="Save"
                  pointer
                  size={20}
                  color={theme.icon.neutral.default}
                  hoverColor={theme.icon.neutral.default}
                  onClick={() => {
                    if (!!conference_id) {
                      setCurrentConferenceID(conference_id);
                      setSaveCallToLibraryModal(true);
                    }
                  }}
                />
                <PhoenixIcon
                  svg={download}
                  size={16}
                  pointer
                  color={loadingDownload ? theme.icon.neutral.disabled : theme.icon.neutral.default}
                  hoverColor={loadingDownload ? theme.icon.neutral.disabled : theme.icon.neutral.default}
                  onClick={() => {
                    if (loadingDownload) return;
                    setLoadingDownload(true);
                    downloadResourceCors(url, undefined, () => setLoadingDownload(false));
                  }}
                />
              </>
            )}
            <PhoenixIcon
              svg={volume_2}
              size={16}
              color={theme.icon.brand.default}
              hoverColor={theme.icon.brand.default}
            />
            <CustomRangeSlider handleVolumeChange={handleVolumeChange} />
          </FlexDiv>

          <FlexDiv align="center" gap={12}>
            <PlaybackSpeedText active={playbackSpeed === 0.75} onClick={() => handleSpeedChange(0.75)}>
              0.75x
            </PlaybackSpeedText>
            <PlaybackSpeedText active={playbackSpeed === 1} onClick={() => handleSpeedChange(1)}>
              1.0x
            </PlaybackSpeedText>
            <PlaybackSpeedText active={playbackSpeed === 1.25} onClick={() => handleSpeedChange(1.25)}>
              1.25x
            </PlaybackSpeedText>
            <PlaybackSpeedText active={playbackSpeed === 1.5} onClick={() => handleSpeedChange(1.5)}>
              1.5x
            </PlaybackSpeedText>
            <PlaybackSpeedText active={playbackSpeed === 1.75} onClick={() => handleSpeedChange(1.75)}>
              1.75x
            </PlaybackSpeedText>
            <PlaybackSpeedText active={playbackSpeed === 2} onClick={() => handleSpeedChange(2)}>
              2.0x
            </PlaybackSpeedText>
          </FlexDiv>
        </FlexDiv>
      </ControlContainer>

      <WaveformContainer>
        <div
          style={{
            width: "100%",
            height: "50%",
            boxShadow: `0px 0.5px 0px 0px ${theme.border.neutral.primary}`,
            position: "absolute",
          }}
        />
        <Waveform ref={waveformContainer} aiProcessing={aiProcessing || !aiPhase1} />
      </WaveformContainer>

      {showTimeline && (
        <TimelineContainer>
          {Array.from({ length: 101 })?.map((_, i) => (
            <TimelineTick index={i} key={`tick-${i}`} duration={callDuration} />
          ))}
        </TimelineContainer>
      )}

      {showCoachingNotes && (
        <NotesContainer>
          <MyPriorCallNotes
            isInCallReport
            callNotes={lead_data.call_notes}
            color={"#D8D8D8"}
            textcolor={theme.BLACK_COLOR}
          />
          <CoachingNotes
            coachingNotes={regions ? regions : []}
            playRegion={playRegion}
            addRegion={addRegion}
            updateNotes={updateNotes}
            removeRegion={removeRegion}
            repLoading={repLoading}
            sendFeedbackFunction={() => sendReviewCallReportToRep({ variables: { conference_id } })}
          />
        </NotesContainer>
      )}
    </FlexDiv>
  );
};

const TimelineContainer = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 30px;

  padding: 0px 24px 8px 24px;

  background-color: ${theme.fill.neutral.secondary};
`;

const TimelineTick: React.FC<{ index: number; duration?: number }> = ({ index, duration = 0 }) => {
  const primaryTick = index % 5 === 0;
  const percentage = index / 100;
  const seconds = Math.floor(duration * percentage);
  const formattedTime = moment.utc(seconds * 1000).format(seconds >= 3600 ? "H:mm:ss" : "m:ss");

  return (
    <Tick primaryTick={primaryTick}>
      {primaryTick && <TimeLabel labelLen={formattedTime.length}>{formattedTime}</TimeLabel>}
    </Tick>
  );
};

const Tick = styled.div<{ primaryTick: boolean }>`
  position: relative;

  width: 1px;
  height: ${({ primaryTick }) => (primaryTick ? "6px" : "4px")};

  background-color: ${theme.border.neutral.primary};
`;

const TimeLabel = styled(AppText)<{ labelLen: number }>`
  position: absolute;
  top: 8px;
  left: ${({ labelLen }) => (labelLen <= 4 ? "-10px" : labelLen <= 5 ? "-13px" : "-18px")};

  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: ${theme.text.neutral.tertiary};
`;

const WaveformContainer = styled.div`
  position: relative;
  background-color: ${theme.fill.brand.disabled};
`;

const Waveform = styled.div<{ aiProcessing?: boolean }>`
  margin: 0px 24px;
  padding: 8px 0px;

  & > wave {
    overflow: unset !important;
  }
  & > wave wave {
    height: ${({ aiProcessing }) => (aiProcessing ? "56px" : "420px")};
  }
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 88px;

  padding: 16px 24px;

  background-color: ${theme.fill.brand.secondary};
  border-top: 1px solid ${theme.border.brand.tertiary};
`;

const PlaybackSpeedText = styled(AppText)<{ active: boolean }>`
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: ${(props) => (props.active ? theme.text.neutral.primary : theme.text.neutral.secondary)};
  cursor: pointer;
  border-bottom: none;
  user-select: none;
`;

interface PlayControllerProps {
  handlePlay: () => void;
  handleSkipForward: () => void;
  handleSkipBack: () => void;
  isPlaying: boolean;
  aiPhase1: boolean;
  disabled: boolean;
}

const PlayController: React.FC<PlayControllerProps> = ({
  handlePlay,
  isPlaying,
  handleSkipForward,
  handleSkipBack,
  aiPhase1,
  disabled,
}) => {
  return (
    <PlayControllerContainer aiPhase1={aiPhase1}>
      <FlexDiv align="center" gap={8} style={{ cursor: "pointer", padding: "8px" }} onClick={handleSkipBack}>
        <PhoenixIcon
          svg={rotate}
          size={16}
          color={theme.icon.neutral.default}
          hoverColor={theme.icon.neutral.default}
          pointer
          style={{ transform: "scaleX(-1)" }}
        />
        <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.text.neutral.secondary}>
          15
        </AppText>
      </FlexDiv>

      <PlayPauseButton onClick={handlePlay} disabled={disabled}>
        <PhoenixIcon
          svg={isPlaying ? pause : play}
          size={20}
          variant="white"
          hoverColor="white"
          pointer
          fillIcon
          style={{ paddingLeft: isPlaying ? "0px" : "3px" }}
        />
      </PlayPauseButton>

      <FlexDiv align="center" gap={8} style={{ cursor: "pointer", padding: "8px" }} onClick={handleSkipForward}>
        <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.text.neutral.secondary}>
          30
        </AppText>
        <PhoenixIcon
          svg={rotate}
          size={16}
          color={theme.icon.neutral.default}
          hoverColor={theme.icon.neutral.default}
          pointer
        />
      </FlexDiv>
    </PlayControllerContainer>
  );
};

const PlayControllerContainer = styled.div<{ aiPhase1: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;

  width: 184px;
  height: 56px;
  padding: 8px;

  margin-left: ${(props) => (props.aiPhase1 ? "auto" : 0)};
  margin-right: auto;

  border: 1px solid ${theme.border.brand.tertiary};
  border-radius: 360px;
  background-color: ${theme.fill.neutral.primary};
`;

const PlayPauseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  cursor: pointer;
  border: none;
  border-radius: 100px;
  background-color: ${theme.buttonfill.brand.default};

  :hover {
    background-color: ${theme.buttonfill.brand.hover};
  }

  :focus {
    border: 1px solid white;
    box-shadow: 0 0 0 1px ${theme.border.brand.primary};
  }

  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const RangeSliderInput = styled.input`
  appearance: none;
  outline: none;

  width: 120px;
  height: 4px;

  border-radius: 100px;
  background-color: ${theme.fill.neutral.tertiary};

  overflow: hidden;

  ::-webkit-slider-thumb {
    appearance: none;
    width: 4px;
    height: 4px;
    border-radius: 100px;
    background-color: ${theme.fill.brand.primary};

    box-shadow: -125px 0 0 124px ${theme.fill.brand.primary};
  }

  ::-moz-range-thumb {
    appearance: none;
    width: 4px;
    height: 4px;
    border-radius: 100px;
    background-color: ${theme.fill.brand.primary};

    box-shadow: -125px 0 0 124px ${theme.fill.brand.primary};
  }
`;

interface CustomRangeSliderProps {
  handleVolumeChange: (val: number) => void;
}

const CustomRangeSlider: React.FC<CustomRangeSliderProps> = ({ handleVolumeChange }) => {
  const [volume, setVolume] = useState(80);

  useDebounce(() => handleVolumeChange(volume), [volume], 50);

  return (
    <RangeSliderInput
      type="range"
      min={0}
      max={100}
      value={volume}
      onChange={(e) => setVolume(parseInt(e.target.value))}
    />
  );
};

const NotesContainer = styled.div`
  margin-top: 13px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const formatTime = (time: number, includeHours: boolean = false) => {
  const rounded = Math.floor(time);
  const hours = Math.floor(rounded / 3600);
  const minutes = Math.floor((rounded % 3600) / 60);
  const seconds = rounded % 60;

  if (includeHours) {
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
      return `${minutes}:${seconds.toString().padStart(2, "0")}`;
    }
  } else {
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }
};

export { WaveformV2 };
