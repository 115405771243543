import React, { useState, useEffect } from "react";
import { Dispatch, SetStateAction } from "react";
import { Modal } from "./Modal";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { PhoenixProgressBar } from "../UI/Phoenix";
import { getScoreColor } from "../../utils/misc";
import { AppText, FlexDiv } from "../UI";

interface CallScoreMoreInfoModalProps {
  blinds: boolean;
  setBlinds: Dispatch<
    SetStateAction<{
      title: string;
      score: number;
      description: string;
    } | null>
  >;
  showCallScoreMoreInfo: { title: string; score: number; description: string } | null;
}

export const CallScoreMoreInfoModal: React.FC<CallScoreMoreInfoModalProps> = ({
  blinds,
  setBlinds,
  showCallScoreMoreInfo,
}) => {
  const [score, setScore] = useState(() => 0);
  useEffect(() => {
    // timeout used to trigger animation
    const timeout = setTimeout(() => {
      setScore(showCallScoreMoreInfo?.score || 0);
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Modal open={blinds} onClose={() => setBlinds(null)} bigCloseButton={true} popupAnimation>
      <Container>
        <ScoreContainer>
          <FlexDiv justify="space-between">
            <AppText fontSize={16} fontWeight={600} lineHeight={22}>
              {showCallScoreMoreInfo?.title}
            </AppText>
            <AppText fontSize={14} fontWeight={600} lineHeight={20}>
              {showCallScoreMoreInfo?.score}/100
            </AppText>
          </FlexDiv>
          <PhoenixProgressBar
            step={score}
            maxStep={100}
            height={4}
            color={getScoreColor(showCallScoreMoreInfo?.score || 0)}
          />
        </ScoreContainer>

        <AppText fontSize={14} fontWeight={400} lineHeight={20} textAlign="center">
          {showCallScoreMoreInfo?.description}
        </AppText>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  width: 476px;
  min-height: 302px;
  padding: 62px 40px 40px 40px;
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 386px;
  padding: 16px;

  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
`;
