import * as React from "react";
import { useRef, useState } from "react";
import styled from "styled-components";
import { loggedInUser } from "../../apollo/cache";
import { theme } from "../../utils/theme";
import {
  AppErrorText,
  AppIconButton,
  AppText,
  FlexDiv,
  Loading,
  NewAppSelect,
  ReactDatesWrapper,
  SkeletonBlock,
} from "../UI";
import { NewAppButton } from "../UI/NewAppButton";

import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { FieldArray, Formik, FormikProps } from "formik";
import moment from "moment";
import * as Yup from "yup";
import "../../utils/react-big-calendar.css";
import { appToast } from "../../utils/toast";
import { InputField } from "../Field/InputField";

import { timezone_list_items } from "../../static/";

import { SingleDatePicker } from "react-dates";
import { FiTrash2 } from "react-icons/fi";
import { FormAreaInputField } from "../Field";
import { PhoenixIcon, PhoenixInput, PhoenixMultiSelect, PhoenixTextArea } from "../UI/Phoenix";
import { arrow_left } from "../../images/NewDesign";
import { PhoenixAppButton } from "../UI/Phoenix";
import { SelectDispo } from "../Smart";
import { PanelModal } from "../modal";
import { useFlags } from "launchdarkly-react-client-sdk";

import Switch from "react-switch";
import { PhoenixInputField } from "../Field/Phoenix";
interface AvailableTimeItem {
  user_id: string;
  user_name: string;
  start_time: string;
  event_count: number;
}

interface DisappearingDivProps {
  dataEvent: any;
  user_id?: string;
  selectedDataID?: any;
  selectedDataTime?: any;
  selectedDataLeadID?: string;
  setCurrentTask?: (currentTask: string) => void;
  id?: string;
  time?: string;
  phone_number?: string;
  title?: string;
  type?: string;
  lead_id: string;
  lead_primary_email?: string;
  lead_full_name?: string;
  fromNonCallActivity?: boolean;
  closeModal: () => void;
  dispositionType?: string;
  intent_id?: string;
  disableNoContectForDemo?: boolean;
  selectedDisposition?: string;
  isThisACallback: boolean;
}

interface IFetchAvailableTimes {
  fetchAvailableTimes: AvailableTimeItem[];
}
const AVAILABLE_SPECIFIC_TIMES = gql`
  query fetchAvailableTimes(
    $day: DateTime
    $timezone: String
    $user_id: String
    $action: String
    $callback: Boolean
    $lead_id: String
    $reschedule_own_event: Boolean
  ) {
    fetchAvailableTimes(
      day: $day
      timezone: $timezone
      user_id: $user_id
      action: $action
      callback: $callback
      lead_id: $lead_id
      reschedule_own_event: $reschedule_own_event
    )
  }
`;

const EDIT_CALENDAR_EVENT = gql`
  mutation editCalendarEvent(
    $id: String!
    $event_start_time: DateTime!
    $event_end_time: DateTime
    $event_title: String!
    $event_location: String!
    $event_description: String!
    $joinMe_id: String
    $guest_list: [String]
    $disposition_id: String
  ) {
    editCalendarEvent(
      id: $id
      event_start_time: $event_start_time
      event_end_time: $event_end_time
      event_title: $event_title
      event_location: $event_location
      event_description: $event_description
      joinMe_id: $joinMe_id
      guest_list: $guest_list
      disposition_id: $disposition_id
    ) {
      id
      action_label
      start_time
      end_time
      what
      where
      description
      timezone
      join_me_id
      join_me_item {
        schedule_item_id
        schedule_item {
          booking_id
          join_me_item {
            id
            viewer_url
          }
        }
      }
      timeoff_request {
        result
        schedule_item_id
      }
      guests {
        id
        email
        result
      }
      lead_intent {
        id
        type
        label
        associated_action
        lead {
          id
          full_name
          business_name
          primary_email
          primary_phone_number
        }
        user {
          id
          full_name
          team_name
          role
        }
      }
    }
  }
`;

interface MyFormikProps {
  id: string;
  lead_id: string;
  event_start_time: string;
  event_end_time: string;
  guest_list: string[];
  event_title: string;
  event_description: string;
  joinMe_id: string;
  event_location: string;
  timezone: string;
  selected_person_spoke_to: string;
  selected_disposition_id: string;
  zoom_invite: boolean;
}

const EditEventDetailsV2: React.FC<DisappearingDivProps> = ({
  selectedDataID,
  selectedDataTime,
  selectedDataLeadID,
  lead_primary_email,
  lead_id,
  fromNonCallActivity,
  closeModal,
  disableNoContectForDemo = false,
  selectedDisposition,
  dataEvent,
  isThisACallback,
  ...props
}) => {
  const { zoomIntegration } = useFlags();
  const formikRef = useRef() as any;
  const [dateValue, setDateValue] = useState(
    !!dataEvent.scheduleItem.start_time ? moment(dataEvent.scheduleItem.start_time) : moment(),
  );

  const [focused, setFocused] = useState(false);

  // step 0: select disposition (person spoke to -> disposition)

  // step 1: edit event details (title, date, time, location, description, guests)

  const [step, setStep] = useState(0);

  const editEventSchema = Yup.object().shape({
    id: Yup.string(),
    event_start_time: Yup.string()
      .required("Start time is required")
      .test("Start time is after the present", "Start time must be in the future.", (value) =>
        moment(value).isAfter(moment()),
      ),
    event_end_time: Yup.string().required(),
    event_title: Yup.string(),
    event_location: Yup.string().nullable(),
    timezone: Yup.string(),
    guest_list: Yup.array()
      .notRequired()
      .nullable()
      .of(Yup.string().required("Email address cannot be empty").email("Please enter a valid email address").trim()),
    event_description: Yup.string().nullable(),
    joinMe_id: Yup.string().nullable(),
    lead_id: Yup.string().required(),
    zoom_invite: Yup.boolean().nullable(),
  });

  const { data, loading: loadingTimes, error: errorTimes } = useQuery<IFetchAvailableTimes>(AVAILABLE_SPECIFIC_TIMES, {
    variables: {
      day: dateValue,
      timezone: formikRef?.current?.values?.timezone || dataEvent.scheduleItem.timezone || "America/New_York",
      callback: isThisACallback,
      lead_id: lead_id,
      reschedule_own_event: true,
      user_id:
        loggedInUser().role === "ADMIN" || loggedInUser().role == "SM"
          ? dataEvent?.scheduleItem?.lead_intent?.user?.id
          : null,
    },
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  // used in step 1 (edit calendar event)

  const [editCalendarEvent, { loading: loadingEdit, error: errorEdit }] = useMutation(EDIT_CALENDAR_EVENT, {
    async onCompleted({ editCalendarEvent }) {
      console.log("editCalendarEvent: ", editCalendarEvent);
      if (!editCalendarEvent) {
        appToast("Error editCalendarEvent. Something went wrong.");
        return;
      }
      appToast("Event updated!");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `editCalendarEvent GraphQL Error: ${message}`,
      });
      console.log("Error in editCalendarEvent: ", message);
    },
  });

  const SelectDispoForEditEvent = ({
    values,
    setFieldValue,
    loading,
  }: {
    values: MyFormikProps;
    setFieldValue: (field: string, value: any) => void;
    loading?: boolean;
  }) => {
    return (
      <SelectDispoDiv>
        {loading ? (
          <SkeletonBlock height={100} width={"100%"} borderRadius={6} />
        ) : (
          <SelectDispo
            selectedPersonSpokeTo={values?.selected_person_spoke_to}
            selectedDispositionId={values?.selected_disposition_id}
            selectedEventType={isThisACallback ? "Schedule Callback" : "Reschedule Demo"}
            setSelectedPersonSpokeTo={(personSpokeTo: string) => {
              setFieldValue("selected_person_spoke_to", personSpokeTo);
            }}
            setSelectedDispositionId={(dispositionId: string) => {
              setFieldValue("selected_disposition_id", dispositionId);
            }}
            lead_id={values?.lead_id}
            vertical
          />
        )}
      </SelectDispoDiv>
    );
  };
  const EditEventDetailsMain = ({
    values,
    setFieldValue,
  }: {
    values: MyFormikProps;
    setFieldValue: (field: string, value: any) => void;
  }) => {
    return (
      <MainEventDetails>
        {zoomIntegration && (
          <FlexDiv gap={8}>
            <Switch
              checked={values.zoom_invite}
              onChange={(checked: boolean) => setFieldValue("zoom_invite", checked)}
              onColor={theme.PRIMARY500}
              offColor={theme.NEUTRAL200}
              height={16}
              width={32}
              handleDiameter={12}
              checkedIcon={false}
              uncheckedIcon={false}
            />
            <AppText fontSize={12} fontWeight={500}>
              Enable Zoom Invite
            </AppText>
          </FlexDiv>
        )}

        <FlexDiv>
          <PhoenixInputField
            name="event_title"
            titleText="Event Title"
            requiredStar
            displayNoContextText
            titleTextSpacing={8}
          />
        </FlexDiv>
        <FlexDiv gap={8} direction="column">
          <AppText fontSize={12} fontWeight={500}>
            Select a Date<span style={{ color: theme.ATTENTION700 }}>*</span>
          </AppText>
          <ReactDatesWrapper>
            <SingleDatePicker
              id="make-sale-date-picker"
              required
              date={moment(dateValue)}
              onDateChange={(date: any) => {
                setDateValue(date);
              }}
              focused={focused}
              onFocusChange={({ focused }) => setFocused(focused)}
              numberOfMonths={1}
            />
          </ReactDatesWrapper>
        </FlexDiv>
        <PhoenixMultiSelect
          name="timezone"
          titleText="Select Timezone"
          isClearable={false}
          titleFontWeight={500}
          titleTextSpacing={8}
          isMulti={false}
          required
          options={timezone_list_items}
          value={timezone_list_items.filter((item: any) => item.value === values.timezone)}
          onChange={(e: any) => {
            setFieldValue("timezone", e.value);
          }}
          marginBottom={false}
        />

        <FlexDiv gap={8} direction="column">
          <AppText>
            Select Time<span style={{ color: "red" }}>*</span>
          </AppText>
          {loadingTimes ? (
            <SkeletonBlock height={450} width={"100%"} borderRadius={4} />
          ) : errorTimes ? (
            <AppErrorText>Error loading available times!</AppErrorText>
          ) : (
            <ScrollSegment>
              {data &&
                data.fetchAvailableTimes &&
                (() => {
                  const availableTimes = data.fetchAvailableTimes.filter((item: AvailableTimeItem) =>
                    moment(item.start_time).isAfter(moment()),
                  );

                  return availableTimes.length > 0 ? (
                    availableTimes?.map((item: AvailableTimeItem, index: any) => (
                      <NewAppButton
                        variant={"secondary"}
                        key={`${item.user_id}${item.start_time}`}
                        selected={values.event_start_time === item.start_time}
                        onClick={async () => {
                          setFieldValue("event_start_time", item.start_time);
                        }}
                      >
                        {values.timezone
                          ? moment(item.start_time).tz(values.timezone).format("h:mm A")
                          : moment(item.start_time).format("h:mm A")}
                        {!!item.event_count && <span style={{ opacity: 0.6 }}>{` (${item.event_count})`}</span>}
                      </NewAppButton>
                    ))
                  ) : (
                    <AppText>No available times found for this date.</AppText>
                  );
                })()}
            </ScrollSegment>
          )}
        </FlexDiv>

        <PhoenixTextArea
          name="event_description"
          title="Event Description"
          value={values.event_description}
          onChange={(e: any) => {
            setFieldValue("event_description", e.target.value);
          }}
        />
        <FlexDiv gap={8} direction="column">
          <AppText fontSize={12} fontWeight={500}>
            Guests<span style={{ color: "red" }}>*</span>
          </AppText>

          <FieldArray name="guest_list">
            {({ remove, push }) => (
              <>
                {values.guest_list?.map((item: string, index: number) => (
                  <>
                    <GuestRowDiv>
                      <PhoenixInputField
                        name={`guest_list[${index}]`}
                        value={item}
                        onChange={(e: any) => {
                          setFieldValue(`guest_list[${index}]`, e.target.value);
                        }}
                      />
                      <AppIconButton
                        onClick={async () => {
                          remove(index);
                        }}
                      >
                        <FiTrash2 />
                      </AppIconButton>
                    </GuestRowDiv>
                  </>
                ))}

                <NewAppButton size="sm" variant={"secondary"} onClick={() => push("")}>
                  Add Guest
                </NewAppButton>
              </>
            )}
          </FieldArray>
        </FlexDiv>
      </MainEventDetails>
    );
  };

  const loading = loadingEdit;

  const error = errorEdit;

  if (error) {
    return <AppErrorText>Error loading data!</AppErrorText>;
  }

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        lead_id: lead_id,
        id: dataEvent?.scheduleItem?.id ?? "",
        event_start_time: !!dataEvent.scheduleItem.start_time ? dataEvent.scheduleItem.start_time : undefined,
        event_end_time: !!dataEvent.scheduleItem.end_time ? dataEvent.scheduleItem.end_time : undefined,
        timezone: !!dataEvent?.scheduleItem?.timezone ? dataEvent.scheduleItem.timezone : "",
        guest_list: dataEvent.scheduleItem.guests?.map((item: any) => item.email) || [],
        event_title: dataEvent.scheduleItem.what || "",
        event_description: dataEvent.scheduleItem.description,
        joinMe_id: dataEvent.scheduleItem.join_me_id,
        event_location: dataEvent.scheduleItem.where ?? "",
        selected_person_spoke_to: "",
        selected_disposition_id: "",
        zoom_invite: dataEvent.scheduleItem.zoom_invite ?? false,
      }}
      validationSchema={editEventSchema}
      onSubmit={async (values) => {
        await editCalendarEvent({
          variables: {
            id: values.id,
            event_start_time: values.event_start_time,
            event_end_time: undefined,
            event_title: values.event_title,
            event_location: values.event_location,
            event_description: values.event_description,
            joinMe_id: values.joinMe_id,
            guest_list: values.guest_list,
            disposition_id: values.selected_disposition_id,
            zoom_invite: zoomIntegration ? values.zoom_invite : undefined,
          },
        });
        closeModal();
      }}
    >
      {({
        submitForm,
        isSubmitting,
        values,
        setFieldValue,
        dirty,
        isValid,
        errors,
        touched,
      }: FormikProps<MyFormikProps>) => {
        console.log("values: ", values);
        console.log("errors: ", errors);
        console.log("dirty: ", dirty);
        console.log("isValid: ", isValid);
        return (
          <PanelModal open={true} onClose={closeModal} width={440} bigCloseButton>
            <ModalContent>
              <Header>
                <AppText fontSize={20} fontWeight={600}>
                  Edit Event
                </AppText>
              </Header>
              {loadingEdit ? (
                <SkeletonBlock height={"100vh"} width={"100%"} borderRadius={4} />
              ) : (
                <MainDiv>
                  {step === 0 && (
                    <SelectDispoForEditEvent values={values} setFieldValue={setFieldValue} loading={loading} />
                  )}
                  {step === 1 && <EditEventDetailsMain values={values} setFieldValue={setFieldValue} />}
                </MainDiv>
              )}

              <ButtonDiv>
                <PhoenixAppButton buttonType="secondary" variant="danger-outline" onClick={closeModal}>
                  Cancel
                </PhoenixAppButton>
                <PhoenixAppButton
                  variant="brand"
                  buttonType="secondary"
                  disabled={
                    (step === 0 && !values.selected_disposition_id) ||
                    (step === 1 && (!dirty || !isValid)) ||
                    isSubmitting ||
                    loadingEdit
                  }
                  onClick={
                    step === 0
                      ? () => {
                          setStep(1);
                        }
                      : submitForm
                  }
                >
                  {step === 0 ? "Next" : "Save"}
                </PhoenixAppButton>
              </ButtonDiv>
            </ModalContent>
          </PanelModal>
        );
      }}
    </Formik>
  );
};

const MainEventDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 16px;
  justify-content: center;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 40px;
  overflow-y: auto;
  flex-grow: 1;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 40px;
  background-color: ${theme.WHITE_COLOR};
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const SelectDispoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const ScrollSegment = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
`;

const GuestRowDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
`;

export { EditEventDetailsV2 };
