import React, { useMemo, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import { caret_down, xIcon } from "src/images/NewDesign";
import { theme } from "src/utils/theme";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { useClickOutside } from "src/utils/hooks";
import { AppText } from "src/Components/UI";

const removeIconStyle = { cursor: "pointer" };

interface DynamicDropdownProps<T> {
  applyChanges?: () => void;
  children: React.ReactNode | React.ReactNode[];
  icon?: any;
  isOpen?: boolean;
  label: string;
  onClear?: () => void;
  onClose?: () => void;
  onRemove?: () => void;
  renderHeader: React.ReactNode | ((value: T) => React.ReactNode);
  setIsOpen?: (isOpen: boolean) => void;
  value: T | T[];
}

export const DropdownComparisonBadge = ({ value }: { value: string }) => (
  <ComparisonBadgeWrapper>
    <AppText fontSize={12} color={theme.text.neutral.primary}>
      {value}
    </AppText>
    <PhoenixIcon svg={caret_down} color={theme.PRIMARY500} hoverColor={theme.PRIMARY600} size={16} fillIcon />
  </ComparisonBadgeWrapper>
);

const DynamicDropdown = <T,>({
  children,
  icon = caret_down,
  label,
  onClear,
  onClose,
  isOpen: controlledIsOpen,
  onRemove,
  setIsOpen: setControlledIsOpen,
  renderHeader,
  value,
}: DynamicDropdownProps<T>) => {
  const wrapperRef = useRef(null);
  const [localIsOpen, setLocalIsOpen] = useState(false);

  const isOpen = controlledIsOpen ?? localIsOpen;
  const setIsOpen = setControlledIsOpen ?? setLocalIsOpen;

  const handleClickOutside = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      onClose?.();
    }
  }, [isOpen, setIsOpen, onClose]);

  useClickOutside(wrapperRef, handleClickOutside);

  const toggleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
    if (isOpen) {
      onClose?.();
    }
  }, [isOpen, setIsOpen, onClose]);

  const hasValue = useMemo(() => {
    return Array.isArray(value) ? value.length > 0 : !!value;
  }, [value]);

  const renderHeaderValue = useMemo(() => {
    return hasValue ? (
      <>
        <AppText fontSize={12} fontWeight={500} color={theme.PRIMARY500}>
          {label}
          {renderHeader ? ":" : ""}
        </AppText>
        {renderHeader}
      </>
    ) : (
      <AppText fontSize={12}>{label}</AppText>
    );
  }, [hasValue, renderHeader, value, label]);

  return (
    <DropdownWrapper ref={wrapperRef}>
      <DropdownHeader onClick={toggleDropdown}>
        <HeaderValue>{renderHeaderValue}</HeaderValue>
        {onRemove ? (
          <PhoenixIcon
            color={theme.PRIMARY500}
            fillIcon
            hoverColor={theme.PRIMARY600}
            onClick={(e) => {
              e.stopPropagation();
              onRemove?.();
            }}
            size={16}
            style={removeIconStyle}
            svg={xIcon}
          />
        ) : (
          <PhoenixIcon svg={icon} color={theme.PRIMARY500} hoverColor={theme.PRIMARY600} size={16} fillIcon />
        )}
      </DropdownHeader>
      {isOpen && (
        <DropdownMenu>
          <DropdownMenuContainer>{children}</DropdownMenuContainer>
          <DropdownFooter>
            <PhoenixAppButton disabled={!hasValue} onClick={onClear} buttonType="ghost-small" variant="danger-outline">
              Clear selection
            </PhoenixAppButton>

            <PhoenixAppButton onClick={handleClickOutside} buttonType="ghost-small">
              Apply
            </PhoenixAppButton>
          </DropdownFooter>
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default DynamicDropdown;

const ComparisonBadgeWrapper = styled.div`
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 500;
  gap: 8px;
  padding: 2px 8px;
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownHeader = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 4px 8px;
  height: 32px;
`;

const DropdownMenu = styled.div`
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: 350px;
  overflow: hidden;
  position: absolute;
  top: calc(100% + 8px);
  width: clamp(380px, 100vw, 100%);
  z-index: 3;

  animation: ${theme.lightPopup} 0.2s ease-in-out;
`;

const DropdownMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  height: 100%;
  overflow-y: auto;
`;

export const DropdownOptionList = styled.ul`
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
`;

export const DropdownOptionItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  gap: 8px;
  padding: 10px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const DropdownFooter = styled.div<{ disabled?: boolean }>`
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #ccc;
  color: red;
  cursor: pointer;
  display: flex;
  font-size: 10px;
  font-weight: semibold;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
`;

const HeaderValue = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const DropdownMoreBadge = styled.div`
  background-color: ${theme.PRIMARY500};
  border-radius: 4px;
  padding: 2px 4px;
`;
