import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { AppText, NewAppButton } from "../UI";
import { PhoenixAppButton, PhoenixTextArea } from "../UI/Phoenix";
import { Modal } from "./Modal";

interface DisappearingDivProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  saveNewNote?: any;
  addNote: boolean;
  timeText?: string;
  onDelete?: any;
  noteText?: string;
  updateNoteFunction?: (noteText: string) => void;
}

const UpdateCallNotesPopup: React.FC<DisappearingDivProps> = ({
  blinds,
  setBlinds,
  saveNewNote,
  addNote,
  timeText,
  onDelete,
  noteText,
  updateNoteFunction,
}) => {
  const [deleteNote, setDeleteNote] = useState<boolean>(false);

  const [temporaryNotes, setTemporaryNotes] = useState<string>(!!noteText ? noteText : "");

  return (
    <Modal open={blinds} onClose={() => setBlinds(false)} bigCloseButton>
      <>
        {deleteNote ? (
          <ContentDiv>
            <DeleteDiv>
              <PopupText1 style={{ textAlign: "center" }}>
                Are you sure you want <br /> to delete this note?
              </PopupText1>
              <ButtonsDiv>
                <PhoenixAppButton buttonType="secondary" variant="danger-outline" onClick={onDelete}>
                  Yes, Delete
                </PhoenixAppButton>
                <PhoenixAppButton variant="brand-outline" buttonType="secondary" onClick={() => setDeleteNote(false)}>
                  No, Go Back
                </PhoenixAppButton>
              </ButtonsDiv>
            </DeleteDiv>
          </ContentDiv>
        ) : (
          <ContentDiv>
            <PopupText1 style={{ marginBottom: !!timeText ? "0px" : "34px" }}>
              {addNote ? "Add a call note" : "Edit call note"}
            </PopupText1>
            {!!timeText && <PopupText2>{timeText}</PopupText2>}
            <PhoenixTextArea
              placeholder="Type your notes here..."
              value={temporaryNotes}
              onChange={(e: any) => setTemporaryNotes(e.target.value)}
              fixedSize
              width={580}
              height={477}
            />

            <ButtonsDiv>
              {!addNote ? (
                <PhoenixAppButton
                  variant="danger-outline"
                  buttonType="secondary"
                  onClick={() => setDeleteNote(true)}
                  uppercase
                >
                  Delete
                </PhoenixAppButton>
              ) : (
                <div />
              )}
              <PhoenixAppButton
                variant={"brand"}
                buttonType="secondary"
                uppercase
                disabled={noteText === temporaryNotes || (addNote && temporaryNotes.trim() === "")}
                onClick={async () => {
                  if (addNote) {
                    !!saveNewNote && (await saveNewNote(temporaryNotes));
                    setBlinds(false);
                  } else {
                    !!updateNoteFunction && (await updateNoteFunction(temporaryNotes));
                    setBlinds(false);
                  }
                }}
              >
                Save
              </PhoenixAppButton>
            </ButtonsDiv>
          </ContentDiv>
        )}
      </>
    </Modal>
  );
};

const DeleteDiv = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 60px;
  justify-content: center;
  margin: 80px 0px 50px 0px;
`;

const ButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const PopupText2 = styled(AppText)`
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  margin-top: 9px;
  margin-bottom: 34px;
`;

const PopupText1 = styled(AppText)`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #34363a;
`;

const ContentDiv = styled.div`
  margin: 41px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 580px;
  height: 477px;
  max-height: 70vh;
  max-width: 70vw;
  overflow: auto;
  gap: 16px;
`;

export { UpdateCallNotesPopup };
