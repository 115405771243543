import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { IoIosArrowUp, IoMdClose } from "react-icons/io";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { loggedInUser } from "../../../apollo/cache";
import { iconCalendarNew } from "../../../images";
import caretIcon from "../../../images/ic-nav-forward.svg";
import { sanitizeURL } from "../../../utils/misc";
import "../../../utils/react-big-calendar.css";
import { theme } from "../../../utils/theme";
import { ScheduleTaskItem } from "../../Smart/ScheduleTaskItem";
import { AppButton, AppErrorText, AppText, Loading } from "../../UI";
import { MONTHLY_SCHEDULE } from "../CallSegments/";

interface MyPriortCallNotesProps {
  blinds: boolean;
  setBlinds: any;
}

export const FETCH_SCHEDULED_LEAD_ITEMS = gql`
  query fetchScheduleItems($day: DateTime) {
    fetchScheduleItems(day: $day) {
      id
      start_time
      end_time
      lead_intent {
        associated_action
        lead {
          id
          first_name
          last_name
          business_name
        }
      }
    }
  }
`;

const ScheduleTasksComponent: React.FC<MyPriortCallNotesProps> = ({ blinds, setBlinds }) => {
  const [currentData, setCurrentData] = useState({ id: "", time: "", lead_id: "" });
  const [modalOn, setModalOn] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [calendarMonth, setCalendarMonth] = useState(moment().format("YYYY-MM"));
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [expandedState, setExpandedState] = useState(false);

  const { data, loading, error, refetch } = useQuery(FETCH_SCHEDULED_LEAD_ITEMS, {
    variables: { day: selectedDate },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataCal, loading: loadingCal, error: errorCal, refetch: refetchCal } = useQuery(MONTHLY_SCHEDULE, {
    variables: { yearmonth: calendarMonth },
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const localizer = momentLocalizer(moment);

  const monthlyEvents = dataCal?.fetchScheduleItemsMonth?.map((item: any) => ({
    start: moment(item, "YYYY-MM-DD").format(),
    end: moment(item, "YYYY-MM-DD").add(5, "minutes").format(),
    title: " ",
    color: theme.PRIMARY500,
  }));

  function eventStyleGetter(event: any, start: any, end: any, isSelected: any) {
    const style = {
      backgroundColor: event.color,
      borderRadius: "50%",
      color: theme.WHITE_COLOR,
      border: "0px",
      display: "block",
      maxHeight: "14px",
      minHeight: "14px",
      maxWidth: "14px",
      margin: "auto auto",
      paddin: "0px",
      fontSize: "11px",
      lineHeight: "12px",
    };
    return {
      style: style,
    };
  }

  const history = useHistory();

  return (
    <MyScheduleDiv>
      <>
        {expandedState ? (
          <MaximizedScheduleDiv>
            <LeadHistoryDiv>
              <MinimizeDiv onClick={() => setExpandedState(false)}>
                <MyScheduleTitle>My Schedule</MyScheduleTitle>
                <IoIosArrowUp height={30} style={{ transform: "rotate(180deg)", margin: "2px 8px" }} />
              </MinimizeDiv>
              <ControlsDiv>
                {selectedDate !== moment().format("YYYY-MM-DD") && (
                  <MyScheduleTodayButton onClick={() => setSelectedDate(moment().format("YYYY-MM-DD"))}>
                    Today
                  </MyScheduleTodayButton>
                )}

                <img
                  src={caretIcon}
                  height={24}
                  alt="Calendar icon"
                  style={{ cursor: "pointer", transform: "rotate(-180deg)", margin: "0 8px" }}
                  onClick={() => {
                    setSelectedDate(moment(selectedDate).subtract(1, "d").format("YYYY-MM-DD"));
                  }}
                />
                <img
                  height={24}
                  src={caretIcon}
                  alt="Calendar icon"
                  style={{ cursor: "pointer", marginRight: "8px" }}
                  onClick={() => {
                    setSelectedDate(moment(selectedDate).add(1, "d").format("YYYY-MM-DD"));
                  }}
                />
                <img
                  src={iconCalendarNew}
                  alt="Calendar icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowDatePicker(!showDatePicker);
                    setModalOn(false);
                  }}
                />
              </ControlsDiv>
            </LeadHistoryDiv>
            <MyScheduleSubheading>
              <MyScheduleSubtitle>
                {moment(selectedDate, "YYYY-MM-DD").calendar(null, {
                  sameDay: "[Today]",
                  nextDay: "[Tomorrow]",
                  nextWeek: "dddd",
                  lastDay: "[Yesterday]",
                  lastWeek: "[Last] dddd",
                  sameElse: "MM/DD/YYYY",
                })}
              </MyScheduleSubtitle>
            </MyScheduleSubheading>
            {loading ? (
              <Loading />
            ) : error ? (
              <AppErrorText>Error fetching scheduled items!</AppErrorText>
            ) : data.fetchScheduleItems.length === 0 ? (
              <AppText fontSize={13} style={{ margin: "10px auto" }}>
                There are no Scheduled Events on this day.
              </AppText>
            ) : (
              <ScrollDiv>
                {data &&
                  data.fetchScheduleItems &&
                  data.fetchScheduleItems
                    .slice()
                    .sort((a: any, b: any) => {
                      if (moment(a.start_time).isBefore(moment(b.start_time))) return -1;
                      if (moment(a.start_time).isAfter(moment(b.start_time))) return 1;
                    })
                    ?.map((item: any, index: number) => (
                      <ScheduleTaskItem
                        key={index}
                        onClick={() => {
                          const santizedID = sanitizeURL(item.id);

                          history.push(
                            loggedInUser()?.role === "ADMIN" || loggedInUser()?.role === "SM"
                              ? `/event-manager/${santizedID}`
                              : `/manage-schedule/${santizedID}`,
                          );
                        }}
                        associated_action={item.lead_intent?.associated_action ?? ""}
                        first_name={item.lead_intent?.lead?.first_name ?? ""}
                        last_name={item.lead_intent?.lead?.last_name ?? ""}
                        start_time={item.start_time}
                        end_time={item.end_time}
                      />
                    ))}
              </ScrollDiv>
            )}
          </MaximizedScheduleDiv>
        ) : (
          <MinimizedScheduleDiv onClick={() => setExpandedState(true)}>
            <MyScheduleTitle>My Schedule</MyScheduleTitle> <IoIosArrowUp height={35} />
          </MinimizedScheduleDiv>
        )}

        {showDatePicker && (
          <CalendarDiv>
            <Calendar
              selectable={true}
              localizer={localizer}
              onDrillDown={(e) => {
                setSelectedDate(moment(e).format("YYYY-MM-DD"));
              }}
              events={!!monthlyEvents ? monthlyEvents : []}
              defaultDate={!!calendarMonth ? moment(calendarMonth, "YYYY-MM").toDate() : undefined}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 300, width: 400 }}
              views={["month"]}
              defaultView={"month"}
              onSelectEvent={({ start, end }) => {
                console.log("cal: ", start, end);
                setSelectedDate(moment(start).format("YYYY-MM-DD"));
                setCalendarMonth(moment(start).format("YYYY-MM"));
                setShowDatePicker(false);
              }}
              onSelectSlot={({ start, end, action }) => console.log("Click: ", moment(start))}
              eventPropGetter={eventStyleGetter}
              onNavigate={(e) => {
                setCalendarMonth(moment(e).format("YYYY-MM"));
              }}
            />
            <CloseButton
              onClick={() => {
                setShowDatePicker(false);
              }}
            >
              <IoMdClose size={22} color={theme.PRIMARY500} />
            </CloseButton>

            {loadingCal && (
              <CalendarLoadingDiv>
                <Loading />
              </CalendarLoadingDiv>
            )}
          </CalendarDiv>
        )}
      </>
    </MyScheduleDiv>
  );
};

const CalendarLoadingDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 49px;
  bottom: 0px;
  left: 0px;

  width: 100%;
  height: calc(100% - 49px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: wait;
  z-index: 8;
`;

const MinimizeDiv = styled.div`
  display: flex;
  cursor: pointer;
`;

const MinimizedScheduleDiv = styled.div`
  position: fixed;
  bottom: 0px;
  left: 85px;
  right: 10px;
  width: 215px;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 6px;
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 13px 18px 16px 16px;
  box-shadow: -1px -1px 4px 2px rgba(0, 0, 0, 0.14);
  background-color: ${theme.WHITE_COLOR};
  z-index: 3;
`;

const ScrollDiv = styled.div`
  overflow-y: auto;
  max-height: 70vh;
`;

const MaximizedScheduleDiv = styled(MinimizedScheduleDiv)`
  width: 438px;
  flex-direction: column;
  height: fit-content;
  overflow: auto;
  cursor: auto;
`;

const AbsoluteDiv = styled.div`
  position: fixed;
  left: 490px;
  bottom: 650px;
  z-index: 9;
`;
const AddLeadIcon = styled.div`
  right: 10px;
  position: relative;
  display: flex;
  place-items: center;
`;
const AddNewLead = styled(AppButton)`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CalendarDiv = styled.div`
  position: fixed;
  left: 450px;
  bottom: 150px;
  z-index: 5;
  background-color: ${theme.WHITE_COLOR};
`;

const CloseButton = styled.div`
  position: absolute;
  height: 28px;
  width: 28px;
  right: -14px;
  top: -14px;
  background: ${theme.NEUTRAL100};
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
  z-index: 8;
`;

const ControlsDiv = styled.div`
  display: flex;
  align-items: center;
`;

const MyScheduleTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  :hover {
    cursor: pointer;
  }
`;

const LeadHistoryDiv = styled.div`
  height: 50px;
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MyScheduleSubtitle = styled.div`
  color: ${theme.PRIMARY500};
  background: ${theme.NEUTRAL100};
  text-transform: uppercase;
  width: auto;
  padding: 0px 10px;
  height: 24px;
  border-radius: 12px;
  margin: 6px solid ${theme.WHITE_COLOR};
  z-index: 2;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

const MyScheduleTodayButton = styled.div`
  color: ${theme.BLACK_COLOR};
  background: ${theme.NEUTRAL100};
  text-transform: uppercase;
  width: auto;
  padding: 0px 10px;
  height: 24px;
  margin: 6px solid ${theme.WHITE_COLOR};
  z-index: 2;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
`;

const MyScheduleSubheading = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :before {
    content: "";
    position: absolute;
    top: 12px;
    right: 0;
    width: calc(100%);
    height: 2px;
    background-color: ${theme.NEUTRAL100};
  }
`;

const MyScheduleDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const NextDayButton = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: solid 1px #efefef;
  background-color: #f8f9fb;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { ScheduleTasksComponent };
