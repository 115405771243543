import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { AppText, AppTextArea, AppErrorText, Loading, FlexDiv } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { theme } from "../../utils/theme";
import moment from "moment-timezone";
import { useQuery, gql } from "@apollo/client";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useContext, useMemo, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { MONTHLY_SCHEDULE } from "../Segments/CallSegments";
import { iconCalendarNew } from "../../images";
import { ScheduleContext, CallContext, ModalContext } from "../../context/";
import { formatUSD, formatTypeName } from "../../utils/format";
import { LeadCardV2 } from "../Segments/DashboardSideBarSegments";
import { ReassignLeadModalComponent, SelectScheduledEventModal } from "../modal";
import { FETCH_CURRENT_LEAD_POLL_INTERVAL, FETCH_MY_SCHEDULE_POLL_INTERVAL } from "../../utils/variables";
import { FETCH_GENERAL_CALLBACKS } from "../Pages";
import { MixpanelActions } from "../../services/mixpanel";
import { CORE_LEAD_CARD_DATA } from "./../../apollo/fragments";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PhoenixIcon } from "../UI/Phoenix";
import { arrow_left, arrow_right, calendar } from "../../images/NewDesign";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";

export const FETCH_MY_SCHEDULE = gql`
  query fetchMySchedule($date: DateTime!) {
    fetchMySchedule(date: $date) {
      general_call_count
      events {
        id
        type
        label
        lead_value
        start
        end
      }
    }
  }
`;
export const FETCH_GENERAL_CALLBACKS_DAY = gql`
  query fetchGeneralCallbacksForSpecificDate($date: DateTime!) {
    fetchGeneralCallbacksForSpecificDate(date: $date) {
      start
      end
      intent {
        id
        type
        lead {
          id
          full_name
          business_name
          lead_value {
            value
          }
          rep_id
        }
      }
    }
  }
`;

const FETCH_SCHEDULE_ITEM = gql`
  ${CORE_LEAD_CARD_DATA}
  query scheduleItem($id: String!) {
    scheduleItem(where: { id: $id }) {
      id
      action_label
      start_time
      end_time
      lead_intent {
        id
        type
        label
        associated_action
        lead {
          ...CoreLeadCardData
          id

          full_name
          business_name
          primary_email
          primary_phone_number
          first_name
          last_name
          industry
          sub_industry
          lead_source
          city
          state
          country
          timezone_by_state
          current_lead_type
          rep_id
          lead_value {
            value
          }
          ...CoreLeadCardData
        }
        user {
          id
          full_name
          team_name
          role
        }
      }
    }
  }
`;

function eventStyleGetter(event: any, start: any, end: any, isSelected: any) {
  const style = {
    backgroundColor: event.color,
    borderRadius: "50%",
    color: theme.WHITE_COLOR,
    border: "0px",
    display: "block",
    maxHeight: "14px",
    minHeight: "14px",
    maxWidth: "14px",
    margin: "auto auto",
    paddin: "0px",
    fontSize: "11px",
    lineHeight: "12px",
  };
  return {
    style: style,
  };
}

const GeneralEventsModal = () => {
  const { myScheduleDate } = useContext(ScheduleContext);
  const { data: generalCallBackData, loading: generalCallbackLoading, error: generalCallbackError } = useQuery(
    FETCH_GENERAL_CALLBACKS_DAY,
    {
      fetchPolicy: "network-only",
      variables: {
        date: myScheduleDate,
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const myScheduleUnderscoreDate = useMemo(() => {
    return moment(myScheduleDate, "YYYY-MM-DD").format("MM-DD-YYYY").replace(/-/g, "_");
  }, [myScheduleDate]);

  if (generalCallbackLoading || generalCallbackError) return <></>;

  const restructuredEvents = generalCallBackData.fetchGeneralCallbacksForSpecificDate.length
    ? generalCallBackData.fetchGeneralCallbacksForSpecificDate?.map((item: any) => ({
        id: item?.intent?.id,
        lead_id: item?.intent?.lead?.id,
        type: item.intent.type,
        schedule_item: {
          start_time: item.start,
          end_time: item.end,
        },
        lead: {
          id: item?.intent?.lead?.id,
          full_name: item.intent.lead.full_name,
          business_name: item.intent.lead.business_name,
          lead_value: {
            value: item.intent.lead.lead_value.value,
          },
        },
      }))
    : [];

  // const eventsOnDay = generalCallBackData.fetchGeneralCallBacks
  //   .slice()
  // .filter((item: any) => item[myScheduleDate.toString().replaceAll("-", "_")]);business_name

  // return <>{JSON.stringify(eventsOnDay)}</>;
  const sortedRestructuredEvents = restructuredEvents.sort((a: any, b: any) => {
    return moment(a.schedule_item.start_time).diff(moment(b.schedule_item.start_time));
  });

  return restructuredEvents.length > 0 ? (
    <SelectScheduledEventModal generalTime events={sortedRestructuredEvents} />
  ) : (
    <></>
  );
};

const MyScheduleCal: React.FC = ({ ...props }) => {
  const [selectedMonth, setSelectedMonth] = useState(moment().format("YYYY-MM"));
  const [selectedMyScheduleEventId, setSelectedMyScheduleEventId] = useState("");

  const {
    showDatePicker,
    setShowDatePicker,
    myScheduleDate,
    setMyScheduleDate,
    // selectedMyScheduleEventId,
    // setSelectedMyScheduleEventId,
  } = useContext(ScheduleContext);

  const {
    reassignLeadModal,
    setReassignLeadModal,
    selectedLeadToReassign,
    setSelectedLeadToReassign,
    generalTimesEventsModal,
    setGeneralTimesEventsModal,
  } = useContext(ModalContext);

  const { data: dataEvents, loading: loadingEvents, error: errorEvents, refetch: refetchEvents } = useQuery(
    FETCH_MY_SCHEDULE,
    {
      variables: {
        date: moment.utc(myScheduleDate, "YYYY-MM-DD").endOf("d").toDate(),
      },
      fetchPolicy: "network-only",
      pollInterval: FETCH_MY_SCHEDULE_POLL_INTERVAL,
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataCal, loading: loadingCal, error: errorCal, refetch: refetchCal } = useQuery(MONTHLY_SCHEDULE, {
    variables: { yearmonth: selectedMonth },
    fetchPolicy: "no-cache",
  });

  const { data: dataEvent, loading: loadingEvent, error: errorEvent, refetch: refetchEvent } = useQuery(
    FETCH_SCHEDULE_ITEM,
    {
      fetchPolicy: "network-only",
      skip: !selectedMyScheduleEventId,
      variables: {
        id: selectedMyScheduleEventId,
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const monthlyEvents = dataCal?.fetchScheduleItemsMonth?.map((item: any) => ({
    start: moment(item, "YYYY-MM-DD").format(),
    end: moment(item, "YYYY-MM-DD").add(5, "minutes").format(),
    title: " ",
    color: theme.PRIMARY500,
  }));

  // render() {
  const localizer = momentLocalizer(moment);

  const calendarData = [
    { title: "one", start: moment().toDate(), end: moment().subtract(1, "hour").toDate() },
    { title: "two", start: moment().subtract(1, "hour").toDate(), end: moment().subtract(2, "hour").toDate() },
    { title: "three", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(2.5, "hour").toDate() },
    { title: "four", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "5", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "6", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "7", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "8", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "9", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "7", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "12", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "14", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "2435", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "265", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
    { title: "64", start: moment().subtract(2, "hour").toDate(), end: moment().subtract(3, "hours").toDate() },
  ];

  const calData = useMemo(() => {
    return loadingEvents || errorEvents || !dataEvents
      ? []
      : dataEvents.fetchMySchedule.events
          ?.map((item: any) => ({
            event_id: item.id,
            title: item.label,
            start: moment(moment.unix(Math.floor(Number(item.start) / 1000))).toDate(),
            end: moment(moment.unix(Math.floor(Number(item.end) / 1000))).toDate(),
            // start: new Date(moment(moment.unix(Math.floor(Number(item.start / 1000)))).toDate()),
            // end: new Date(moment(moment.unix(Math.floor(Number(item.end / 1000)))).toDate()),
            lead_value: `${item.lead_value}`,
            type: item.type,
          }))
          ?.concat({
            title: `${dataEvents.fetchMySchedule.general_call_count} General Callbacks`,
            start: moment(myScheduleDate).toDate(),
            end: moment(myScheduleDate).toDate(),
            allDay: true,
          });
  }, [dataEvents]);

  // const maxHour = useMemo(() => {
  //   return !!dataEvents?.fetchMySchedule?.events?.length
  //     ? moment(myScheduleDate)
  //         .hour(Math.max(...dataEvents.fetchMySchedule.events?.map((item: any) => Number(moment(item.end).hour()))))
  //         .add(6, "hour")
  //         .toDate()
  //     : moment().minute(0).add(6, "hour").toDate();
  // }, [
  //   !!dataEvents && !!dataEvents.fetchMySchedule && !!dataEvents.fetchMySchedule.events && !loadingEvents,
  //   myScheduleDate,
  // ]);

  // const minHour = useMemo(() => {
  //   return !!dataEvents?.fetchMySchedule?.events?.length && myScheduleDate !== moment().format("YYYY-MM-DD")
  //     ? moment(myScheduleDate)
  //         .hour(Math.min(...dataEvents.fetchMySchedule.events?.map((item: any) => Number(moment(item.start).hour()))))
  //         .toDate()
  //     : moment().startOf("hour").toDate();
  //   // : moment().minute(0).subtract(1, "hour").toDate();
  // }, [
  //   !!dataEvents && !!dataEvents.fetchMySchedule && !!dataEvents.fetchMySchedule?.events && !loadingEvents,
  //   myScheduleDate,
  // ]);

  return (
    <CalContainer tall={!!selectedMyScheduleEventId} curTime={moment().format("h:mm A")}>
      {reassignLeadModal && !!selectedLeadToReassign.lead_id && !!selectedLeadToReassign.source_user_id && (
        <ReassignLeadModalComponent repVersion />
      )}
      {generalTimesEventsModal && <GeneralEventsModal />}
      {/* <button onClick={() => setGeneralTimesEventsModal(true)}>GEN TIMES</button> */}
      {!!selectedMyScheduleEventId ? (
        <div style={{ marginTop: "0px" }}>
          <LogNonCallDiv
            style={{
              borderTop: "none",
              borderBottom: `1px solid ${theme.NEUTRAL200}`,
            }}
          >
            {/* <NewAppButton small borderless width={50} height={28} onClick={() => setSelectedMyScheduleEventId("")}> */}
            <NewAppButton width={50} height={28} onClick={() => setSelectedMyScheduleEventId("")}>
              Back
            </NewAppButton>
            <AppText>{moment(myScheduleDate, "YYYY-MM-DD").format("dddd MMM D")}</AppText>
          </LogNonCallDiv>
          {!!dataEvent?.scheduleItem?.id ? (
            <>
              <LeadCardV2
                isInMyScheduleComponent
                scheduledEvent={{
                  start_time: dataEvent.scheduleItem.start_time,
                  end_time: dataEvent.scheduleItem.end_time,
                  event_type: dataEvent.scheduleItem.action_label,
                  event_status: "",
                }}
                intent={dataEvent.scheduleItem?.lead_intent}
                leadData={dataEvent.scheduleItem?.lead_intent?.lead}
              />
              <LogNonCallDiv style={{ padding: "24px" }}>
                <a href={`/manage-schedule/${selectedMyScheduleEventId}`} target="_blank">
                  <NewAppButton
                    width={200}
                    height={48}
                    variant="secondary"
                    onClick={() => {
                      MixpanelActions.track("Schedule Event", {
                        type: "Reschedule",
                        tab: "Calendar",
                        view: "Dashboard",
                      });
                    }}
                  >
                    Edit Event
                  </NewAppButton>
                </a>
                <NewAppButton
                  height={48}
                  width={200}
                  variant="secondary"
                  onClick={() => {
                    MixpanelActions.track("Schedule Event", {
                      type: "Reassign",
                      tab: "Calendar",
                      view: "Dashboard",
                    });
                    setSelectedLeadToReassign({
                      lead_id: dataEvent?.scheduleItem?.lead_intent?.lead?.id,
                      source_user_id: dataEvent?.scheduleItem?.lead_intent?.user?.id,
                      lead_id_list: [],
                    });
                    setReassignLeadModal(!reassignLeadModal);
                    // alert(
                    //   JSON.stringify({
                    //     lead_id: dataEvent.scheduleItem.lead_intent.lead.id,
                    //     source_user_id: dataEvent.scheduleItem.lead_intent.user.id,
                    //   }),
                    // );
                  }}
                >
                  Reassign Lead
                </NewAppButton>
              </LogNonCallDiv>
            </>
          ) : (
            <Loading />
          )}
        </div>
      ) : (
        <>
          {/* <p>
            {calData.slice().filter((item: any) => !item.allDay)?.length}{" "}
            {`${calData.slice().filter((item: any) => !item.allDay)?.[0]?.start}`}
          </p> */}
          <Calendar
            className="myschedule"
            components={{ toolbar: CalendarToolbar, event: EventComponent }}
            // popup={true}
            selectable={true}
            localizer={localizer}
            events={calData}
            date={moment(myScheduleDate, "YYYY-MM-DD").toDate()}
            // eventPropGetter={eventStyleGetter}
            views={["day"]}
            defaultView={"day"}
            // min={minHour}
            // min={moment().startOf("hour").toDate()}
            scrollToTime={moment().startOf("hour").subtract(1, "hour").toDate()}
            max={moment(myScheduleDate, "YYYY-MM-DD").hour(23).toDate()}
            dayLayoutAlgorithm="overlap"
            // startAccessor="start"
            // endAccessor="end"
            // step={5}
            // timeslots={9}
            // onSelectSlot={(e) => handleNewTimeoffClick(moment(e.start).toDate(), moment(e.end).toDate())}
            onSelectEvent={(e: any) => {
              if (!!e.allDay) {
                MixpanelActions.track("Schedule Event", {
                  type: "Event Selection",
                  selection: "General",
                  tab: "Calendar",
                  view: "Dashboard",
                });
                setGeneralTimesEventsModal(true);
              }
              if (!!e.event_id) {
                MixpanelActions.track("Schedule Event", {
                  type: "Event Selection",
                  selection: "Event",
                  tab: "Calendar",
                  view: "Dashboard",
                });
                setSelectedMyScheduleEventId(e.event_id);
              }
              // alert(JSON.stringify(e));
            }}
          />
          {showDatePicker && (
            <CalendarDiv>
              <Calendar
                selectable={true}
                localizer={localizer}
                onDrillDown={(e) => {
                  setMyScheduleDate(moment(e).format("YYYY-MM-DD"));
                }}
                events={!!monthlyEvents ? monthlyEvents : []}
                defaultDate={!!selectedMonth ? moment(selectedMonth, "YYYY-MM").toDate() : undefined}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 300, width: 400 }}
                views={["month"]}
                defaultView={"month"}
                onSelectEvent={({ start, end }) => {
                  console.log("cal: ", start, end);
                  setMyScheduleDate(moment(start).format("YYYY-MM-DD"));
                  setSelectedMonth(moment(start).format("YYYY-MM"));
                  setShowDatePicker(false);
                }}
                onSelectSlot={({ start, end, action }) => console.log("Click: ", moment(start))}
                eventPropGetter={eventStyleGetter}
                onNavigate={(e) => {
                  setSelectedMonth(moment(e).format("YYYY-MM"));
                }}
              />
              <CloseButton
                onClick={() => {
                  setShowDatePicker(false);
                }}
              >
                <IoMdClose size={22} color={theme.PRIMARY500} />
              </CloseButton>

              {loadingCal && (
                <CalendarLoadingDiv>
                  <Loading />
                </CalendarLoadingDiv>
              )}
            </CalendarDiv>
          )}
        </>
      )}
    </CalContainer>
  );
};

const EventComponent = (event: any, styles: any) => (
  <EventDetailsDiv>
    <FlexBetweenDiv>
      <FlexDiv gap={8} className={styles.eventName}>
        <div className={styles.eventName} style={{ width: "fit-content", maxWidth: "110px" }}>
          <EventText>{event?.event?.title || ""}</EventText>
        </div>
      </FlexDiv>
      <FlexDiv className={styles.eventName} style={{ width: "fit-content", maxWidth: "60%" }}>
        {/* <EventTextClip>{!!event.event.lead_value ? `PDV ${formatUSD(event.event.lead_value)}` : ""}</EventTextClip> */}
        <EventTextClip>{formatTypeName(event?.event?.type || "")}</EventTextClip>
      </FlexDiv>
    </FlexBetweenDiv>
  </EventDetailsDiv>
);

const CalendarToolbar: React.FC = (props: any) => {
  const { showDatePicker, setShowDatePicker, myScheduleDate, setMyScheduleDate } = useContext(ScheduleContext);

  const goBackMonth = () => {
    MixpanelActions.track("Schedule Event", {
      type: "Date Selection",
      selection: "Back",
      tab: "Calendar",
      view: "Dashboard",
    });
    props.onNavigate("PREV");
    setMyScheduleDate(moment(myScheduleDate, "YYYY-MM-DD").subtract(1, "day").format("YYYY-MM-DD"));
  };

  const goForwardMonth = () => {
    MixpanelActions.track("Schedule Event", {
      type: "Date Selection",
      selection: "Next",
      tab: "Calendar",
      view: "Dashboard",
    });
    props.onNavigate("NEXT");
    setMyScheduleDate(moment(myScheduleDate, "YYYY-MM-DD").add(1, "day").format("YYYY-MM-DD"));
  };

  const goToday = () => {
    MixpanelActions.track("Schedule Event", {
      type: "Date Selection",
      selection: "Today",
      tab: "Calendar",
      view: "Dashboard",
    });
    props.onNavigate("TODAY");
    setMyScheduleDate(moment().format("YYYY-MM-DD"));
  };

  const changeTitleFormat = (view: string, date: Date) => {
    if (view === "day") return moment(date).format("MMMM Do[,] YYYY");
    else if (view === "week") {
      return `${moment(date).weekday(0).format("MM/DD")} - ${moment(date).weekday(6).format("MM/DD")}`;
    } else if (view === "month") return moment(date).format("MMMM YYYY");
    else return moment(date).format("MMMM YYYY");
  };

  return (
    <RelDiv>
      <ToolbarContainer className="rbc-toolbar">
        <FlexDiv align="center" gap={8}>
          <PhoenixIcon
            svg={calendar}
            style={{ cursor: "pointer" }}
            size="small"
            onClick={() => {
              MixpanelActions.track("Schedule Event", {
                type: "Date Picker",
                tab: "Calendar",
                view: "Dashboard",
              });
              setShowDatePicker(!showDatePicker);
            }}
          />
          <AppText fontSize={12} style={{ lineHeight: "18px" }}>
            {changeTitleFormat(props.view, props.date)}
          </AppText>
        </FlexDiv>
        <FlexDiv align="center" gap={16}>
          {/* <button onClick={goToday}>Today</button>
          <button onClick={goBackMonth}>Back</button>
          <button onClick={goForwardMonth}>Next</button> */}
          <AppText
            fontSize={10}
            fontWeight={600}
            variant="primary"
            style={{
              cursor: "pointer",
              letterSpacing: "1px",
              textTransform: "uppercase",
              borderBottom: "none",
            }}
            onClick={goToday}
          >
            Today
          </AppText>
          <FlexDiv align="center" gap={8}>
            <PhoenixIcon svg={arrow_left} size="small" style={{ cursor: "pointer" }} onClick={goBackMonth} />
            <PhoenixIcon svg={arrow_right} size="small" style={{ cursor: "pointer" }} onClick={goForwardMonth} />
          </FlexDiv>
        </FlexDiv>
      </ToolbarContainer>
    </RelDiv>
  );
};

const LogNonCallDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 16px; */
  padding: 16px 24px;
  border-top: 1px solid ${theme.NEUTRAL200};
  grid-gap: 12px;
`;

const EventText = styled(AppText)`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${theme.PRIMARY500};
  max-width: 100%;
`;
const EventTextClip = styled(AppText)`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${theme.PRIMARY500};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EventDetailsDiv = styled.div`
  height: 100%;
  padding-bottom: 2px;
`;

const FlexBetweenDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 8px;
  justify-content: space-between;
`;

const CalendarButton = styled(NewAppButton)``;

const RelDiv = styled.div`
  position: relative;
  width: 100%;
`;

const ToolbarContainer = styled.div`
  /* position: sticky; */
  /* top: 0px; */
  /* bottom: 70px; */
  /* height: auto;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; */

  // styled defined in '.rbc-toolbar' class
`;

const CalendarDiv = styled.div`
  position: fixed;
  left: 450px;
  bottom: 150px;
  z-index: 5;
  background-color: ${theme.WHITE_COLOR};
`;

const CalendarLoadingDiv = styled.div`
  position: absolute;
  right: 0px;
  top: 49px;
  bottom: 0px;
  left: 0px;

  width: 100%;
  height: calc(100% - 49px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: wait;
  z-index: 8;
`;

const CloseButton = styled.div`
  position: absolute;
  height: 28px;
  width: 28px;
  right: -14px;
  top: -14px;
  background: ${theme.NEUTRAL100};
  border-radius: 50%;
  cursor: pointer;
  padding: 3px;
  z-index: 8;
`;

interface DivProps {
  tall: boolean;
  curTime: string;
}

const CalContainer = styled.div<DivProps>`
  max-height: ${(props) => (props.tall ? "fit-content" : "400px")};
  min-height: 400px;
  /* overflow: auto; */
  position: relative;
  max-width: 467px;
  .myschedule {
    .rbc-btn {
      color: inherit;
      font: inherit;
      margin: 0;
    }

    button.rbc-btn {
      overflow: visible;
      text-transform: none;
      -webkit-appearance: button;
      appearance: button;
      cursor: pointer;
    }

    button[disabled].rbc-btn {
      cursor: not-allowed;
    }

    button.rbc-input::-moz-focus-inner {
      border: 0;
      padding: 0;
    }

    .rbc-calendar {
      box-sizing: border-box;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      position: relative;
    }

    .rbc-calendar *,
    .rbc-calendar *:before,
    .rbc-calendar *:after {
      box-sizing: inherit;
    }

    .rbc-abs-full,
    .rbc-row-bg {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .rbc-ellipsis,
    .rbc-event-label,
    .rbc-row-segment .rbc-event-content,
    .rbc-show-more {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .rbc-rtl {
      direction: rtl;
    }

    .rbc-off-range {
      color: #999999;
    }

    .rbc-off-range-bg {
      /* changed */
      background: #f8f8f8;
    }

    .rbc-header {
      overflow: hidden;
      flex: 1 0 0%;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
      font-weight: bold;
      font-size: 12px;
      min-height: 0;
      background-color: #dddddd;
      border-bottom: 1px solid #ddd;
    }
    .rbc-header + .rbc-header {
      border-left: 1px solid #ddd;
    }
    .rbc-rtl .rbc-header + .rbc-header {
      border-left-width: 0;
      border-right: 1px solid #ddd;
    }
    .rbc-header > a,
    .rbc-header > a:active,
    .rbc-header > a:visited {
      color: inherit;
      text-decoration: none;
    }

    .rbc-row-content {
      position: relative;
      user-select: none;
      -webkit-user-select: none;
      z-index: 4;
    }

    .rbc-today {
      background-color: #eaf6ff;
    }

    .rbc-toolbar {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      padding: 12px 16px;
      background: white;
    }
    .rbc-toolbar .rbc-toolbar-label {
      flex-grow: 1;
      padding: 0 10px;
      /* changed */
      text-align: center;
      font-size: 12px;
      color: black;
    }

    .rbc-btn-group {
      display: inline-block;
      white-space: nowrap;
    }
    .rbc-btn-group > button:first-child:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .rbc-btn-group > button:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .rbc-btn-group > button:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
    .rbc-btn-group button + button {
      margin-left: -1px;
    }
    .rbc-rtl .rbc-btn-group button + button {
      margin-left: 0;
      margin-right: -1px;
    }
    .rbc-btn-group + .rbc-btn-group,
    .rbc-btn-group + button {
      margin-left: 10px;
    }

    .rbc-event {
      border: none;
      box-sizing: border-box;
      box-shadow: none;
      margin: 0;
      padding: 2px 5px;
      background-color: ${theme.PRIMARY200};
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      width: 100%;
      text-align: left;
    }
    .rbc-slot-selecting .rbc-event {
      cursor: inherit;
      pointer-events: none;
    }
    .rbc-event.rbc-selected {
      background-color: #265985;
    }
    .rbc-event:focus {
      outline: 5px auto #3b99fc;
    }

    .rbc-event-label {
      /* Time of event here */
      font-size: 10px;
      display: none;
    }

    .rbc-event-overlaps {
      box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
    }

    .rbc-event-continues-prior {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .rbc-event-continues-after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .rbc-event-continues-earlier {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .rbc-event-continues-later {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .rbc-row {
      display: flex;
      flex-direction: row;
    }

    .rbc-row-segment {
      padding: 0 1px 1px 1px;
    }

    .rbc-selected-cell {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .rbc-show-more {
      background-color: rgba(255, 255, 255, 0.3);
      z-index: 4;
      font-weight: bold;
      font-size: 85%;
      height: auto;
      line-height: normal;
    }

    .rbc-month-view {
      position: relative;
      border: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      flex: 1 0 0;
      width: 100%;
      user-select: none;
      -webkit-user-select: none;
      height: 100%;
    }

    .rbc-month-header {
      display: flex;
      flex-direction: row;
    }

    .rbc-month-row {
      display: flex;
      position: relative;
      flex-direction: column;
      flex: 1 0 0;
      flex-basis: 0px;
      overflow: hidden;
      height: 100%;
    }
    .rbc-month-row + .rbc-month-row {
      border-top: 1px solid #ddd;
    }

    .rbc-date-cell {
      flex: 1 1 0;
      min-width: 0;
      padding-right: 5px;
      text-align: right;
    }
    .rbc-date-cell.rbc-now {
      font-weight: bold;
    }
    .rbc-date-cell > a,
    .rbc-date-cell > a:active,
    .rbc-date-cell > a:visited {
      color: inherit;
      text-decoration: none;
    }

    .rbc-row-bg {
      display: flex;
      flex-direction: row;
      flex: 1 0 0;
      overflow: hidden;
      background-color: white;
    }

    .rbc-day-bg {
      flex: 1 0 0%;
    }
    .rbc-day-bg + .rbc-day-bg {
      border-left: 1px solid #ddd;
    }
    .rbc-rtl .rbc-day-bg + .rbc-day-bg {
      border-left-width: 0;
      border-right: 1px solid #ddd;
    }

    .rbc-overlay {
      position: absolute;
      z-index: 5;
      border: 1px solid #e5e5e5;
      background-color: #fff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
      padding: 10px;
    }
    .rbc-overlay > * + * {
      margin-top: 1px;
    }

    .rbc-overlay-header {
      border-bottom: 1px solid #e5e5e5;
      margin: -10px -10px 5px -10px;
      padding: 2px 10px;
    }

    .rbc-agenda-view {
      display: flex;
      flex-direction: column;
      flex: 1 0 0;
      overflow: auto;
      /* Time label day here */
      font-size: 10px;
    }
    .rbc-agenda-view table.rbc-agenda-table {
      width: 100%;
      border: 1px solid #ddd;
      border-spacing: 0;
      border-collapse: collapse;
    }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      padding: 5px 10px;
      vertical-align: top;
    }
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
      padding-left: 15px;
      padding-right: 15px;
      text-transform: lowercase;
    }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left: 1px solid #ddd;
    }
    .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left-width: 0;
      border-right: 1px solid #ddd;
    }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid #ddd;
    }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      padding: 3px 5px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
    .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      text-align: right;
    }

    .rbc-agenda-time-cell {
      text-transform: lowercase;
    }
    .rbc-agenda-time-cell .rbc-continues-after:after {
      content: " »";
    }
    .rbc-agenda-time-cell .rbc-continues-prior:before {
      content: "« ";
    }

    .rbc-agenda-date-cell,
    .rbc-agenda-time-cell {
      white-space: nowrap;
    }

    .rbc-agenda-event-cell {
      width: 100%;
    }

    .rbc-time-column {
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }
    .rbc-time-column .rbc-timeslot-group {
      flex: 1;
    }

    .rbc-timeslot-group {
      border-bottom: 1px solid #fff;
      /* Height of hour */
      min-height: 40px;
      display: flex;
      flex-flow: column nowrap;
    }
    .rbc-timeslot-group > .rbc-time-slot {
      border-top: none;
    }
    .rbc-timeslot-group > .rbc-time-slot:not(:first-child) {
      border-top: 1px dashed #fff;
    }

    .rbc-time-gutter,
    .rbc-header-gutter {
      flex: none;
      background-color: ${theme.PRIMARY100};
    }
    .rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot {
      border: none;
      text-align: center;
    }

    .rbc-label {
      /* Time label day here */
      font-size: 8px;
      font-weight: 600;
      padding: 0px 8px 0px 8px;
      color: ${theme.NEUTRAL350};
    }

    .rbc-day-slot {
      position: relative;
    }
    .rbc-day-slot .rbc-events-container {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      margin-right: 10px;
      top: 0;
    }
    .rbc-day-slot .rbc-events-container.rbc-rtl {
      left: 10px;
      right: 0;
    }
    .rbc-day-slot .rbc-event {
      border: 1px solid ${theme.PRIMARY300};
      display: flex;
      max-height: 100%;
      min-height: 24px;
      flex-flow: column wrap;
      align-items: flex-start;
      overflow: hidden;
      position: absolute;
    }
    .rbc-day-slot .rbc-event-label {
      flex: none;
      padding-right: 5px;
      width: auto;
    }
    .rbc-day-slot .rbc-event-content {
      width: 100%;
      flex: 1 1 0;
      word-wrap: break-word;
      line-height: 1;
      height: 100%;
      min-height: 1em;
      /* Event details font size */
      font-size: 12px;
      line-height: 1.5;
    }

    .rbc-time-view-resources .rbc-time-gutter,
    .rbc-time-view-resources .rbc-time-header-gutter {
      position: sticky;
      left: 0;
      background-color: white;
      border-right: 1px solid #ddd;
      z-index: 10;
      margin-right: -1px;
    }
    .rbc-time-header-gutter {
      border: none;
    }

    .rbc-time-view-resources .rbc-time-header {
      overflow: hidden;
    }

    .rbc-time-view-resources .rbc-time-header-content {
      min-width: auto;
      flex: 1 0 0;
      flex-basis: 0px;
    }

    .rbc-time-view-resources .rbc-time-header-cell-single-day {
      display: none;
    }

    .rbc-time-view-resources .rbc-day-slot {
      min-width: 140px;
    }

    .rbc-time-view-resources .rbc-header,
    .rbc-time-view-resources .rbc-day-bg {
      width: 140px;
      flex: 1 1 0;
      flex-basis: 0 px;
    }

    .rbc-time-header-content + .rbc-time-header-content {
      margin-left: -1px;
    }

    .rbc-time-slot {
      flex: 1 0 0;
      min-height: 28px;
    }
    .rbc-time-slot.rbc-now {
      font-weight: bold;
    }

    .rbc-day-header {
      text-align: center;
    }

    .rbc-slot-selection {
      z-index: 10;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      font-size: 75%;
      width: 100%;
      padding: 3px;
    }

    .rbc-slot-selecting {
      cursor: move;
    }

    .rbc-time-view {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      border: 1px solid #ddd;
      min-height: 0;
    }
    .rbc-time-view .rbc-time-gutter {
      white-space: nowrap;
    }
    .rbc-time-view .rbc-allday-cell {
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      position: relative;
    }
    .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
      border-left: 1px solid #ddd;
    }
    .rbc-time-view .rbc-allday-events {
      position: relative;
      z-index: 4;
    }
    .rbc-time-view .rbc-row {
      box-sizing: border-box;
      min-height: 20px;
    }

    .rbc-time-header {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
    }
    .rbc-time-header.rbc-overflowing {
      border-right: 1px solid #ddd;
    }
    .rbc-rtl .rbc-time-header.rbc-overflowing {
      border-right-width: 0;
      border-left: 1px solid #ddd;
    }
    .rbc-time-header > .rbc-row:first-child {
      border-bottom: 1px solid #ddd;
    }
    .rbc-time-header > .rbc-row.rbc-row-resource {
      border-bottom: 1px solid #ddd;
    }

    .rbc-time-header-cell-single-day {
      display: none;
    }

    .rbc-time-header-content {
      flex: 1;
      display: flex;
      min-width: 0;
      flex-direction: column;
      border-left: 1px solid #ddd;
    }
    .rbc-rtl .rbc-time-header-content {
      border-left-width: 0;
      border-right: 1px solid #ddd;
    }
    .rbc-time-header-content > .rbc-row.rbc-row-resource {
      border-bottom: 1px solid #ddd;
      flex-shrink: 0;
    }

    .rbc-time-content {
      display: flex;
      flex: 1 0 0%;
      align-items: flex-start;
      width: 100%;
      overflow-y: auto;
      position: relative;
      border-top: none;
      max-height: 300px;
    }
    .rbc-time-content > .rbc-time-gutter {
      flex: none;
    }
    .rbc-time-content > * + * > * {
      border-left: 1px solid #fff;
    }
    .rbc-rtl .rbc-time-content > * + * > * {
      border-left-width: 0;
      border-right: 1px solid #ddd;
    }
    .rbc-time-content > .rbc-day-slot {
      width: 100%;
      user-select: none;
      -webkit-user-select: none;
      background-color: ${theme.PRIMARY100};
    }

    .rbc-current-time-indicator::before {
      position: relative;
      top: -22px;
      left: 8px;
      padding: 2px 6px 2px 6px;

      background-color: ${theme.PRIMARY100};
      color: ${theme.DANGER600};
      border-radius: 16px;

      content: "${(props) => props.curTime}";
      font-size: 8px;
      font-weight: 600;
      line-height: 12px;
    }
    .rbc-current-time-indicator {
      position: absolute;
      z-index: 3;
      left: 0;
      right: 0;
      height: 1px;
      background-color: transparent;
      border-top: 1px solid ${theme.DANGER600};
      pointer-events: none;
    }
  }
`;

export { MyScheduleCal };
