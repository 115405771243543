import React, { Dispatch, SetStateAction, useState } from "react";
import { AppText, AppTextArea, NewAppButton } from "../../../UI";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { appToast } from "../../../../utils/toast";
import { Modal } from "../../../modal/Modal";

const EDIT_CALL_NOTE = gql`
  mutation editNote($notes: String!, $lead_activity_id: String!) {
    editNote(notes: $notes, lead_activity_id: $lead_activity_id) {
      id
      notes
      updated_at
    }
  }
`;

const DELETE_CALL_NOTE = gql`
  mutation deleteNote($lead_activity_id: String!) {
    deleteNote(lead_activity_id: $lead_activity_id) {
      id
      notes
      updated_at
    }
  }
`;

interface EditDeleteCallNoteProps {
  editCallNote: { visible: boolean; note: string; lead_activity_id: string };
  setEditCallNote: Dispatch<SetStateAction<{ visible: boolean; note: string; lead_activity_id: string }>>;
  lead_activity_id: string;
  note: string;
}

const EditDeleteCallNote = ({ lead_activity_id, note, editCallNote, setEditCallNote }: EditDeleteCallNoteProps) => {
  const [updatedCallNote, setUpdatedCallNote] = useState(note);
  const [deleteCallNote, setDeleteCallNote] = useState(false);

  const [editNote, { loading: loadingEditNote, error: errorEditNote }] = useMutation(EDIT_CALL_NOTE, {
    onError: (e) => {
      appToast(`Could not edit note: ", ${e.message}`);
      Sentry.captureEvent({
        message: `editNote GraphQL Error: ${e.message}`,
      });
    },
    onCompleted: () => {
      appToast("Note updated.");
      setEditCallNote({ visible: false, note: "", lead_activity_id: "" });
    },
    refetchQueries: ["fetchLeadHistory"],
  });

  const [deleteNote, { loading: loadingDeleteNote, error: errorDeleteNote }] = useMutation(DELETE_CALL_NOTE, {
    onError: (e) => {
      appToast(`Could not update notes: ", ${e.message}`);
      Sentry.captureEvent({
        message: `deleteNote GraphQL Error: ${e.message}`,
      });
    },
    onCompleted: () => {
      appToast("Note deleted successfully");
      setEditCallNote({ visible: false, note: "", lead_activity_id: "" });
    },
    refetchQueries: ["fetchLeadHistory"],
  });

  return (
    <>
      {deleteCallNote && (
        <Modal open={deleteCallNote} onClose={() => setDeleteCallNote(false)}>
          <ModalContainer>
            <ModalHeader>Permanently delete this note?</ModalHeader>
            <ModalText>You are about to delete the note below. Are you sure?</ModalText>
            <ModalNotesQuote>{editCallNote.note}</ModalNotesQuote>
            <ButtonsDiv>
              <NewAppButton
                variant="secondary"
                style={{ marginRight: "16px" }}
                onClick={() => {
                  setDeleteCallNote(false);
                }}
              >
                CANCEL
              </NewAppButton>
              <NewAppButton
                variant="attention"
                style={{ marginRight: "16px" }}
                disabled={loadingDeleteNote}
                onClick={async () => {
                  await deleteNote({
                    variables: {
                      lead_activity_id: lead_activity_id,
                    },
                  });
                }}
              >
                DELETE
              </NewAppButton>
            </ButtonsDiv>
          </ModalContainer>
        </Modal>
      )}
      <ContainerDiv>
        <AppTextArea
          fixedSize
          borderColor={theme.NEUTRAL200}
          value={updatedCallNote}
          onChange={(e: any) => setUpdatedCallNote(e.target.value)}
        />
        <ButtonsDiv>
          <NewAppButton
            variant="attention"
            style={{ marginRight: "16px" }}
            onClick={() => {
              setDeleteCallNote(true);
            }}
          >
            DELETE
          </NewAppButton>
          <NewAppButton
            variant="secondary"
            style={{ marginRight: "16px" }}
            onClick={() => {
              setEditCallNote({ visible: false, note: "", lead_activity_id: "" });
            }}
          >
            CANCEL
          </NewAppButton>
          <NewAppButton
            variant="primary"
            disabled={loadingEditNote || loadingDeleteNote}
            type="submit"
            onClick={async () => {
              await editNote({
                variables: {
                  notes: updatedCallNote,
                  lead_activity_id: lead_activity_id,
                },
              });
            }}
          >
            SAVE
          </NewAppButton>
        </ButtonsDiv>
      </ContainerDiv>
    </>
  );
};

const ModalHeader = styled(AppText)`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const ModalText = styled(AppText)`
  font-size: 16px;
  margin-bottom: 24px;
`;

const ModalNotesQuote = styled(AppText)`
  font-style: italic;
  font-size: 16px;
  margin-bottom: 24px;
`;

const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px;
  align-items: center;
  justify-content: center;
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px;
`;

const ContainerDiv = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export { EditDeleteCallNote };
