import React, { useContext } from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { useMutation, gql } from "@apollo/client";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { theme } from "../../../utils/theme";
import { errorToast, notificationToast, successToast } from "../../../utils/toast";

const DELETE_SEQUENCE_STEP = gql`
  mutation deleteStep($stepId: String!) {
    delete_step(step_id: $stepId)
  }
`;

interface ConfirmDeleteStepModalProps {
  stepId: string;
  sequenceData: any;
}

export const ConfirmDeleteStepModal: React.FC<ConfirmDeleteStepModalProps> = ({ stepId, sequenceData }) => {
  const {
    showConfirmDeleteSequenceStep,
    setShowConfirmDeleteSequenceStep,
    sequenceStepData,
    setSequenceStepData,
    setUpdateStepTrigger,
  } = useContext(ModalContext);

  const [deleteStep, { loading: loadingDeleteSequenceStep }] = useMutation(DELETE_SEQUENCE_STEP, {
    async onCompleted({ delete_step }) {
      console.log("deleteStep:", delete_step);
      successToast("Sequence step deleted!");
      setSequenceStepData({});
      setUpdateStepTrigger(true);
    },
    onError({ message }) {
      notificationToast({ message: `${message}`, variant: "error" });
      Sentry.captureEvent({
        message: `delete_sequence GraphQL Error: ${message}`,
      });
      console.log(`delete_sequence GraphQL Error: ${message}`);
      setShowConfirmDeleteSequenceStep(false);
    },
    refetchQueries: ["fetchSequence"],
  });

  const handleDelete = async () => {
    const curStepOriginId = sequenceData?.active_steps?.find((step: any) => step.id === stepId)?.origin_id;

    await deleteStep({
      variables: {
        stepId: stepId,
      },
    });

    const sharedOriginList = sequenceData?.active_steps?.filter(
      (step: any) => step?.origin_id === curStepOriginId && step?.id !== stepId,
    );

    // if we are deleting the last branch, also delete the 'No' branch
    if (sharedOriginList?.length === 1 && sharedOriginList[0]?.is_no_branch) {
      await deleteStep({
        variables: {
          stepId: sharedOriginList[0]?.id,
        },
      });
    }

    setShowConfirmDeleteSequenceStep(false);
  };

  return (
    <Modal
      open={showConfirmDeleteSequenceStep}
      onClose={() => {
        setShowConfirmDeleteSequenceStep(false);
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Delete Sequence Step
          </AppText>
          <div
            style={{
              marginBottom:
                !sequenceStepData?.nodeData?.is_host_step && !sequenceStepData?.nodeData?.conditions?.length
                  ? "90px"
                  : "45px",
            }}
          >
            <AppText
              fontSize={14}
              style={{
                marginBottom:
                  (sequenceStepData?.nodeData?.is_host_step || !!sequenceStepData?.nodeData?.conditions?.length) &&
                  "16px",
              }}
            >
              Are you sure you want to delete this step? This action cannot be undone.
            </AppText>
            {sequenceStepData?.nodeData?.is_host_step && (
              <AppText fontSize={14} color={theme.DANGER600}>
                Deleting this step will also delete any downstream branches.
              </AppText>
            )}
            {!!sequenceStepData?.nodeData?.conditions?.length && (
              <AppText fontSize={14} color={theme.DANGER600}>
                Deleting this branch will also delete any downstream steps.
              </AppText>
            )}
          </div>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => setShowConfirmDeleteSequenceStep(false)}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={handleDelete}
            disabled={!!loadingDeleteSequenceStep}
          >
            Yes, Continue
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  padding: 40px 40px 0px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
