import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { useContext } from "react";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { AppButton, AppErrorText, Loading } from "../UI";
import { FormMultiSelectField } from "../Field";
import { ModalContext } from "../../context";
import { Dispatch, SetStateAction } from "react";
import { Formik, FormikProps } from "formik";
import { toTitleCase } from "../../utils/format";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { appToast } from "../../utils/toast";
import { MixpanelActions } from "../../services/mixpanel";
import { Modal } from "./Modal";

const FETCH_ALL_MANAGERS_AND_ADMINS = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      admins_managers {
        id
        full_name
        last_name
      }
    }
  }
`;

const LOG_REVIEW_CALL_REPORT = gql`
  mutation logReviewCallReport($conference_id: String!, $reviewer_ids: [String!]!) {
    logReviewCallReport(conference_id: $conference_id, reviewer_ids: $reviewer_ids)
  }
`;

const FETCH_REPS_FROM_CONFERENCE = gql`
  query fetchRepsFromConference($conference_id: String!) {
    fetchRepsFromConference(conference_id: $conference_id) {
      id
      full_name
    }
  }
`;

const SEND_REVIEW_CALL_REPORT_TO_REP = gql`
  mutation sendReviewCallReportToRep($conference_id: String!, $rep_ids: [String!]!) {
    sendReviewCallReportToRep(conference_id: $conference_id, rep_ids: $rep_ids)
  }
`;

interface CallFeedbackProps {
  blinds: boolean;
  setBlinds: Dispatch<SetStateAction<boolean>>;
  role: string;
  conference_id?: string;
}

interface MyFormikProps {
  id_array: any[];
}

const callFeedbackSchema = Yup.object().shape({
  id_array: Yup.array().required("Select at least one person!"),
});
const CallFeedbackModal: React.FC<CallFeedbackProps> = ({ blinds, setBlinds, role, conference_id }) => {
  // Fetch all Managers and Admins
  const { data: managerAdminData, loading: managerAdminLoading, error: managerAdminError } = useQuery(
    FETCH_ALL_MANAGERS_AND_ADMINS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  // Fetch Reps from Conference (only used for ADMIN experience)
  const { data: repData, loading: repLoading, error: repError } = useQuery(FETCH_REPS_FROM_CONFERENCE, {
    variables: {
      conference_id: conference_id,
    },
    skip: !conference_id,
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  // Send Call Report to Manager
  const [logReviewCallReport, { loading: reviewLoading, error: reviewError }] = useMutation(LOG_REVIEW_CALL_REPORT, {
    async onCompleted({ logReviewCallReport }) {
      if (!logReviewCallReport) {
        appToast("Error submitting review. Something went wrong.");
        return;
      }
      MixpanelActions.track("Review Requested", {});
      appToast("Review requested!");
      MixpanelActions.track("Review Requested", { ...logReviewCallReport });
      setShowCallFeedbackModal(false);
      setBlinds(false);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `logReviewCallReport GraphQL Error: ${message}`,
      });
      console.log("Error in logReviewCallReport: ", message);
    },
  });

  // Send Call Review
  const [sendReviewCallReportToRep, { loading: reviewSentLoading, error: reviewSentError }] = useMutation(
    SEND_REVIEW_CALL_REPORT_TO_REP,
    {
      async onCompleted({ sendReviewCallReportToRep }) {
        if (!sendReviewCallReportToRep) {
          appToast("Error sending review. Something went wrong");
          return;
        }
        appToast("Review sent!");
        setShowCallFeedbackModal(false);
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `sendReviewCallReportToRep GraphQL Error: ${message}`,
        });
        console.log("Error sending review: ", message);
      },
    },
  );

  const { setShowCallFeedbackModal } = useContext(ModalContext);

  if (managerAdminLoading || repLoading) {
    return (
      <Modal open={blinds} onClose={() => setBlinds(false)}>
        <FeedbackContainer>
          <Loading />
        </FeedbackContainer>
      </Modal>
    );
  }

  if ((managerAdminError && role === "rep") || (repError && role === "manager")) {
    return (
      <Modal open={blinds} onClose={() => setBlinds(false)}>
        <FeedbackContainer>
          <AppErrorText>Error loading options.</AppErrorText>
        </FeedbackContainer>
      </Modal>
    );
  }

  const managerAdminOptions = managerAdminData?.fetchOrganization?.admins_managers
    ?.slice()
    .sort((a: { lastName: string }, b: { lastName: string }) => {
      if (a.lastName?.[0] > b.lastName?.[0]) return 1;
      if (a.lastName?.[0] < b.lastName?.[0]) return -1;
    })
    ?.map((item: any) => {
      return {
        value: item?.id,
        label: toTitleCase(item?.full_name),
        lastName: item?.last_name,
      };
    });

  const initialRep = {
    label: repData?.fetchRepsFromConference?.[0]?.full_name ?? null,
    value: repData?.fetchRepsFromConference?.[0]?.id ?? null,
  };

  const repOptions = repData?.fetchRepsFromConference
    ?.slice()
    .sort((a: { lastName: string }, b: { lastName: string }) => {
      if (a.lastName?.[0] > b.lastName?.[0]) return 1;
      if (a.lastName?.[0] < b.lastName?.[0]) return -1;
    })
    ?.map((item: any) => {
      return {
        value: item?.id,
        label: item?.full_name,
      };
    });

  // What the rep sees
  const repObject = {
    header: "Who would you like to review this call?",
    info: "You can select additional managers or admin by selecting their name in the dropdown below",
    initialValues: [],
    submitText: "Submit Call for Review",
    onSubmit: async (values: any) =>
      await logReviewCallReport({
        variables: {
          conference_id: values?.conference_id ?? "",
          reviewer_ids: values?.id_array?.map((item: any) => {
            return item.value;
          }),
        },
      }),
    options: managerAdminOptions,
  };

  // What the manager sees
  const managerObject = {
    header: "Select which reps you'd like to receive your call notes",
    info: "You can select multiple reps by selecting their name in the dropdown below.",
    initialValues: [initialRep],
    submitText: "Send Call Notes",
    onSubmit: async (values: any) =>
      await sendReviewCallReportToRep({
        variables: {
          conference_id: conference_id,
          rep_ids: values.id_array?.map((item: any) => {
            return item.value;
          }),
        },
      }),
    options: repOptions,
  };

  return (
    <Modal open={blinds} onClose={() => setBlinds(false)}>
      <FeedbackContainer>
        <Header>{role === "manager" ? managerObject.header : repObject.header}</Header>
        <InfoDiv>{role === "manager" ? managerObject.info : repObject.info}</InfoDiv>
        <Formik
          initialValues={{
            id_array: role === "manager" ? managerObject.initialValues : repObject.initialValues,
          }}
          onSubmit={(values) => {
            role === "manager" ? managerObject.onSubmit(values) : repObject.onSubmit(values);
          }}
          validationSchema={callFeedbackSchema}
        >
          {({ submitForm, setFieldValue, isValid, isSubmitting }: FormikProps<MyFormikProps>) => {
            return (
              <>
                <SelectDiv>
                  <FormMultiSelectField
                    name={"id_array"}
                    options={role === "manager" ? managerObject.options : repObject.options}
                    onChange={(e: any) => {
                      setFieldValue("id_array", !!e ? e : []);
                    }}
                  />
                </SelectDiv>
                {isSubmitting ? (
                  <Loading />
                ) : (
                  <SubmitButton onClick={submitForm}>
                    {role === "manager" ? managerObject.submitText : repObject.submitText}
                  </SubmitButton>
                )}
                <CancelButton
                  onClick={() => {
                    setBlinds(false);
                    setShowCallFeedbackModal(false);
                  }}
                >
                  Cancel
                </CancelButton>
              </>
            );
          }}
        </Formik>
      </FeedbackContainer>
    </Modal>
  );
};

const FeedbackContainer = styled.div`
  width: 580px;
  height: 477px;
  background-color: ${theme.WHITE_COLOR};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
`;

const Header = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  margin-top: 96px;
  margin-bottom: 14px;
  padding: 0px 64px 0px 65px;
  text-align: center;
`;

const InfoDiv = styled.div`
  font-size: 16px;
  padding: 0px 104px 0px 109px;
  font-weight: 400;
  margin-bottom: 32px;
  height: 42px;
  text-align: center;
`;

const SelectDiv = styled.div`
  width: 295px;
`;

const SubmitButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  font-size: 14px;
  margin-bottom: 24px;
`;

const CancelButton = styled.div`
  color: black;
  width: auto;
  font-size: 10px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export { CallFeedbackModal };
