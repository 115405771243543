import React, { Dispatch, SetStateAction, useContext } from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { useMutation, gql } from "@apollo/client";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { theme } from "../../../utils/theme";
import { errorToast, successToast } from "../../../utils/toast";
import { MixpanelActions } from "../../../services/mixpanel";
import { Sequence } from "src/__generated__/graphql";
import { findEntryCriteriaMatchingLabel, getInclusionCriteriaFilters } from "src/utils/sequences";

const DELETE_SEQUENCE = gql`
  mutation deleteSequence($sequenceId: String!) {
    delete_sequence(sequence_id: $sequenceId)
  }
`;

interface DeleteSequenceModalProps {
  sequenceId: string;
  setManualLoad: Dispatch<SetStateAction<boolean>>;
  sequence?: Sequence;
}

export const DeleteSequenceModal: React.FC<DeleteSequenceModalProps> = ({ sequence, sequenceId, setManualLoad }) => {
  const { showDeleteSequenceModal, setShowDeleteSequenceModal } = useContext(ModalContext);

  const [deleteSequence, { loading: loadingDeleteSequence }] = useMutation(DELETE_SEQUENCE, {
    async onCompleted({ delete_sequence }) {
      console.log("delete_sequence:", delete_sequence);
      successToast("Sequence successfully deleted!");
      setShowDeleteSequenceModal(false);

      MixpanelActions.track("Sequence Deleted", {
        steps: sequence?.steps_count,
        sms_steps: sequence?.sms_action_count,
        email_steps: sequence?.email_action_count,
        dial_steps: sequence?.phone_action_count,
        feedback_steps: sequence?.manager_feedback_count,
        custom_steps: sequence?.custom_task_action_count,
        active_leads: sequence?.leads_count,
        coverted_leads: sequence?.conversion_count,
        completed_leads: sequence?.completed_count,
        not_interested_leads: sequence?.not_interested_count,
        system_exits: sequence?.exit_count,
        all_leads: sequence?.all_leads_count,
        is_active: !sequence?.is_paused,
        branches: sequence?.active_steps?.filter((ele: any) => !!ele?.conditions?.length).length,
        entry_criteria: findEntryCriteriaMatchingLabel(sequence?.sequence_entry_criteria?.current_phase),
        inclusion_criteria_filters: getInclusionCriteriaFilters(sequence?.sequence_entry_criteria),
      });
    },
    onError({ message }) {
      errorToast(`${message}`);
      Sentry.captureEvent({
        message: `delete_sequence GraphQL Error: ${message}`,
      });
      console.log(`delete_sequence GraphQL Error: ${message}`);
      setShowDeleteSequenceModal(false);
    },
    refetchQueries: ["fetchSequences"],
  });

  return (
    <Modal
      open={showDeleteSequenceModal}
      onClose={() => {
        setShowDeleteSequenceModal(false);
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Delete Sequence
          </AppText>
          <AppText fontSize={14}>Are you sure you want to delete this sequence? This action cannot be undone.</AppText>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => setShowDeleteSequenceModal(false)}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              deleteSequence({
                variables: {
                  sequenceId: sequenceId,
                },
              });
              setManualLoad(true);
            }}
            disabled={!!loadingDeleteSequence}
          >
            Yes, Continue
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  padding: 40px 40px 90px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
