import React, { useContext } from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { theme } from "../../../utils/theme";
import { Segment } from "../../../types/SequenceTypes";

interface HideReportingSectionModalProps {
  selectedSegment: Segment | null;
  segments: Segment[];
  setSegments: React.Dispatch<React.SetStateAction<Segment[]>>;
}

export const HideReportingSectionModal: React.FC<HideReportingSectionModalProps> = ({
  selectedSegment,
  segments,
  setSegments,
}) => {
  const { showHideReportingSectionModal, setShowHideReportingSectionModal } = useContext(ModalContext);

  return (
    <Modal
      open={showHideReportingSectionModal}
      onClose={() => {
        setShowHideReportingSectionModal(false);
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Hide Section
          </AppText>
          <AppText fontSize={14}>
            Are you sure you want to hide this section? You can turn visibility back on at any time.
          </AppText>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => setShowHideReportingSectionModal(false)}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              setSegments(segments.filter((segment) => segment.type !== selectedSegment?.type));
              setShowHideReportingSectionModal(false);
            }}
          >
            Yes, Continue
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  padding: 40px 40px 90px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
