export const CLOUDINARY_UPLOAD_PRESET = "lb2zups1V2"; // no info disclosure risk
export const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const CLOUDINARY_UPLOAD_PRESET_COMPANY_LOGO = "";
export const CLOUDINARY_UPLOAD_URL = "";
export const CLOUDINARY_URL = "";
export const DEFAULT_CLOUDINARY_IMAGE = "hfvs3jniatz9yadvcb7r";
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const WS_BACKEND_URL = process.env.REACT_APP_WS_BACKEND_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const MAX_SMS_CHARACTERS = 1600;

export const DEFAULT_DOCUMENT_TITLE = "Sellfire";

// export const BACKEND_URL = "https://6e467a398536.ngrok.io";
export const PLACEHOLDER_IMAGE = "https://via.placeholder.com/150";

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

export const USER_CSV_EXAMPLE_URL = "https://opsiq-csv-bucket-prod.s3.amazonaws.com/sellfire-users-template+-+Sheet1.csv";
export const LEAD_CSV_EXAMPLE_URL = "https://opsiq-csv-bucket-prod.s3.amazonaws.com/CSV_Lead_Upload_Template.csv";

// poll intervals
export const FETCH_CURRENT_LEAD_POLL_INTERVAL = 60000 * 5; // 5 minutes
export const FETCH_MY_SCHEDULE_POLL_INTERVAL = 60000 * 15; // 15 minutes
export const FETCH_USER_INFO_DASHBOARD_POLL_INTERVAL = 60000 * 15; // 15 minutes
export const FETCH_SWITCH_LEAD_CARD_OPTION_POLL_INTERVAL = 60000 * 5; // 15 minutes
export const FETCH_CONFERENCE_POLL_INTERVAL = 15000; // 15 seconds
export const FETCH_USER_TRANSFER_LIST_INTERVAL = 5000; // 15 seconds
export const FETCH_SMS_ITEMS_POLL_INTERVAL = 15000; // 15 seconds
export const FETCH_SMS_ITEMS_INFO_HEADER_POLL_INTERVAL = 60000 * 3; // 3 minutes
export const LEAD_IMPORT_HISTORY_POLL_INTERVAL = 30000; // 30 seconds
export const CURRENT_IMPORT_POLL_INTERVAL = 15000; // 60 seconds
export const FETCH_NOTIFICATIONS_POLL_INTERVAL = 60000 * 15; // 15 minutes
export const FETCH_CONFERENCE_PARTICIPANTS_POLL_INTERVAL = 60000 * 1.5; // 1.5 minute
export const TRANSFER_TIMEOUT_SECONDS = 30; // in seconds (not milliseconds)

export const SEARCH_DEBOUNCE_INTERVAL = 500; // half a second
export const SETTINGS_DEBOUNCE_INTERVAL = 1000; // 1 second
/**
 * Amount of time a toast message will appear before closing automatically
 */
export const TOAST_CLOSE_DURATION = 6000;

// export const API_URL = `https://6e467a398536.ngrok.io/twilio`;
export const API_URL = BACKEND_URL;

export const environment = process.env.REACT_APP_ENVIRONMENT || "Development";
export const isProduction = environment === "Production";
export const version = process.env.REACT_APP_VERSION;

export const REACT_APP_GOOGLE_CALENDAR_SYNC_CALLBACK_URL = process.env.REACT_APP_GOOGLE_CALENDAR_SYNC_CALLBACK_URL;
export const REACT_APP_MICROSOFT_CALENDAR_SYNC_CALLBACK_URL =
  process.env.REACT_APP_MICROSOFT_CALENDAR_SYNC_CALLBACK_URL;
export const REACT_APP_JOINME_SYNC_CALLBACK = process.env.REACT_APP_JOINME_SYNC_CALLBACK;
export const REACT_APP_ZOOM_SYNC_CALLBACK = process.env.REACT_APP_ZOOM_SYNC_CALLBACK;
// export const TOKEN_REFRESH_TIME = 600_000; // 10 minutes
// export const TOKEN_REFRESH_TIME = 1_000 * 30; // 30 seconds
export const TOKEN_REFRESH_TIME = 60_000 * 60 * 4; // 4 hours
// export const TOKEN_REFRESH_TIME_MAIN = 60_000 * 60 * 4; // 4 hours
// export const TOKEN_REFRESH_TIME_MAIN = 1_000 * 30; // 4 hours

export const MANAGER_GRID_PERSIST_KEY = "MANAGER_GRID_PERSIST_KEY";
export const SIMPLE_GRID_PERSIST_KEY = "SIMPLE_GRID_PERSIST_KEY";
export const TZ_OVERRIDE_PERSIST_KEY = "TZ_OVERRIDE_PERSIST_KEY";
export const LEAD_POOL_GRID_PERSIST_KEY = "LEAD_POOL_GRID_PERSIST_KEY";
export const LEAD_FUNNEL_GRID_PERSIST_KEY = "LEAD_FUNNEL_GRID_PERSIST_KEY";
export const CALL_LIBRARY_PERSIST_KEY = "CALL_LIBRARY_PERSIST_KEY";

export const PERSIST_VERSION = "9";
export const TOKEN_PERSIST_KEY = `token-${PERSIST_VERSION}`;
export const USER_PERSIST_KEY = `user-${PERSIST_VERSION}`;
export const STEP_SYSVIEW_PERSIST_KEY = `step_sysview-${PERSIST_VERSION}`;

export const ABLY_API_KEY = process.env.REACT_APP_ABLY_API_KEY || "";

export const LAUNCH_DARKLY_KEY = process.env.REACT_APP_LAUNCH_DARKLY_KEY || "";

export const DEFAULT_MRR_LABEL = `MRR + One-Time Fees`;

export const HTML_TAGS_SAFE_TO_RENDER_IN_UI = [
  "b",
  "i",
  "em",
  "strong",
  "a",
  "p",
  "br",
  "ul",
  "li",
  "ol",
  "span",
  "div",
  "img",
  "table",
  "tr",
  "td",
  "th",
  "tbody",
  "thead",
  "tfoot",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
];

export const HTML_ATTRIBUTES_SAFE_TO_RENDER_IN_UI = [
  "href",
  "src",
  "alt",
  "style",
  "width",
  "height",
  "border",
  "cellspacing",
  "cellpadding",
  "align",
  "valign",
  "bgcolor",
  "colspan",
  "rowspan",
];

export enum APP_ROLES {
  AE = "AE",
  SDR = "SDR",
  ADMIN = "ADMIN",
  SM = "SM",
  PS = "PS",
}

export const APP_REP_ROLES = [APP_ROLES.AE, APP_ROLES.SDR];

export const APP_ENABLEMENT_ROLES = [APP_ROLES.ADMIN, APP_ROLES.PS];

export enum USER_STATUS {
  IDLE = "IDLE",
  CALL = "CALL",
  // deprecated status kept for backwards compatibility
  DEMO = "DEMO",
  OFFLINE = "OFFLINE",
}

export enum DraggableFieldType {
  FIELD = "FIELD",
  REQUIRED_FIELD = "REQUIRED_FIELD",
  NOTE = "NOTE",
  WARNING = "WARNING",
  LINK = "LINK",
  HEADER = "HEADER",
  PANDADOC = "PANDADOC",
}

export const DEFAULT_SYSTEM_FIELDS_FOR_MAKE_SALE_FLOW = [
  "CONTRACT_DURATION",
  "PAYMENT_TERMS",
  "PRODUCT_SELECTION",
  "PRIMARY_PHONE_NUMBER",
  "BUSINESS_NAME",
  "FIRST_NAME",
  "LAST_NAME",
  "EMAIL",
  "ADDRESS",
  "COUNTRY",
  "CITY",
  "ZIP_CODE",
  "INDUSTRY",
  "SUB_INDUSTRY",
  "LEAD_SOURCE",
  "SALE_NOTE",
  "MRR",
];

export const DEFAULT_SYSTEM_FIELDS_FOR_OPSIQ_FORMS = [
  "PRIMARY_PHONE_NUMBER",
  "BUSINESS_NAME",
  "FIRST_NAME",
  "LAST_NAME",
  "EMAIL",
  "ADDRESS",
  "COUNTRY",
  "CITY",
  "STATE",
  "ZIP_CODE",
  "INDUSTRY",
  "SUB_INDUSTRY",
  "LEAD_SOURCE",
];

// we might want to have the value be a few options for fallbacks.
export const FONT_OPTIONS = [
  { label: "Comic Sans MS", value: "Comic Sans MS" },
  { label: "Courier New", value: "Courier New" },
  { label: "Georgia", value: "Georgia" },
  { label: "Impact", value: "Impact" },
  { label: "Times New Roman", value: "Times New Roman" },
  { label: "Trebuchet MS", value: "Trebuchet MS" },
  { label: "Verdana", value: "Verdana" },
  { label: "Arial", value: "Arial" },
];
