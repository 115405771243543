import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { FlexDiv } from "../FlexDiv";
import { PhoenixIcon, PhoenixInput, PhoenixRadio } from "../Phoenix";
import { AppText } from "../AppText";
import Switch from "react-switch";
import { clamp, cloneDeep } from "lodash";
import { clock } from "../../../images/NewDesign";
import { time } from "console";

export const AddTimeDelay = ({ ...props }) => {
  const { setFieldValue, values, edit } = props;

  const currentDelay = props?.values?.sequenceStepData?.actions?.[0]?.delay_amount;
  const [addTimeDelay, setAddTimeDelay] = useState<boolean>(!!currentDelay);
  const [timeRadio, setTimeRadio] = useState<string>(currentDelay ? String(currentDelay) : "");

  const nonCustomtimesInMinutes = ["120", "1440", "240", "10080"];
  const delay_minutes = currentDelay;
  const delay_days = String(Math.floor(delay_minutes / 1440));
  const remaining_minutes_after_days = delay_minutes % 1440;
  const delay_hours = String(Math.floor(remaining_minutes_after_days / 60));
  const remaining_minutes = String(remaining_minutes_after_days % 60);

  const isOldCustomValue = timeRadio !== "" && !nonCustomtimesInMinutes.includes(timeRadio);

  const [customTime, setCustomTime] = useState(
    isOldCustomValue
      ? {
          day: delay_days,
          hour: delay_hours,
          minute: remaining_minutes,
        }
      : {
          day: "",
          hour: "",
          minute: "",
        },
  );

  useEffect(() => {
    setFieldValue("tasks", [
      {
        ...values?.tasks?.[0],
        delay_days: parseInt(customTime.day) || 0,
        delay_hours: parseInt(customTime.hour) || 0,
        delay_minutes: parseInt(customTime.minute) || 0,
      },
    ]);
  }, [customTime]);

  useEffect(() => {
    // handle time delay modification in edit state
    if (edit && !addTimeDelay) {
      const newTasksObj = cloneDeep(values?.tasks?.[0]);
      delete newTasksObj.delay_days;
      delete newTasksObj.delay_hours;
      delete newTasksObj.delay_minutes;
      setFieldValue("tasks", [newTasksObj]);
    } else if (edit && addTimeDelay) {
      timeRadio !== "Custom"
        ? handleRadioChange(timeRadio)
        : setFieldValue("tasks", [
            {
              ...values?.tasks?.[0],
              delay_days: parseInt(customTime.day) || 0,
              delay_hours: parseInt(customTime.hour) || 0,
              delay_minutes: parseInt(customTime.minute) || 0,
            },
          ]);
    }
  }, [addTimeDelay]);

  const handleRadioChange = (time: string) => {
    addTimeDelay && setTimeRadio(time);
    const nonCustomtimesInMinutes = ["120", "1440", "240", "10080"];

    switch (time) {
      case "2 Hours":
      case "120":
        setTimeRadio("2 Hours");
        setFieldValue("tasks", [
          {
            ...values?.tasks?.[0],
            delay_days: 0,
            delay_hours: 2,
            delay_minutes: 0,
          },
        ]);
        break;
      case "1 Day":
      case "1440":
        setTimeRadio("1 Day");
        setFieldValue("tasks", [
          {
            ...values?.tasks?.[0],
            delay_days: 1,
            delay_hours: 0,
            delay_minutes: 0,
          },
        ]);
        break;
      case "Custom":
        setFieldValue("tasks", [
          {
            ...values?.tasks?.[0],
            delay_days: parseInt(customTime.day) || 0,
            delay_hours: parseInt(customTime.hour) || 0,
            delay_minutes: parseInt(customTime.minute) || 0,
          },
        ]);
        break;
      case "4 Hours":
      case "240":
        setTimeRadio("4 Hours");
        setFieldValue("tasks", [
          {
            ...values?.tasks?.[0],
            delay_days: 0,
            delay_hours: 4,
            delay_minutes: 0,
          },
        ]);
        break;
      case "1 Week":
      case "10080":
        setTimeRadio("1 Week");
        setFieldValue("tasks", [
          {
            ...values?.tasks?.[0],
            delay_days: 7,
            delay_hours: 0,
            delay_minutes: 0,
          },
        ]);
        break;
      case "":
        break;
      default:
        if (nonCustomtimesInMinutes.includes(time)) break;

        setTimeRadio("Custom");
        const delay_minutes = parseInt(time);
        const delay_days = Math.floor(delay_minutes / 1440);
        const remaining_minutes_after_days = delay_minutes % 1440;
        const delay_hours = Math.floor(remaining_minutes_after_days / 60);
        const remaining_minutes = remaining_minutes_after_days % 60;

        setFieldValue("tasks", [
          {
            ...values?.tasks?.[0],
            delay_days,
            delay_hours,
            delay_minutes: remaining_minutes,
          },
        ]);

        break;
    }
  };

  return (
    <TimeDelayContainer>
      <TimeDelayTop gap={8} align="center">
        <Clock>
          <PhoenixIcon svg={clock} size="small" variant="white" hoverColor="white" />
        </Clock>
        <FlexDiv direction="column" gap={4} style={{ width: "211px" }}>
          <AppText fontSize={12} fontWeight={500}>
            {edit ? "New" : "Add"} Time Delay
          </AppText>
          <AppText color={theme.NEUTRAL300} fontSize={12} fontWeight={500}>
            This step will occur after an established time interval.
          </AppText>
        </FlexDiv>
        <div style={{ marginLeft: "auto" }}>
          <Switch
            onChange={(checked) => {
              setAddTimeDelay(checked);
              handleRadioChange(timeRadio);

              if (!edit) {
                !checked
                  ? setFieldValue("tasks", [
                      {
                        ...values?.tasks?.[0],
                        delay_days: 0,
                        delay_hours: 0,
                        delay_minutes: 0,
                      },
                    ])
                  : handleRadioChange(timeRadio);
              }
            }}
            onColor={theme.PRIMARY500}
            checked={addTimeDelay}
            height={16}
            width={32}
            checkedIcon={false}
            uncheckedIcon={false}
            handleDiameter={12}
          />
        </div>
      </TimeDelayTop>
      <TimeDelayBottom direction="column" gap={14}>
        <FlexDiv wrap="wrap" style={{ rowGap: "16px" }}>
          {["2 Hours", "1 Day", "Custom", "4 Hours", "1 Week"]?.map((time: string) => (
            <TimeRadioSelect
              key={`radio-${time}`}
              style={{ marginLeft: ["1 Day", "1 Week"].includes(time) ? "42px" : time === "Custom" ? "50px" : "0px" }}
            >
              <PhoenixRadio
                selected={timeRadio === time}
                onClick={() => handleRadioChange(time)}
                style={{ cursor: addTimeDelay ? "pointer" : "unset" }}
                unselectedBorderColor={addTimeDelay ? theme.NEUTRAL300 : theme.NEUTRAL250}
                selectedBorderColor={addTimeDelay ? theme.PRIMARY500 : theme.NEUTRAL250}
                selectedDotColor={addTimeDelay ? theme.PRIMARY500 : theme.NEUTRAL250}
              />
              <AppText fontSize={12} fontWeight={500} color={addTimeDelay ? theme.BLACK_COLOR : theme.NEUTRAL250}>
                {time}
              </AppText>
            </TimeRadioSelect>
          ))}
        </FlexDiv>

        <FlexDiv gap={8}>
          <div>
            <AppText
              fontSize={12}
              fontWeight={500}
              color={timeRadio === "Custom" && addTimeDelay ? theme.BLACK_COLOR : theme.NEUTRAL250}
            >
              Days
            </AppText>
            <PhoenixInput
              disabled={timeRadio !== "Custom" || !addTimeDelay}
              inputValueType="number"
              value={customTime.day}
              min="1"
              onChange={(e: any) => setCustomTime({ ...customTime, day: clamp(e.target?.value, 0, 9999).toString() })}
              displayNoContextText
              style={{ backgroundColor: `${theme.WHITE_COLOR}` }}
            />
          </div>
          <div>
            <AppText
              fontSize={12}
              fontWeight={500}
              color={timeRadio === "Custom" && addTimeDelay ? theme.BLACK_COLOR : theme.NEUTRAL250}
            >
              Hours
            </AppText>
            <PhoenixInput
              disabled={timeRadio !== "Custom" || !addTimeDelay}
              inputValueType="number"
              value={customTime.hour}
              min="1"
              onChange={(e: any) => setCustomTime({ ...customTime, hour: clamp(e.target?.value, 0, 9999).toString() })}
              displayNoContextText
              style={{ backgroundColor: `${theme.WHITE_COLOR}` }}
            />
          </div>
          <div>
            <AppText
              fontSize={12}
              fontWeight={500}
              color={timeRadio === "Custom" && addTimeDelay ? theme.BLACK_COLOR : theme.NEUTRAL250}
            >
              Minutes
            </AppText>
            <MinuteInput
              type="number"
              disabled={timeRadio !== "Custom" || !addTimeDelay}
              value={customTime.minute}
              min="0"
              max="45"
              step="15"
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e: any) => setCustomTime({ ...customTime, minute: clamp(e.target?.value, 0, 45).toString() })}
            />
          </div>
        </FlexDiv>
      </TimeDelayBottom>
    </TimeDelayContainer>
  );
};

const MinuteInput = styled.input`
  width: 110px;
  height: 40px;
  padding-left: 15px;

  border-radius: 4px;
  border: 1px solid ${theme.NEUTRAL300};

  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  font-weight: 400;

  :focus {
    outline: 1px solid ${theme.PRIMARY500};
    outline-offset: 1px;
  }

  :disabled {
    color: ${theme.NEUTRAL300};
  }
`;

const TimeRadioSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TimeDelayContainer = styled.div`
  margin-top: auto;
  border: 1px solid ${theme.NEUTRAL450};
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px ${theme.NEUTRAL450};
`;

const Clock = styled.div`
  width: fit-content;
  height: fit-content;
  margin-bottom: auto;
  padding: 4px;
  border-radius: 50%;
  background-color: ${theme.NEUTRAL450};
`;

const TimeDelayTop = styled(FlexDiv)`
  padding: 16px 16px 16px 12px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;
const TimeDelayBottom = styled(FlexDiv)`
  padding: 16px;
`;
