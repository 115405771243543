import styled from "styled-components";
import { theme } from "../../utils/theme";

interface AppTabProps {
  selected: boolean;
  width?: number | string;
  height?: number | string;
  padding?: number | string;
}

export const AppTabDiv = styled.div<AppTabProps>`
  width: ${(props) =>
    !!props.width && typeof props.width === "number"
      ? `${props.width}px`
      : !!props.width && typeof props.width === "string"
      ? props.width
      : "100%"};
  height: ${(props) =>
    !!props.height && typeof props.height === "number"
      ? `${props.height}px`
      : !!props.height && typeof props.height === "string"
      ? props.height
      : "unset"};
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    !!props.padding && typeof props.padding === "number"
      ? `${props.padding}px`
      : !!props.padding && typeof props.padding === "string"
      ? props.padding
      : "10px 0px 9px 0px"};
  font-weight: 600;
  color: ${(props) => (props.selected ? theme.BLACK_COLOR : `${theme.NEUTRAL300}`)};
  border-bottom: ${(props) => (props.selected ? `2px solid ${theme.PRIMARY500}` : `2px solid ${theme.NEUTRAL200}`)};
  /* display: flex;
  align-items: center;
  justify-content: center; */
`;

AppTabDiv.defaultProps = {
  height: 40,
  selected: false,
};
