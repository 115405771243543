import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { PhoenixAppButton, PhoenixInput } from "../../UI/Phoenix";
import { useMutation, gql } from "@apollo/client";
import { theme } from "../../../utils/theme";
import { errorToast, successToast } from "../../../utils/toast";
import { cloneDeep } from "lodash";
import { MixpanelActions } from "../../../services/mixpanel";

const RENAME_SEQUENCE = gql`
  mutation createOrUpdateSequence($sequenceInput: SequenceInput!) {
    createOrUpdateSequence(SequenceInput: $sequenceInput) {
      id
      name
    }
  }
`;

interface RenameSequenceModal {
  dataSequences?: any;
  sequenceList?: any[];
  setSequenceList?: Dispatch<SetStateAction<any[]>>;
}

export const RenameSequenceModal: React.FC<RenameSequenceModal> = ({
  dataSequences,
  sequenceList,
  setSequenceList,
}) => {
  const { showRenameSequenceModal, setShowRenameSequenceModal, renameSequenceData, setRenameSequenceData } = useContext(
    ModalContext,
  );

  const [renameValue, setRenameValue] = useState(renameSequenceData?.name);

  const [renameSequence, { loading: loadingRenameSequence }] = useMutation(RENAME_SEQUENCE, {
    async onCompleted({ createOrUpdateSequence }) {
      console.log("renameSequence:", createOrUpdateSequence);
      successToast("Sequence successfully renamed.");
      setShowRenameSequenceModal(false);

      // rename sequence without loading state -- only happens on a successful backend update
      if (!!dataSequences && !!sequenceList && !!setSequenceList) {
        const sequenceListClone = cloneDeep(sequenceList);
        const sequenceToUpdate = sequenceListClone?.find((ele: any) => ele?.id === createOrUpdateSequence?.id);
        if (sequenceToUpdate) {
          sequenceToUpdate.name = createOrUpdateSequence?.name;
          setSequenceList(sequenceListClone);
        }
      }

      MixpanelActions.track("Sequence Edits Saved", { type: "rename" });
    },
    onError({ message }) {
      errorToast(`${message}`);
      Sentry.captureEvent({
        message: `renameSequence GraphQL Error: ${message}`,
      });
      console.log(`renameSequence GraphQL Error: ${message}`);
    },
  });

  const handleSubmit = () => {
    return renameSequence({
      variables: {
        sequenceInput: { ...renameSequenceData, name: renameValue },
      },
    });
  };

  return (
    <Modal
      open={showRenameSequenceModal}
      onClose={() => {
        setShowRenameSequenceModal(false);
        setRenameSequenceData({});
      }}
      bigCloseButton
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Rename Sequence
          </AppText>
          <PhoenixInput
            value={renameValue}
            onChange={(e: any) => setRenameValue(e.target.value)}
            displayNoContextText
          />
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              setShowRenameSequenceModal(false);
              setRenameSequenceData({});
            }}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={handleSubmit}
            disabled={!renameValue.length || loadingRenameSequence}
          >
            Continue
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;

  width: 100%;
  padding: 40px 40px 90px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
