import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React, { useMemo, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ModalContext } from "../../context";
import { MixpanelActions } from "../../services/mixpanel";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { FETCH_CONFERENCE_POLL_INTERVAL } from "../../utils/variables";
import { CallReportSegment, CallReportSideBar } from "../Segments/CallReportSegments";
import { Loading } from "../UI";
import { SaveToFolderModal } from "../modal";
import { CallFeedbackModal } from "../modal/CallFeedbackModal";
import { loggedInUser } from "../../apollo/cache";

const FETCH_CONFERENCE = gql`
  query fetchConference($conference_id: String!) {
    fetchConference(conference_id: $conference_id) {
      id
      salesperson
      users {
        id
      }
      lead {
        id
        first_name
        last_name
        business_name
        city
        state
        channel
        current_lead_type
        industry
        lead_source
        call_notes {
          id
          notes
          created_at
        }
      }
      latest_call_result {
        id
        label
      }
      recording_url_computed
      duration
      is_call_live
      start_time
      coaching_notes {
        id
        text
        start
        end
      }
      coaching_notes_sorted {
        id
        text
        start
        end
      }
      first_user_to_explore_id
      waveform_data_url_computed
    }
  }
`;

const ADD_COACHING_NOTE = gql`
  mutation addCoachingNote($conference_id: String!, $text: String!, $start: Int!, $end: Int!, $note_id: String) {
    addCoachingNote(conference_id: $conference_id, text: $text, start: $start, end: $end, note_id: $note_id) {
      id
      text
      start
      end
    }
  }
`;

const UPDATE_COACHING_NOTE = gql`
  mutation UpdateOneNoteItem($noteItemId: String!, $text: String!, $start: Int, $end: Int) {
    updateOneNoteItem(note_item_id: $noteItemId, text: $text, start: $start, end: $end) {
      id
      text
      start
      end
    }
  }
`;

const DELETE_COACHING_NOTE = gql`
  mutation DeleteOneNoteItem($noteItemId: String!) {
    deleteOneNoteItem(note_item_id: $noteItemId) {
      id
    }
  }
`;

interface ParamTypes {
  conference_id: string;
  from_system_view?: string;
}

const CallReport: React.FC = () => {
  const { conference_id, from_system_view } = useParams<ParamTypes>();

  // used for mixpanel tracking (OPS-7383)
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [peakDataLoading, setPeakDataLoading] = React.useState(false);

  const { data, loading, error, refetch, startPolling, stopPolling, called } = useQuery(FETCH_CONFERENCE, {
    variables: { conference_id: conference_id },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
    onCompleted({ fetchConference }) {
      setPageLoaded(true);
      !recordingUrl && setRecordingUrl(fetchConference?.recording_url_computed);

      const fetchData = async () => {
        if (!fetchConference?.waveform_data_url_computed) {
          setPeakData(undefined);
          return;
        }

        setPeakDataLoading(true);
        try {
          const response = await fetch(fetchConference.waveform_data_url_computed);
          const json = await response.json();
          setPeakData(json.data);
        } catch (error) {
          console.error("Failed to fetch peak data:", error);
          setPeakData(undefined);
        } finally {
          setPeakDataLoading(false);
        }
      };

      fetchData();

      console.log("fetchConf data: ", data);
    },
  });

  const [recordingUrl, setRecordingUrl] = useState(undefined);

  const {
    setShowCallFeedbackModal,
    showCallFeedbackModal,
    saveCallToLibraryModal,
    setSaveCallToLibraryModal,
    currentConferenceID,
  } = useContext(ModalContext);

  useEffect(() => {
    console.log("data: ", data);
    if (!called || !data) {
      return;
    }
    if (!!data.fetchConference.is_call_live) {
      startPolling(FETCH_CONFERENCE_POLL_INTERVAL);
    } else {
      stopPolling();
    }
    return () => {
      stopPolling();
      console.log("fetch conference stop polling");
    };
  }, [data]);

  // mixpanel tracking (OPS-7383)

  // ops-7383 - track call report viewed
  useEffect(() => {
    // loaded page state is necessary for is_call_live to be defined
    // conference query is polled so we need to make sure we only track the initial load
    if (!pageLoaded) {
      return;
    }

    MixpanelActions.track("Call Report", {
      type: "call report viewed",
      user_role: loggedInUser()?.role || "N/A",
      is_call_live: data?.fetchConference?.is_call_live,
      duration: data?.fetchConference?.is_call_live ? undefined : data?.fetchConference?.duration,
      first_view: !data?.fetchConference?.first_user_to_explore_id,
    });
  }, [pageLoaded]);

  const [peakData, setPeakData] = useState();

  const [addCoachingNote] = useMutation(ADD_COACHING_NOTE, {
    onCompleted({ coachingNote }) {
      console.log("addCoachingNote: ", coachingNote);

      if (!coachingNote) {
        return;
      }
      MixpanelActions.track("Coaching Note", { type: "add" });
    },
    onError({ message }) {
      console.log("Error in addCoachingNote: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `addCoachingNote GraphQL Error: ${message}`,
      });
    },
  });
  const [editCoachingNote, { loading: editCoachingNoteLoading, error: editCoachingNoteError }] = useMutation(
    UPDATE_COACHING_NOTE,
    {
      onCompleted({ coachingNote }) {
        console.log("editCoachingNote: ", coachingNote);
        if (!coachingNote) {
          return;
        }
      },
      onError({ message }) {
        console.log("Error in editCoachingNote: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `editCoachingNote GraphQL Error: ${message}`,
        });
      },
    },
  );
  const [deleteCoachingNote, { loading: deleteCoachingNoteLoading, error: deleteCoachingNoteError }] = useMutation(
    DELETE_COACHING_NOTE,
    {
      onCompleted({ coachingNote }) {
        console.log("deleteCoachingNote: ", coachingNote);

        if (!coachingNote) {
          return;
        }
      },
      onError({ message }) {
        console.log("Error in deleteCoachingNote: ", message);
        appToast(message);
        Sentry.captureEvent({
          message: `deleteCoachingNote GraphQL Error: ${message}`,
        });
      },
    },
  );

  if (loading || peakDataLoading) return <Loading />;
  if (error) return <p>Error Loading Call Report</p>;

  console.log("conference_data", data);

  const {
    latest_call_result,
    coaching_notes,
    is_call_live,
    lead,
    users,
    recording_url_computed,
    salesperson,
    coaching_notes_sorted,
    start_time,
  } = data.fetchConference;

  const formattedCoachingNotes = coaching_notes_sorted?.map(
    (note: { id: string; text: string; start: number; end: number }) => {
      return {
        id: note.id,
        start: note.start,
        end: note.end,
        data: {
          note: note.text,
        },
      };
    },
  );

  return (
    <CallReportContainer>
      <MainArea>
        {showCallFeedbackModal && (
          <CallFeedbackModal
            blinds={showCallFeedbackModal}
            setBlinds={setShowCallFeedbackModal}
            role="manager"
            conference_id={conference_id}
          />
        )}
        {saveCallToLibraryModal && (
          <SaveToFolderModal
            blinds={saveCallToLibraryModal}
            setBlinds={setSaveCallToLibraryModal}
            conferenceID={currentConferenceID}
          />
        )}
        <CallReportSideBar
          data={lead}
          salesperson={salesperson}
          call_result={latest_call_result}
          from_system_view={!!from_system_view}
        />
        <CallReportSegment
          salesperson={salesperson}
          url={recordingUrl}
          peakData={peakData}
          conference_id={conference_id}
          lead_data={lead}
          coaching_notes={formattedCoachingNotes}
          refetch_call_report={refetch}
          deleteCoachingNote={deleteCoachingNote}
          addCoachingNote={addCoachingNote}
          editCoachingNote={editCoachingNote}
          is_call_live={is_call_live}
          start_time={start_time}
          callDuration={data?.fetchConference?.duration}
        />
      </MainArea>
    </CallReportContainer>
  );
};

const CallReportContainer = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  min-width: 1300px;
  width: calc(100vw - 72px);
  margin-left: 72px;
  height: calc(100vh);
  min-height: calc(100vh);
  max-height: calc(100vh);
  background: ${theme.NEUTRAL100};
`;

const MainArea = styled.div`
  min-width: 1300px;
  overflow-y: scroll;
  padding: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 270px 1000px;
  min-height: calc(100vh);
  max-height: calc(100vh);
  justify-content: center;
`;

export { CallReport };
