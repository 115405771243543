import styled from "styled-components";
import * as React from "react";

import { Dispatch, SetStateAction, useContext } from "react";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";
import gql from "graphql-tag";
import { ModalContext } from "../../context";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { useMutation } from "@apollo/client";
import { appToast } from "../../utils/toast";
import * as Sentry from "@sentry/react";

const DISCONNECT_INTEGRATION = gql`
  mutation disconnectIntegration($integration_type: INTEGRATION_TYPE!) {
    disconnectIntegration(integration_type: $integration_type)
  }
`;

// enum IIntegrationType {
//   HubSpot = "HubSpot",
//   PandaDoc = "PandaDoc",
//   SalesForce = "SalesForce",
//   Zoom = "Zoom",
// }

const DisconnectIntegrationModal: React.FC = () => {
  const {
    showDisconnectIntegrationModal,
    setShowDisconnectIntegrationModal,
    selectedIntegrationDisconnect,
    setSelectedIntegrationDisconnect,
  } = useContext(ModalContext);

  const [disconnectIntegration, { loading: loadingDisconnect, error: errorDisconnect }] = useMutation(
    DISCONNECT_INTEGRATION,
    {
      async onCompleted({ disconnectIntegration }) {
        console.log("disconnectIntegration: ", disconnectIntegration);
        if (!disconnectIntegration) {
          appToast(`Error disconnecting ${selectedIntegrationDisconnect}. Something went wrong.`);
          return;
        }
        appToast("Integration removed successfully!");
      },
      onError({ message }) {
        appToast(`Error disconnecting ${selectedIntegrationDisconnect}. Please contact support.`);
        Sentry.captureEvent({
          message: `disconnectIntegration GraphQL Error: ${message}`,
        });
        console.log("Error in disconnectIntegration: ", message);
      },
      refetchQueries: ["checkIntegrationStatus"],
    },
  );

  const handleCancel = () => {
    setSelectedIntegrationDisconnect("");
    setShowDisconnectIntegrationModal(false);
  };

  const handleDisconnect = async () => {
    await disconnectIntegration({
      variables: {
        integration_type: selectedIntegrationDisconnect as any,
      },
    });
    handleCancel();
  };

  return (
    <Modal
      open={showDisconnectIntegrationModal}
      onClose={() => setShowDisconnectIntegrationModal(false)}
      bigCloseButton
    >
      <EditCardDiv>
        <TitleDiv>
          <TitleText>{`Disconnect ${selectedIntegrationDisconnect}`}</TitleText>
        </TitleDiv>

        <FlexDiv>
          <FlexInnerDiv>
            <AppText fontSize={14} style={{ textAlign: "center", marginBottom: "16px" }} fontWeight={400}>
              Toggling this off will disconnect your integration, and you will need to re-authenticate to turn it back
              on.
            </AppText>
            <br />
            <AppText fontSize={14} style={{ textAlign: "center", marginBottom: "16px" }} fontWeight={400}>
              {`Are you sure you want to disconnect ${selectedIntegrationDisconnect}?`}
            </AppText>
            {/* </FlexInnerDiv>
          <FlexInnerDiv> */}
          </FlexInnerDiv>
        </FlexDiv>
        <SubmitDiv>
          <div
            style={{
              width: "118px",
            }}
          >
            <PhoenixAppButton
              variant="danger-outline"
              buttonType="secondary"
              style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
              onClick={handleCancel}
            >
              no, do not disconnect
            </PhoenixAppButton>
          </div>
          <div
            style={{
              width: "138px",
            }}
          >
            <PhoenixAppButton
              disabled={loadingDisconnect}
              variant="brand"
              buttonType="secondary"
              onClick={handleDisconnect}
              style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
            >
              yes, disconnect
            </PhoenixAppButton>
          </div>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const SubmitDiv = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 16px 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
`;

const FlexInnerDiv = styled.div`
  height: 130px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
  position: relative;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 476px;
`;

export { DisconnectIntegrationModal };
