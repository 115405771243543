import styled from "styled-components";
import React, { useState } from "react";
import { AppButton, AppText, FlexDiv } from "../UI";
import { PhoenixAppButton, PhoenixInput, PhoenixMultiRangeSlider } from "../UI/Phoenix";
import { theme } from "../../utils/theme";
import { Modal } from "./Modal";
interface DisappearingDivProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  duration: number;
}

export const CreateSoundbiteModal: React.FC<DisappearingDivProps> = ({ blinds, setBlinds, duration }) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(duration);

  return (
    <Modal open={blinds} onClose={() => setBlinds(false)} popupAnimation bigCloseButton>
      <Container>
        <Main>
          <AppText fontSize={16} fontWeight={600} lineHeight={22}>
            Create a Soundbite
          </AppText>

          <FlexDiv gap={52}>
            <PhoenixInput titleText="Start" showNumberArrows width={104} displayNoContextText />

            <PlayPauseButton />

            <PhoenixInput titleText="End" showNumberArrows width={104} displayNoContextText />
          </FlexDiv>

          <PhoenixMultiRangeSlider min={0} max={duration} />
        </Main>

        <ButtonContainer>
          <PhoenixAppButton buttonType="secondary" variant="danger-outline" onClick={() => setBlinds(false)}>
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton buttonType="secondary">Continue</PhoenixAppButton>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 476px;
  height: 354px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 16px 40px;

  border-top: 1px solid ${theme.border.neutral.secondary};
`;

const PlayPauseButton = () => {
  return <PlayPauseButtonContainer>x</PlayPauseButtonContainer>;
};

const PlayPauseButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;

  border-radius: 100px;
  background-color: ${theme.buttonfill.brand.default};

  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  :hover {
    background-color: ${theme.buttonfill.brand.hover};
  }
`;
