import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import * as Yup from "yup";
import { MRR_LABEL } from "../../apollo/query";
import { LeadFilterContext } from "../../context";
import { info, reorder } from "../../images/NewDesign";
import { getTitle } from "../../utils/format";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { PhoenixInputField } from "../Field/Phoenix";
import { AppErrorText, AppText, FlexDiv, Loading } from "../UI";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";

const GET_COLUMNS = gql`
  query fetchColumns {
    fetchUser {
      id
      visible_my_leads_columns_computed
    }
    fetchMyLeadsColumnOptions
  }
`;

const UPDATE_COLUMNS = gql`
  mutation saveRepSelectedColumns($selected_columns: [String!]!) {
    saveRepSelectedColumns(selected_columns: $selected_columns) {
      id
      visible_my_leads_columns_computed
    }
  }
`;

interface Props {
  open: boolean;
  onClose: () => void;
  setIsInitialPageLoad?: (value: boolean) => void;
  refetchTable: () => void;
}

interface MyFormikProps {
  available_fields_search: string;
}

const searchboxSchema = Yup.object().shape({
  available_fields_search: Yup.string().notRequired(),
});

const LeadSearchManageColumnsModal: React.FC<Props> = ({ onClose, open, setIsInitialPageLoad, refetchTable }) => {
  const { leadSort, setLeadSort, setLeadSortAscending, refetchUserSavedHeaders } = useContext(LeadFilterContext);
  const { data: mrrLabel } = useQuery(MRR_LABEL);
  const [dataColumns, setDataColumns] = useState<string[]>([]);
  const [visibleFields, setVisibleFields] = useState<string[]>([]);

  // state dependent helpers
  const reorderReps = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setVisibleFields(result);
  };

  const onDragEndReps = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderReps(visibleFields, result.source.index, result.destination.index);
  };

  const { data, loading, error } = useQuery(GET_COLUMNS, {
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [updateColumns, { data: dataUpdate, loading: loadingUpdate, error: errorUpdate }] = useMutation(
    UPDATE_COLUMNS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const columns = data.saveRepSelectedColumns.visible_my_leads_columns_computed;
        const formatedColumns = columns?.map((column: string) => {
          return getTitle(column, mrrLabel);
        });
        appToast("Columns updated");

        // if the new columns don't include the current sort column, reset the sort column to the first column
        if (!formatedColumns.includes(leadSort)) {
          setLeadSort(formatedColumns[0]);
          setLeadSortAscending(true);
        }
        // refetch the table's columns
        refetchUserSavedHeaders();

        // refetch the leads
        refetchTable();

        setIsInitialPageLoad && setIsInitialPageLoad(false);
      },
      onError: ({ message }) => {
        appToast(message);
      },
      // refetchQueries: ["filterLeads"],
    },
  );

  useEffect(() => {
    if (!data || loading || error) {
      return;
    }
    setDataColumns(data.fetchMyLeadsColumnOptions);
    setVisibleFields(data.fetchUser.visible_my_leads_columns_computed);
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      bigCloseButton
      style={{ transform: "none", left: "calc(50% - 396px)", top: "calc(50% - 245px)" }}
    >
      <EditCardDiv>
        <TitleDiv>
          <AppText fontSize={16} fontWeight={600} color={theme.text.neutral.primary}>
            Manage Columns
          </AppText>
        </TitleDiv>
        <Formik
          initialValues={{
            available_fields_search: "",
          }}
          validationSchema={searchboxSchema}
          onSubmit={() => {}}
        >
          {({ values }: FormikProps<MyFormikProps>) => {
            return (
              <ScrollDiv>
                <RepListsGrid>
                  <SelectRepsContainer style={{ borderRight: `1px solid ${theme.border.neutral.secondary}` }}>
                    <RepsListContainer>
                      <AppText>Available fields</AppText>
                      <PhoenixInputField
                        name="available_fields_search"
                        placeholder="Search Fields"
                        searchInput
                        displayNoContextText
                      />
                      <RepsScrollDiv>
                        {!!loading ? (
                          <Loading />
                        ) : error ? (
                          <AppErrorText>Error loading reps</AppErrorText>
                        ) : (
                          !!dataColumns &&
                          dataColumns
                            ?.slice()
                            ?.sort((a: any, b: any) =>
                              // alphabetical sort
                              a.toLowerCase().localeCompare(b.toLowerCase()),
                            )
                            ?.filter(
                              (item: any) =>
                                !values.available_fields_search ||
                                `${item}`.toLowerCase().includes(values.available_fields_search.toLowerCase()),
                            )

                            ?.map((item: any) => (
                              <FlexDiv gap={8} key={item?.id}>
                                <PhoenixCheckbox
                                  checked={visibleFields.includes(item)}
                                  onChange={() => {
                                    visibleFields.includes(item)
                                      ? setVisibleFields(visibleFields.slice().filter((li: string) => li !== item))
                                      : setVisibleFields([...visibleFields, item]);
                                  }}
                                />
                                <AppText fontSize={12} fontWeight={500}>
                                  {getTitle(item, mrrLabel)}
                                </AppText>
                                {item === "Notes" ? (
                                  <>
                                    <PhoenixStyledTooltip id="notes-helper" />
                                    <PhoenixIcon
                                      svg={info}
                                      size={16}
                                      variant="brand"
                                      data-for="notes-helper"
                                      data-tip="You must include notes filter to make this column visible"
                                    />
                                  </>
                                ) : null}
                              </FlexDiv>
                            ))
                        )}
                      </RepsScrollDiv>
                    </RepsListContainer>
                  </SelectRepsContainer>

                  <SelectRepsContainer>
                    <RepsListContainer>
                      <AppText>Visible fields</AppText>
                      <DragDropContext onDragEnd={onDragEndReps}>
                        <Droppable droppableId="droppablecolfields">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{ height: "100%", overflowY: "auto" }}
                            >
                              <DragElements>
                                {loading ? (
                                  <Loading />
                                ) : error ? (
                                  <AppErrorText>Error loading reps</AppErrorText>
                                ) : (
                                  !!visibleFields && (
                                    <>
                                      {visibleFields
                                        .slice()
                                        .sort((a, b) => visibleFields.indexOf(a) - visibleFields.indexOf(b))
                                        ?.map((item: any, index: number) => (
                                          <Draggable key={item} draggableId={item} index={index}>
                                            {(provided, snapshot) => (
                                              <RepDraggableDiv
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <PhoenixIcon svg={reorder} size={16} variant="brand" pointer />
                                                <PhoenixCheckbox
                                                  checked={visibleFields.includes(item)}
                                                  onChange={() =>
                                                    visibleFields.includes(item)
                                                      ? setVisibleFields(
                                                          visibleFields.slice().filter((li: string) => li !== item),
                                                        )
                                                      : setVisibleFields([...visibleFields, item])
                                                  }
                                                />
                                                <AppText fontSize={12} fontWeight={500}>
                                                  {getTitle(item, mrrLabel)}
                                                </AppText>
                                              </RepDraggableDiv>
                                            )}
                                          </Draggable>
                                        ))}
                                    </>
                                  )
                                )}
                              </DragElements>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </RepsListContainer>
                  </SelectRepsContainer>
                </RepListsGrid>
              </ScrollDiv>
            );
          }}
        </Formik>
        <SubmitDiv>
          <PhoenixAppButton variant={"danger-outline"} buttonType="secondary" onClick={onClose}>
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            variant={"brand"}
            buttonType={"secondary"}
            disabled={loadingUpdate}
            onClick={async () => {
              if (!visibleFields.length) {
                appToast("You must select at least once visible field");
                return;
              }

              await updateColumns({ variables: { selected_columns: visibleFields } });
              onClose();
            }}
          >
            Continue
          </PhoenixAppButton>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const RepDraggableDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  margin-bottom: 16px;
`;

const DragElements = styled.div`
  /* min-height: 208px; */
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
`;

const RepListsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  // border between the two columns
  border-right: 1px solid ${theme.border.neutral.secondary};
`;

const RepsScrollDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
`;

const RepsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-height: 100%;
`;

const SelectRepsContainer = styled.div`
  height: 400px;
  max-height: 400px;
  padding: 24px 40px 0px 40px;
  /* position: relative; */
`;

const ScrollDiv = styled.div`
  max-height: 434px;
  height: 434px;
  overflow-y: auto;
`;

const SubmitDiv = styled.div`
  width: 100%;
  padding: 16px 40px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.surface.neutral.primary};
  border-top: solid 1px ${theme.border.neutral.secondary};
`;

const TitleDiv = styled.div`
  position: relative;
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const EditCardDiv = styled.div`
  z-index: 15;

  width: 780px;
  max-height: 75vh;
`;
export { LeadSearchManageColumnsModal };
