import * as React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppText } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";
interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
  /**
   * Only pass in the id if it is an edit operation
   */
  id?: string;
  onDeleteFunction: any;
  fieldType: string;
  fieldName?: string;
  customFields?: boolean;
  loading: boolean;
  userDelete?: boolean;
  deleteUserID?: string;
  warningText?: string;
}

const DeleteFieldV2: React.FC<DisappearingDivProps> = ({
  blinds,
  close,
  id,
  onDeleteFunction,
  fieldType = "",
  fieldName,
  customFields = false,
  loading = false,
  userDelete = false,
  deleteUserID,
  warningText,
  ...propsField
}) => {
  return (
    <Modal open={blinds} onClose={() => close()} bigCloseButton popupAnimation>
      <ModalContent>
        <HeaderDiv>{customFields && <DeleteText fontWeight={600}>Delete {fieldType}?</DeleteText>}</HeaderDiv>
        <BodyDiv>
          <DeleteText>
            Are you sure you want to delete this {fieldType.toLowerCase()}? This action cannot be undone.
          </DeleteText>
          {!!warningText && <WarningText>{warningText}</WarningText>}
        </BodyDiv>
        <SubmitDiv>
          <SubmitFlexBetweenDiv>
            <PhoenixAppButton
              buttonType="secondary"
              type="button"
              uppercase={true}
              onClick={close}
              style={{ fontSize: "10px" }}
              height={40}
              variant="danger-outline"
            >
              No, Cancel
            </PhoenixAppButton>

            <PhoenixAppButton
              buttonType="secondary"
              type="button"
              uppercase={true}
              height={40}
              style={{ fontSize: "10px" }}
              onClick={() => {
                onDeleteFunction(fieldType === "Custom Field" ? id : fieldName);
                close();
              }}
            >
              Yes, Continue
            </PhoenixAppButton>
          </SubmitFlexBetweenDiv>
        </SubmitDiv>
      </ModalContent>
    </Modal>
  );
};

const SubmitFlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
`;

const ModalContent = styled.div`
  width: 456px;
  height: 326px;
`;
const DeleteText = styled(AppText)`
  width: 100%;
  font-size: 14px;
  text-align: center;
`;

const SubmitDiv = styled.div`
  position: absolute;
  bottom: 0px;
  height: 80px;
  width: 100%;
  gap: 12px;
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const HeaderDiv = styled.div`
  position: relative;
  padding: 10px 40px;
  overflow: auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--border-neutral-secondary, #e1e2e7);
  background: var(--fill-neutral-primary, #fff);
  margin-top: 10px;
`;

const BodyDiv = styled.div`
  position: relative;
  padding: 40px;
  overflow: auto;
  width: 100%;
  height: 186px;
  margin-bottom: 88px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const WarningText = styled(AppText)`
  font-size: 10px;
  color: ${theme.text.danger.primary};
  text-align: center;
  margin-top: 16px;
`;

export { DeleteFieldV2 };
