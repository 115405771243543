import React, { useMemo } from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { CallReportSegment } from "../CallReportSegments";
import { Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { AIProcessingCall } from "./AIProcessingCall";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";

interface SummaryTabProps {
  aiProcessing: boolean;
  aiData: any;
  refetch_call_report: () => void;
  deleteCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  addCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  editCoachingNote: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  is_call_live: boolean;
  start_time: string;
  salesperson: string;
  recording_url: string;
  peakData: any;
  conference_id: string;
  lead: any;
  formattedCoachingNotes: any;
  conferenceData: any;
}

const lineChartHeight = 28;

export const SummaryTab: React.FC<SummaryTabProps> = ({
  aiProcessing,
  aiData,
  refetch_call_report,
  deleteCoachingNote,
  addCoachingNote,
  editCoachingNote,
  is_call_live,
  start_time,
  salesperson,
  recording_url,
  peakData,
  conference_id,
  lead,
  formattedCoachingNotes,
  conferenceData,
}) => {
  return (
    <BodyContent>
      <FlexDiv direction="column" justify="space-between" height={aiProcessing ? "100%" : "initial"}>
        {!aiProcessing ? (
          <SummaryContainer>
            <FlexDiv
              gap={16}
              direction="column"
              style={{ maxWidth: "50%", maxHeight: "240px", overflowY: "auto", paddingBottom: "16px" }}
            >
              <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                Summary
              </AppText>

              <SummaryList>
                {aiData?.summary?.map((point: any, index: number) => (
                  <SummaryPoint key={`summary-${index}`}>{point.text}</SummaryPoint>
                ))}
              </SummaryList>
            </FlexDiv>
            <FlexDiv
              gap={16}
              direction="column"
              style={{ maxWidth: "50%", maxHeight: "240px", overflowY: "auto", paddingBottom: "16px" }}
            >
              <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                Next Steps
              </AppText>
              <NextStepsList>
                {!!aiData?.nextSteps?.length ? (
                  aiData?.nextSteps?.map((point: any, index: number) => (
                    <NextStepsListItem key={`next-steps-${index}`}>{point.text}</NextStepsListItem>
                  ))
                ) : (
                  <AppText fontSize={12} fontWeight={400} color={theme.text.neutral.secondary}>
                    No next steps identified
                  </AppText>
                )}
              </NextStepsList>
            </FlexDiv>
          </SummaryContainer>
        ) : (
          <AIProcessingCall />
        )}

        <CallReportSegment
          salesperson={salesperson}
          url={recording_url}
          peakData={peakData}
          conference_id={conference_id}
          lead_data={lead}
          coaching_notes={formattedCoachingNotes}
          refetch_call_report={refetch_call_report}
          deleteCoachingNote={deleteCoachingNote}
          addCoachingNote={addCoachingNote}
          editCoachingNote={editCoachingNote}
          is_call_live={is_call_live}
          start_time={start_time}
          aiProcessing={aiProcessing}
          showTimeline={conferenceData?.duration >= 20}
          callDuration={conferenceData?.duration}
        />
      </FlexDiv>

      {!aiProcessing && (
        <>
          <SentimentChartContainer>
            <FlexDiv align="center" gap={8} style={{ paddingLeft: "62px" }}>
              <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase>
                Rep Sentiment:
              </AppText>
              <StatusTag score={aiData?.avgRepSentiment || 0} />
            </FlexDiv>

            {/* width of 99.9% is a workaround to get responsive width
                      see: https://stackoverflow.com/q/50891591 */}
            <ResponsiveContainer width="99.9%" height={lineChartHeight}>
              <LineChart
                data={aiData?.repSentimentDataPoints || []}
                height={lineChartHeight}
                margin={{ left: 24, right: 24 }}
              >
                {/* linearGradient coords and stops taken from figma */}
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2={lineChartHeight} gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#00A56F" />
                    <stop offset="0.25" stop-color="#73BF44" />
                    <stop offset="0.50" stop-color="#ECC80F" />
                    <stop offset="0.70" stop-color="#FA8700" />
                    <stop offset="1" stop-color="#E91E16" />
                  </linearGradient>
                </defs>

                <ReferenceLine y={0} stroke={theme.border.neutral.primary} strokeWidth={0.5} />
                <Line dataKey="score" stroke="url(#colorUv)" strokeWidth={2} dot={false} />
                <XAxis type="number" dataKey="x" domain={[0, conferenceData?.duration || 100]} hide={true} />
                <YAxis type="number" domain={[-1, 1]} hide={true} />
              </LineChart>
            </ResponsiveContainer>
          </SentimentChartContainer>
          <SentimentChartContainer>
            <FlexDiv align="center" gap={8} style={{ paddingLeft: "62px" }}>
              <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase>
                Lead Sentiment:
              </AppText>
              <StatusTag score={aiData?.avgRepSentiment || 0} />
            </FlexDiv>

            <ResponsiveContainer width="99.9%" height={lineChartHeight}>
              <LineChart
                data={aiData?.leadSentimentDataPoints || []}
                height={lineChartHeight}
                margin={{ left: 24, right: 24 }}
              >
                <ReferenceLine y={0} stroke={theme.border.neutral.primary} strokeWidth={0.5} />
                <Line dataKey="score" stroke="url(#colorUv)" strokeWidth={2} dot={false} />
                <XAxis type="number" dataKey="x" domain={[0, conferenceData?.duration || 100]} hide={true} />
                <YAxis type="number" domain={[-1, 1]} hide={true} />
              </LineChart>
            </ResponsiveContainer>
          </SentimentChartContainer>

          <FlexDiv direction="column" gap={24} style={{ padding: "24px 0px" }}>
            {/* TODO: update away from salesperson */}
            <SpeakerTimeline timelineData={aiData?.repSpeakingTimeline} speaker={conferenceData?.salesperson} />
            <SpeakerTimeline
              timelineData={aiData?.leadSpeakingTimeline}
              speaker={conferenceData?.lead?.first_name + conferenceData?.lead?.last_name}
            />
          </FlexDiv>
        </>
      )}
    </BodyContent>
  );
};

const BodyContent = styled.div`
  max-height: calc(100vh - 206px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SentimentChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  height: 104px;

  padding: 16px 0px 24px 0px;
  background-color: ${theme.fill.neutral.secondary};
`;

const SummaryContainer = styled.div`
  display: flex;
  gap: 40px;

  min-height: 240px;

  padding: 24px 40px 0px 40px;
`;

const SummaryList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  list-style-position: outside;
  padding-left: 16px;
`;

const SummaryPoint = styled.li`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  ::marker {
    font-size: 12px;
  }
`;

const NextStepsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 16px;

  list-style-position: outside;
  padding-left: 16px;
`;

const NextStepsListItem = styled.li`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  ::marker {
    font-size: 12px;
  }
`;

const SpeakerTimeline: React.FC<{ timelineData: any; speaker: string }> = ({ timelineData, speaker }) => {
  return (
    <FlexDiv direction="column" gap={8} style={{ padding: "0px 24px" }}>
      <AppText fontSize={16} fontWeight={400} lineHeight={22} style={{ paddingLeft: "12px" }}>
        {speaker}
      </AppText>

      <TimelineContainer>
        {!!timelineData?.length ? (
          timelineData?.map((o: any) => (
            <div
              style={{
                height: "100%",
                width: `${o.width}%`,
                backgroundColor: o.talking ? theme.fill.brand.primary : theme.fill.neutral.secondary,
              }}
            />
          ))
        ) : (
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: theme.fill.neutral.secondary,
            }}
          />
        )}
      </TimelineContainer>
    </FlexDiv>
  );
};

const TimelineContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
`;

interface StatusTagProps {
  score: number;
}

const StatusTag: React.FC<StatusTagProps> = ({ score }) => {
  const label = useMemo(() => {
    if (score >= 0.3) {
      return "Positive";
    } else if (score <= -0.3) {
      return "Negative";
    } else {
      return "Neutral";
    }
  }, [score]);
  const color = useMemo(() => {
    switch (label) {
      case "Positive":
        return theme.text.success.primary;
      case "Negative":
        return theme.text.danger.primary;
      default:
        return theme.text.warning.primary;
    }
  }, [label]);

  return (
    <StatusTagContainer color={color}>
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={color}>
        {label}
      </AppText>
    </StatusTagContainer>
  );
};

const StatusTagContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 24px;
  padding: 4px 8px;

  border: 1px solid ${({ color }) => color};
  border-radius: 4px;
`;
