import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText, Tokens } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { PhoenixAppButton, PhoenixIcon, PhoenixTextArea } from "../../UI/Phoenix";
import Switch from "react-switch";
import { info } from "../../../images/NewDesign";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AllTokens } from "../../../types";
import { PhoenixStyledTooltip } from "../../Dumb/PhoenixStyledTooltip";
import { numDialedFromTooltip } from "../../../images";
import { errorToast, successToast } from "../../../utils/toast";
import { throttle } from "lodash";
import { useFlags } from "launchdarkly-react-client-sdk";

const FETCH_ALL_TOKENS = gql`
  query fetchTokensForTemplate {
    fetchTokensForTemplate
  }
`;

const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields {
    fetchCustomFields {
      id
      key
    }
  }
`;

const FETCH_CALL_NOTES_TEMPLATE = gql`
  query fetchCallNotesTemplate {
    fetchCallNotesTemplate {
      body
      included_number_dialed_from
      require_notes
    }
  }
`;

const CREATE_OR_UPDATE_CALL_NOTES_TEMPLATE = gql`
  mutation createOrUpdateCallNotesTemplate($templateInput: CallNotesTemplateInput!) {
    createOrUpdateCallNotesTemplate(TemplateInput: $templateInput) {
      body
      included_number_dialed_from
      require_notes
    }
  }
`;

interface ExpectedTokenResponse {
  fetchTokensForTemplate?: AllTokens;
}

export const StepCallNoteTemplate: React.FC = () => {
  const { callNoteTemplates } = useFlags();

  const [template, setTemplate] = useState("");
  const [numDialedFrom, setNumDialedFrom] = useState(false);
  const [requireNotes, setRequireNotes] = useState(false);

  const { data: dataTokens, loading: loadingTokens } = useQuery<ExpectedTokenResponse>(FETCH_ALL_TOKENS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: dataCustomFields, loading: loadingCustomFields } = useQuery(FETCH_CUSTOM_FIELDS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });
  const { loading: loadingCallNotesTemplate } = useQuery(FETCH_CALL_NOTES_TEMPLATE, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
    onCompleted({ fetchCallNotesTemplate }) {
      const templateData = fetchCallNotesTemplate[0];
      setTemplate(templateData?.body);
      setNumDialedFrom(templateData?.included_number_dialed_from || false);
      setRequireNotes(templateData?.require_notes || false);
    },
  });

  const [createOrUpdateCallNotesTemplate, { loading: loadingCreateOrUpdateCallNotesTemplate }] = useMutation(
    CREATE_OR_UPDATE_CALL_NOTES_TEMPLATE,
    {
      onCompleted({ createOrUpdateCallNotesTemplate }) {
        successToast("Template saved!");
        console.log("createOrUpdateCallNotesTemplate: ", createOrUpdateCallNotesTemplate);
      },
      onError({ message }) {
        errorToast(message);
      },
    },
  );

  const tokens = useMemo(() => {
    const customFieldTokens = dataCustomFields?.fetchCustomFields?.map((field: { id: string; key: string }) => ({
      token: `contact_customField.${field.key}`,
      token_formatted: `[contact_customField.${field.key}]`,
      fallback: "",
    }));
    return { ...dataTokens?.fetchTokensForTemplate, "Custom Field": customFieldTokens };
  }, [dataTokens, dataCustomFields]);

  // 2 second throttle to prevent spamming server
  const [isSaving, setIsSaving] = useState(false);
  const throttledSave = useMemo(
    () =>
      throttle(
        async () => {
          setIsSaving(true);
          try {
            await createOrUpdateCallNotesTemplate({
              variables: {
                templateInput: {
                  body: template,
                  included_number_dialed_from: numDialedFrom,
                  require_notes: requireNotes,
                },
              },
            });
          } finally {
            setIsSaving(false);
          }
        },
        2000,
        { leading: true, trailing: false },
      ),
    [createOrUpdateCallNotesTemplate, template, numDialedFrom, requireNotes],
  );

  if (!callNoteTemplates || callNoteTemplates === undefined) return null;

  return (
    <Main>
      <PhoenixStyledTooltip
        place="right"
        id={"num-dialed-from"}
        fontSize={10}
        padding={8}
        imgSrc={numDialedFromTooltip}
        imgAlt="Number Dialed From Example"
        imgSize={244}
      />

      <Header>
        <AppText fontSize={22} fontWeight={500} lineHeight={28}>
          Call Notes Template
        </AppText>
        <PhoenixAppButton
          variant="brand"
          buttonType="secondary"
          disabled={loadingCallNotesTemplate || loadingCreateOrUpdateCallNotesTemplate || isSaving}
          onClick={throttledSave}
        >
          Save
        </PhoenixAppButton>
      </Header>

      <Body>
        <FlexDiv direction="column" gap={24} width={600}>
          <FlexDiv align="center" gap={8}>
            <Switch
              checked={numDialedFrom}
              onChange={(check) => setNumDialedFrom(check)}
              onColor={theme.fill.brand.primary}
              height={16}
              width={32}
              checkedIcon={false}
              uncheckedIcon={false}
              handleDiameter={12}
              disabled={loadingCallNotesTemplate}
            />

            <FlexDiv align="center" gap={4}>
              <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                Included Number Dialed From
              </AppText>
              <PhoenixIcon svg={info} size={16} variant="brand" data-for="num-dialed-from" data-tip="" />
            </FlexDiv>
          </FlexDiv>
          <FlexDiv align="center" gap={8}>
            <Switch
              checked={requireNotes}
              onChange={(check) => setRequireNotes(check)}
              onColor={theme.fill.brand.primary}
              height={16}
              width={32}
              checkedIcon={false}
              uncheckedIcon={false}
              handleDiameter={12}
              disabled={loadingCallNotesTemplate}
            />

            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
              Require notes on all calls before proceeding to the next action
            </AppText>
          </FlexDiv>

          <FlexDiv direction="column" gap={8}>
            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
              Call Notes
            </AppText>
            <PhoenixTextArea
              value={template}
              onChange={(e) => setTemplate(e.target.value)}
              ableToResize={false}
              hideError
              height={160}
              width="100%"
              cursorColor={theme.border.neutral.primary}
              borderColor={theme.border.neutral.primary}
              backgroundColor={theme.surface.neutral.primary}
              placeholder={loadingCallNotesTemplate ? "Loading..." : "Text goes here"}
              readOnly={loadingCallNotesTemplate}
            />
          </FlexDiv>

          <TokenContainer>
            <Tokens loading={loadingTokens || loadingCustomFields} tokens={tokens} ignoreOverflow />
          </TokenContainer>
        </FlexDiv>
      </Body>
    </Main>
  );
};

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 90px;
  padding: 24px 40px;

  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const Body = styled.div`
  display: flex;
  justify-content: center;

  height: 80vh;
  padding: 40px;

  background-color: ${theme.surface.brand.secondary};

  @media (min-height: 1080px) {
    height: 86vh;
  }
`;

const TokenContainer = styled.div`
  overflow-y: auto;
`;
