import * as React from "react";
import * as Sentry from "@sentry/react";
import ReactTooltip from "react-tooltip";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useContext, useEffect } from "react";
import { ModalContext } from "../../context";
import styled, { css, keyframes } from "styled-components";

import { theme } from "../../utils/theme";
import { formatUSDRaw } from "../../utils/format";
import { renderLeadIntentEventTypeValues } from "../../utils/misc";
import { appToast } from "../../utils/toast";

import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { CORE_LEAD_CARD_DATA } from "../../apollo/fragments";

import { AppSidebarCard, AppText, Loading, DarkDiv, StatCard } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { FlexDiv } from "../UI/FlexDiv";
import { searchIcon } from "../../images";
import { primary_x, merge_white, user_white, xIcon } from "../../images/NewDesign";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";

import { MergeLeadsModal } from "./MergeLeadsModal";
import { loggedInUser } from "../../apollo/cache";
import { info } from "../../images/NewDesign";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput } from "../UI/Phoenix";
import { FETCH_LEADS } from "./MergeContactsModal";
import { Lead } from "../../__generated__/graphql";
import { Modal } from "./Modal";
import { useParams } from "react-router-dom";

const FAVORITE_LEAD = gql`
  mutation favoriteLead($lead_id: String!) {
    favoriteLead(lead_id: $lead_id) {
      id
      favorited
    }
  }
`;

const UNFAVORITE_LEAD = gql`
  mutation unfavoriteLead($lead_id: String!) {
    unfavoriteLead(lead_id: $lead_id) {
      id
      favorited
    }
  }
`;

const ADD_LEAD_CUSTOM_OBJECT_ASSOCIATION = gql`
  mutation upsertLeadCustomObjectRowAssociation($id: String, $data: LeadCustomObjectRowUpsertInput!) {
    upsertLeadCustomObjectRowAssociation(id: $id, data: $data) {
      id
    }
  }
`;

interface ParamTypes {
  row_id: string;
  custom_object_id: string;
}

export const MergeCOLeadsModal: React.FC = () => {
  const { showMergeCOLeadModal, setShowMergeCOLeadModal } = useContext(ModalContext);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [leadData, setLeadData] = useState([] as any);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [reset, setReset] = useState(false);

  const { custom_object_id, row_id } = useParams<ParamTypes>();

  const recordsPerPage = 24;

  const [fetchLeadData, { data: listData, loading: listLoading, error: listError }] = useLazyQuery(FETCH_LEADS, {
    fetchPolicy: "network-only",
    onCompleted() {
      handleListUpdate(reset);
      setSearchResult(search);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    fetchLeadData({
      variables: {
        skip: page * recordsPerPage,
        take: recordsPerPage,
        searchText: search,
      },
    });
  }, []);

  const [upsertLeadCustomObjectRowAssociation, { loading: upsertLoading, error: upsertError }] = useMutation(
    ADD_LEAD_CUSTOM_OBJECT_ASSOCIATION,
    {
      onCompleted: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
      },
      refetchQueries: ["fetchLeadsFromCustomObjectRow"],
    },
  );

  const handleListUpdate = (reset: boolean) => {
    if (reset) {
      const newList = [...listData.fetchLeads];
      listData?.fetchLeads?.length < recordsPerPage ? setHasMore(false) : setHasMore(true);
      setLeadData(newList);
      setReset(false);
      return;
    }
    const newList = [...leadData, ...listData.fetchLeads];
    listData?.fetchLeads?.length < recordsPerPage ? setHasMore(false) : setHasMore(true);
    setLeadData(newList);
  };

  const handleShowMore = () => {
    setPage(page + 1);
    fetchLeadData({
      variables: {
        skip: page * recordsPerPage,
        take: recordsPerPage,
        searchText: search,
      },
    });
  };

  return (
    <Modal open={showMergeCOLeadModal} onClose={() => setShowMergeCOLeadModal(false)} bigCloseButton>
      <ModalHeader>
        <HeaderText>Add Associated Lead</HeaderText>
        <FlexDiv justify="space-between" align="center">
          <form onSubmit={(e) => e.preventDefault()}>
            <SearchBoxContainer>
              <PhoenixInput
                width={374}
                displayNoContextText
                type="search"
                searchInput
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                placeholder="Search"
                insiderightElementOverride={
                  <PhoenixIcon
                    svg={xIcon}
                    size={16}
                    onClick={() => setSearch("")}
                    style={{ cursor: "pointer" }}
                    variant="neutral"
                  />
                }
              />
              <FlexDiv gap={16} align="center">
                <PhoenixAppButton
                  variant={"brand"}
                  buttonType={"secondary"}
                  type="submit"
                  onClick={() => {
                    setPage(0);
                    setReset(true);
                    setSelectedLead(null);
                    fetchLeadData({
                      variables: {
                        skip: 0,
                        take: recordsPerPage,
                        searchText: search,
                      },
                    });
                  }}
                >
                  SEARCH
                </PhoenixAppButton>
                {listLoading && reset && <Loading />}
              </FlexDiv>
            </SearchBoxContainer>
          </form>
        </FlexDiv>
      </ModalHeader>
      <ModalContentContainer>
        <ScrollDiv id="merge-contact-modal-scroll">
          <InfiniteScroll
            dataLength={leadData.length}
            next={handleShowMore}
            hasMore={hasMore}
            loader={<Loading />}
            scrollableTarget="merge-contact-modal-scroll"
            style={{
              ...InfiniteScrollStyle,
              justifyContent: leadData?.length < 3 && leadData?.length > 0 ? "flex-start" : "space-evenly",
            }}
          >
            {leadData?.length
              ? leadData?.map((ele: Lead, i: number) => (
                  <LeadDetailCard
                    data={ele}
                    key={`${i}-${ele.id}`}
                    selectedLead={selectedLead}
                    setSelectedLead={setSelectedLead}
                  />
                ))
              : !listLoading && <NoneFoundText fontSize={16}>No leads found that match '{searchResult}'</NoneFoundText>}
          </InfiniteScroll>
        </ScrollDiv>
      </ModalContentContainer>
      <ModalFooter>
        <PhoenixAppButton
          buttonType="secondary"
          variant="danger-outline"
          onClick={() => setShowMergeCOLeadModal(false)}
          uppercase={true}
        >
          Cancel
        </PhoenixAppButton>
        <PhoenixAppButton
          buttonType="secondary"
          variant="brand"
          onClick={() => {
            upsertLeadCustomObjectRowAssociation({
              variables: {
                data: {
                  lead_id: selectedLead?.id,
                  row_id: row_id,
                  custom_object_id,
                },
              },
            });
            setShowMergeCOLeadModal(false);
          }}
          uppercase={true}
          disabled={!selectedLead}
        >
          Continue
        </PhoenixAppButton>
      </ModalFooter>
    </Modal>
  );
};

interface LeadDetailCardProps {
  data: Lead;
  selectedLead: Lead | null;
  setSelectedLead: (lead: Lead | null) => void;
}

const LeadDetailCard: React.FC<LeadDetailCardProps> = ({ data, selectedLead, setSelectedLead }) => {
  const [favorite, setFavorite] = useState(data?.favorited);

  const disabled = selectedLead && selectedLead.id !== data.id;
  const [favoriteLead, { loading: favoriteLoading, error: favoriteError }] = useMutation(FAVORITE_LEAD, {
    variables: {
      lead_id: data?.id,
    },
    async onCompleted({ favoriteLead }) {
      if (!favoriteLead) {
        appToast("Error favoriting lead. Something went wrong.");
        return;
      }
      setFavorite(true);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    optimisticResponse: {
      favoriteLead: {
        id: data?.id,
        favorited: true,
        __typename: "Lead",
      },
    },
    refetchQueries: ["FetchAssociateContact"],
  });

  const [unfavoriteLead, { loading: unfavoriteLoading, error: unfavoriteError }] = useMutation(UNFAVORITE_LEAD, {
    variables: {
      lead_id: data?.id,
    },
    async onCompleted({ unfavoriteLead }) {
      if (!unfavoriteLead) {
        appToast("Error unfavoriting lead. Something went wrong.");
        return;
      }
      setFavorite(false);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `Error favoriting lead GraphQL Error: ${message}`,
      });
      console.log("Error favoriting lead: ", message);
    },
    optimisticResponse: {
      unfavoriteLead: {
        id: data?.id,
        favorited: false,
        __typename: "Lead",
      },
    },
    refetchQueries: ["FetchAssociateContact"],
  });

  const renderAssociateButton = () => {
    if (selectedLead && selectedLead.id === data.id) {
      return (
        <MergeButton
          variant="danger-outline"
          buttonType="secondary"
          hoverVariant="danger-outline"
          onClick={() => {
            setSelectedLead(null);
          }}
        >
          <AppText variant="error" fontWeight={600} uppercase={true}>
            Disassociate
          </AppText>
        </MergeButton>
      );
    } else {
      return (
        <MergeButton
          variant="brand"
          buttonType="secondary"
          hoverVariant="brand-outline"
          onClick={() => {
            setSelectedLead(data);
          }}
        >
          <AppText variant="primary" fontWeight={600} uppercase={true}>
            Associate
          </AppText>
        </MergeButton>
      );
    }
  };

  return (
    <LeadCard disabled={disabled || false}>
      {/* header */}
      <FlexDiv gap={8} align={"center"}>
        {favorite ? (
          <AiFillStar color={theme.TERTIARY500} onClick={() => unfavoriteLead()} style={{ cursor: "pointer" }} />
        ) : (
          <AiOutlineStar color={theme.PRIMARY500} onClick={() => favoriteLead()} style={{ cursor: "pointer" }} />
        )}
        <AppText
          fontSize={14}
          fontWeight={600}
          style={{ cursor: "pointer", border: "none" }}
          onClick={() => window.open(`/lead-detail/${data?.id}`, "_blank", "noreferrer")}
        >
          {data?.business_name ? data?.business_name : "NA"}
        </AppText>
      </FlexDiv>

      {/* body */}
      <FlexDiv direction="column" gap={16} justify="space-between" style={{ height: "100%" }}>
        <FlexDiv gap={16}>
          <StatCard variant="primary" icon={user_white} text="PRIMARY" />
          <FlexDiv direction="column">
            <AppText>{data?.full_name}</AppText>
            <AppText>
              {data?.city ? data?.city + ", " : ""}
              {data?.state ? data?.state + " " : ""}
              {data?.city || data?.state
                ? data?.timezone_by_state
                  ? data?.timezone_by_state?.split(" ").pop()
                  : ""
                : data?.timezone ?? ""}
            </AppText>
            <AppText>{data?.industry ?? ""}</AppText>
            <AppText>{`${data?.channel} Call`}</AppText>
            <AppText>{`MRR + One-Time Fees: ${formatUSDRaw(data?.computed_mrr || 0)}`}</AppText>
          </FlexDiv>
        </FlexDiv>

        <FlexDiv direction="column" gap={16}>
          {renderEventTypeCard(data)}
          {renderAssociateButton()}
        </FlexDiv>
      </FlexDiv>
    </LeadCard>
  );
};

interface EventTypeObject {
  [key: string]: any;
}

const ModalHeader = styled.div`
  display: flex;
  padding: 16px 40px 24px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const HeaderText = styled(AppText)`
  color: var(--text-neutral-primary, #000);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 0px 24px;
  height: 576px;
  background: ${theme.surface.brand.secondary};
  width: 816px;
`;

const ModalFooter = styled.div`
  display: flex;
  padding: 16px 40px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const renderEventTypeCard = (lead: EventTypeObject) => {
  const intentEventType = renderLeadIntentEventTypeValues(lead);

  return (
    <RenderEventTypeInfoDiv>
      {/* <EventIconDiv>{intentEventType.icon && <img src={intentEventType.icon} alt="event type icon" />}</EventIconDiv> */}

      <KeyEventDiv>
        <AppText fontSize={12} fontWeight={600} style={{ color: theme.PRIMARY500 }}>
          {intentEventType.leadValue}
        </AppText>
      </KeyEventDiv>

      <div>
        <EventTypeDiv>
          <AppText style={{ fontSize: 14 }}>{intentEventType.label}</AppText>
        </EventTypeDiv>
        <EventTypeMessage>
          <AppText style={{ fontSize: 12 }}>{!!intentEventType.message ? intentEventType.message : "---"}</AppText>
        </EventTypeMessage>
      </div>
      <EventTypeTooltip>
        <ReactTooltip
          place={"left"}
          multiline
          effect="solid"
          css={{
            maxWidth: 200,
            lineHeight: 1.4,
            textAlign: "center",
            fontFamily: theme.PRIMARY_FONT,
          }}
          backgroundColor={theme.PRIMARY800}
          getContent={(dataTip) => (
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "10px",
                lineHeight: "14px",
              }}
            >
              {dataTip}
            </span>
          )}
        />
        <PhoenixIcon data-tip={intentEventType.tooltip} variant="neutral" svg={info} size={14} />
      </EventTypeTooltip>
    </RenderEventTypeInfoDiv>
  );
};

const InfiniteScrollStyle = {
  display: "flex",
  flexWrap: "wrap" as "wrap",
  gap: "16px",
  // columns: "326px",
  // width: "100%",
};

const SearchBoxContainer = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const SearchInput = styled.input`
  width: 320px;
  height: 40px;

  border-radius: 4.4px;
  border: solid 0.9px ${theme.PILL_GRAY};
  padding: 8px 8px 8px 30px;

  font-family: ${theme.PRIMARY_FONT};
  background-color: ${theme.WHITE_COLOR};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${theme.NEUTRAL300};
  }
`;

interface IconProps {
  url: string;
  color: string;
  size: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

const Icon = styled.div<IconProps>`
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
  bottom: ${(props) => props.bottom}px;
  left: ${(props) => props.left}px;
  background-color: ${(props) => props.color};
  -webkit-mask: url(${(props) => props.url}) no-repeat center;
  mask: url(${(props) => props.url}) no-repeat center;
  mask-size: ${(props) => props.size}px;
  transition: background-color 0.2s ease;
`;

const ActionButton = styled(NewAppButton)`
  letter-spacing: 1px;
  font-size: 10px;
  min-width: 95px;
`;

const ScrollDiv = styled.div`
  overflow: auto;
  /* max-height: 750px;
  height: 750px; */
`;

interface LeadCardProps {
  disabled: boolean;
}

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
`;

const LeadCard = styled.div<LeadCardProps>`
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          animation: ${fadeOut} 0.3s ease-out forwards;
        `
      : css`
          pointer-events: auto;
          opacity: 1;
          animation: ${fadeIn} 0.3s ease-out forwards;
        `}
  break-inside: avoid;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 362px;
  padding: 16px;
  background: #fff;
  border: 1px solid ${theme.PILL_GRAY};
  border-radius: 8px;
`;

const RenderEventTypeInfoDiv = styled.div`
  background-color: ${theme.NEUTRAL100};
  border-radius: 8px;
  padding: 16px;
  display: grid;
  grid-template-columns: 76px 1fr;
  height: fit-content;
  position: relative !important;
  grid-column-gap: 8px;
`;

const KeyEventDiv = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-right: 2px solid ${theme.NEUTRAL200};
`;

const EventTypeDiv = styled.div`
  /* grid-area: 1 / 2 / 2 / 4; */
  justify-self: start;
  white-space: nowrap;
`;

const EventTypeMessage = styled.div`
  grid-area: 2 / 1 / 3 / 5;
`;

const EventTypeTooltip = styled.div`
  /* grid-area: 1 / 4 / 2 / 5; */
  /* width: 100%; */
  text-align: right;
  position: absolute;
  right: 16px;
  top: 16px;
`;

const MergeButton = styled(PhoenixAppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  margin-left: auto;
  background-color: ${theme.WHITE_COLOR};
  :focus {
    outline: none;
    outline-offset: 1px;
  }
`;

const NoneFoundText = styled(AppText)`
  color: ${theme.NEUTRAL300};
  margin-top: 32px;
  animation: fadeIn 0.3s forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
