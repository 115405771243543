import styled from "styled-components";
import * as React from "react";
import { AppErrorText, AppInput, AppText, Loading } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import { useContext, useState } from "react";
import {
  iconButtonArrowGray,
  iconNewMoveRow,
  newCloseModal,
  searchIcon,
  showMoreArrow,
  tempIconUpDown,
} from "../../images";
import { NewAppButton } from "../UI/NewAppButton";
import { GridFilterContext } from "../../context";
import { AppCheckbox } from "../UI/AppCheckbox";
import { gql, useMutation, useQuery } from "@apollo/client";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { appToast } from "../../utils/toast";
import * as Sentry from "@sentry/react";
import { FETCH_USER_CALL_REPORT_COLUMNS } from ".";
import { downloadResourceAWS } from "../../utils/misc";
import moment from "moment";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField } from "../Field";

const EXPORT_CALL_REPORT = gql`
  mutation exportCallReport(
    $dashboard_detail_args: DashboardDetailArgument!
    $column_options: [CallReportListSortOption!]!
  ) {
    exportCallReport(dashboard_detail_args: $dashboard_detail_args, column_options: $column_options) {
      url
    }
  }
`;

interface ExportCallsProps {
  dashboardVars: object;
}

const searchboxSchema = Yup.object().shape({
  available_fields_search: Yup.string().notRequired(),
});

interface MyFormikProps {
  available_fields_search: string;
}

const ExportCallListModal: React.FC<ExportCallsProps> = ({ dashboardVars }) => {
  // const [searchTerm, setSearchTerm] = useState("");

  const [selectedAvailableFields, setSelectedAvailableFields] = useState([] as string[]);
  const [selectedincludedFields, setSelectedincludedFields] = useState([] as string[]);
  const [includedFields, setIncludedFields] = useState([] as string[]);

  const { callsExportModal, setCallsExportModal, tableView } = useContext(GridFilterContext);

  // const [tempLeadFilter, setTempLeadFilter] = useState(leadFilter);
  // const [tempSelected, setTempSelected] = useState(selected);

  const dataAllColumns = [
    { metric_label: "Time of Call", metric: "CallTime", fixed: false },
    { metric_label: "Lead ID", metric: "LeadID", fixed: false },
    { metric_label: "Contact Name", metric: "LeadName", fixed: false },
    { metric_label: "Business Name", metric: "BusinessName", fixed: false },
    { metric_label: "Sales Rep", metric: "RepName", fixed: false },
    { metric_label: "Call Duration", metric: "CallDuration", fixed: false },
    { metric_label: "Call Result", metric: "CallResult", fixed: false },
    { metric_label: "Set", metric: "Set", fixed: true },
    { metric_label: "Held", metric: "Held", fixed: true },
    { metric_label: "Closed", metric: "Close", fixed: true },
    { metric_label: "Time of Event", metric: "EventTime", fixed: false },
    { metric_label: "Sales Rep Who Set Demo", metric: "SetByUser", fixed: false },
    { metric_label: "Sales Rep Who made the Sale", metric: "ClosedByUser", fixed: false },
    { metric_label: "Industry", metric: "Industry", fixed: false },
    { metric_label: "Sub-Industry", metric: "SubIndustry", fixed: false },
    { metric_label: "Lead Source", metric: "LeadSource", fixed: false },
    { metric_label: "Revenue", metric: "Revenue", fixed: false },
    { metric_label: "Revenue Value", metric: "RevenueValue", fixed: false },
    { metric_label: "Recurring Revenue", metric: "RecurringRevenue", fixed: false },
    { metric_label: "Associated Action", metric: "AssociatedAction", fixed: false },
    { metric_label: "Days Set Out", metric: "DaysSetOut", fixed: false },
    { metric_label: "Dials: Current Sales Cycle", metric: "DialsInCurrentSalesCycle", fixed: false },
    { metric_label: "Call End Reason", metric: "CallEndReason", fixed: false },
    // { metric_label: "Next Scheduled Event", metric: "nextScheduledEvent", fixed: false },
    // { metric_label: "Next Scheduled Event Type", metric: "TypeOfNextScheduledEvent", fixed: false },
    // { metric_label: "Time of Most Recent Call", metric: "MostRecentCallTime", fixed: false },
  ];

  const { data: dataColumns, loading: loadingColumns, error: errorColumns } = useQuery(FETCH_USER_CALL_REPORT_COLUMNS, {
    fetchPolicy: "network-only",
    onError({ message }) {
      // Sentry.captureEvent({
      //   message: `fetchUser for Call Report Columns GraphQL Error: ${message}`,
      // });
      console.log(`Error in fetchUser for Call Report Columns: `, message);
      appToast(message);
    },
    onCompleted() {
      setIncludedFields(dataColumns?.fetchUser?.visible_call_report_columns_computed);
    },
  });

  const [exportCallReport, { loading: loadingExport }] = useMutation(EXPORT_CALL_REPORT, {
    async onCompleted({ exportCallReport }) {
      console.log("exportCallReport: ", exportCallReport);
      if (!exportCallReport) {
        appToast("Error exporting calls. Something went wrong.");
        return;
      }
      appToast("Success! Download should begin shortly");
      !!exportCallReport.url &&
        downloadResourceAWS(exportCallReport.url, `callreports-${moment().format("YYYY-MM-DD-hhmma")}.csv`);
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `exportCallReport GraphQL Error: ${message}`,
      });
      console.log("Error in exportCallReport: ", message);
    },
  });

  const reorderReps = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setIncludedFields(result);
  };

  const onDragEndReps = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorderReps(includedFields, result.source.index, result.destination.index);
  };

  return (
    <Modal open={callsExportModal} onClose={() => setCallsExportModal(false)}>
      <EditCardDiv>
        <TitleDiv>
          <TitleText>Customize Export</TitleText>
        </TitleDiv>
        <Formik
          initialValues={{
            available_fields_search: "",
          }}
          validationSchema={searchboxSchema}
          onSubmit={() => {}}
        >
          {({ values }: FormikProps<MyFormikProps>) => {
            return (
              <ScrollDiv>
                <RepListsGrid>
                  <SelectRepsContainer>
                    <AppText>Available fields</AppText>
                    <RepsListContainer>
                      <SearchRepsInput name="available_fields_search" placeholder="Search Fields" />
                      <RepsScrollDiv>
                        {!!loadingColumns ? (
                          <Loading />
                        ) : errorColumns ? (
                          <AppErrorText>Error loading reps</AppErrorText>
                        ) : (
                          !!dataColumns &&
                          dataAllColumns
                            .slice()
                            .filter((item: any) => {
                              return (
                                (!values.available_fields_search ||
                                  `${item.metric_label}`
                                    .toLowerCase()
                                    .includes(values.available_fields_search.toLowerCase())) &&
                                !includedFields.includes(item.metric)
                                //  &&
                                // ["nextScheduledEvent", "TypeOfNextScheduledEvent", "MostRecentCallTime"].includes(
                                //   item.metric,
                                // ) &&
                                // tableView === "Pipeline"
                              );
                            })
                            ?.map((item: any) => (
                              <AppCheckbox
                                title={item.metric_label}
                                checked={selectedAvailableFields.includes(item.metric)}
                                onClick={() => {
                                  selectedAvailableFields.includes(item.metric)
                                    ? setSelectedAvailableFields(
                                        selectedAvailableFields.slice().filter((li: string) => li !== item.metric),
                                      )
                                    : setSelectedAvailableFields([...selectedAvailableFields, item.metric]);
                                }}
                              />
                            ))
                        )}
                      </RepsScrollDiv>
                    </RepsListContainer>
                  </SelectRepsContainer>

                  <FlexColDiv>
                    <IconButton
                      style={{ marginBottom: "8px" }}
                      onClick={() => {
                        setIncludedFields([...includedFields, ...selectedAvailableFields]);
                        setSelectedAvailableFields([]);
                      }}
                    >
                      <img src={iconButtonArrowGray} alt="Add selected fields to included" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setIncludedFields(
                          includedFields.slice().filter((item) => !selectedincludedFields.includes(item)),
                        );
                        setSelectedincludedFields([]);
                      }}
                    >
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src={iconButtonArrowGray}
                        alt="Remove selected fields from included"
                      />
                    </IconButton>
                  </FlexColDiv>

                  <SelectRepsContainer>
                    <SpaceBetweenDiv>
                      <AppText>Included fields</AppText>
                    </SpaceBetweenDiv>
                    <RepsListContainer>
                      <DragDropContext onDragEnd={onDragEndReps}>
                        <Droppable droppableId="droppablecolfields">
                          {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              <DragElements>
                                {loadingColumns ? (
                                  <Loading />
                                ) : errorColumns ? (
                                  <AppErrorText>Error loading reps</AppErrorText>
                                ) : (
                                  !!dataColumns && (
                                    <>
                                      {dataAllColumns
                                        .slice()
                                        .filter((item: any) => includedFields.includes(item.metric))
                                        .sort(
                                          (a, b) => includedFields.indexOf(a.metric) - includedFields.indexOf(b.metric),
                                        )
                                        ?.map((item: any, index: number) => (
                                          <Draggable key={item.metric} draggableId={item.metric} index={index}>
                                            {(provided, snapshot) => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <RepDraggableDiv>
                                                  <img
                                                    style={{ marginRight: "8px", marginTop: "6px" }}
                                                    src={iconNewMoveRow}
                                                    height="12px"
                                                    alt="Drag me"
                                                  />
                                                  <AppCheckbox
                                                    // disabled={item.fixed}
                                                    title={item.metric_label}
                                                    checked={selectedincludedFields.includes(item.metric)}
                                                    onClick={() =>
                                                      selectedincludedFields.includes(item.metric)
                                                        ? setSelectedincludedFields(
                                                            selectedincludedFields
                                                              .slice()
                                                              .filter((li: string) => li !== item.metric),
                                                          )
                                                        : setSelectedincludedFields([
                                                            ...selectedincludedFields,
                                                            item.metric,
                                                          ])
                                                    }
                                                  />
                                                </RepDraggableDiv>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                    </>
                                  )
                                )}
                              </DragElements>
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </RepsListContainer>
                  </SelectRepsContainer>
                </RepListsGrid>
              </ScrollDiv>
            );
          }}
        </Formik>
        <SubmitDiv>
          <ManageButtons
            onClick={() => {
              setCallsExportModal(!callsExportModal);
            }}
          >
            Cancel
          </ManageButtons>
          <ManageButtons
            variant={"primary"}
            onClick={async () => {
              await exportCallReport({
                variables: {
                  dashboard_detail_args: dashboardVars,
                  column_options: includedFields,
                },
              });
            }}
            disabled={includedFields.length === 0 || loadingExport}
          >
            Save
          </ManageButtons>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const DisabledDiv = styled.div`
  padding-left: 20px;
  /* margin-bottom: 12px; */
  display: flex;
  align-items: center;
  height: 24px;
`;

const DisabledOptionText = styled(AppText)`
  opacity: 0.6;
`;

const MaxError = styled(AppText)`
  color: ${theme.ATTENTION700};
  margin-top: 8px;
  font-size: 10px;
`;

const ContainerDivSort = styled.div`
  min-width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RepDraggableDiv = styled.div`
  display: flex;
`;

const DragElements = styled.div`
  max-height: 208px;
  min-height: 208px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const IconButton = styled(NewAppButton)`
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RepListsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-gap: 16px;
  margin: 24px 30px;
`;

const FlexColDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const RepsScrollDiv = styled.div`
  max-height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SearchRepsInput = styled(InputField)`
  background-color: ${theme.NEUTRAL100};
  margin: 0px;
  /* margin-top: 16px; */
  padding-left: 40px;
  z-index: 10;
  background: url(${searchIcon}) no-repeat scroll 15px 10px;
`;

const RepsListContainer = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  height: 240px;
  overflow: hidden;
  padding: 16px;
  background-color: ${theme.WHITE_COLOR};
  margin-top: 12px;
  border-radius: 2px;
`;

const SelectRepsContainer = styled.div`
  width: 330px;
`;

const ManageButtons = styled(NewAppButton)`
  width: 186px;
  height: 48px;
`;

const ScrollDiv = styled.div`
  max-height: 434px;
  height: 434px;
  overflow-y: auto;
`;

const SubmitDiv = styled.div`
  position: absolute;
  height: 80px;
  bottom: 0px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const PaddingAndScrollDiv = styled.div`
  /* padding-bottom: 20px; */
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  padding: 24px 32px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const FilterOptionsContainer = styled.div``;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionContainerDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  max-height: 300px;
  /* padding-bottom: 20px; */
  /* overflow: auto; */
`;

const EditCardDiv = styled.div`
  /* position: absolute; */
  /* left: 25px;
  top: 50px; */
  z-index: 15;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 7px;
  /* background: ${theme.NEUTRAL100}; */
  /* border-radius: 50%; */
  cursor: pointer;
  /* padding: 3px; */
  z-index: 5;
`;

export { ExportCallListModal };
