import styled from "styled-components";
import * as React from "react";

import { useContext } from "react";
import { AppText, SkeletonBlock } from "../UI";
import { theme } from "../../utils/theme";
import gql from "graphql-tag";
import { ModalContext } from "../../context";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { useMutation } from "@apollo/client";
import { appToast } from "../../utils/toast";

const LEAD_BATCH_PHASE_UPDATE = gql`
  mutation LeadBatchPhaseUpdate($leadIds: [String!]!, $phase: PHASE!) {
    leadBatchPhaseUpdate(lead_ids: $leadIds, phase: $phase) {
      error_count
      errors {
        lead_id
        message
      }
      success_count
      total
    }
  }
`;

interface LeadBatchPhaseUpdateExpectedResponse {
  leadBatchPhaseUpdate?: {
    error_count?: number;
    errors?: {
      lead_id?: string;
      message?: string;
    }[];
    success_count?: number;
    total?: number;
  };
}

const BulkStatusChangeModal: React.FC = () => {
  const { showBulkStatusChangeModal, setShowBulkStatusChangeModal, bulkStatusChangeModalData } = useContext(
    ModalContext,
  );

  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const [successCount, setSuccessCount] = React.useState<number>(0);
  const [errorCount, setErrorCount] = React.useState<number>(0);

  const hasErrors = errorMessages.length > 0;

  const [
    bulkUpdateLeadStatus,
    { loading: loadingBulkUpdateLeadStatus, error: errorBulkUpdateLeadStatus },
  ] = useMutation<LeadBatchPhaseUpdateExpectedResponse>(LEAD_BATCH_PHASE_UPDATE, {
    onCompleted: (data) => {
      if (data?.leadBatchPhaseUpdate?.error_count === 0) {
        appToast("All Leads updated successfully");
        setShowBulkStatusChangeModal(false);
      } else {
        appToast(`${data?.leadBatchPhaseUpdate?.success_count} lead(s) updated successfully`);
        appToast(`${data?.leadBatchPhaseUpdate?.error_count} lead(s) failed to update`);
        setSuccessCount(data?.leadBatchPhaseUpdate?.success_count || 0);
        setErrorCount(data?.leadBatchPhaseUpdate?.error_count || 0);
        setErrorMessages(data?.leadBatchPhaseUpdate?.errors?.map((error) => error.message || "") || ([] as string[]));
      }
    },
    onError: (error) => {
      appToast("Something went wrong. Please try again later.");
    },
  });

  const handleCancel = () => {
    setShowBulkStatusChangeModal(false);
  };
  const handleContinue = () => {
    bulkUpdateLeadStatus({
      variables: {
        leadIds: bulkStatusChangeModalData?.selected_lead_ids || [],
        phase: bulkStatusChangeModalData?.desired_status || "",
      },
    });
  };

  const loading = loadingBulkUpdateLeadStatus;
  const globalError = errorBulkUpdateLeadStatus;

  if (loading) {
    return (
      <Modal open={showBulkStatusChangeModal} onClose={() => setShowBulkStatusChangeModal(false)} bigCloseButton>
        <SkeletonBlock height={400} width={"100%"} borderRadius={6} />
      </Modal>
    );
  }

  if (globalError) {
    return (
      <Modal open={showBulkStatusChangeModal} onClose={() => setShowBulkStatusChangeModal(false)} bigCloseButton>
        <div>Something went wrong. Please try again later.</div>
      </Modal>
    );
  }

  return (
    <Modal open={showBulkStatusChangeModal} onClose={() => setShowBulkStatusChangeModal(false)} bigCloseButton>
      <EditCardDiv>
        <TitleDiv>
          <TitleText>Update Status To {bulkStatusChangeModalData?.desired_status} </TitleText>
        </TitleDiv>

        <FlexDiv>
          {!hasErrors ? (
            <ErrorStep />
          ) : (
            <Main>
              <AppText
                style={{
                  color: theme.SUCCESS500,
                }}
              >
                {successCount} lead(s) updated successfully.
              </AppText>
              <AppText
                style={{
                  color: theme.DANGER500,
                }}
              >
                Unable to update status for {errorCount} lead(s).
              </AppText>
              <AppText>Please fix the errors below and try again.</AppText>
              <ul>
                {errorMessages?.map((message) => (
                  <li key={message}>
                    <AppText>{message}</AppText>
                  </li>
                ))}
              </ul>
            </Main>
          )}
        </FlexDiv>
        <SubmitDiv>
          <PhoenixAppButton
            variant="danger-outline"
            buttonType="secondary"
            style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
            onClick={handleCancel}
          >
            Cancel
          </PhoenixAppButton>

          <PhoenixAppButton
            variant="brand"
            buttonType="secondary"
            disabled={hasErrors}
            onClick={handleContinue}
            style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
          >
            Continue
          </PhoenixAppButton>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const ErrorStep = ({
  bulkStatusChangeModalData,
}: {
  bulkStatusChangeModalData?: {
    selected_lead_ids: string[];
  };
}) => {
  return (
    <ErrorDiv>
      <AppText fontSize={14} style={{ textAlign: "center" }} fontWeight={400}>
        Are you sure you want to update the status of these {bulkStatusChangeModalData?.selected_lead_ids?.length} lead
        {`${(bulkStatusChangeModalData?.selected_lead_ids?.length ?? 0) > 1 ? "s" : ""}`}?
      </AppText>
    </ErrorDiv>
  );
};
const ErrorDiv = styled.div`
  display: flex;
  height: 112px;
  width: 396px;
  align-items: center;
  justify-content: center;
`;

const Main = styled.div`
  display: flex;
  min-height: 112px;
  align-items: center;
  justify-content: center;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 24px 40px 24px;
  height: 100%;
  align-items: center;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
  position: relative;
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 476px;
`;

const SubmitDiv = styled.div`
  border-top: 1px solid ${theme.border.neutral.secondary};
  padding-top: 16px;
  padding: 16px 40px 16px 40px;

  display: flex;
  justify-content: space-between;
`;

export { BulkStatusChangeModal };
