import React, { Dispatch, SetStateAction, useContext } from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";
import { FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { eye_off } from "../../../images/NewDesign";
import { SaleConfigPage } from "../../../utils/misc";

interface DeleteSalePageModalProps {
  id: string;
  pageState: {
    pages: SaleConfigPage[];
    setPages: Dispatch<SetStateAction<SaleConfigPage[]>>;
    setSelectedPage: Dispatch<SetStateAction<SaleConfigPage | undefined>>;
    setPageTitle: Dispatch<SetStateAction<string>>;
    hiddenPages: SaleConfigPage[];
    setHiddenPages: Dispatch<SetStateAction<SaleConfigPage[]>>;
  };
  requiredFieldData: string[];
  handleHidePage: (id: string) => void;
}

export const DeleteSalePageModal: React.FC<DeleteSalePageModalProps> = ({
  id,
  pageState: { pages, setPages, setSelectedPage, setPageTitle, hiddenPages, setHiddenPages },
  requiredFieldData,
  handleHidePage,
}) => {
  const { showDeleteSalePageModal, setShowDeleteSalePageModal, deleteHiddenPage, setDeleteHiddenPage } = useContext(
    ModalContext,
  );

  return (
    <Modal
      open={showDeleteSalePageModal}
      onClose={() => {
        setShowDeleteSalePageModal(false);
        !!deleteHiddenPage && setDeleteHiddenPage("");
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody style={{ padding: !deleteHiddenPage ? "40px 40px 22px 40px" : "40px 40px 72px 40px" }}>
          <AppText fontSize={16} fontWeight={600}>
            Delete Page
          </AppText>

          <FlexDiv direction="column" gap={16} align="center">
            <FlexDiv direction="column" align="center">
              <AppText fontSize={14} fontWeight={400}>
                Are you sure you want to delete this page?
              </AppText>
              <AppText fontSize={14} fontWeight={400}>
                You cannot undo this action.
              </AppText>
            </FlexDiv>
            {!deleteHiddenPage && (
              <>
                <FlexDiv
                  align="center"
                  gap={4}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleHidePage(id);
                    setShowDeleteSalePageModal(false);
                    !!deleteHiddenPage && setDeleteHiddenPage("");
                  }}
                >
                  <PhoenixIcon svg={eye_off} variant="brand" hoverColor={theme.PRIMARY500} size={16} pointer />
                  <AppText
                    fontSize={10}
                    fontWeight={600}
                    lineHeight={16}
                    uppercase
                    letterSpacing={1}
                    color={theme.PRIMARY500}
                  >
                    Hide it instead
                  </AppText>
                </FlexDiv>
                <AppText fontSize={14} fontWeight={400} lineHeight={20} color={theme.PILL_DARK_RED}>
                  All required fields will be moved to the last page.
                </AppText>
              </>
            )}
          </FlexDiv>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              setShowDeleteSalePageModal(false);
              !!deleteHiddenPage && setDeleteHiddenPage("");
            }}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              if (!!deleteHiddenPage) {
                const newHiddenPages = hiddenPages.filter((p) => p.id !== deleteHiddenPage);
                setHiddenPages(newHiddenPages);
                setDeleteHiddenPage("");
                setShowDeleteSalePageModal(false);
                return;
              }

              const newPages = pages.filter((p) => p.id !== id);

              // apply required to page if there is only 1 page
              if (newPages.length === 1) {
                newPages[0].required = true;
              }

              // if there are required fields, move them to the last page
              const requiredFields =
                pages
                  .find((p) => p.id === id)
                  ?.sections?.filter((s) => requiredFieldData?.find((f) => f === s.system_field)) || [];
              const lastPage = newPages[newPages.length - 1];
              const lastPageSections = lastPage.sections || [];
              lastPage.sections = lastPageSections.concat(requiredFields);

              setPages(newPages);
              setSelectedPage(newPages[0]);
              setPageTitle(newPages[0].title);
              setShowDeleteSalePageModal(false);
            }}
          >
            Yes, Delete It
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
