import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { appToast } from "src/utils/toast";
import { theme } from "src/utils/theme";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";

import DynamicDropdown from "./DynamicDropdown";
import RadioSelect from "./RadioSelect";
import DateRangePicker from "./DateRangePicker";
import { hasValue } from "src/utils/misc";
import { LeadFilterOperator } from "src/context";
import FilterSelect from "./FilterSelect";
import { FILTER_OPERATIONS } from "./shared";

const GET_DISPOSITION_TYPE_OPTIONS = gql`
  query getDispositionTypeOptions {
    getDispositionTypeOptions
  }
`;

const GET_CALL_RESULT_OPTIONS = gql`
  query getCallResultOptions {
    getCallResultOptions
  }
`;

const CallResultFilter = ({
  onChange,
  onChangeOperator,
  operator,
  removeFilter,
  value,
}: {
  value?: any;
  onChange?: (value: any) => void;
  removeFilter?: () => void;
  onChangeOperator?: (v: LeadFilterOperator) => void;
  operator?: LeadFilterOperator;
}) => {
  const [localValue, setLocalValue] = useState<{
    call_result_type: string | undefined;
    call_result_option: string[];
    lowerbound_date: string | undefined;
    upperbound_date: string | undefined;
  }>(value);
  const [localOperator, setLocalOperator] = useState<LeadFilterOperator | undefined>(operator);
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  const { data: dataDispositionTypeOptions } = useQuery(GET_DISPOSITION_TYPE_OPTIONS, {
    fetchPolicy: "network-only",
  });

  const { data: dataCallResultOptions } = useQuery(GET_CALL_RESULT_OPTIONS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value);
      setLocalOperator(operator);
      hasChangesRef.current = false;
    } else {
      if (!value.call_result_type) {
        setLocalValue({
          ...value,
          call_result_type: "Anytime",
        });
      }
    }
  }, [value, operator, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value) || localOperator !== operator;
  }, [localValue, value, localOperator, operator]);

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (newIsOpen) {
        setIsOpen(newIsOpen);
        return;
      }

      const hasCallResultOption =
        Array.isArray(localValue?.call_result_option) && localValue.call_result_option.length > 0;
      const hasCallResultType = !!localValue?.call_result_type;

      if (hasCallResultOption || hasCallResultType) {
        if (!hasCallResultOption && hasCallResultType) {
          const clearedValue = {
            ...localValue,
            call_result_type: undefined,
          };
          onChange?.(clearedValue);
          onChangeOperator?.(LeadFilterOperator.DoesInclude);
          setLocalValue(clearedValue);
          setLocalOperator(LeadFilterOperator.DoesInclude);
          setIsOpen(false);
          hasChangesRef.current = false;
          return;
        }

        if (!hasCallResultType && hasCallResultOption) {
          appToast("Invalid Call Result Filter! Must specify a type");
          return;
        }
      }

      if (hasChangesRef.current) {
        // Only update operator if we have a valid filter
        if (hasCallResultOption && hasCallResultType) {
          onChange?.(localValue);
          onChangeOperator?.(localOperator as LeadFilterOperator);
        } else {
          onChange?.(localValue);
          onChangeOperator?.(LeadFilterOperator.DoesInclude);
        }
        hasChangesRef.current = false;
      }

      setIsOpen(false);
    },
    [localValue, localOperator, onChange, onChangeOperator],
  );

  const handleValueChange = useCallback((updates: any) => {
    setLocalValue((prev) => ({ ...prev, ...updates }));
    hasChangesRef.current = true;
  }, []);

  const handleClear = useCallback(() => {
    setLocalValue({
      call_result_type: undefined,
      call_result_option: [],
      lowerbound_date: undefined,
      upperbound_date: undefined,
    });
    setLocalOperator(undefined);
    hasChangesRef.current = true;
  }, []);

  const handleOperatorChange = useCallback((v: OptionItem) => {
    setLocalOperator?.(v.value as LeadFilterOperator);
    hasChangesRef.current = true;
  }, []);

  const dispositionTypeOptions: OptionItem[] = [
    ...(dataDispositionTypeOptions?.getDispositionTypeOptions ?? []),
  ].sort((a, b) => a.label.localeCompare(b.label));

  const callResultOptions: OptionItem[] = [...(dataCallResultOptions?.getCallResultOptions ?? [])].sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  const selectedDispositionType = dispositionTypeOptions.find((item) => item.value === localValue?.call_result_type);
  const selectedCallResultOption = useMemo(() => {
    if (!Array.isArray(localValue?.call_result_option)) return [];
    return callResultOptions.filter((item) => localValue.call_result_option.includes(item.value.toString()));
  }, [callResultOptions, localValue?.call_result_option]);

  const label = useMemo(() => {
    return `${selectedDispositionType?.label} ${
      selectedCallResultOption?.length > 0
        ? `that is ${selectedCallResultOption.map((item) => item.label).join(", ")}`
        : ""
    }${
      localValue?.lowerbound_date && localValue?.upperbound_date
        ? `, ${moment(localValue.lowerbound_date).format("MM/DD/YYYY")} - ${moment(localValue.upperbound_date).format(
            "MM/DD/YYYY",
          )}`
        : ""
    }`.trim();
  }, [selectedDispositionType, selectedCallResultOption, localValue]);

  return (
    <DynamicDropdown<string>
      label="Call Result"
      onClear={handleClear}
      isOpen={isOpen}
      setIsOpen={handleIsOpenChange}
      value={localValue?.call_result_type ?? ""}
      onRemove={hasValue(localValue) ? removeFilter : undefined}
      renderHeader={
        <AppText
          fontSize={12}
          color={theme.PRIMARY500}
          style={{ maxWidth: "180px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {label}
        </AppText>
      }
    >
      <FlexDiv direction="column" gap={16}>
        <RadioSelect
          direction={dispositionTypeOptions.length > 2 ? "column" : "row"}
          gap={dispositionTypeOptions.length > 2 ? 16 : 24}
          options={dispositionTypeOptions}
          onChange={(v) =>
            handleValueChange({
              call_result_type: v.value,
              call_result_option: [],
            })
          }
          value={localValue?.call_result_type}
        />

        <FilterSelect
          name="call_result_operator"
          onChange={handleOperatorChange}
          options={FILTER_OPERATIONS}
          value={localOperator}
        />

        <PhoenixMultiSelect
          isMulti
          titleText="Call Result Type"
          titleTextSpacing={8}
          marginBottom={false}
          menuPosition="fixed"
          menuShouldBlockScroll
          name="call_result_option"
          options={callResultOptions}
          placeholder="Select call result type"
          value={selectedCallResultOption}
          onChange={(items: OptionItem[]) => {
            handleValueChange({
              call_result_option: items?.map((item) => item.value),
            });
          }}
        />
        <DateRangePicker
          endDateId="call_result_end_date"
          onChange={(newValue) => handleValueChange(newValue)}
          startDateId="call_result_start_date"
          value={localValue}
        />
      </FlexDiv>
    </DynamicDropdown>
  );
};

export default CallResultFilter;
