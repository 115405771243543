import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { useQuery, gql } from "@apollo/client";
import { ModalContext } from "../../context";
import { AppText, Loading, NewAppButton } from "../UI";
import { Modal } from "./Modal";
import { newCloseModal, iconCopy } from "../../images";
import { formatPhoneNumber } from "../../utils/format";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";

const FETCH_JOIN_DETAILS = gql`
  query fetchConferenceJoinInfo {
    joinInfo: fetchConferenceJoinInfo {
      dial_number
      conference_code
    }
  }
`;

const JoinDetailsModal: React.FC = () => {
  const { data, loading, error, refetch } = useQuery(FETCH_JOIN_DETAILS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { showJoinDetailsModal, setShowJoinDetailsModal } = useContext(ModalContext);

  useEffect(() => {
    if (!(loading || error) && !data.joinInfo.conference_code && showJoinDetailsModal) refetch();
  }, [showJoinDetailsModal]);

  const handleCopy = async () => {
    try {
      const copyStr = `Dial-In: ${formatPhoneNumber(`+1${data.joinInfo.dial_number}`)}\nAccess Pin: ${
        data.joinInfo.conference_code
      }`;
      await navigator.clipboard.writeText(copyStr);
      appToast("Successfully copied join details!");
    } catch (err) {
      console.log("COPYING ERROR", err);
      Sentry.captureEvent({
        message: `Join Details Copying Error: ${err}`,
      });
      appToast("Problem copying join details :(");
    }
  };

  return (
    <Modal open={showJoinDetailsModal} onClose={() => setShowJoinDetailsModal(false)}>
      {loading || error ? (
        <Loading />
      ) : (
        <>
          <TitleDiv>
            <TitleText>Join Details</TitleText>
          </TitleDiv>
          <ContentDiv>
            <DetailsDiv>
              <Detail>Dial-In: {formatPhoneNumber(`+1${data.joinInfo.dial_number}`)}</Detail>
              <Detail>Access Pin: {data.joinInfo.conference_code}</Detail>
            </DetailsDiv>
            <Copy onClick={handleCopy}>
              <img src={iconCopy} placeholder="copy icon" />
              <CopyText>Copy Join Details</CopyText>
            </Copy>
          </ContentDiv>
        </>
      )}
    </Modal>
  );
};

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};

  button {
    position: absolute;
    right: 0;
  }
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const ContentDiv = styled.div`
  margin: 2rem;
  font-size: 14px;
`;

const DetailsDiv = styled.div`
  margin-bottom: 2rem;
`;

const Detail = styled.p`
  margin-bottom: 1rem;
`;

const Copy = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
`;

const CopyText = styled.span`
  color: ${theme.PRIMARY500};
  font-weight: bold;
  margin-left: 0.5rem;
`;

export { JoinDetailsModal };
