import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, Loading } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import * as Sentry from "@sentry/react";
import { useContext } from "react";
import { ModalContext } from "../../context";
import { useMutation, gql } from "@apollo/client";
import { appToast } from "../../utils/toast";
import { sanitizeURL } from "../../utils/misc";

interface DisappearingDivProps {
  close: () => void;
}

const IGNORE_LEAD_UPLOAD_WARNING = gql`
  mutation ignoreLeadUploadWarning($upload_id: String!) {
    ignoreLeadUploadWarning(upload_id: $upload_id)
  }
`;

const LeadCSVUpload: React.FC<DisappearingDivProps> = ({ close }) => {
  const { csvModal, setCsvModal } = useContext(ModalContext);
  const {
    visible,
    num_imported,
    num_updated,
    num_error,
    num_queued,
    error_report_url,
    num_staged,
    upload_id,
  } = csvModal;
  console.log("error_report_url: ", error_report_url);
  console.log(
    "RES: ",
    visible,
    num_imported,
    num_updated,
    num_error,
    num_queued,
    error_report_url,
    num_staged,
    upload_id,
  );

  const [ignoreLeadUploadWarning, { loading: loadingOverride, error: errorOverride }] = useMutation(
    IGNORE_LEAD_UPLOAD_WARNING,
    {
      async onCompleted({ ignoreLeadUploadWarning }) {
        if (!ignoreLeadUploadWarning) {
          appToast("Error updating leads. Something went wrong.");
          return;
        }
        appToast("Leads will be updated");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `ignoreLeadUploadWarning GraphQL Error: ${message}`,
        });
        console.log("Error in ignoreLeadUploadWarning: ", message);
      },
    },
  );

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Follow Up page of Admin Onboarding"}>
      <Modal open={visible} onClose={close}>
        {
          <CenterDiv>
            {num_imported > 0 && (
              <ImportedText>
                {num_imported} {num_updated > 1 ? "Leads Have" : "Lead Has"} Been Imported!
              </ImportedText>
            )}
            {num_updated > 0 && (
              <UpdatedText>
                {num_updated} {num_updated > 1 ? "Leads Have" : "Lead Has"} Been Updated!
              </UpdatedText>
            )}
            {num_queued > 0 && <UpdatedText>{num_queued} Leads Have Been Queued for Upload!</UpdatedText>}
            {!!num_error && <ErrorText>However, {num_error} Entries were Rejected!</ErrorText>}
            {!!error_report_url && (
              <a href={sanitizeURL(error_report_url)} target="_blank" rel="noopener noreferrer">
                <ErrorReportButton>Download Error Report</ErrorReportButton>
              </a>
            )}
            <ExplanationText style={{ marginTop: 15 }}>
              We're processing your leads in the background. You can leave this page.
            </ExplanationText>
            {!!num_error && (
              <ExplanationText style={{ marginTop: 15 }}>
                You may re-upload a CSV with the changes after you close this window
              </ExplanationText>
            )}
            <AddEmailButton type="submit" onClick={close}>
              Continue
            </AddEmailButton>
          </CenterDiv>
        }
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

const CancelClickableText = styled(AppText)`
  text-align: center;
  margin: 10px auto;
  color: ${theme.ATTENTION700};
  border-bottom: 1px solid ${theme.ATTENTION700};
  font-size: 15px;
  cursor: pointer;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 5;
`;

const ImportedText = styled(AppText)`
  font-size: 21px;
  text-align: center;
  font-weight: 500;
`;

const UpdatedText = styled(AppText)`
  font-size: 17px;
  text-align: center;
`;

const ErrorText = styled(AppText)`
  font-size: 17px;
  text-align: center;
  color: ${theme.ATTENTION700};
  margin: 10px;
`;

const ExplanationText = styled(AppText)`
  font-size: 15px;
  text-align: center;
  width: 100%;
`;

const ExplanationTextFocus = styled(AppText)`
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  width: 100%;
`;

const ExplanationContainer = styled.div`
  border: solid 1px #ddddde;
  padding: 20px 30px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ErrorReportButton = styled(AppButton)`
  background-color: ${theme.BLACK_COLOR};
  color: #fff;
  width: 264px;
  height: 45px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 13px;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const AddEmailButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  margin: 25px auto;
`;

export { LeadCSVUpload };
