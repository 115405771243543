import styled, { css } from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { theme } from "../../utils/theme";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AppErrorText, AppText, Loading } from "../UI";
import { useState, useContext, useEffect, useMemo } from "react";
import { CallReportItem } from "../Smart/CallReportItem";
import { FaCaretDown, FaCaretUp, FaPlayCircle } from "react-icons/fa";
import { GridFilterContext, ModalContext } from "../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatBusinessName, formatCallDuration, formatImportDate } from "../../utils/format";
import GreenCheck from "../../images/icons-ic-notifications-accept.svg";
import { iconDownload, iconSave } from "../../images";
import { downloadResourceCors } from "../../utils/misc";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { appToast } from "../../utils/toast";

const FETCH_CALL_REPORTS = gql`
  query fetchCallReportForLead($call_report_lead_args: CallReportLeadArgument!) {
    fetchCallReportForLead(call_report_lead_args: $call_report_lead_args) {
      id
      created_at
      conference {
        id
        duration
        start_time
        recording_url_computed
      }
      related_disposition {
        id
        phase
        person_spoke_to
        type
        label
        associated_action
      }
      schedule_item {
        id
        start_time
        action_label
      }
      user {
        id
        first_name
        last_name
      }
      lead {
        id
        first_name
        last_name
        business_name
        set
        held
        close
      }
    }
  }
`;

const FETCH_USER_CALL_REPORT_COLUMNS = gql`
  query fetchUserCallReportColumns {
    fetchUser {
      id
      visible_call_report_columns_computed
    }
  }
`;

interface CallReportProps {
  userID?: string;
  teamID?: string;
  leadID?: string;
  orgID?: string;
  metric?: string;
  gridFilter: boolean;
  callReportPage?: boolean;
}

const CallReportListLead: React.FC<CallReportProps> = (props) => {
  const [sortAscending, setSortAscending] = useState(false);
  const { dateStart, dateEnd, products, channel, gridFilter } = useContext(GridFilterContext);

  const [reportsList, setReportsList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [hasToBeRefetched, setHasToBeRefetched] = useState(false);

  const takeNumber = 7;

  const { saveCallToLibraryModal, setSaveCallToLibraryModal, currentConferenceID, setCurrentConferenceID } = useContext(
    ModalContext,
  );

  const { data: listData, loading: listLoading, error: listError, refetch, called } = useQuery(FETCH_CALL_REPORTS, {
    variables: {
      call_report_lead_args: {
        lead_id: !!props.leadID ? props.leadID : undefined,
        skip: skip,
        take: takeNumber,
        order_by: !!props.metric && props.metric === "Pipeline" ? "EventTime" : "CallTime",
        desc: !sortAscending,
      },
    },
    fetchPolicy: "network-only",
    onCompleted() {
      const newList = [...reportsList, ...listData.fetchCallReportForLead].reduce(
        (acc, cv) => (acc?.map((a: any) => a.id).includes(cv.id) ? acc : [...acc, cv]),
        [],
      );
      setReportsList(newList);
      // setReportsList(reportsList.concat(listData.fetchCallReportForLead));
      if (listData.fetchCallReportForLead.length < takeNumber) setHasMore(false);
      else setHasMore(true);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataColumns, loading: loadingColumns, error: errorColumns } = useQuery(FETCH_USER_CALL_REPORT_COLUMNS, {
    fetchPolicy: "network-only",
    onError({ message }) {
      // Sentry.captureEvent({
      //   message: `fetchUser for Call Report Columns GraphQL Error: ${message}`,
      // });
      console.log(`Error in fetchUser for Call Report Columns: `, message);
      appToast(message);
    },
  });

  const handleShowMore = () => {
    setSkip(skip + takeNumber);
    // refetch();
  };

  useEffect(() => {
    if (!called) {
      return;
    }
    setReportsList([]);
    setSkip(0);
    setHasMore(true);
  }, [
    props.gridFilter,
    props.leadID,
    props.metric,
    props.orgID,
    props.teamID,
    props.userID,
    channel,
    products,
    dateStart,
    dateEnd,
    sortAscending,
  ]);

  // useEffect(() => {
  //   if (hasToBeRefetched === true) {
  //     // funcRefetch();
  //     setHasToBeRefetched(false);
  //   }
  // }, [hasToBeRefetched]);

  // if (listLoading)
  //   return (
  //     <ListContainer callReportPage={props.callReportPage}>
  //       <Loading />
  //     </ListContainer>
  //   );

  const visibleColumns = useMemo(() => {
    return dataColumns?.fetchUser?.visible_call_report_columns_computed ?? [];
  }, [dataColumns]);

  if (listError || errorColumns)
    return (
      <ListContainer callReportPage={props.callReportPage}>
        <AppErrorText>Error Loading Items</AppErrorText>
      </ListContainer>
    );

  if (loadingColumns)
    return (
      <ListContainer callReportPage={props.callReportPage}>
        <Loading />
      </ListContainer>
    );

  // If no call reports available.
  if (!listLoading && reportsList.length === 0 && listData?.fetchCallReportForLead?.length === 0)
    return (
      <ListContainer
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        callReportPage={props.callReportPage}
      >
        <AppText>No reports to display.</AppText>
      </ListContainer>
    );

  const numberOfAdjustableColumns = visibleColumns
    .slice()
    .filter((item: string) => !["Set", "Held", "Close"].includes(item)).length;

  const RenderTableHeaderFor = (metric: string) => {
    switch (metric) {
      case "CallTime":
        return (
          <CallListTH style={{ minWidth: "140px" }}>
            <HeaderText
              style={{
                cursor:
                  props.metric && !["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric)
                    ? "pointer"
                    : "default",
              }}
              onClick={() =>
                props.metric &&
                !["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric) &&
                setSortAscending(!sortAscending)
              }
            >
              Time of Call{" "}
              {props.metric &&
                !["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric) &&
                (sortAscending ? <FaCaretUp /> : <FaCaretDown />)}
            </HeaderText>
          </CallListTH>
        );
      case "LeadName":
        return (
          <CallListTH>
            <HeaderText>Contact Name</HeaderText>
          </CallListTH>
        );
      case "BusinessName":
        return (
          <CallListTH>
            <HeaderText>Business Name</HeaderText>
          </CallListTH>
        );
      case "RepName":
        return (
          <CallListTH>
            <HeaderText>Sales Rep</HeaderText>
          </CallListTH>
        );
      case "CallResult":
        return (
          <CallListTH>
            <HeaderText>
              {props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric)
                ? "Associated Action"
                : "Call Result"}
            </HeaderText>
          </CallListTH>
        );
      case "EventTime":
        return (
          <CallListTH style={{ width: "140px" }}>
            <HeaderText>Time of Event</HeaderText>
          </CallListTH>
        );
      case "Set":
        return (
          <CallListTH style={{ width: "40px" }}>
            <HeaderTextCenter>Set</HeaderTextCenter>
          </CallListTH>
        );
      case "Held":
        return (
          <CallListTH style={{ width: "40px" }}>
            <HeaderTextCenter>Held</HeaderTextCenter>
          </CallListTH>
        );
      case "Close":
        return (
          <CallListTH style={{ width: "40px" }}>
            <HeaderTextCenter>Closed</HeaderTextCenter>
          </CallListTH>
        );

      default:
        return <></>;
    }
  };

  const RenderTableDataFor = (metric: string, item: any) => {
    switch (metric) {
      case "CallTime":
        const isMetricRelevant =
          !!props.metric && ["AllSales", "TotalRevenueValue", "TotalRevenue"].includes(props.metric);
        const isPipelineMetric = props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric);
        const isNonCall = item?.communication_type === "NonCall";

        const dateToDisplay = isMetricRelevant
          ? item.created_at
          : isPipelineMetric
          ? item.conference?.start_time
          : isNonCall
          ? item?.created_at
          : item?.conference?.start_time ?? item?.created_at;

        return (
          <TableData style={{ minWidth: "140px" }}>
            <ListItemText>{formatImportDate(dateToDisplay)}</ListItemText>
          </TableData>
        );
      case "LeadName":
        return (
          <TableData>
            <ListItemText>{`${item.lead?.first_name ?? ""} ${item.lead?.last_name ?? ""}`}</ListItemText>
          </TableData>
        );
      case "BusinessName":
        return (
          <TableData>
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.lead?.id ?? ""}`}>
              <ListItemText>{formatBusinessName(item.lead?.business_name)}</ListItemText>
            </WrapAnchor>
          </TableData>
        );
      case "RepName":
        return (
          <TableData>
            <ListItemText>{`${item.user?.first_name ?? ""} ${item.user?.last_name ?? ""}`}</ListItemText>
          </TableData>
        );
      case "CallResult":
        return (
          <TableData>
            <ListItemText>
              {props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric)
                ? item.related_disposition?.associated_action ?? "No Action"
                : item.related_disposition?.label ?? "No Result"}
            </ListItemText>
          </TableData>
        );
      case "EventTime":
        return (
          <TableData style={{ minWidth: "140px" }}>
            <ListItemText>
              {!!item.schedule_item?.start_time
                ? formatImportDate(item.schedule_item?.start_time)
                : formatImportDate(item?.created_at)}
            </ListItemText>
          </TableData>
        );
      case "Set":
        return (
          <TableData>
            <ListItemIcon>{item?.lead?.set ? <img src={GreenCheck} height="10px" /> : "-"}</ListItemIcon>
          </TableData>
        );
      case "Held":
        return (
          <TableData>
            <ListItemIcon>{item?.lead?.held ? <img src={GreenCheck} height="10px" /> : "-"}</ListItemIcon>
          </TableData>
        );
      case "Close":
        return (
          <TableData>
            <ListItemIcon>{item?.lead?.close ? <img src={GreenCheck} height="10px" /> : "-"}</ListItemIcon>
          </TableData>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {/* {reportsList.length} {JSON.stringify(hasMore)} {JSON.stringify(listLoading)} length of return:{" "}
      {listData?.fetchCallReportForLead.length} metric: {props.metric} */}
      {props.callReportPage && <TitleText>Call History</TitleText>}
      {/* {JSON.stringify(visibleColumns)} {numberOfAdjustableColumns} */}
      <ListContainer callReportPage={props.callReportPage} id="call-report-list">
        <InfiniteScroll
          dataLength={reportsList.length}
          next={handleShowMore}
          hasMore={hasMore}
          loader={<Loading />}
          scrollableTarget="call-report-list"
        >
          <MainTable>
            {/* <HeaderDiv> */}
            <CallListTHead>
              <tr>
                {visibleColumns?.map((metric: string) => RenderTableHeaderFor(metric))}
                {/* case "EventTime":
              return (
    <CallListTH>
      {props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric) && (
        <HeaderText onClick={() => setSortAscending(!sortAscending)}>
          Time of Scheduled Activity {sortAscending ? <FaCaretUp /> : <FaCaretDown />}
        </HeaderText>
      )}
      <HeaderText />
    </CallListTH>
                ); */}
                <CallListTH></CallListTH>
              </tr>
            </CallListTHead>
            {/* </HeaderDiv> */}
            <tbody>
              {!!reportsList.length &&
                reportsList
                  .slice()
                  // Sort function needs to update the start time based off which metric is used. Pipeline has a different start time than others.
                  // .sort(function (a: any, b: any) {
                  //   const time =
                  //     !!props.metric && ["AllSales", "TotalRevenueValue", "TotalRevenue"].includes(props.metric)
                  //       ? "created_at"
                  //       : props.metric === "Pipeline"
                  //       ? "schedule_item"
                  //       : "conference" ?? "conference";
                  //   if (time === "created_at") {
                  //     if (a?.created_at < b?.created_at) {
                  //       return sortAscending ? -1 : 1;
                  //     }
                  //     if (a?.created_at > b?.created_at) {
                  //       return sortAscending ? 1 : -1;
                  //     }
                  //   }
                  //   if (a?.[time]?.start_time < b?.[time]?.start_time) {
                  //     return sortAscending ? -1 : 1;
                  //   }
                  //   if (a?.[time]?.start_time > b?.[time]?.start_time) {
                  //     return sortAscending ? 1 : -1;
                  //   } else return 0;
                  // })
                  ?.map((item: any, index: number) => {
                    return (
                      <tr key={`index${index}-conference${item.conference?.id}`} style={{ maxWidth: "990px" }}>
                        {visibleColumns?.map((metric: string) => RenderTableDataFor(metric, item))}
                        <TableData>
                          {!!item?.conference?.recording_url_computed && (
                            <FlexDiv>
                              <ListItemDuration display={!!item.conference?.duration}>
                                <ReactTooltip
                                  multiline
                                  place="bottom"
                                  effect="solid"
                                  css={{
                                    maxWidth: 600,
                                    lineHeight: 1.4,
                                    textAlign: "center",
                                    fontFamily: theme.PRIMARY_FONT,
                                  }}
                                  backgroundColor={theme.PRIMARY800}
                                  getContent={(dataTip) => (
                                    <span
                                      style={{
                                        fontFamily: "Inter",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "10px",
                                        lineHeight: "14px",
                                      }}
                                    >
                                      {dataTip}
                                    </span>
                                  )}
                                />
                                <StyledLink to={`/call-report/${item.conference?.id}`}>
                                  <div style={{ display: "flex", height: "100%", paddingRight: "2px" }}>
                                    <CenterDiv>
                                      <FaPlayCircle
                                        size={10}
                                        color={theme.PRIMARY500}
                                        style={{ marginRight: "6px", marginLeft: "4px" }}
                                      />
                                    </CenterDiv>
                                    <CenterDiv>{formatCallDuration(item.conference?.duration ?? 0)}</CenterDiv>
                                  </div>
                                </StyledLink>
                              </ListItemDuration>
                              {!!item.conference?.duration && (
                                <IconDiv>
                                  <img
                                    data-tip="Save"
                                    onClick={() => {
                                      setCurrentConferenceID(item.conference?.id);
                                      setSaveCallToLibraryModal(true);
                                    }}
                                    src={iconSave}
                                    alt="Save to Call Library"
                                  />
                                  {!!item?.conference?.recording_url_computed && (
                                    <div style={{ marginLeft: "2px" }} data-tip="Download">
                                      {/* <a href={`${recording_url}`} download={`${recording_url.split("/").pop()}.wav`} target="_blank"> */}
                                      <img
                                        src={iconDownload}
                                        alt="Download"
                                        onClick={() => downloadResourceCors(item.conference.recording_url_computed)}
                                      />
                                      {/* </a> */}
                                    </div>
                                  )}
                                </IconDiv>
                              )}
                            </FlexDiv>
                          )}
                        </TableData>
                      </tr>
                    );
                  })}
            </tbody>
          </MainTable>
          {/* {listLoading === true && <Loading />} */}
        </InfiniteScroll>
      </ListContainer>
    </>
  );
};

const AbsoluteDiv = styled.div`
  display: sticky;
  top: 0;
`;

const TableRowHead = styled.div`
  position: sticky;
  top: 0px;
`;

const CallListTHead = styled.thead`
  position: sticky !important;
  inset-block-start: 0;
  top: 0px;
`;

const CallListTH = styled.th`
  /* th { */
  /* position: relative; */

  /* } */
  position: sticky !important;
  inset-block-start: 0;
`;

const IconDiv = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-end;
  align-items: center;
  font-size: 8px;
  line-height: 13px;
  color: ${theme.TERTIARY500};
  margin-left: 10px;
  cursor: pointer;
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;

interface ListItemDurationProps {
  display: boolean;
}

const ListItemDuration = styled.div<ListItemDurationProps>`
  border: solid 0.7px ${theme.PRIMARY500};
  border-radius: 2.1px;
  width: 74px;
  height: 17px;
  font-size: 10px;
  place-self: center;
  display: ${(props) => (props.display ? "flex" : "none")};
  margin-left: 12px;
`;

const WrapAnchor = styled.a`
  max-width: 100px;
  text-overflow: ellipsis;
`;

const TableData = styled.td`
  display: table-cell;
  /* overflow: hidden; */
  height: 40px;
  border-bottom: solid 1px ${theme.NEUTRAL100};
  table-layout: auto;
`;

const ListItemIcon = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListItemText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  padding-right: 15px;
`;

const MainTable = styled.table`
  max-width: 100%;
  position: relative;
  table-layout: auto;
  width: 100%;
  overflow-x: hidden;
`;

const TitleText = styled(AppText)`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.NEUTRAL500};
  margin-top: 14px;
  margin-bottom: 5px;
`;

const HeaderDiv = styled.div`
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 0.3fr 0.3fr 0.3fr 1.5fr;
  align-items: center;
  justify-items: start;
  margin-top: 14px;
  height: 30px;
  top: 0px;
  background-color: ${theme.WHITE_COLOR};
`;

const HeaderText = styled(AppText)`
  font-size: 8px;
  font-weight: 500;
  border-bottom: none;
`;

const HeaderTextCenter = styled(AppText)`
  margin: auto;
  font-size: 8px;
  font-weight: 500;
`;

interface CallReportPageProps {
  callReportPage?: boolean;
}

const ListContainer = styled.div<CallReportPageProps>`
  /* width: ${(props) => (props.callReportPage ? "947px" : "1040px")}; */
  width: 100%;
  height: 299px;
  border-radius: ${(props) => (props.callReportPage ? "3px" : "16px")};
  box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5);
  background-color: ${theme.WHITE_COLOR};
  margin-left: ${(props) => (props.callReportPage ? "0px" : "0px")};
  margin-bottom: ${(props) => (props.callReportPage ? "50px" : "20px")};
  overflow: auto;
  padding: 0px 25px 0px 25px;
`;

export { CallReportListLead };
