import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { CallReportSegment } from "../CallReportSegments";
import { Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { AIProcessingCall } from "./AIProcessingCall";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { PhoenixInput } from "../../UI/Phoenix";
import { useDebounce } from "../../../utils/hooks";
import { ProfileImage } from "../SettingSegments/ProfileImage";
import { CircularProgressbar } from "react-circular-progressbar";

type TranscriptMessage = {
  id: string;
  from: {
    id: string;
    name: string;
  };
  text: string;
  endTime: string;
  phrases: any[];
  duration: number;
  sentiment: {
    polarity: {
      score: number;
    };
    suggested: string;
  };
  startTime: string;
  timeOffset: number;
  conversationId: string;
};

type Topic = {
  id: string;
  text: string;
  type: string;
  score: number;
  messageIds: string[];
  parentRefs: any[];
};

interface TranscriptTabProps {
  aiProcessing: boolean;
  aiData: any;
  conferenceData: any;
}

export const TranscriptTab: React.FC<TranscriptTabProps> = ({ aiProcessing, aiData, conferenceData }) => {
  const [selectedTrackers, setSelectedTrackers] = useState<{ name: string; messageRefs: any[] }[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);
  const [selectedSpeakers, setSelectedSpeakers] = useState<string[]>([]);

  const [heights, setHeights] = useState<{ [key: number]: number }>({});

  const [debouncedTranscriptSearch, setDebouncedTranscriptSearch] = useState<string>("");

  const filteredTranscript = useMemo(() => {
    return conferenceData?.symbol_ai_data?.transcript?.messages?.filter((m: TranscriptMessage) => {
      // OR logic within selectedTopics
      const topicMatch = selectedTopics.length === 0 || selectedTopics.some((st) => st.messageIds.includes(m.id));

      // OR logic within selectedTrackers
      const trackerMatch =
        selectedTrackers.length === 0 || selectedTrackers.some((st) => st.messageRefs.some((mr) => mr.id === m.id));

      // OR logic within selectedSpeakers
      const speakerMatch = selectedSpeakers.length === 0 || selectedSpeakers.includes(m?.from?.name);

      // AND logic between groups
      return topicMatch && trackerMatch && speakerMatch;
    });
  }, [selectedTrackers, selectedTopics, selectedSpeakers, conferenceData]);

  return !aiProcessing ? (
    <FlexDiv>
      <TranscriptFilterContainer>
        <AppText
          fontSize={10}
          fontWeight={600}
          lineHeight={16}
          letterSpacing={1}
          uppercase
          color={theme.text.neutral.secondary}
        >
          Filter Transcript
        </AppText>

        <TranscriptFilterBox label="Speakers" secondaryLabel="Talk Time">
          <FlexDiv direction="column" gap={8}>
            {Object.entries(aiData?.talkTime || {})?.map(([speaker, talkTime]: any) => (
              <SpeakerFilter
                key={speaker}
                speaker={speaker}
                talkTime={talkTime}
                selected={selectedSpeakers.includes(speaker)}
                onClick={() =>
                  selectedSpeakers.includes(speaker)
                    ? setSelectedSpeakers(selectedSpeakers.filter((s: string) => s !== speaker))
                    : setSelectedSpeakers([...selectedSpeakers, speaker])
                }
              />
            ))}
          </FlexDiv>
        </TranscriptFilterBox>

        <TranscriptFilterBox label="Trackers">
          <FlexDiv gap={8} wrap="wrap">
            {aiData?.trackers?.map((tracker: { name: string; messageRefs: any[] }) => (
              <Tracker
                key={tracker.name}
                tracker={tracker.name}
                selected={
                  !!selectedTrackers.find((st: { name: string; messageRefs: any[] }) => st.name === tracker.name)
                }
                onClick={() =>
                  !!selectedTrackers.find((st: { name: string; messageRefs: any[] }) => st.name === tracker.name)
                    ? setSelectedTrackers(
                        selectedTrackers.filter((st: { name: string; messageRefs: any[] }) => st.name !== tracker.name),
                      )
                    : setSelectedTrackers([...selectedTrackers, tracker])
                }
              />
            ))}
          </FlexDiv>
        </TranscriptFilterBox>

        <TranscriptFilterBox label="Topics">
          <FlexDiv gap={8} wrap="wrap">
            {aiData?.topics?.map((topic: Topic) => (
              <Topic
                key={topic.text}
                topic={topic.text}
                selected={!!selectedTopics.find((st: Topic) => st.id === topic.id)}
                onClick={() =>
                  !!selectedTopics.find((st: Topic) => st.id === topic.id)
                    ? setSelectedTopics(selectedTopics.filter((st: Topic) => st.id !== topic.id))
                    : setSelectedTopics([...selectedTopics, topic])
                }
              />
            ))}
          </FlexDiv>
        </TranscriptFilterBox>
      </TranscriptFilterContainer>

      <TranscriptContainer>
        <FlexDiv justify="center" align="center" padding="16px 40px 32px 40px">
          <TranscriptSearch setDebouncedTranscriptSearch={setDebouncedTranscriptSearch} />
        </FlexDiv>
        {aiData?.formattedTranscript
          ?.filter((t: any) => t?.text?.toLowerCase()?.includes(debouncedTranscriptSearch.toLowerCase()))
          ?.map((t: any, i: number) => {
            return (
              <TranscriptRow
                key={`${t?.text}-${i}`}
                text={t?.text}
                speaker={t?.speaker}
                timestamp={t?.timestamp}
                show={!!filteredTranscript.find((ft: any) => ft?.id === t.id)}
                index={i}
                heights={heights}
                setHeights={setHeights}
              />
            );
          })}
      </TranscriptContainer>
    </FlexDiv>
  ) : (
    <AIProcessingCall />
  );
};

interface TranscriptRowProps {
  text: string;
  speaker: string;
  timestamp: string;
  show: boolean;
  index: number;
  heights: { [key: number]: number };
  setHeights: React.Dispatch<React.SetStateAction<{ [key: number]: number }>>;
}

const TranscriptRow: React.FC<TranscriptRowProps> = ({
  text,
  speaker,
  timestamp,
  show,
  setHeights,
  heights,
  index,
}) => {
  const rowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!!rowRef.current && show) {
      setHeights((prevHeights) => ({
        ...prevHeights,
        [index]: rowRef.current ? rowRef.current.scrollHeight : 0,
      }));
    }
  }, [show]);

  return (
    <TranscriptRowContainer ref={rowRef} show={show} height={heights[index] || 0}>
      <AppText
        fontSize={12}
        fontWeight={400}
        lineHeight={18}
        color={theme.text.neutral.tertiary}
        style={{ minWidth: "72px", maxWidth: "72px" }}
      >
        {timestamp}
      </AppText>
      <FlexDiv direction="column" gap={8}>
        <FlexDiv align="center" gap={8}>
          <ProfileImage diameter={24} borderWidth={0} borderDiameter={24} />
          <AppText fontSize={12} fontWeight={500} lineHeight={18}>
            {speaker}
          </AppText>
        </FlexDiv>
        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
          {text}
        </AppText>
      </FlexDiv>
    </TranscriptRowContainer>
  );
};

const TranscriptRowContainer = styled.div<{ show: boolean; height: number }>`
  display: flex;
  gap: 8px;
  padding-left: 24px;
  overflow: hidden;

  min-height: ${({ show, height }) => (show ? `${height}px` : "0")};
  max-height: ${({ show, height }) => (show ? `${height}px` : "0")};

  margin-bottom: ${({ show }) => (show ? "24px" : "0")};

  transition: min-height 0.2s ease-out, max-height 0.2s ease-out, margin 0.2s ease-out;
`;

const TranscriptContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: calc(100vh - 206px);
  overflow-y: auto;

  padding: 16px 0px;
`;

const TranscriptFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 384px;
  height: 100%;

  padding: 24px;

  border-right: 1px solid ${theme.border.neutral.secondary};
`;

interface TranscriptFilterBoxProps {
  label: string;
  secondaryLabel?: string;
  children: React.ReactNode;
}

const TranscriptFilterBox: React.FC<TranscriptFilterBoxProps> = ({ label, secondaryLabel, children }) => {
  return (
    <FlexDiv
      direction="column"
      gap={16}
      style={{ padding: "16px 0px 24px 0px", borderTop: `1px solid ${theme.border.neutral.secondary}` }}
    >
      <FlexDiv justify="space-between">
        <AppText fontSize={14} fontWeight={500} lineHeight={20}>
          {label}
        </AppText>
        {secondaryLabel && (
          <AppText fontSize={14} fontWeight={500} lineHeight={18} color={theme.text.neutral.secondary}>
            {secondaryLabel}
          </AppText>
        )}
      </FlexDiv>
      <div>{children}</div>
    </FlexDiv>
  );
};

interface SpeakerFilterProps {
  talkTime: number;
  speaker: string;
  selected?: boolean;
  onClick?: () => void;
}

const SpeakerFilter: React.FC<SpeakerFilterProps> = ({ talkTime, speaker, selected, onClick }) => {
  const [tt, setTT] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTT(talkTime);
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <SpeakerFilterContainer selected={selected} onClick={onClick}>
      <FlexDiv align="center" gap={8}>
        <ProfileImage diameter={24} borderWidth={0} borderDiameter={24} />
        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
          {speaker}
        </AppText>
      </FlexDiv>

      <FlexDiv align="center" gap={8} style={{ minWidth: "56px", maxWidth: "56px" }}>
        <div style={{ minWidth: "24px", maxWidth: "24px", minHeight: "24px", maxHeight: "24px" }}>
          <CircularProgressbar
            value={tt}
            strokeWidth={12}
            styles={{
              path: {
                stroke: theme.fill.brand.primary,
                strokeLinecap: "round",
                transitionDuration: "0.5s",
              },
              trail: {
                stroke: theme.fill.neutral.tertiary,
              },
            }}
          />
        </div>
        <AppText
          fontSize={10}
          fontWeight={500}
          lineHeight={14}
          color={theme.text.neutral.secondary}
          style={{ marginRight: "auto" }}
        >
          {talkTime}%
        </AppText>
      </FlexDiv>
    </SpeakerFilterContainer>
  );
};

const SpeakerFilterContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 40px;

  padding: 8px;

  background-color: ${({ selected }) => (selected ? theme.fill.brand.tertiary : "none")};
  border: 1px solid ${({ selected }) => (selected ? theme.border.brand.primary : theme.border.neutral.secondary)};
  border-radius: 8px;

  :hover {
    background-color: ${theme.fill.brand.tertiary};
  }
  transition: background-color 0.15s ease-in-out, border 0.1s ease-in-out;
  cursor: pointer;
`;

const Tracker: React.FC<{ tracker: string; selected: boolean; onClick: () => void }> = ({
  tracker,
  selected,
  onClick,
}) => {
  const color = getTrackerColor(tracker);
  return (
    <TrackerContainer selected={selected} color={color} onClick={onClick}>
      <TrackerText fontSize={8} fontWeight={600} lineHeight={12} uppercase selected={selected} textColor={color}>
        {tracker}
      </TrackerText>
    </TrackerContainer>
  );
};

const TrackerText = styled(AppText)<{ selected: boolean; textColor: string }>`
  color: ${(props) => (props.selected ? theme.text.neutral.inverse : props.textColor)};
  transition: color 0.15s ease-in-out;
`;

const TrackerContainer = styled.div<{ selected: boolean; color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  padding: 4px 8px;

  border: 1px solid ${(props) => props.color};
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? props.color : "none")};
  cursor: pointer;

  transition: background-color 0.15s ease-in-out;

  :hover {
    background-color: ${(props) => props.color};
    ${TrackerText} {
      color: ${theme.text.neutral.inverse};
    }
  }
`;

const Topic: React.FC<{ topic: string; selected: boolean; onClick: () => void }> = ({ topic, selected, onClick }) => {
  return (
    <TopicContainer selected={selected} onClick={onClick}>
      <TopicText fontSize={8} fontWeight={600} lineHeight={12} uppercase selected={selected}>
        {topic}
      </TopicText>
    </TopicContainer>
  );
};

const TopicText = styled(AppText)<{ selected: boolean }>`
  color: ${(props) => (props.selected ? theme.text.neutral.inverse : theme.text.neutral.tertiary)};
  transition: color 0.15s ease-in-out;
`;

const TopicContainer = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  padding: 4px 8px;

  border: 1px solid ${theme.fill.neutral.quaternary};
  border-radius: 4px;
  background-color: ${(props) => (props.selected ? theme.fill.neutral.quaternary : "none")};
  cursor: pointer;

  transition: background-color 0.15s ease-in-out;

  :hover {
    background-color: ${theme.fill.neutral.quaternary};
    ${TopicText} {
      color: ${theme.text.neutral.inverse};
    }
  }
`;

interface TranscriptSearchProps {
  setDebouncedTranscriptSearch: React.Dispatch<React.SetStateAction<string>>;
}

const TranscriptSearch: React.FC<TranscriptSearchProps> = ({ setDebouncedTranscriptSearch }) => {
  const [transcriptSearch, setTranscriptSearch] = useState("");

  useDebounce(
    () => {
      setDebouncedTranscriptSearch(transcriptSearch);
    },
    [transcriptSearch],
    400,
  );

  return (
    <div style={{ width: "100%", maxWidth: "900px" }}>
      <PhoenixInput
        placeholder="Search Transcript"
        variant="primary"
        type="text"
        searchInput
        fontSize={10}
        fontWeight={400}
        displayNoContextText
        value={transcriptSearch}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTranscriptSearch(e.target.value)}
        height={40}
      />
    </div>
  );
};

const getTrackerColor = (tracker: string) => {
  const colors = [
    theme.border.success.inverse,
    theme.border.dataviz1.primary,
    theme.border.dataviz4.inverse,
    theme.border.dataviz2.primary,
    theme.border.success.primary,
  ];

  switch (tracker) {
    case "Setting the Demo":
      return colors[0];
    case "Gatekeeper Intro":
      return colors[1];
    case "Confirming The Demo":
      return colors[2];
    case "30 Second Pitch":
      return colors[3];
    case "Decision Maker Intro":
      return colors[4];
    default:
      return colors[Math.floor(Math.random() * colors.length)];
  }
};
