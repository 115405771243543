import moment from "moment";
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { CallContext, ModalContext, UserStatusContext } from "../../context";
import { formatTypeName, formatUSD } from "../../utils/format";
import { theme } from "../../utils/theme";
import { AppText } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { Modal } from "./Modal";

interface EventsModalProps {
  events: any[];
  generalTime?: boolean;
}

const statusColor = (status: string) => {
  switch (status) {
    case "Accepted":
      return theme.SUCCESS500;
    case "Pending":
      return theme.TERTIARY500;
    case "Declined":
      return theme.ATTENTION700;
    default:
      return theme.BLACK_COLOR;
  }
};

const ReturnAcceptanceStatus = (status: string) => {
  return <span style={{ fontWeight: 600, color: statusColor(status) }}>{status}</span>;
};

const SelectScheduledEventModal: React.FC<EventsModalProps> = ({ events, generalTime }) => {
  const { selectEventModal, setSelectEventModal, generalTimesEventsModal, setGeneralTimesEventsModal } = useContext(
    ModalContext,
  );
  const {
    nextActionOverride,
    setNextActionOverride,
    stopScheduledEventNotification,
    setStopScheduledEventNotification,
  } = useContext(CallContext);

  const { updateUserToIdle } = useContext(UserStatusContext);

  return (
    <Modal
      open={generalTime ? generalTimesEventsModal : selectEventModal}
      onClose={() => {
        generalTime ? setGeneralTimesEventsModal(false) : setSelectEventModal(false);
      }}
    >
      <EditCardDiv>
        <TitleDiv>
          <TitleText>Select Event</TitleText>
        </TitleDiv>

        <FlexDiv>
          <EventText style={{ fontSize: "14px", fontWeight: 600 }}>
            {generalTime ? "Your general time callbacks" : "Looks like you’re double booked!"}
          </EventText>
          <EventText style={{ fontSize: "14px" }}>Please select the event you’d like to make your next dial.</EventText>
          {!generalTime && (
            <EventText>
              Remember you can use your My Schedule module to re-assign your backup events to other Reps, reschedule
              them for another time, or dial them if your first selection doesn’t show up.
            </EventText>
          )}
          <EventsContainer>
            {events?.map((item: any) => (
              <EventContainer>
                <DashboardRow>
                  <LeadInfoDiv>
                    {!!item.type && <CallTypeText>{formatTypeName(item.type)}</CallTypeText>}
                    {!!item.lead?.lead_value?.value && (
                      <CallValueText>{formatUSD(item.lead.lead_value.value)}</CallValueText>
                    )}
                  </LeadInfoDiv>
                </DashboardRow>
                <CardGrid>
                  <Horizontal>
                    <LeadCardText style={{ fontSize: "14px", fontWeight: 600 }}>{item.lead.business_name}</LeadCardText>
                    {/* </Horizontal>
                  <Horizontal> */}
                    <LeadCardText>{item.lead.full_name}</LeadCardText>
                    {/* </Horizontal>
                  <Horizontal> */}
                    <LeadCardText style={{ marginBottom: "0px" }}>
                      {!!item.schedule_item?.start_time && moment(item.schedule_item.start_time).format("h:mm A")}
                      {!!item.schedule_item?.start_time && !!item.schedule_item?.end_time && " - "}
                      {!!item.schedule_item?.end_time && moment(item.schedule_item.end_time).format("h:mm A")}
                      &nbsp; &nbsp;
                      {ReturnAcceptanceStatus("")}
                    </LeadCardText>
                  </Horizontal>

                  <MakeNextActionButton
                    // small
                    variant={"secondary"}
                    onClick={() => {
                      updateUserToIdle({ do_not_update_if_on_call: true });

                      setNextActionOverride({
                        intent_object: {
                          id: item?.id,
                          lead: {
                            id: item.lead?.id,
                            primary_phone_number: item.lead?.primary_phone_number,
                            ...item.lead,
                          },
                          ...item,
                        },
                        parent: "nextAction",
                      });

                      // this tells the returnNextDialLeadIntent function to skip the first check and use the above override
                      setStopScheduledEventNotification(true);
                      generalTime ? setGeneralTimesEventsModal(false) : setSelectEventModal(false);
                    }}
                  >
                    Make Next Action
                  </MakeNextActionButton>
                </CardGrid>
              </EventContainer>
            ))}
          </EventsContainer>

          <CancelButton
            onClick={() => (generalTime ? setGeneralTimesEventsModal(false) : setSelectEventModal(!selectEventModal))}
          >
            Cancel
          </CancelButton>
        </FlexDiv>
      </EditCardDiv>
    </Modal>
  );
};

const CancelButton = styled(NewAppButton)`
  min-height: 48px;
  margin-top: 32px;
`;

const LeadCardText = styled(AppText)`
  margin-bottom: 4px;
  width: 100%;
`;

const MakeNextActionButton = styled(NewAppButton)`
  border-radius: 2px;
  width: 114px;
  height: 32px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 16px; */
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 236px 114px;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
`;

const LeadInfoDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 4px;
`;

const CallValueText = styled(AppText)`
  color: ${theme.SUCCESS500};
  margin: 0px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
`;

const CallTypeText = styled(AppText)`
  color: ${theme.PRIMARY500};
  border: 1px solid ${theme.PRIMARY500};
  box-sizing: border-box;
  border-radius: 12px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  padding: 4.5px 10px;
  margin-right: 8px;
  text-transform: capitalize;
`;

const DashboardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-top: 16px;
`;

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
  /* margin-bottom: 32px; */
`;

const EventContainer = styled.div`
  width: 100%;
  /* padding: 16px 24px; */
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.NEUTRAL200};
  border-bottom: none;
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom: 1px solid ${theme.NEUTRAL200};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const EventText = styled(AppText)`
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
  max-height: calc(100vh - 300px);
  width: 100%;
  overflow: auto;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
  position: relative;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.NEUTRAL100};
  border-bottom: solid 1px ${theme.NEUTRAL200};
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: fit-content;
  width: 455px;
`;

export { SelectScheduledEventModal };
