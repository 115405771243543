import Axios from "axios";
import { API_URL } from "../utils/variables";
import { checkForExpiredSession } from "../utils/auth";

export const axios = Axios.create({
  baseURL: API_URL,
});

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    checkForExpiredSession({
      message: error.response.data.error,
      locations: ["response"],
      path: error.response.config.url,
    });
    return Promise.reject(error);
  },
);
