import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, Loading } from "../UI";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import * as Sentry from "@sentry/react";
import { useMutation, gql } from "@apollo/client";
import { appToast } from "../../utils/toast";

interface DisappearingDivProps {
  visible: boolean;
  close: () => void;
  num_imported?: number;
  num_updated?: string;
  num_errors?: string;
  error_report?: string;
  num_stagged?: number;
  upload_id?: string;
}

const IGNORE_USER_UPLOAD_WARNING = gql`
  mutation ignoreUserUploadWarning($upload_id: String!) {
    ignoreUserUploadWarning(upload_id: $upload_id)
  }
`;

const UserCSVUpload: React.FC<DisappearingDivProps> = ({
  visible,
  close,
  num_imported,
  num_updated,
  num_errors,
  error_report,
  num_stagged,
  upload_id,
}) => {
  const [ignoreUserUploadWarning, { loading: loadingOverride, error: errorOverride }] = useMutation(
    IGNORE_USER_UPLOAD_WARNING,
    {
      async onCompleted({ ignoreUserUploadWarning }) {
        if (!ignoreUserUploadWarning) {
          appToast("Error updating users. Something went wrong.");
          return;
        }
        appToast("Users will be updated");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `ignoreUserUploadWarning GraphQL Error: ${message}`,
        });
        console.log("Error in ignoreUserUploadWarning: ", message);
      },
    },
  );

  console.log("error_report_url: ", error_report);
  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Follow Up page of Admin Onboarding"}>
      <Modal open={visible} onClose={close}>
        {!!num_stagged && num_stagged > 0 && !!upload_id ? (
          <CenterDiv>
            <ImportedText>
              {num_stagged} {num_stagged > 1 ? "users" : "user"} in this import currently{" "}
              {num_stagged > 1 ? "exist" : "exists"} in Sellfire
            </ImportedText>
            <ImportedText style={{ color: theme.ATTENTION700, marginTop: "10px" }}>
              Would you like to overwrite all {num_stagged} {num_stagged > 1 ? "users" : "user"}?
            </ImportedText>
            {!!num_errors && <ErrorText>{num_errors} Entries were Rejected!</ErrorText>}
            {!!error_report && (
              <a href={error_report} target="_blank">
                <ErrorReportButton>Download Error Report</ErrorReportButton>
              </a>
            )}
            {!!num_errors && (
              <ExplanationContainer>
                <ExplanationText>All entries in CSV must have these titles: </ExplanationText>
                <br />
                <ExplanationTextFocus>
                  role (either SM, AE, SDR), first_name, last_name, primary_phone_number, email
                </ExplanationTextFocus>
              </ExplanationContainer>
            )}
            <ExplanationText style={{ marginTop: 15 }}>
              You may re-upload a CSV with the changes after you close this window
            </ExplanationText>
            {loadingOverride ? (
              <Loading />
            ) : (
              <AddEmailButton
                style={{ marginBottom: "0px" }}
                type="submit"
                onClick={async () => {
                  await ignoreUserUploadWarning({
                    variables: {
                      upload_id: upload_id,
                    },
                  });
                  close();
                }}
              >
                Yes, Update Leads
              </AddEmailButton>
            )}
            <CancelClickableText onClick={close}>{`No, cancel update of ${num_stagged}`}</CancelClickableText>
          </CenterDiv>
        ) : (
          <CenterDiv>
            <ImportedText>{num_imported} Users Have Been Imported!</ImportedText>
            <UpdatedText>{num_updated} Users Have Been Updated!</UpdatedText>
            {!!num_errors && <ErrorText>However, {num_errors} Entries were Rejected!</ErrorText>}
            {!!error_report && (
              <a href={error_report} target="_blank">
                <ErrorReportButton>Download Error Report</ErrorReportButton>
              </a>
            )}
            {!!num_errors && (
              <ExplanationContainer>
                <ExplanationText>All entries in CSV must have these titles: </ExplanationText>
                <br />
                <ExplanationTextFocus>
                  role (either SM, AE, SDR), first_name, last_name, primary_phone_number, email
                </ExplanationTextFocus>
              </ExplanationContainer>
            )}
            <ExplanationText style={{ marginTop: 15 }}>
              You may re-upload a CSV with the changes after you close this window
            </ExplanationText>
            <AddEmailButton type="submit" onClick={close}>
              Continue
            </AddEmailButton>
          </CenterDiv>
        )}
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

const CancelClickableText = styled(AppText)`
  text-align: center;
  margin: 10px auto;
  color: ${theme.ATTENTION700};
  border-bottom: 1px solid ${theme.ATTENTION700};
  font-size: 15px;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.blinds ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 5;
`;

const ImportedText = styled(AppText)`
  font-size: 21px;
  text-align: center;
`;

const UpdatedText = styled(AppText)`
  font-size: 17px;
  text-align: center;
`;

const ErrorText = styled(AppText)`
  font-size: 17px;
  text-align: center;
  color: ${theme.ATTENTION700};
  margin: 10px;
`;

const ExplanationText = styled(AppText)`
  font-size: 15px;
  text-align: center;
  width: 100%;
`;

const ExplanationTextFocus = styled(AppText)`
  font-size: 15px;
  text-align: center;
  font-weight: 600;
  width: 100%;
`;

const ExplanationContainer = styled.div`
  border: solid 1px #ddddde;
  padding: 20px 30px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ErrorReportButton = styled(AppButton)`
  background-color: ${theme.BLACK_COLOR};
  color: #fff;
  width: 264px;
  height: 45px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 13px;
`;

const CenterDiv = styled.div`
  width: 620px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

const AddEmailButton = styled(AppButton)`
  width: 264px;
  height: 45px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  margin: 25px auto;
`;

export { UserCSVUpload };
