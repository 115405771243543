import React, { createContext, FunctionComponent, useState, useMemo, useEffect, Dispatch, SetStateAction } from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { LOGGED_IN_USER } from "../apollo/query";
import moment from "moment";
import { MixpanelActions } from "../services/mixpanel";
import { isEmpty } from "lodash";
import { isUserRep } from "../apollo/cache";
import { OptionItem } from "../types";

interface IDateRange {
  start: string;
  end: string;
  label: string;
}

interface IFilters {
  start_date: string;
  end_date: string;
  channel: string;
  products: string;
}

// these interfaces are based on data from the backend

// here are the expected properties for the filter args

// but they could be undefined hence the conditional checks
export interface repFilterArgsInterface {
  team_ids?: (string | undefined)[];
  site_ids?: (string | undefined)[];
  roles?: (string | undefined)[];
  user_ids?: (string | undefined)[];
}

export interface leadFilterArgsInterface {
  Channels?: string[];
  LeadSources?: string[];
  LeadCreationSources?: string[];
  primary_industries?: string[];
  sub_industries?: string[];
  NextScheduledEventDays?: number;
  NextScheduledEventTypes?: string[];
  sequences?: string[];
  custom_fields?: { id: string; key: string; type?: string; value: string[] }[];
}

export interface dateFilterArgsInterface {
  date_range?: string;
  upperbound_date?: string;
  lowerbound_date?: string;
}

interface columnOptionArgsInterface {
  visible_reporting_fields?: string[];
  visible_coaching_fields?: string[];
  visible_activity_fields?: string[];
  visible_event_fields?: string[];
  visible_pipeline_fields?: string[];
  visible_transfer_fields?: string[];
  visible_transfer_quality_fields?: string[];
}

export interface selectedCellInterface {
  id?: string;
  metric?: string;
  position?: string;
  computed_id?: string;
  organization_id?: string;
  row_label?: string;
  metric_label?: string;
  metric_type?: string;
  role?: string;
  team_id?: string;
  site_id?: string;
  user_id?: string;
  group_option?: string;
  group_reference?: string;
}

interface FilterContextState {
  // dateRange: IDateRange;
  dateStart: string;
  dateEnd: string;
  dateLabel: string;
  channel: string | undefined;
  products: string[];
  tableView: string;
  // setDateRange: (range: IDateRange) => void;
  setDateStart: (date: string) => void;
  setDateEnd: (date: string) => void;
  setDateLabel: (label: string) => void;
  setChannel: (channel: any) => void;
  setProducts: (products: string[]) => void;
  setTableView: (tableView: string) => void;

  selectedView: string;
  setSelectedView: (selectedView: string) => void;

  leadSources: string[];
  setLeadSources: (products: string[]) => void;

  gridFilter: any;
  setGridFilter: Dispatch<SetStateAction<any>>;
  gridFilterNum: number;
  selectedGridFilters: any;
  setSelectedGridFilters: Dispatch<SetStateAction<any>>;
  resetGridFilters: any;

  repFilter: any;
  setRepFilter: Dispatch<SetStateAction<any>>;
  resetRepFilter: any;
  repFilterNum: number;

  filterSidebarExpanded: boolean;
  setFilterSidebarExpanded: Dispatch<SetStateAction<boolean>>;

  gridModal: boolean;
  setGridModal: Dispatch<SetStateAction<boolean>>;

  tableModal: boolean;
  setTableModal: Dispatch<SetStateAction<boolean>>;

  selectedCell: selectedCellInterface | undefined;
  setSelectedCell: (item: selectedCellInterface | undefined) => void;

  groupOption: string;
  setGroupOption: (item: string) => void;

  measureBy: string;
  setMeasureBy: (item: string) => void;

  sortOption: string;
  setSortOption: (item: string) => void;

  callsExportModal: boolean;
  setCallsExportModal: Dispatch<SetStateAction<boolean>>;

  currentSavedView: any;
  setCurrentSavedView: Dispatch<SetStateAction<any>>;

  resetCurrentSavedView: () => void;

  columnOptions: any;
  setColumnOptions: Dispatch<SetStateAction<any>>;

  isDefaultView: any;

  sharedViewId: any;
  setSharedViewId: Dispatch<SetStateAction<any>>;

  sharedViewOrgId: any;
  setSharedViewOrgId: Dispatch<SetStateAction<any>>;

  funnelSegment: string | undefined;
  setFunnelSegment: Dispatch<SetStateAction<string>>;

  tablePipelineMetric: string;
  setTablePipelineMetric: Dispatch<SetStateAction<string>>;

  pipelineIgnoreCloseDatesComputed: boolean;
  setPipelineIgnoreCloseDates: Dispatch<SetStateAction<boolean>>;

  repFilterArgs: repFilterArgsInterface;

  leadFilterArgs: leadFilterArgsInterface;

  dateFilterArgs: dateFilterArgsInterface;

  columnOptionArgs: columnOptionArgsInterface;
  groupOptionArgs: string;
  transferRole: TransferRole;
  setTransferRole: Dispatch<SetStateAction<TransferRole>>;
}

const DEFAULT_REP_FILTER_STATE = {
  team_ids: [] as (string | undefined)[],
  site_ids: [] as (string | undefined)[],
  roles: [] as (string | undefined)[],
  user_ids: [] as (string | undefined)[],
};

type TransferRole = "AE" | "SDR";

export const GridFilterContext = createContext<FilterContextState>({} as FilterContextState);

export const FilterContext: FunctionComponent = ({ children }) => {
  const [sharedViewId, setSharedViewId] = useState<string>("");
  const [sharedViewOrgId, setSharedViewOrgId] = useState<string>("");
  const [dateStart, setDateStart] = useState<string>((!sharedViewId && localStorage.getItem("date_start")) || "");
  const [dateEnd, setDateEnd] = useState<string>((!sharedViewId && localStorage.getItem("date_end")) || "");

  const [dateLabel, setDateLabel] = useState<string>((!sharedViewId && localStorage.getItem("date_label")) || "Today");
  const [channel, setChannel] = useState<string | undefined>(localStorage.getItem("channel") || undefined);
  const [products, setProducts] = useState<string[]>(JSON.parse(localStorage.getItem("filter_products") || "[]") || []);
  const [tableView, setTableView] = useState<string>(
    (!sharedViewId && localStorage.getItem("table_view")) || "Coaching",
  );

  const [selectedCell, setSelectedCell] = useState<selectedCellInterface | undefined>(undefined);
  const [selectedView, setSelectedView] = useState<string>(
    (!sharedViewId && localStorage.getItem("selected_view")) || "Chart",
  );
  const [groupOption, setGroupOption] = useState<string>(
    (!sharedViewId && localStorage.getItem("group_option")) || "Team",
  );
  const [measureBy, setMeasureBy] = useState<string>(
    (!sharedViewId && localStorage.getItem("measure_by")) || "user_id",
  );
  const [sortOption, setSortOption] = useState<string>((!sharedViewId && localStorage.getItem("sort_option")) || "");

  const [tablePipelineMetric, setTablePipelineMetric] = useState<string>("LeadCount");
  const [pipelineIgnoreCloseDates, setPipelineIgnoreCloseDates] = useState<boolean>(
    JSON.parse(localStorage.getItem("pipeline_ignore_close_dates") || "false") || false,
  );

  const [transferRole, setTransferRole] = useState<TransferRole>("AE");

  const pipelineIgnoreCloseDatesComputed = tableView === "Pipeline" && pipelineIgnoreCloseDates;

  const [funnelSegment, setFunnelSegment] = useState<string>(
    (!sharedViewId && localStorage.getItem("funnel_segment")) || "Top",
  );

  interface IGridFilter {
    channels: string[];
    industries: string[];
    sub_industries: string[];
    lead_sources: string[];
    lead_creation_sources: string[];
    next_scheduled_event: {
      next_scheduled_event_days: number | undefined;
      next_scheduled_event_types: string[];
    };
    sequences: string[];
    custom_fields: { id: string; key: string; type?: string; value: string[] }[];
  }
  const [gridFilter, setGridFilter] = useState<IGridFilter>({
    channels: [] as string[],
    industries: [] as string[],
    sub_industries: [] as string[],
    lead_sources: [] as string[],
    lead_creation_sources: [] as string[],
    next_scheduled_event: {
      next_scheduled_event_days: undefined as number | undefined,
      next_scheduled_event_types: [] as string[],
    },
    sequences: [] as string[],
    custom_fields: [] as { id: string; key: string; type?: string; value: string[] }[],
  });

  const [currentSavedView, setCurrentSavedView] = useState(
    !!localStorage?.getItem("saved_view")
      ? JSON.parse(
          localStorage?.getItem("saved_view") ??
            `{
          "id":"",
          "name":"",
        }`,
        )
      : {
          id: "",
          name: "",
        },
  );

  const isDefaultView = useMemo(() => !!currentSavedView?.is_default_view, [currentSavedView?.id]);

  const resetCurrentSavedView = () => {
    setCurrentSavedView({
      id: "",
      name: "",
    });
  };

  const { data: loggedInUser, loading: loggedInUserLoading } = useQuery(LOGGED_IN_USER);
  /**
   * This should only fire on logout
   */

  useEffect(() => {
    if (!loggedInUser?.loggedInUser?.id) {
      resetCurrentSavedView();
      console.log("resetting current saved view");
    }
  }, [loggedInUser?.loggedInUser?.id]);

  const [columnOptions, setColumnOptions] = useState(
    !!localStorage?.getItem("columnOptions")
      ? JSON.parse(
          localStorage?.getItem("columnOptions") ??
            `{
              visible_reporting_fields: [],
              visible_coaching_fields: [],
              visible_activity_fields: [],
              visible_event_fields: [],
              visible_pipeline_fields: [],
              visible_transfer_fields: [],
              visible_transfer_quality_fields: [],
      }`,
        )
      : {
          visible_reporting_fields: [] as string[],
          visible_coaching_fields: [] as string[],
          visible_activity_fields: [] as string[],
          visible_event_fields: [] as string[],
          visible_pipeline_fields: [] as string[],
          visible_transfer_fields: [] as string[],
          visible_transfer_quality_fields: [] as string[],
        },
  );

  const [repFilter, setRepFilter] = useState<repFilterArgsInterface>(DEFAULT_REP_FILTER_STATE);

  const resetRepFilter = () => {
    setRepFilter(DEFAULT_REP_FILTER_STATE);
  };

  useEffect(() => {
    localStorage.setItem("pipeline_ignore_close_dates", JSON.stringify(pipelineIgnoreCloseDates) || "false");
  }, [pipelineIgnoreCloseDates]);

  useEffect(() => {
    setSelectedCell(undefined);
  }, [loggedInUser?.loggedInUser?.id]);

  useEffect(() => {
    if (!sharedViewId) {
      localStorage.setItem(
        "saved_view",
        JSON.stringify(currentSavedView) ||
          `{
        id: "",
        name: "",
      },`,
      );
      setSelectedCell(undefined);
    }
  }, [currentSavedView]);

  useEffect(() => {
    if (!sharedViewId) {
      console.log("column options changed", columnOptions);
      localStorage.setItem(
        "columnOptions",
        JSON.stringify(columnOptions) ||
          `{
      visible_reporting_fields: [],
      visible_coaching_fields: [],
      visible_activity_fields: [],
      visible_event_fields: [],
      visible_pipeline_fields: [],
      visible_transfer_fields: [],
      visible_transfer_quality_fields: [],
}`,
      );
    }
  }, [columnOptions]);

  useEffect(() => {
    if (!sharedViewId) {
      localStorage.setItem(
        "repFilter",
        JSON.stringify(repFilter) ||
          `{
      team_ids: [],
      site_ids: [],
      roles: [],
      user_ids: [],
          }`,
      );
    }
  }, [repFilter]);

  useEffect(() => {
    if (!sharedViewId) {
      localStorage.setItem(
        "gridFilter",
        JSON.stringify(gridFilter) ||
          `{
      "channels": [],
      "industries": [],
      "sub_industries": [],
      "lead_sources": [],
      "lead_creation_sources": [],
      "next_scheduled_event": {
        "next_scheduled_event_days" : ${undefined},
        "next_scheduled_event_types" : [],
      },
      "sequences": [],
   }`,
      );
    }
  }, [gridFilter]);

  useEffect(() => {
    if (!sharedViewId) {
      localStorage.setItem("date_start", dateStart);
    }
  }, [dateStart]);

  useEffect(() => {
    if (!sharedViewId) {
      localStorage.setItem("date_end", dateEnd);
    }
  }, [dateEnd]);

  useEffect(() => {
    if (!sharedViewId) {
      if (!!dateLabel && dateLabel !== localStorage.getItem("date_label")) {
        MixpanelActions.track("Dashboard Event", {
          type: "Dashboard Date Changed",
          view: `${tableView}`,
          group_option: `${groupOption}`,
          measure_by: `${measureBy}`,
          date_type: `${dateLabel}`,
        });
      }
      localStorage.setItem("date_label", dateLabel);
    }
  }, [dateLabel]);

  useEffect(() => {
    if (!sharedViewId && !!funnelSegment) {
      localStorage.setItem("funnel_segment", funnelSegment);
    }
  }, [funnelSegment]);

  const [selectedGridFilters, setSelectedGridFilters] = useState(
    JSON.parse(localStorage.getItem("selected_grid_filters") || "[]") || [],
  );

  // Grid filters expanded state
  const [filterSidebarExpanded, setFilterSidebarExpanded] = useState(
    localStorage.getItem("filter_sidebar_expanded") === "true",
  );

  const resetGridFilters = () => {
    setSelectedGridFilters([]);
    setGridFilter({
      channels: [],
      industries: [],
      sub_industries: [],
      lead_sources: [],
      lead_creation_sources: [],
      next_scheduled_event: {
        next_scheduled_event_days: undefined,
        next_scheduled_event_types: [],
      },
      sequences: [],
      custom_fields: [],
    });
  };

  useEffect(() => {
    if (!!selectedCell?.id) {
      if (tableView === "Pipeline") {
        setFunnelSegment("");
      }
      MixpanelActions.track("Dashboard Event", {
        type: "Dashboard Cell Clicked",
        view: `${tableView}`,
        metric_name: `${selectedCell?.metric_label}`,
        group_option: `${groupOption}`,
        date_type: `${dateLabel}`,
        measure_by: `${measureBy}`,
      });
    }
  }, [selectedCell]);

  useEffect(() => {
    if (funnelSegment === "Top" || funnelSegment === "Bottom") {
      setSelectedCell(undefined);
    }
  }, [funnelSegment]);

  useEffect(() => {
    localStorage.setItem("selected_grid_filters", JSON.stringify(selectedGridFilters) || "[]");
  }, [selectedGridFilters]);

  useEffect(() => {
    localStorage.setItem("channel", channel || "");
  }, [channel]);

  useEffect(() => {
    localStorage.setItem("filter_products", JSON.stringify(products) || "");
  }, [products]);

  useEffect(() => {
    localStorage.setItem("group_option", groupOption);
    switch (groupOption) {
      case "Site": {
        return setRepFilter({ ...repFilter, team_ids: [] });
      }
      case "Team": {
        return setRepFilter({ ...repFilter, site_ids: [] });
      }
      default:
        break;
    }
  }, [groupOption]);

  useEffect(() => {
    localStorage.setItem("measure_by", measureBy);
  }, [measureBy]);

  useEffect(() => {
    localStorage.setItem("sort_option", sortOption);
  }, [sortOption]);

  useEffect(() => {
    //if statement to check if tableView has changed since the component's initial table_view (to allow accurate Mixpanel reporting)
    if (tableView !== localStorage.getItem("table_view")) {
      MixpanelActions.track("Performance Dashboad", {
        type: "table impression",
        view: `${tableView}`,
        group_option: `${groupOption}`,
        date_range: `${dateLabel}`,
        measure_by: `${measureBy}`,
      });
    }
    localStorage.setItem("table_view", `${tableView}`);
    console.log("change table_view: ", tableView);
  }, [tableView]);

  useEffect(() => {
    //if statement to check if tableView has changed since the component's initial table_view (to allow accurate Mixpanel reporting)

    localStorage.setItem("selected_view", `${selectedView}`);
    console.log("change selected_view: ", selectedView);
  }, [selectedView]);

  useEffect(() => {
    localStorage.setItem("filter_sidebar_expanded", `${filterSidebarExpanded}`);
    console.log("change filterSidebarExpanded: ", filterSidebarExpanded);
  }, [filterSidebarExpanded]);

  // Start of leads filters for upcoming dials

  const [leadSources, setLeadSources] = useState(JSON.parse(localStorage.getItem("lead_sources") || "[]") || []);

  // Column manager states
  const [gridModal, setGridModal] = useState(false);
  const [tableModal, setTableModal] = useState(false);
  const [callsExportModal, setCallsExportModal] = useState(false);

  useEffect(() => {
    localStorage.setItem("lead_sources", JSON.stringify(leadSources) || "");
  }, [leadSources]);

  /**
   * The number of filters applied
   */

  const repFilterNum =
    (repFilter.team_ids?.length ?? 0) +
    (repFilter.site_ids?.length ?? 0) +
    (repFilter.roles?.length ?? 0) +
    (repFilter.user_ids?.length ?? 0);

  // dashboard args
  const gridFilterNum =
    gridFilter.channels?.length +
    gridFilter.industries?.length +
    gridFilter.lead_sources?.length +
    gridFilter.lead_creation_sources?.length +
    gridFilter?.next_scheduled_event?.next_scheduled_event_types?.length +
    (gridFilter?.next_scheduled_event?.next_scheduled_event_days ? 1 : 0) +
    gridFilter.sequences?.length +
    gridFilter.custom_fields?.length;

  const leadFilterArgs: leadFilterArgsInterface = {
    Channels: gridFilter.channels,
    LeadSources: gridFilter.lead_sources,
    LeadCreationSources: gridFilter.lead_creation_sources,
    primary_industries: gridFilter.industries,
    sub_industries: gridFilter.sub_industries,
    NextScheduledEventDays: gridFilter?.next_scheduled_event?.next_scheduled_event_days
      ? typeof gridFilter?.next_scheduled_event?.next_scheduled_event_days === "string"
        ? parseInt(gridFilter?.next_scheduled_event?.next_scheduled_event_days)
        : gridFilter?.next_scheduled_event?.next_scheduled_event_days
      : 0,
    NextScheduledEventTypes: gridFilter?.next_scheduled_event?.next_scheduled_event_types || [],
    sequences: gridFilter?.sequences,
    custom_fields: gridFilter?.custom_fields,
  };

  const repFilterArgs = {
    team_ids: repFilter.team_ids,
    site_ids: repFilter.site_ids,
    roles: tableView === "TransferQuality" || tableView === "Transfer" ? [transferRole] : repFilter.roles,
    user_ids: isUserRep() ? loggedInUser?.loggedInUser?.id : repFilter.user_ids,
  };

  const dateFilterArgs: dateFilterArgsInterface = {
    date_range: dateLabel,
    lowerbound_date: !!pipelineIgnoreCloseDatesComputed
      ? undefined
      : !!dateStart
      ? `${moment(dateStart).toDate()}`
      : undefined,
    upperbound_date: !!pipelineIgnoreCloseDatesComputed
      ? undefined
      : !!dateEnd
      ? `${moment(dateEnd).toDate()}`
      : undefined,
  };

  const columnOptionArgs = {
    visible_reporting_fields: columnOptions.visible_reporting_fields,
    visible_coaching_fields: columnOptions.visible_coaching_fields,
    visible_activity_fields: columnOptions.visible_activity_fields,
    visible_event_fields: columnOptions.visible_event_fields,
    visible_pipeline_fields: columnOptions.visible_pipeline_fields,
    visible_transfer_fields: columnOptions?.visible_transfer_fields,
    visible_transfer_quality_fields: columnOptions?.visible_transfer_quality_fields,
  };

  const groupOptionArgs = isUserRep() ? "None" : groupOption;

  const memoizedValue = useMemo(
    () => ({
      dateStart,
      dateEnd,
      dateLabel,
      channel,
      products,
      tableView,
      leadSources,
      gridFilter,
      setGridFilter,
      repFilter,
      setRepFilter,
      selectedGridFilters,
      setSelectedGridFilters,
      resetGridFilters,
      setDateStart,
      setDateEnd,
      setDateLabel,
      setChannel,
      setProducts,
      setTableView,
      selectedView,
      setSelectedView,
      setLeadSources,
      filterSidebarExpanded,
      setFilterSidebarExpanded,
      gridModal,
      setGridModal,
      tableModal,
      setTableModal,
      selectedCell,
      setSelectedCell,
      groupOption,
      setGroupOption,
      measureBy,
      setMeasureBy,
      sortOption,
      setSortOption,
      callsExportModal,
      setCallsExportModal,
      resetRepFilter,
      currentSavedView,
      setCurrentSavedView,
      resetCurrentSavedView,
      columnOptions,
      setColumnOptions,
      isDefaultView,
      sharedViewId,
      setSharedViewId,
      sharedViewOrgId,
      setSharedViewOrgId,
      repFilterNum,
      gridFilterNum,
      funnelSegment,
      setFunnelSegment,
      tablePipelineMetric,
      setTablePipelineMetric,
      pipelineIgnoreCloseDatesComputed,
      setPipelineIgnoreCloseDates,
      repFilterArgs,
      leadFilterArgs,
      dateFilterArgs,
      columnOptionArgs,
      groupOptionArgs,
      transferRole,
      setTransferRole,
    }),
    [
      dateStart,
      dateEnd,
      dateLabel,
      channel,
      products,
      tableView,
      leadSources,
      gridFilter,
      setGridFilter,
      repFilter,
      setRepFilter,
      selectedGridFilters,
      setSelectedGridFilters,
      resetGridFilters,
      setDateStart,
      setDateEnd,
      setDateLabel,
      setChannel,
      setProducts,
      setTableView,
      selectedView,
      setSelectedView,
      setLeadSources,
      filterSidebarExpanded,
      setFilterSidebarExpanded,
      gridModal,
      tableModal,
      selectedCell,
      setSelectedCell,
      groupOption,
      setGroupOption,
      measureBy,
      setMeasureBy,
      sortOption,
      setSortOption,
      callsExportModal,
      setCallsExportModal,
      resetRepFilter,
      currentSavedView,
      setCurrentSavedView,
      resetCurrentSavedView,
      columnOptions,
      setColumnOptions,
      isDefaultView,
      sharedViewId,
      setSharedViewId,
      sharedViewOrgId,
      setSharedViewOrgId,
      repFilterNum,
      gridFilterNum,
      funnelSegment,
      setFunnelSegment,
      tablePipelineMetric,
      setTablePipelineMetric,
      pipelineIgnoreCloseDatesComputed,
      setPipelineIgnoreCloseDates,
      repFilterArgs,
      leadFilterArgs,
      dateFilterArgs,
      columnOptionArgs,
      groupOptionArgs,
      transferRole,
      setTransferRole,
    ],
  );

  return <GridFilterContext.Provider value={memoizedValue}>{children}</GridFilterContext.Provider>;
};
