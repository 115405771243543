import React, { useContext, useMemo } from "react";
import { components } from "react-select";
import styled from "styled-components";
import { LeadFilterContext } from "src/context";
import { theme } from "src/utils/theme";
import { PhoenixIcon } from "src/Components/UI/Phoenix";
import { AppText, FlexDiv } from "src/Components/UI";
import { star, star_filled } from "src/images/NewDesign";
import { OptionItem } from "src/types";
import FilterSelect from "./FilterSelect";

type SavedViewDropdownProps = {
  savedViews: any[];
};

interface GroupedSavedView {
  label: string;
  options: OptionItem[];
}

const formatViewToOption = (view: any) => ({
  is_default: view.is_default,
  is_favorite: view.is_favorite,
  label: view.name,
  value: view.id,
});

const formatGroupLabel = (data: any) =>
  data.label ? (
    <GroupHeader>
      <AppText fontSize={12} fontWeight={600}>
        {data.label}
      </AppText>
    </GroupHeader>
  ) : null;

const SavedViewDropdown = ({ savedViews }: SavedViewDropdownProps) => {
  const { Option } = components;

  const { savedViewID, setSavedViewID } = useContext(LeadFilterContext);

  const options = useMemo(() => savedViews.map(formatViewToOption), [savedViews]);

  const groupedOptions = useMemo((): GroupedSavedView[] => {
    return [
      {
        label: "",
        options: options.filter((view: any) => view.is_favorite && !view.is_default) ?? [],
      },
      {
        label: "Default",
        options: options.filter((view: any) => view.is_default) ?? [],
      },
      {
        label: "All",
        options: options.filter((view: any) => !view.is_default && !view.is_favorite) ?? [],
      },
    ];
  }, [options]);

  const selectedOption = options.find((o) => o.value === savedViewID) ?? null;

  const CustomOption = (props: any) => (
    <Option {...props}>
      <FlexDiv align="center" gap={8} width="100%">
        {props?.data?.is_favorite ? (
          <PhoenixIcon
            color={theme.WARNING500}
            fillIcon={props?.data?.is_favorite}
            size={16}
            svg={props?.data?.is_favorite ? star_filled : star}
            variant="alert"
          />
        ) : null}
        <TruncatedText>{props?.data?.label}</TruncatedText>
      </FlexDiv>
    </Option>
  );

  return (
    <FilterSelect
      components={{ Option: CustomOption }}
      computedValue={selectedOption}
      formatGroupLabel={formatGroupLabel}
      maxValueWidth={370}
      name="saved_view"
      onChange={(v) => setSavedViewID(v?.value as string)}
      options={groupedOptions}
      placeholder="Select a View"
      value={null}
      width={140}
      maxHeight={32}
      dropdownArrowStyle={{ padding: "2px 8px" }}
    />
  );
};

export default SavedViewDropdown;

const GroupHeader = styled(FlexDiv)`
  align-items: center;
  border-top: 1px solid ${theme.NEUTRAL300};
  display: flex;
  justify-content: space-between;
  padding: 10px 0 2px 0;
`;

const TruncatedText = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
