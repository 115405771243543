import styled from "styled-components";
import * as React from "react";

import { useContext } from "react";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";
import gql from "graphql-tag";
import { ModalContext } from "../../context";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { useMutation } from "@apollo/client";
import { appToast } from "../../utils/toast";

const DELETE_COACHING_NOTE = gql`
  mutation deleteOneNoteItem($noteItemId: String!) {
    deleteOneNoteItem(note_item_id: $noteItemId) {
      id
    }
  }
`;

const DeleteCoachNoteModal: React.FC<{ noteItemId: string }> = ({ noteItemId }) => {
  const { showDeleteCoachNoteModal, setShowDeleteCoachNoteModal } = useContext(ModalContext);

  const [deleteCoachingNote, { loading: deleteCoachingNoteLoading }] = useMutation(DELETE_COACHING_NOTE, {
    onCompleted({ coachingNote }) {
      console.log("deleteCoachingNote: ", coachingNote);
      setShowDeleteCoachNoteModal(false);
    },
    onError({ message }) {
      console.log("Error in deleteCoachingNote: ", message);
    },
    refetchQueries: ["fetchCallNotes"],
  });

  const handleCancel = () => setShowDeleteCoachNoteModal(false);

  const handleDelete = async () => {
    deleteCoachingNote({ variables: { noteItemId } });
  };

  return (
    <Modal
      open={showDeleteCoachNoteModal}
      onClose={() => setShowDeleteCoachNoteModal(false)}
      bigCloseButton
      popupAnimation
    >
      <EditCardDiv>
        <AppText fontSize={16} fontWeight={600} lineHeight={22} textAlign="center">
          Delete Coaching Note
        </AppText>

        <FlexDiv>
          <FlexInnerDiv>
            <AppText fontSize={14} style={{ textAlign: "center", marginBottom: "16px" }} fontWeight={400}>
              Are you sure you want to delete this coaching note? This action cannot be undone.
            </AppText>
          </FlexInnerDiv>
        </FlexDiv>
        <SubmitDiv>
          <PhoenixAppButton variant="danger-outline" buttonType="secondary" uppercase onClick={handleCancel}>
            no, cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            variant="brand"
            buttonType="secondary"
            onClick={handleDelete}
            uppercase
            disabled={deleteCoachingNoteLoading}
          >
            yes, continue
          </PhoenixAppButton>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const SubmitDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 40px;
  border-top: 1px solid ${theme.NEUTRAL200};
  width: 100%;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
`;

const FlexInnerDiv = styled.div`
  height: 130px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 476px;
  padding-top: 40px;
  align-items: center;
`;

export { DeleteCoachNoteModal };
