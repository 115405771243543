import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { AppText } from "../AppText";
import { PhoenixIcon, PhoenixMultiSelect } from "../Phoenix";
import { caret_down, xIcon } from "../../../images/NewDesign";
import { Cell } from "./SequenceLeaderboard";
import { Dot } from "../../Pages";
import { LeadListWindowSort, SequenceLeadListWindowItem } from "../../../types/SequenceTypes";
import { OptionItem } from "../../../types";
import { gql, useQuery } from "@apollo/client";
import { SkeletonBlock } from "../SkeletonBlock";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "../Loading";

const FETCH_LEADS_IN_SEQUENCE = gql`
  query fetchLeadsInSequence($sequenceId: String!, $queryType: LeadInSequenceQuery!, $take: Int, $skip: Int) {
    fetchLeadsInSequence(sequence_id: $sequenceId, query_type: $queryType, take: $take, skip: $skip)
  }
`;

interface LeadListWindowProps {
  onClose: () => void;
  draggable: boolean;
  sequenceId: string;
  metrics: {
    conversion_count: number;
    active_count: number;
    completed_count: number;
    not_interested_count: number;
    all_leads_count: number;
    system_exit_count: number;
  };
}

const take = 25;

export const LeadListWindow: React.FC<LeadListWindowProps> = ({ onClose, draggable, sequenceId, metrics }) => {
  const draggableRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);
  const initialOffset = useRef({ x: 0, y: 0 });

  const [dragState, setDragState] = useState<boolean>(false);

  const [order, setOrder] = useState<LeadListWindowSort>(null);
  const [filter, setFilter] = useState<OptionItem>({
    label: `All Leads (${metrics.all_leads_count})`,
    value: "all",
  });

  const [leadList, setLeadList] = useState<SequenceLeadListWindowItem[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(true);

  const drilldownOptions = useMemo(
    () => [
      { label: `All Leads (${metrics.all_leads_count})`, value: "all" },
      { label: `Active (${metrics.active_count})`, value: "active" },
      { label: `Completed (${metrics.completed_count})`, value: "completed" },
      { label: `Not Interested (${metrics.not_interested_count})`, value: "not_interested" },
      { label: `Conversion (${metrics.conversion_count})`, value: "converted" },
      { label: `System Exit (${metrics.system_exit_count})`, value: "system_exit" },
    ],
    [sequenceId, metrics],
  );

  useEffect(() => {
    // reset infinite scroll on ID change
    setSkip(0);
    setLeadList([]);
    setShowSkeleton(true);
    setHasMore(true);
    setFilter({ label: `All Leads (${metrics.all_leads_count})`, value: "all" });
  }, [sequenceId]);

  const handleShowMore = () => setSkip((prev) => prev + take);

  const { loading: loadingLeads } = useQuery(FETCH_LEADS_IN_SEQUENCE, {
    fetchPolicy: "network-only",
    variables: {
      sequenceId,
      skip: skip,
      take: take,
      queryType: filter.value,
    },
    onCompleted({ fetchLeadsInSequence }) {
      console.log("fetchLeadsInSequence:", fetchLeadsInSequence);

      if (!fetchLeadsInSequence) return;

      const incomingData = [...fetchLeadsInSequence];
      const newList = [...leadList, ...incomingData];

      setLeadList(newList);
      if (incomingData?.length < take) setHasMore(false);
      else setHasMore(true);

      setShowSkeleton(false);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const handleDragStart = (e: React.MouseEvent) => {
    e.preventDefault();

    if (draggableRef.current) {
      const ele = draggableRef.current;

      const boundingRect = ele.getBoundingClientRect();
      initialOffset.current = {
        x: e.clientX - boundingRect.left,
        y: e.clientY - boundingRect.top,
      };
      isDragging.current = true;
      setDragState(true);

      document.addEventListener("mousemove", handleDrag);
      document.addEventListener("mouseup", handleDragEnd);
    }
  };

  const handleDrag = (e: MouseEvent) => {
    e.preventDefault();

    if (!isDragging.current) return;

    if (draggableRef.current) {
      const ele = draggableRef.current;

      const mousePosition = {
        x: e.clientX,
        y: e.clientY,
      };

      // Calculate the new positions considering the element's dimensions
      const newLeft = mousePosition.x - initialOffset.current.x;
      const newTop = mousePosition.y - initialOffset.current.y;

      // Get the dimensions of the document body
      const docWidth = document.body.clientWidth;
      const docHeight = document.body.clientHeight;

      // Calculate the maximum allowed positions considering the element's dimensions
      const maxLeft = docWidth - ele.offsetWidth;
      const maxTop = docHeight - ele.offsetHeight;

      // Clamp the new positions within the boundaries of the document body
      ele.style.left = `${Math.max(0, Math.min(newLeft, maxLeft))}px`;
      ele.style.top = `${Math.max(0, Math.min(newTop, maxTop))}px`;
    }
  };

  const handleDragEnd = () => {
    isDragging.current = false;
    setDragState(false);
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", handleDragEnd);
  };

  return (
    <WindowContainer ref={draggableRef}>
      <Header
        onMouseDown={(e: React.MouseEvent) => draggable && handleDragStart(e)}
        dragState={draggable ? dragState : undefined}
      >
        <AppText fontSize={14} fontWeight={600} lineHeight={20} color={theme.WHITE_COLOR}>
          Lead List
        </AppText>
        <PhoenixIcon svg={xIcon} size={16} variant="white" hoverColor="white" pointer onClick={onClose} />
      </Header>

      <DropdownContainer>
        <PhoenixMultiSelect
          name="lead-list-window-filter"
          value={filter}
          options={drilldownOptions}
          onChange={(item: OptionItem) => {
            if (item.value !== filter.value) {
              setFilter(item);

              // reset infinite scroll
              setSkip(0);
              setLeadList([]);
              setShowSkeleton(true);
            }
          }}
          marginBottom={false}
          isMulti={false}
          isClearable={false}
          width={300}
          minHeight={40}
          maxHeight={40}
        />
      </DropdownContainer>

      <Body id="lead-list-body">
        <InfiniteScroll
          dataLength={leadList.length}
          next={handleShowMore}
          hasMore={hasMore}
          loader={<Loading />}
          scrollableTarget="lead-list-body"
          style={{ minHeight: "205px", overflow: "unset" }}
        >
          <Row>
            <Cell width={32} padding="12px" />
            <Cell
              width={120}
              padding="8px 16px"
              pointer={!!leadList.length}
              onClick={() => (order === "name-desc" ? setOrder("name-asc") : setOrder("name-desc"))}
            >
              <AppText fontSize={10} fontWeight={600} lineHeight={14}>
                Name
              </AppText>
              <PhoenixIcon
                svg={caret_down}
                size={16}
                color={theme.PRIMARY500}
                fillIcon
                pointer
                style={{
                  marginLeft: "8px",
                  display: !order?.includes("name") || !leadList.length ? "none" : undefined,
                  transform: order?.includes("asc") ? "rotate(180deg)" : undefined,
                }}
              />
            </Cell>
            <Cell
              width={124}
              padding="8px 5px 8px 16px"
              pointer={!!leadList.length}
              onClick={() => (order === "business-desc" ? setOrder("business-asc") : setOrder("business-desc"))}
            >
              <AppText fontSize={10} fontWeight={600} lineHeight={14}>
                Business Name
              </AppText>
              <PhoenixIcon
                svg={caret_down}
                size={16}
                color={theme.PRIMARY500}
                fillIcon
                pointer
                style={{
                  marginLeft: "8px",
                  display: !order?.includes("business") || !leadList.length ? "none" : undefined,
                  transform: order?.includes("asc") ? "rotate(180deg)" : undefined,
                }}
              />
            </Cell>
            <Cell
              width={120}
              padding="8px 16px"
              pointer={!!leadList.length}
              onClick={() => (order === "rep-desc" ? setOrder("rep-asc") : setOrder("rep-desc"))}
            >
              <AppText fontSize={10} fontWeight={600} lineHeight={14}>
                Rep Name
              </AppText>
              <PhoenixIcon
                svg={caret_down}
                size={16}
                color={theme.PRIMARY500}
                fillIcon
                pointer
                style={{
                  marginLeft: "8px",
                  display: !order?.includes("rep") || !leadList.length ? "none" : undefined,
                  transform: order?.includes("asc") ? "rotate(180deg)" : undefined,
                }}
              />
            </Cell>
          </Row>
          {!!leadList &&
            leadList
              .slice()
              .sort((a: SequenceLeadListWindowItem, b: SequenceLeadListWindowItem) => handleListSort(order, a, b))
              ?.map((data: SequenceLeadListWindowItem, i: number) => (
                <Row key={`leadlist-${data.lead_id}-${i}`}>
                  <Cell width={32} padding="12px">
                    <Dot
                      color={
                        filter.value === "active"
                          ? theme.SUCCESS500
                          : filter.value === "completed"
                          ? theme.PRIMARY500
                          : filter.value === "not_interested"
                          ? theme.DANGER600
                          : filter.value === "converted"
                          ? theme.DATAVIZ_1_400
                          : filter.value === "system_exit"
                          ? theme.WARNING500
                          : handleDotColor(data?.exit_type)
                      }
                    />
                  </Cell>
                  <Cell width={120} padding="8px 16px">
                    <AppText
                      fontSize={10}
                      fontWeight={400}
                      lineHeight={14}
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {data?.lead_full_name || "NA"}
                    </AppText>
                  </Cell>
                  <Cell width={125} padding="8px 5px 8px 16px">
                    <AppText
                      fontSize={10}
                      fontWeight={400}
                      lineHeight={14}
                      onClick={() => window.open(`/lead-detail/${data?.lead_id}`, "_blank", "noreferrer")}
                      style={{ overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer", borderBottom: "none" }}
                    >
                      {data?.business_name}
                    </AppText>
                  </Cell>
                  <Cell width={120} padding="8px 16px">
                    <AppText
                      fontSize={10}
                      fontWeight={400}
                      lineHeight={14}
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {data?.rep_full_name || "NA"}
                    </AppText>
                  </Cell>
                </Row>
              ))}

          {loadingLeads && showSkeleton && (
            <>
              {Array(5)
                .fill(null)
                ?.map((_, index: number) => (
                  <div style={{ padding: "4px 4px 0px 4px" }} key={`lead-list-window-skeleton-${index}`}>
                    <SkeletonBlock width="100%" height={45} borderRadius={4} delayNumber={index + 3} />
                  </div>
                ))}
            </>
          )}

          {!loadingLeads && !leadList.length && <NoLeadFoundText>No leads found.</NoLeadFoundText>}
        </InfiniteScroll>
      </Body>
    </WindowContainer>
  );
};

function handleDotColor(exitType: string | null) {
  switch (exitType) {
    case "END_OF_SEQUENCE":
      return theme.PRIMARY500;
    case "NOT_INTERESTED":
      return theme.DANGER600;
    case "SALE":
      return theme.DATAVIZ_1_400;
    default:
      return theme.SUCCESS500;
  }
}

const handleListSort = (order: LeadListWindowSort, a: SequenceLeadListWindowItem, b: SequenceLeadListWindowItem) => {
  switch (order) {
    case "business-desc":
      return a?.business_name?.localeCompare(b?.business_name);
    case "business-asc":
      return b?.business_name?.localeCompare(a?.business_name);
    case "rep-desc":
      return a?.rep_full_name?.localeCompare(b?.rep_full_name);
    case "rep-asc":
      return b?.rep_full_name?.localeCompare(a?.rep_full_name);
    case "name-desc":
      return a?.lead_full_name?.localeCompare(b?.lead_full_name);
    case "name-asc":
      return b?.lead_full_name?.localeCompare(a?.lead_full_name);
    default:
      return 0;
  }
};

const WindowContainer = styled.div`
  position: fixed;
  top: 270px;
  left: 140px;
  z-index: 1000;

  width: 340px;
  min-height: 300px;
  height: fit-content;
  max-height: 80%;

  overflow: hidden;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
`;

interface HeaderProps {
  dragState?: boolean;
}

const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 16px;

  background-color: ${theme.PRIMARY900};

  cursor: ${(props) => (!!props.dragState ? "grabbing" : props.dragState === false ? "grab" : "default")};
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  width: fit-content;
  min-height: 32px;
  height: fit-content;

  animation: ${theme.fadeIn} 0.2s ease-in-out forwards;
`;

const Body = styled.div`
  overflow: auto;
  max-width: 100%;
  max-height: 56vh;
  min-height: 205px;

  background-color: ${theme.WHITE_COLOR};

  & ${Row}:nth-child(odd) {
    background: ${theme.PRIMARY50};
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60px;
`;

const NoLeadFoundText = styled(AppText)`
  padding: 16px;
  animation: ${theme.textFadeIn} 0.2s ease-in-out forwards;
`;
