import React, { useState, useContext } from "react";
import styled from "styled-components";
import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { AppText, NewAppButton, AppErrorText, Loading } from "../UI";
import { ModalContext } from "../../context";
import { Formik, FormikProps, Field } from "formik";
import * as Yup from "yup";
import { theme } from "../../utils/theme";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { appToast } from "../../utils/toast";
import { MixpanelActions } from "../../services/mixpanel";
import ReactTooltip from "react-tooltip";
import { Modal } from "./Modal";
const STAR_VALUES = [1, 2, 3, 4, 5];

const FEEDBACK_OPTIONS = [
  {
    label: "Imperfect Audio Quality",
    tooltip: "i.e. audio quality is of static quality.",
    value: "imperfect_audio",
  },
  {
    label: "Dropped Call",
    value: "dropped_call",
  },
  {
    label: "Incorrect Caller ID",
    value: "incorrect_caller_id",
  },
  {
    label: "Dial Delay",
    tooltip: "Delay between dial and ring.",
    value: "post_dial_delay",
  },
  {
    label: "Digits not Captured",
    value: "digits_not_captured",
  },
  {
    label: "Unsolicited Call",
    value: "unsolicited_call",
  },
  {
    label: "Call Lagginess ",
    tooltip: "Audio is delayed.",
    value: "audio_latency",
  },
  {
    label: "One-way Audio ",
    tooltip: "One party cannot hear the other party.",
    value: "one_way_audio",
  },
];

const CREATE_TWILIO_FEEDBACK = gql`
  mutation createTwilioFeedback(
    # $conference_id: String!
    $quality: Int!
    $feedback_text: String = ""
    $category_reason: [FeedBackCategoryReason!]!
  ) {
    createTwilioFeedback(
      # conference_id: $conference_id
      quality: $quality
      feedback_text: $feedback_text
      category_reason: $category_reason
    ) {
      id
      quality
      category_reason
      # conference_id
      feedback_text
    }
  }
`;

interface StarProps {
  numStars: number;
  setNumStars: (field: string, value: number) => void;
}
const Stars: React.FC<StarProps> = ({ numStars, setNumStars }) => (
  <StarsContainer>
    {STAR_VALUES?.map((val) => (
      <NewAppButton
        key={`star-${val}`}
        type="button"
        // small
        // borderless
        width={40}
        onClick={() => setNumStars("stars", val)}
      >
        {val <= numStars ? <AiFillStar size={30} /> : <AiOutlineStar size={30} fill="light blue" />}
      </NewAppButton>
    ))}
  </StarsContainer>
);

interface TwilioFeedbackProps {
  // id? number? name?
  conference_id?: string;
}

interface MyFormikProps {
  stars: number;
  reasons: string[];
  feedback_text: string;
}

const twilioFeedbackSchema = Yup.object().shape({
  stars: Yup.number().min(1).required(),
  reasons: Yup.array(),
  feedback_text: Yup.string(),
});

const TwilioFeedbackModal: React.FC<TwilioFeedbackProps> = ({ conference_id }) => {
  const [feedbackSent, setFeedbackSent] = useState(false);
  const { showTwilioFeedbackModal, setShowTwilioFeedbackModal } = useContext(ModalContext);

  const [createTwilioFeedback, { loading, error }] = useMutation(CREATE_TWILIO_FEEDBACK, {
    onCompleted: ({ createTwilioFeedback }) => {
      setFeedbackSent(true);
      appToast("Feedback successfully sent!");
      console.log(createTwilioFeedback);
      MixpanelActions.track("call-quaulity-feedback", { type: "impression", ...createTwilioFeedback });
    },
    onError: ({ message, name }) => {
      appToast(`Error sending feedback: ${message}`);
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error ${message}`,
      // });
      console.log(`Error in ${name}: ${message}`);
    },
  });

  const handleSubmit = async (values: { stars: number; reasons: string[]; feedback_text: string }) => {
    await createTwilioFeedback({
      variables: {
        // conference_id: conference_id || "",
        quality: values.stars,
        feedback_text: values.feedback_text,
        category_reason: values.reasons,
      },
    });
    setShowTwilioFeedbackModal(false);
  };

  return (
    <Modal open={showTwilioFeedbackModal} onClose={() => setShowTwilioFeedbackModal(false)}>
      {feedbackSent ? (
        <FeedbackContainer>
          <AppText fontSize={16}>Feedback has already been sent.</AppText>
          <AppText fontSize={16}>Thank you for the feedback!</AppText>
        </FeedbackContainer>
      ) : (
        <Formik
          initialValues={{
            stars: 0,
            reasons: [],
            feedback_text: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={twilioFeedbackSchema}
        >
          {({ values, errors, submitForm, setFieldValue }: FormikProps<MyFormikProps>) => (
            <FeedbackContainer>
              <AppText fontSize={16} style={{ fontWeight: 600 }}>
                How was the call?
              </AppText>
              <Stars numStars={values.stars} setNumStars={setFieldValue} />
              {errors.stars ? <AppErrorText>{errors.stars}</AppErrorText> : null}
              {values.stars === 5 ? (
                <AppText fontSize={16} style={{ marginBottom: "1rem" }}>
                  It was perfect!
                </AppText>
              ) : (
                values.stars !== 0 && (
                  <>
                    <AppText fontSize={16}>What went wrong?</AppText>
                    <OptionsGrid role="group">
                      {FEEDBACK_OPTIONS?.map((opt: { label: string; value: string; tooltip?: string }, i) => (
                        <CheckboxLabel
                          key={`${opt.value}-${i}`}
                          selected={values.reasons.includes(opt.value)}
                          data-tip={opt.tooltip}
                          data-for="CallFeedbackTooltip"
                        >
                          <Field type="checkbox" name="reasons" value={opt.value} style={{ display: "none" }} />
                          {opt.label}
                          {opt.tooltip && <QuestionMarkMoreInfo>?</QuestionMarkMoreInfo>}
                          <ReactTooltip
                            multiline
                            place="top"
                            effect="solid"
                            css={{
                              maxWidth: 300,
                              lineHeight: 1.4,
                              textAlign: "center",
                              fontFamily: theme.PRIMARY_FONT,
                            }}
                            backgroundColor={theme.PRIMARY800}
                            getContent={(dataTip) => (
                              <span
                                style={{
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  fontSize: "10px",
                                  lineHeight: "14px",
                                }}
                              >
                                {dataTip}
                              </span>
                            )}
                            className="sib-tooltip"
                            id="CallFeedbackTooltip"
                          />
                        </CheckboxLabel>
                      ))}
                    </OptionsGrid>
                    <Field name="feedback_text" as={FeedbackText} placeholder="Enter your feedback here..." />
                  </>
                )
              )}
              <NewAppButton
                variant={"primary"}
                disabled={!values.reasons.length && values.stars < 5}
                onClick={submitForm}
              >
                {loading ? <Loading color={theme.WHITE_COLOR} /> : "Submit"}
              </NewAppButton>
            </FeedbackContainer>
          )}
        </Formik>
      )}
    </Modal>
  );
};

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1rem;
`;

const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem 2rem;

  margin: 1rem 0;
`;

interface CheckboxLabelProps {
  selected: boolean;
}

const CheckboxLabel = styled.label<CheckboxLabelProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 190px;
  height: 40px;
  border-radius: 8px;
  border: ${(props) => (props.selected ? `1px solid ${theme.PRIMARY200}` : `1px solid ${theme.NEUTRAL200}`)};
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;

  color: ${theme.PRIMARY500};
  background-color: ${(props) => (props.selected ? theme.PRIMARY200 : theme.WHITE_COLOR)};

  cursor: pointer;

  :hover {
    border: 1px solid ${theme.NEUTRAL400};
  }
`;

const FeedbackText = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-height: 75px;
  margin-bottom: 1rem;
  padding: 0.5rem;

  font-family: ${theme.PRIMARY_FONT};
`;

const QuestionMarkMoreInfo = styled(AppText)`
  display: inline-block;
  line-height: 10px;
  font-size: 7px;
  padding-left: 3px;
  margin: 5px;
  margin-left: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${theme.NEUTRAL100};
  color: ${theme.PRIMARY500};
  cursor: pointer;
`;

export { TwilioFeedbackModal };
