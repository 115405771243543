import moment from "moment";

export const isOutsideRange = (day: any) => day.isAfter(moment().add(10, "years"));

export const DATE_RANGE_OPTIONS = [
  { start: moment().startOf("day").format(), end: moment().endOf("day").format(), label: "Today", value: "Today" },
  {
    start: moment().subtract(24, "hours").startOf("day").format(),
    end: moment().subtract(24, "hours").endOf("day").format(),
    label: "Yesterday",
    value: "Yesterday",
  },
  {
    start: moment().startOf("week").format(),
    end: moment().endOf("week").format(),
    label: "This Week",
    value: "ThisWeek",
  },
  {
    start: moment().subtract(1, "week").startOf("week").format(),
    end: moment().subtract(1, "week").endOf("week").format(),
    label: "Last Week",
    value: "LastWeek",
  },
  {
    start: moment().startOf("month").format(),
    end: moment().endOf("day").format(),
    label: "Month to Date",
    value: "MonthToDate",
  },
  {
    start: moment().subtract(1, "months").startOf("month").format(),
    end: moment().subtract(1, "months").endOf("month").format(),
    label: "Last Month",
    value: "LastMonth",
  },
  {
    start: moment().startOf("year").format(),
    end: moment().endOf("day").format(),
    label: "Year to Date",
    value: "YearToDate",
  },
  {
    start: moment().subtract(1, "years").startOf("year").format(),
    end: moment().subtract(1, "years").endOf("year").format(),
    label: "Last Year",
    value: "LastYear",
  },
  {
    start: moment().subtract(1, "months").startOf("day").format(),
    end: moment().endOf("day").format(),
    label: "Custom Range",
    value: "CustomRange",
  },
];
