import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";

import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixInput, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";

import DynamicDropdown, { DropdownMoreBadge } from "./DynamicDropdown";
import DateRangePicker from "./DateRangePicker";
import { hasValue } from "src/utils/misc";
import { useOpenOnMoreFilter } from "src/utils/hooks";

type NextScheduledEventFilterProps = {
  isMoreFilter?: boolean;
  onChange: (value: any) => void;
  options: OptionItem[];
  removeFilter?: () => void;
  value: any;
};

const NextScheduledEventFilter = ({
  isMoreFilter,
  onChange,
  options,
  removeFilter,
  value,
}: NextScheduledEventFilterProps) => {
  const [localValue, setLocalValue] = useState<{
    NextScheduledEventTypes: string[];
    NextScheduledEventDays: number;
    lowerbound_date: string | undefined;
    upperbound_date: string | undefined;
  }>(
    value || {
      NextScheduledEventTypes: [],
      NextScheduledEventDays: 0,
      lowerbound_date: undefined,
      upperbound_date: undefined,
    },
  );
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  useOpenOnMoreFilter(value, setIsOpen, isMoreFilter);

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value);
      hasChangesRef.current = false;
    }
  }, [value, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value);
  }, [localValue, value]);

  const selectedOptions = useMemo(
    () => options?.filter((o) => localValue?.NextScheduledEventTypes?.includes(o.value.toString())),
    [options, localValue?.NextScheduledEventTypes],
  );

  const moreItems = localValue?.NextScheduledEventTypes?.length - 1;

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (!newIsOpen && hasChangesRef.current) {
        onChange?.(localValue);
        hasChangesRef.current = false;
      }
      setIsOpen(newIsOpen);
    },
    [localValue, onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue({
      NextScheduledEventTypes: [],
      NextScheduledEventDays: 0,
      lowerbound_date: undefined,
      upperbound_date: undefined,
    });
    hasChangesRef.current = true;
  }, []);

  const handleValueChange = useCallback((updates: any) => {
    setLocalValue((prev) => ({ ...prev, ...updates }));
    hasChangesRef.current = true;
  }, []);

  const headerContent = useMemo(
    () => (
      <>
        <AppText fontSize={12} fontWeight={500} color={theme.PRIMARY500}>
          {selectedOptions?.[0]?.label}
        </AppText>
        {moreItems ? (
          <DropdownMoreBadge>
            <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.inverse}>
              +{moreItems}
            </AppText>
          </DropdownMoreBadge>
        ) : null}

        {localValue?.NextScheduledEventDays ? (
          <AppText fontSize={12} fontWeight={500} color={theme.PRIMARY500}>
            occurs within {localValue.NextScheduledEventDays} day{localValue.NextScheduledEventDays > 1 ? "s" : ""}
          </AppText>
        ) : null}
      </>
    ),
    [selectedOptions, moreItems, localValue?.NextScheduledEventDays],
  );

  return (
    <DynamicDropdown<string[]>
      label="Next Scheduled Event"
      onClear={handleClear}
      onRemove={hasValue(localValue) ? removeFilter : undefined}
      isOpen={isOpen}
      setIsOpen={handleIsOpenChange}
      value={localValue?.NextScheduledEventTypes}
      renderHeader={headerContent}
    >
      <PhoenixMultiSelect
        titleText="Next Scheduled Event"
        titleTextSpacing={8}
        marginBottom={false}
        menuPosition="fixed"
        menuShouldBlockScroll
        name="next_scheduled_event_type"
        options={options}
        placeholder="Select Event Type"
        value={selectedOptions}
        onChange={(items: OptionItem[]) => {
          handleValueChange({
            NextScheduledEventTypes: items?.map((i) => i.value),
          });
        }}
      />

      <FlexDiv align="center" gap={16} width="100%">
        <AppText>Occurs Within</AppText>
        <PhoenixInput
          name="lead_history_number"
          inputValueType="number"
          value={localValue?.NextScheduledEventDays}
          placeholder="0"
          type="number"
          showNumberArrows
          onChange={(e: any) => {
            handleValueChange({
              NextScheduledEventDays: parseInt(e.target.value) || 0,
            });
          }}
          handleNumberArrowUpClick={() =>
            handleValueChange({
              NextScheduledEventDays: (parseInt(localValue?.NextScheduledEventDays?.toString() ?? "0") || 0) + 1,
            })
          }
          handleNumberArrowDownClick={() =>
            handleValueChange({
              NextScheduledEventDays: Math.max(
                0,
                (parseInt(localValue?.NextScheduledEventDays?.toString() ?? "0") || 0) - 1,
              ),
            })
          }
          displayNoContextText
        />
        <AppText>Days</AppText>
      </FlexDiv>

      <DateRangePicker
        endDateId="next_scheduled_event_end_date"
        onChange={handleValueChange}
        startDateId="next_scheduled_event_start_date"
        value={localValue}
      />
    </DynamicDropdown>
  );
};

export default NextScheduledEventFilter;
