import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton, PhoenixInput } from "../../UI/Phoenix";
import { theme } from "../../../utils/theme";
import { gql, useMutation } from "@apollo/client";
import { getLocalStorage } from "../../../utils/misc";
import { appToast, errorToast } from "../../../utils/toast";
import {
  CREATE_OR_UPDATE_SEQUENCE_DASHBOARD_VIEW,
  DELETE_SEQUENCE_DASHBOARD_VIEW,
  defaultReportingView,
} from "../../../utils/sequence-reporting";

interface ReportingViewModalProps {
  setView: React.Dispatch<React.SetStateAction<{ label: string; value: any }>>;
  viewModalData: { id?: string; delete?: boolean };
  setViewModalData: React.Dispatch<React.SetStateAction<{ id?: string; delete?: boolean }>>;
}

export const ReportingViewModal: React.FC<ReportingViewModalProps> = ({ setView, viewModalData, setViewModalData }) => {
  const { showReportingViewModal, setShowReportingViewModal } = useContext(ModalContext);

  const [name, setName] = useState<string>("");

  const deleteState = viewModalData?.id && viewModalData?.delete;

  const [createOrUpdateSequenceDashboardView, { loading: loadingCreateOrUpdate }] = useMutation(
    CREATE_OR_UPDATE_SEQUENCE_DASHBOARD_VIEW,
    {
      onCompleted({ createOrSequenceDashboardView }) {
        console.log("createOrSequenceDashboardView: ", createOrSequenceDashboardView);
        setShowReportingViewModal(false);
        setView({
          label: createOrSequenceDashboardView.view_name,
          value: {
            sequence_reporting_filters: createOrSequenceDashboardView.sequence_reporting_filters,
            sequence_reporting_segments: createOrSequenceDashboardView.sequence_reporting_segments,
          },
        });
        appToast("View created successfully");
      },
      onError({ message }) {
        console.log("Error in createOrSequenceDashboardView: ", message);
        errorToast(message);
      },
      refetchQueries: ["fetchDashboardsViews"],
    },
  );

  const [deleteSequenceDashboardView, { loading: loadingDelete }] = useMutation(DELETE_SEQUENCE_DASHBOARD_VIEW, {
    onCompleted({ deleteSequenceDashboardView }) {
      console.log("deleteSequenceDashboardView: ", deleteSequenceDashboardView);
      setShowReportingViewModal(false);
      setView(defaultReportingView);
      setViewModalData({});
      appToast("View deleted successfully");
    },
    onError({ message }) {
      console.log("Error in deleteSequenceDashboardView: ", message);
      errorToast(message);
    },
    refetchQueries: ["fetchDashboardsViews"],
  });

  return (
    <Modal
      open={showReportingViewModal}
      onClose={() => {
        setShowReportingViewModal(false);
        setViewModalData({});
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={14} fontWeight={600}>
            {deleteState ? "Delete View" : "Name This View"}
          </AppText>

          {deleteState ? (
            <AppText fontSize={14}>Are you sure you want to permanently delete this view?</AppText>
          ) : (
            <PhoenixInput
              placeholder="View Name"
              variant="primary"
              type="text"
              fontSize={12}
              fontWeight={400}
              displayNoContextText
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              width={396}
              height={40}
            />
          )}
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              setShowReportingViewModal(false);
              setViewModalData({});
            }}
          >
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              if (deleteState) {
                deleteSequenceDashboardView({
                  variables: {
                    viewId: viewModalData.id,
                  },
                });
                return;
              }
              createOrUpdateSequenceDashboardView({
                variables: {
                  sequenceDashboardViewInput: {
                    sequence_reporting_filters: JSON.stringify(getLocalStorage("sequence_reporting_filters")),
                    sequence_reporting_segments: JSON.stringify(getLocalStorage("sequence_reporting_segments")),
                    view_name: name,
                  },
                },
              });
            }}
            disabled={(loadingCreateOrUpdate || !name.length) && !deleteState}
          >
            Continue
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  padding: 40px 40px 75px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
