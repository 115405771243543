import * as React from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText } from "../../../UI";

import { HiPlus } from "react-icons/hi";
import RenderAssociateTitle from "../../../Pages/LeadDetail/RenderAssociateTitle";

import {
  chat,
  download,
  mail,
  outgoing_phone_white,
  plain_phone_white,
  play_circle_blue,
} from "../../../../images/NewDesign";

import moment from "moment";
import { useContext } from "react";
import { ModalContext } from "../../../../context";
import { formatCallDuration, formatCallDurationShortened, formatPhoneNumber } from "../../../../utils/format";
import { downloadResourceCors } from "../../../../utils/misc";
import { PhoenixIcon } from "../../../UI/Phoenix";
import { ProfileImageWithText } from "../../SettingSegments/ProfileImageWithText";

const LeadCardLeadHistoryItem = ({
  item,
  primary_lead_id,
  associate_lead_ids,
  contact_id,
  setCurrentConferenceID = () => {},
  setShowSaveToFolderModal = () => {},
  showExtendedInfoSections,
  backgroundColor,
  showRepIcon,
  isAssociatedLead = false,
}: {
  item: any;
  primary_lead_id: string;
  associate_lead_ids: string[];
  contact_id: string;
  setCurrentConferenceID?: (id: string) => void;
  setShowSaveToFolderModal?: (show: boolean) => void;
  showExtendedInfoSections: boolean;
  backgroundColor?: string;
  showRepIcon: boolean;
  isAssociatedLead: boolean;
}) => {
  const { setShowSMSAndEmailDetailsModal, setSMSAndEmailDetailsModalData } = useContext(ModalContext);

  return (
    <HistoryItemDiv key={item.id} backgroundColor={backgroundColor} showRepIcon={showRepIcon}>
      <CallNotesItemV2 showRepIcon={showRepIcon}>
        {showRepIcon && (
          <div
            style={{
              marginTop: "8px",
            }}
          >
            <ProfileImageWithText
              first_name={item?.user?.first_name}
              last_name={item?.user?.last_name}
              profile_image={item?.user?.profile_image}
            />
          </div>
        )}

        <CallNotesDivNewV2>
          {/* title */}
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <div>
              {(() => {
                if (item.communication_type === "Phone" && item.conference?.duration > 0) {
                  return (
                    <AppText fontSize={12} fontWeight={600}>
                      <StyledLink
                        href={`/call-report/${item.conference?.id}`}
                        target="_blank"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {!!item?.related_disposition?.label ? item.related_disposition?.label : "NA"} (
                        {formatCallDurationShortened(item.conference.duration)})
                      </StyledLink>
                    </AppText>
                  );
                } else if (item.communication_type === "Phone" && item.conference?.duration === 0) {
                  return (
                    <AppText fontSize={12} fontWeight={600}>
                      {!!item?.related_disposition?.label ? item.related_disposition?.label : "NA"}
                    </AppText>
                  );
                } else if (item.communication_type === "ExternalTransfer" && item.conference?.duration === 0) {
                  return (
                    <AppText fontSize={12} fontWeight={600}>
                      External Transfer
                    </AppText>
                  );
                } else {
                  return (
                    <AppText fontSize={12} fontWeight={600}>
                      {item.title ? item.title : item.type === "Off Call Note" ? "Off Call Note" : "NA"}
                    </AppText>
                  );
                }
              })()}
            </div>
            <NameDiv>
              <RenderAssociateTitle
                primary_lead_id={primary_lead_id}
                associate_lead_ids={associate_lead_ids}
                contact_id={contact_id}
                isAssociatedLead={isAssociatedLead || !!item?.associate_child}
                lead_activity_data={item}
              />
              <AppText fontSize={12} fontWeight={600}>
                {!item.associate_child ? item?.lead?.full_name || "NA" : item?.associate_child?.full_name || "NA"}
              </AppText>
            </NameDiv>
            {/* to div*/}
            <div>
              {/*  title */}
              {item.communication_type === "Phone" ? (
                <IconAndTitleDiv>
                  {<img src={plain_phone_white} alt="Phone" style={{ height: "16px" }} />}
                  <CommunicationTitleText>Recipient Number</CommunicationTitleText>
                </IconAndTitleDiv>
              ) : item.communication_type === "Email" ? (
                <IconAndTitleDiv>
                  {/* {<img src={mail_blue} alt="Email" style={{ height: "16px" }} />} */}
                  <PhoenixIcon svg={mail} variant="brand" alt="Email" size="small" />
                  <CommunicationTitleText>Receipient Email</CommunicationTitleText>
                </IconAndTitleDiv>
              ) : item.communication_type === "Internal" ? (
                <IconAndTitleDiv>
                  {<img src={plain_phone_white} alt="Phone" style={{ height: "16px" }} />}
                  <CommunicationTitleText>NA</CommunicationTitleText>
                </IconAndTitleDiv>
              ) : item.communication_type === "SMS" ? (
                <IconAndTitleDiv>
                  <img src={chat} alt="SMS" style={{ height: "16px" }} />
                  <CommunicationTitleText>Recipient Number</CommunicationTitleText>
                </IconAndTitleDiv>
              ) : (
                item.communication_type === "ExternalTransfer" && (
                  <IconAndTitleDiv>
                    <img src={plain_phone_white} alt="Phone" style={{ height: "16px" }} />
                    <CommunicationTitleText>Transfer Number</CommunicationTitleText>
                  </IconAndTitleDiv>
                )
              )}

              {/** value */}
              <div
                style={{
                  marginTop: "4px",
                }}
              >
                {item.communication_type === "Internal" && <WrappingSpan>NA</WrappingSpan>}
                {item.communication_type === "Email" && <WrappingSpan>{item.email_item?.to_email ?? ""} </WrappingSpan>}
                {item.communication_type === "Phone" && (
                  <WrappingSpan>
                    {item.channel === "Outbound"
                      ? formatPhoneNumber(item.customer_number)
                      : formatPhoneNumber(item?.user?.phone_number)}
                  </WrappingSpan>
                )}
                {item.communication_type === "SMS" && (
                  <WrappingSpan>
                    {item?.SMSItem?.lead_number_used ? formatPhoneNumber(item?.SMSItem?.lead_number_used) : "NA"}
                  </WrappingSpan>
                )}
                {item.communication_type === "ExternalTransfer" && (
                  <WrappingSpan>
                    {!!item.external_transfer_number ? formatPhoneNumber(item?.external_transfer_number) : "NA"}
                  </WrappingSpan>
                )}
              </div>
            </div>
            {/** from div*/}
            <div>
              {/** title*/}
              {item.communication_type === "Phone" ? (
                <IconAndTitleDiv>
                  <img src={outgoing_phone_white} alt="Phone" style={{ height: "16px" }} />
                  <CommunicationTitleText>Originating Number</CommunicationTitleText>
                </IconAndTitleDiv>
              ) : item.communication_type === "Email" ? (
                <IconAndTitleDiv>
                  {/* <img src={mail_blue} alt="Email" style={{ height: "16px" }} /> */}
                  <PhoenixIcon svg={mail} variant="brand" alt="Email" size="small" />
                  <CommunicationTitleText>Originating Email</CommunicationTitleText>
                </IconAndTitleDiv>
              ) : item.communication_type === "Internal" ? (
                <IconAndTitleDiv>
                  <img src={outgoing_phone_white} alt="Phone" style={{ height: "16px" }} />
                  <CommunicationTitleText>NA</CommunicationTitleText>
                </IconAndTitleDiv>
              ) : item.communication_type === "SMS" ? (
                <IconAndTitleDiv>
                  <img src={chat} alt="SMS" style={{ height: "16px" }} />
                  <CommunicationTitleText>Originating Number</CommunicationTitleText>
                </IconAndTitleDiv>
              ) : (
                item.communication_type === "ExternalTransfer" && (
                  <IconAndTitleDiv>
                    <img src={outgoing_phone_white} alt="Phone" style={{ height: "16px" }} />
                    <CommunicationTitleText>Originating Number</CommunicationTitleText>
                  </IconAndTitleDiv>
                )
              )}

              {/** value */}
              <div
                style={{
                  marginTop: "4px",
                }}
              >
                {item.communication_type === "Phone" ? (
                  <WrappingSpan>
                    {/* check if the call was made using a local presence number */}
                    {!!item.local_number?.number
                      ? item.channel === "Outbound"
                        ? formatPhoneNumber(item?.local_number?.number)
                        : formatPhoneNumber(item?.customer_number)
                      : item?.user?.phone_number
                      ? item.channel === "Outbound"
                        ? formatPhoneNumber(item?.user?.phone_number)
                        : formatPhoneNumber(item?.customer_number)
                      : "NA"}
                  </WrappingSpan>
                ) : item.communication_type === "Email" ? (
                  <WrappingSpan>{item.email_item?.from_email ?? "NA"} </WrappingSpan>
                ) : item.communication_type === "Internal" ? (
                  <WrappingSpan>NA</WrappingSpan>
                ) : item.communication_type === "Email" ? (
                  <WrappingSpan>{item.email_item?.from_email ?? "NA"} </WrappingSpan>
                ) : (
                  item.communication_type === "ExternalTransfer" && (
                    <WrappingSpan>
                      {/* check if the call was made using a local presence number */}
                      {!!item.local_number?.number
                        ? item.channel === "Outbound"
                          ? formatPhoneNumber(item?.local_number?.number)
                          : formatPhoneNumber(item?.customer_number)
                        : item?.user?.phone_number
                        ? item.channel === "Outbound"
                          ? formatPhoneNumber(item?.user?.phone_number)
                          : formatPhoneNumber(item?.customer_number)
                        : "NA"}
                    </WrappingSpan>
                  )
                )}
              </div>
            </div>
          </div>
          {(item.communication_type === "Phone" || item.communication_type === "ExternalTransfer") && (
            <RepAndResultDiv>
              <RepAndResultIcon
                background={
                  item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.bg_color ??
                  theme.PILL_LIGHT_GRAY
                }
                color={
                  item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.font_color ?? "#000000"
                }
              >
                {item?.related_disposition?.computed_call_history_theme?.completed_with_theme?.label ?? "NA"}
              </RepAndResultIcon>
              {
                <RepAndResultIcon
                  background={
                    item?.related_disposition?.computed_call_history_theme?.call_result_theme?.bg_color ??
                    theme.PILL_LIGHT_GRAY
                  }
                  color={
                    item?.related_disposition?.computed_call_history_theme?.call_result_theme?.font_color ??
                    theme.BLACK_COLOR
                  }
                >
                  {item?.related_disposition?.computed_call_history_theme?.call_result_theme?.label ?? "NA"}
                </RepAndResultIcon>
              }
            </RepAndResultDiv>
          )}
        </CallNotesDivNewV2>
        <TimeAndExtendedInfoDiv>
          <div>
            <CallNotesTextV2 bold>
              {!!item.created_at ? moment(item.created_at).format("M/D/YYYY") : ""}
            </CallNotesTextV2>
            <div
              style={{
                height: "20px",
                alignItems: "center",
              }}
            >
              <CallNotesTextV2>{!!item.created_at ? moment(item.created_at).format("h:mm A") : ""}</CallNotesTextV2>
            </div>
          </div>
          {
            // if the call has a recording render the recording info component,
            // if the sms has a email or sms item render text to open the modal
          }
          {showExtendedInfoSections &&
            (item.communication_type === "Phone" || item.communication_type === "ExternalTransfer") &&
            item.conference?.duration > 0 &&
            !!item?.conference?.recording_url_computed && (
              <RecordingInfoDiv>
                <StyledLink href={`/call-report/${item.conference?.id}`} target="_blank">
                  <ListItemDuration>
                    <img
                      src={play_circle_blue}
                      style={{
                        height: "15px",
                        marginRight: "6px",
                      }}
                    />

                    <AppText fontWeight={400} fontSize={12}>
                      {formatCallDuration(item.conference.duration)}
                    </AppText>
                  </ListItemDuration>
                </StyledLink>
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <HiPlus
                    size={16}
                    color={theme.PRIMARY500}
                    data-tip="Save"
                    onClick={() => {
                      setCurrentConferenceID(item.conference?.id);
                      setShowSaveToFolderModal(true);
                    }}
                  />
                  {!!item.conference.recording_url_computed && (
                    <PhoenixIcon
                      svg={download}
                      variant="brand"
                      alt="Download"
                      size="small"
                      data-tip="Download"
                      onClick={() => downloadResourceCors(item.conference.recording_url_computed)}
                    />
                  )}
                </div>
              </RecordingInfoDiv>
            )}

          {showExtendedInfoSections && item.communication_type === "Email" && (
            <div
              style={{
                width: "max-content",
              }}
              onClick={() => {
                const currentItem = JSON.parse(JSON.stringify(item));
                currentItem.modal_primary_lead_id = primary_lead_id;
                currentItem.modal_associate_lead_ids = associate_lead_ids;
                currentItem.modal_contact_id = contact_id;
                currentItem.modal_is_associate_lead = isAssociatedLead;
                setSMSAndEmailDetailsModalData(currentItem);
                setShowSMSAndEmailDetailsModal(true);
              }}
            >
              <AppText variant="primary" fontWeight={400} fontSize={12} style={{ cursor: "pointer" }}>
                View Message
              </AppText>
            </div>
          )}
          {showExtendedInfoSections && item.communication_type === "SMS" && (
            <div
              style={{
                width: "max-content",
              }}
              onClick={() => {
                const currentItem = JSON.parse(JSON.stringify(item));
                currentItem.modal_primary_lead_id = primary_lead_id;
                currentItem.modal_associate_lead_ids = associate_lead_ids;
                currentItem.modal_contact_id = contact_id;
                currentItem.modal_is_associate_lead = isAssociatedLead;
                setSMSAndEmailDetailsModalData(currentItem);
                setShowSMSAndEmailDetailsModal(true);
              }}
            >
              <AppText variant="primary" fontWeight={400} fontSize={12} style={{ cursor: "pointer" }}>
                View Message
              </AppText>
            </div>
          )}
        </TimeAndExtendedInfoDiv>
      </CallNotesItemV2>
    </HistoryItemDiv>
  );
};

const WrappingSpan = styled(AppText)`
  word-break: break-all;
  max-width: 200px;
  font-weight: 400;
  font-size: 12px;
`;

const ListItemDuration = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLink = styled.a`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const NameDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const HistoryItemDiv = styled.div<{ backgroundColor: string | undefined; showRepIcon: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(props) => props.backgroundColor ?? theme.WHITE_COLOR};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${(props) => (props.showRepIcon ? "16px" : "0px")};
  padding-right: ${(props) => (props.showRepIcon ? "16px" : "0px")};
`;

const CallNotesItemV2 = styled.div<{ showRepIcon: boolean }>`
  display: grid;
  width: 100%;
  // the last column was changed to be absolutly positioned

  grid-template-columns: ${(props) => (props.showRepIcon ? "64px 1fr 80px" : "1fr 80px")};
  border-top: ${(props) => (props.showRepIcon ? `  1px solid ${theme.NEUTRAL200}` : "none")};
  align-items: top;
  height: "fit-content";
  padding-top: 12px;

  overflow: visible;
`;

const RepAndResultDiv = styled.div`
  display: flex;
  overflow: visible;
  width: max-content;
  min-height: 30px;
  height: max-content;
`;

interface RepAndResultIcon {
  background?: string;
  color?: string;
}
const RepAndResultIcon = styled.div<RepAndResultIcon>`
  // font
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  text-transform: uppercase;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  margin-right: 4px;

  background-color: ${(props) => {
    return props.background;
  }};

  color: ${(props) => {
    return props.color;
  }};
  // never wrap
  white-space: nowrap;
`;

const TimeAndExtendedInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const IconAndTitleDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: ${theme.PRIMARY500};
`;

interface CustomFieldTextProps {
  bold?: boolean;
}

const CallNotesTextV2 = styled(AppText)<CustomFieldTextProps>`
  font-weight: ${(props) => (props.bold ? 500 : 400)};
  position: relative;
  /* max-width: 230px; */
  width: "fit-content";
  white-space: pre-wrap;
  word-wrap: "no-wrap";
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 11px;
  min-height: 18px;
`;

const RecordingInfoDiv = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  border: 2px solid ${theme.NEUTRAL200};
  padding: 12px;
  border-radius: 12px;
  gap: 12px;
`;

const CallNotesDivNewV2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: none;
  height: 100%;
  width: 100%;
  gap: 12px;
`;

const CommunicationTitleText = styled(AppText)`
  font-family: 400;
  font-size: 12px;
  color: ${theme.PRIMARY500};
  line-height: 18px;
`;

export { LeadCardLeadHistoryItem };
