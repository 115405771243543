import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import {
  AppSelect,
  AppText,
  Loading,
  AppInput,
  AppMultiSelect,
  ReactDatesWrapper,
  NewAppSelect,
  SkeletonBlock,
  AppErrorText,
} from "../UI";
import { theme } from "../../utils/theme";
import { useContext, useEffect, useState } from "react";
import { newCloseModal, showMoreArrow } from "../../images";
import { NewAppButton } from "../UI/NewAppButton";
import { GridFilterContext, ModalContext, LeadFilterContext, LeadFilterOperator } from "../../context";
import { AppCheckbox } from "../UI/AppCheckbox";
import { gql, useQuery, useMutation } from "@apollo/client";
import { countryCodes } from "../../static/countries";
import { loggedInUser } from "../../apollo/cache";
import { FormDatePicker } from "../Field";
import { toNamespacedPath } from "path";
import { appToast } from "../../utils/toast";
import { OptionItem } from "../../types/genericTypes";
import moment from "moment";
import { OutsideEvent } from "../Dumb";
import { DateRangePicker } from "react-dates";
import { useFlags } from "launchdarkly-react-client-sdk";
import { BsTrashFill } from "react-icons/bs";
import { useMemo } from "react";
import { SHOW_LEAD_SOURCE_TO_USER } from "../../apollo/query";
import { PhoenixRadio } from "../UI/Phoenix";

const GET_LEAD_SOURCE_OPTIONS = gql`
  query getUniqueLeadSources {
    getUniqueLeadSources
  }
`;

const GET_CALL_RESULT_OPTIONS = gql`
  query getCallResultOptions {
    getCallResultOptions
  }
`;

const GET_DISPOSITION_TYPE_OPTIONS = gql`
  query getDispositionTypeOptions {
    getDispositionTypeOptions
  }
`;

const GET_STATE_OPTIONS = gql`
  query getStateOptions {
    getStateOptions
  }
`;

const GET_CITY_OPTIONS = gql`
  query getCityOptions {
    getCityOptions
  }
`;

const GET_TIMEZONE_OPTIONS = gql`
  query getTZOptions {
    getTZOptions
  }
`;

const FETCH_REPS_FROM_ORG = gql`
  query fetchOrganization {
    fetchOrganization {
      Reps {
        id
        first_name
        last_name
      }
    }
  }
`;

const FETCH_REPS_FROM_ACTIVITY = gql`
  query fetchRepsAsOptions {
    fetchRepsAsOptions
  }
`;

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions($include_others: Boolean) {
    fetchIndustryOptions(include_others: $include_others) {
      id
      label
      sub_industries
    }
  }
`;

const FETCH_ALL_CUSTOM_FIELDS = gql`
  query FetchCustomFields {
    fetchCustomFields {
      id
      key
      type
      allow_reps_to_edit
      visible
      options
    }
  }
`;

const GET_SUBINDUSTRIES_DATA = gql`
  query fetchSubIndustryWhiteList {
    fetchUser {
      id
      sub_industry_white_list_array
    }
  }
`;

const CHECK_INTEGRATION_STATUS = gql`
  query checkIntegrationStatus {
    checkIntegrationStatus
  }
`;

const callResultsWhenOptions = [
  { label: "First Call Result", value: "a" },
  { label: "Last Call Result", value: "b" },
  { label: "Anytime", value: "c" },
];

const callResultsTypeOptions = [
  { label: "Cold Calls", value: "cold_call" },
  { label: "Call Followups", value: "call_followup" },
  { label: "General Callback Held", value: "general_callback_held" },
  { label: "General Callback Set", value: "general_callback_set_not_held" },
  { label: "Schedule Callback Held", value: "schedule_callback_held" },
  { label: "Schedule Callback Set", value: "schedule_callback_set_not_held" },
  { label: "Demo Held", value: "demo_held" },
  { label: "Demo Set", value: "demo_set_not_held" },
  { label: "Decision Call Held", value: "decision_call_held" },
  { label: "Decision Call Set", value: "decision_call_set_not_held" },
  { label: "Emails Sent", value: "email_sent" },
  { label: "Emails Received", value: "email_received" },
  { label: "Dials", value: "dial" },
];

const leadHistoryOperators = [
  { label: "Greater than", value: "GreaterThan" },
  { label: "Less than", value: "LessThan" },
  { label: "Greater than or equal to", value: "GreaterThanOrEqualTo" },
  { label: "Less than or equal to", value: "LessThanOrEqualTo" },
  { label: "Equal to", value: "EqualTo" },
  { label: "Not equal to", value: "NotEqualTo" },
  { label: "Between", value: "Between" },
  { label: "Not Between", value: "NotBetween" },
];

const leadCreationSrcOptions = [
  { label: "Self-Sourced", value: "self_sourced" },
  { label: "Bulk Import", value: "CSV" },
  { label: "Added by Admin", value: "as_admin" },
  { label: "Hubspot", value: "hubspot" },
  { label: "Salesforce", value: "salesforce" },
];

const leadCreationSrcOptionsHubspot = [
  { label: "Self-Sourced", value: "self_sourced" },
  { label: "Bulk Import", value: "CSV" },
  { label: "Added by Admin", value: "as_admin" },
  { label: "Hubspot", value: "hubspot" },
  { label: "Salesforce", value: "salesforce" },
];

const NEXT_SCHEDULED_EVENT_OPTIONS = [
  { label: "Decision Call", value: "DecisionCall" },
  { label: "Reschedule Demo", value: "RescheduleDemo" },
  { label: "Scheduled Event", value: "ScheduledEvent" },
  { label: "Scheduled Callback", value: "ScheduleCallback" },
  { label: "Demo", value: "Demo" },
  { label: "Follow-Up Demo", value: "FollowUpDemo" },
];

const SystemLeadsFiltersComponent: React.FC = () => {
  // States for whether each filer is expanded
  const [expanded, setExpanded] = useState([] as string[]);

  // Context for filters
  const { leadSources, setLeadSources } = useContext(GridFilterContext);
  const { showFiltersModal, setShowFiltersModal } = useContext(ModalContext);
  const {
    leadSystemFilter,
    setLeadSystemFilter,
    tempLeadFilter,
    setTempLeadFilter,
    currentFilterCount,
    resetLeadSystemFilters,
    leadSystemOperators,
    setLeadSystemOperators,
    tempLeadSystemOperators,
    setTempLeadSystemOperators,
    resetLeadSystemOperators,
  } = useContext(LeadFilterContext);

  const { customFieldsLeadFilter } = useFlags();
  const [subIndustryOptions, setSubIndustryOptions] = useState([]);
  const [supportedCustomFields, setSupportedCustomFields] = useState([] as any);

  const { data: showLeadSourceToUser } = useQuery(SHOW_LEAD_SOURCE_TO_USER);

  // For react-dates
  const [focusedInput, setFocusedInput] = useState(null as any);
  const [focusedInput2, setFocusedInput2] = useState(null as any);

  const { data: industriesData, loading: industriesLoading, error: industriesError } = useQuery(
    GET_POSSIBLE_INDUSTRIES_DATA,
    {
      fetchPolicy: "network-only",
      variables: { include_others: true },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: allCustomFieldsData, loading: customFieldsLoading, error: customFieldsError } = useQuery(
    FETCH_ALL_CUSTOM_FIELDS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
      onCompleted: (data) => {
        setSupportedCustomFields(
          data.fetchCustomFields
            ?.slice()
            ?.filter((item: any) => {
              return item.type === "Dropdown" || item.type === "MultiDropdown" || item.type === "Boolean";
            })
            ?.map((item: any) => ({
              id: item?.id,
              options: item?.type === "Boolean" ? ["true", "false"] : item?.options ?? [],
              key: item?.key,
              type: item?.type,
            })),
        );
      },
    },
  );

  const returnSubIndustryOptions = (industry: string[]) => {
    if (industriesLoading) {
      return [
        {
          label: "Loading...",
          value: "",
        },
      ];
    }
    if (!!industry.length) {
      return (
        industriesData.fetchIndustryOptions
          ?.slice()
          ?.filter((item: any) => industry.includes(item.label))
          ?.map((item: any) => item.sub_industries)
          ?.flat()
          ?.sort() ?? []
      );
    } else {
      return [];
    }
  };

  useEffect(() => {
    const possibleSubIndustries = returnSubIndustryOptions(leadSystemFilter?.industries || []);
    setSubIndustryOptions(possibleSubIndustries);
  }, [industriesLoading]);

  const repVersion = loggedInUser().role === "AE" || loggedInUser().role === "SDR";

  //Queries
  const {
    data: leadSourceOptions,
    error: leadSourceError,
    loading: leadSourceLoading,
  } = useQuery(GET_LEAD_SOURCE_OPTIONS, { fetchPolicy: "network-only" });
  //const { data: cityOptions, error: cityErroy, loading: cityLoading } = useQuery(GET_CITY_OPTIONS);
  const { data: stateOptions, error: stateError, loading: stateLoading } = useQuery(GET_STATE_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: tzOptions, error: tzError, loading: tzLoading } = useQuery(GET_TIMEZONE_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataReps, loading: loadingReps, error: errorReps } = useQuery(FETCH_REPS_FROM_ORG, {
    variables: {
      org_id: loggedInUser().organization_id,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataRepsActivities } = useQuery(FETCH_REPS_FROM_ACTIVITY, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataCallResultOptions, loading: loadingCallResultOptions, error: errorCallResultOptions } = useQuery(
    GET_CALL_RESULT_OPTIONS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const {
    data: dataDispositionTypeOptions,
    loading: loadingDispositionTypeOptions,
    error: errorDispositionTypeOptions,
  } = useQuery(GET_DISPOSITION_TYPE_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataStatus, loading: loadingPDStatus, error: errorPDStatus } = useQuery(CHECK_INTEGRATION_STATUS, {
    fetchPolicy: "network-only",
  });

  //TODO: Add city loading back in.
  const loading = leadSourceLoading || industriesLoading || stateLoading || tzLoading || loadingReps;

  if (loading)
    return (
      <div>
        <SkeletonBlock height={"100vh"} width={"100%"} borderRadius={8} />
      </div>
    );

  // Custom Data
  const leadSourceOptionsArray = leadSourceOptions.getUniqueLeadSources
    ?.map((item: any) => item.label)
    .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));

  const leadCreationSrcOptionsArray = !!dataStatus?.checkIntegrationStatus?.HubSpot
    ? leadCreationSrcOptionsHubspot
        ?.map((item: any) => item.label)
        .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1))
    : leadCreationSrcOptions
        ?.map((item: any) => item.label)
        .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));
  const leadCreationSrcOptionsArrayVals = !!dataStatus?.checkIntegrationStatus?.HubSpot
    ? leadCreationSrcOptionsHubspot
        ?.map((item: any) => item.value)
        .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1))
    : leadCreationSrcOptions
        ?.map((item: any) => item.value)
        .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));

  const industryOptionsArray = (industriesData?.fetchIndustryOptions || [])
    ?.map((item: any) => item?.label ?? "NA")
    .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));

  const subIndustryOptionsArray = subIndustryOptions
    ?.map((item: any) => item)
    .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));

  const repsOptionsArray: OptionItem[] = dataReps?.fetchOrganization?.Reps?.map((item: any) => ({
    label: `${item.first_name} ${item.last_name}`,
    value: item?.id,
  }))?.sort((a: any, b: any) => (`${a.label}`.toLowerCase() > `${b.label}`.toLowerCase() ? 1 : -1));
  const repsOptionsArrayVals: string[] = repsOptionsArray?.map((item: any) => item.value);

  const repsOptionsArrayLabels: string[] = repsOptionsArray?.map((item: any) => item.label);

  // Custom Functions
  const mapOptionsToKey = (key: string) => {
    switch (key) {
      case "reps":
        return repsOptionsArrayVals;
      case "lead_sources":
        return leadSourceOptionsArray;
      case "lead_creation_source":
        return leadCreationSrcOptionsArrayVals;
      case "status":
        return ["Unassigned", "Assigned", "Owned", "Resting", "Retired", "Customer"].sort();
      case "industries":
        return industryOptionsArray;
      case "sub_industry":
        return subIndustryOptionsArray;
      case "states":
        return stateOptions.getStateOptions
          ?.map((item: any) => item)
          .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));
      case "timezones":
        return tzOptions.getTZOptions
          ?.map((item: any) => item)
          .sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));
      case "PDV":
        return ["$0.00-$0.99", "$1.00-$1.99", "$2.00-$2.99", "$3.00+"];
      case "next_scheduled_event":
        return NEXT_SCHEDULED_EVENT_OPTIONS;
      case "channels":
        return ["Inbound", "Outbound"];
      default:
        break;
    }
  };

  const filterOperationArray: OptionItem[] = [
    {
      label: `Does include`,
      value: "DoesInclude",
    },
    {
      label: `Does not include`,
      value: "DoesNotInclude",
    },
    {
      label: `Empty field`,
      value: "EmptyField",
    },
  ];

  const returnOperatorFromKey = (key: string) => {
    switch (key) {
      case "reps":
        return "reps_operator";
      case "lead_sources":
        return "lead_sources_operator";
      case "lead_creation_source":
        return "lead_creation_source_operator";
      case "status":
        return "status_operator";
      case "industries":
        return "industries_operator";
      case "sub_industry":
        return "sub_industry_operator";
      case "states":
        return "states_operator";
      case "timezones":
        return "timezones_operator";
      case "channels":
        return "channels_operator";
      default:
        break;
    }
  };

  const mapLabelToKey = (key: string) => {
    switch (key) {
      case "reps":
        return "Rep";
      case "status":
        return "Status";
      case "industries":
        return "Industry";
      case "sub_industry":
        return "Sub-Industry";
      case "lead_sources":
        return "Lead Sources";
      case "lead_creation_source":
        return "Lead Creation Source";
      case "states":
        return "State";
      case "timezones":
        return "Timezone";
      case "next_scheduled_event":
        return "Next Scheduled Event";
      case "channels":
        return "Channel";
    }
  };

  console.log("hubspot connection", dataStatus?.checkIntegrationStatus?.HubSpot);

  const formatFilterName = (key: string, name: string) => {
    if (key !== "types") return name;
    const array = name.match(/[A-Z][a-z]+/g);
    return array?.join(" ") ?? name;
  };

  const handleCheckboxClick = (key: string, item: string | number) => {
    if (tempLeadFilter[key]?.includes(item)) {
      const filterKeyArray = tempLeadFilter[key]?.filter((selected: string) => {
        return selected !== item;
      });

      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: filterKeyArray,
      });
    } else {
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: [...(tempLeadFilter[key] ?? []), item],
      });
    }
  };

  const handleMultiSelectChange = (key: string, items: string[]) => {
    if (key === "industries") {
      const possibleSubIndustries = returnSubIndustryOptions(items);
      setSubIndustryOptions(possibleSubIndustries);
    }

    setTempLeadFilter({
      ...tempLeadFilter,
      [key]: items,
    });
    // const newSelected = !!tempSelected.length
    //   ? tempSelected
    //       ?.map((item: any) => item)
    //       .filter((filter: any) => !(filter.key === key && items.includes(filter.item)))
    //   : [];

    // setTempSelected([
    //   ...newSelected.slice().filter((item: any) => item.key !== key),
    //   ...items?.map((item: string) => ({ key: key, item: item })),
    // ]);
  };

  const resetIndustries = () => {
    setTempLeadFilter({ ...tempLeadFilter, industries: [], sub_industry: [] });
  };

  const resetSubindustries = () => {
    setTempLeadFilter({ ...tempLeadFilter, sub_industry: [] });
  };

  const onSubmit = () => {
    /* local var used since we have to delete amount 2 to prevent an error in the BE
     when not using Between and not between. if fixed we could simply reference the temp state lead filter */
    let onSubmitTempLeadFilter = { ...tempLeadFilter };

    const hasCallResultOption =
      Array.isArray(onSubmitTempLeadFilter.call_result_filter.call_result_option) &&
      onSubmitTempLeadFilter.call_result_filter.call_result_option.length > 0;
    const hasCallResultType = !!onSubmitTempLeadFilter.call_result_filter.call_result_type;

    if (hasCallResultOption || hasCallResultType) {
      if (!hasCallResultOption) {
        appToast("Invalid Call Result Filter! Must specify an option");
        return;
      }
      if (!hasCallResultType) {
        appToast("Invalid Call Result Filter! Must specify a type");
        return;
      }
    }
    if (
      onSubmitTempLeadFilter?.history_filter?.amount ||
      onSubmitTempLeadFilter?.history_filter?.operator ||
      onSubmitTempLeadFilter?.history_filter?.metric
    ) {
      if (!onSubmitTempLeadFilter?.history_filter?.amount && onSubmitTempLeadFilter?.history_filter?.amount !== 0) {
        appToast("Invalid Lead History Filter! Must specify an amount");
        return;
      }
      if (!onSubmitTempLeadFilter?.history_filter?.operator) {
        appToast("Invalid Lead History Filter! Must specify an operator");
        return;
      }
      if (!onSubmitTempLeadFilter?.history_filter?.metric) {
        appToast("Invalid Lead History Filter! Must specify a metric");
        return;
      }
    }

    // Between and Not_Between checks for history filter
    if (
      onSubmitTempLeadFilter.history_filter?.operator === "Between" ||
      onSubmitTempLeadFilter.history_filter?.operator === "NotBetween"
    ) {
      // if amount_2 is not present, it will throw an error
      if (!onSubmitTempLeadFilter.history_filter?.amount_2) {
        appToast("Invalid Lead History Filter! Must specify a second amount");
        return;
      }

      // amount > amount2 will still run the query but switch the values
      if (
        onSubmitTempLeadFilter?.history_filter?.amount &&
        onSubmitTempLeadFilter?.history_filter?.amount_2 &&
        onSubmitTempLeadFilter?.history_filter?.amount > onSubmitTempLeadFilter.history_filter?.amount_2
      ) {
        const temp = tempLeadFilter.history_filter?.amount;
        onSubmitTempLeadFilter.history_filter.amount = tempLeadFilter.history_filter?.amount_2;
        onSubmitTempLeadFilter.history_filter.amount_2 = temp;
      }
    }
    // any other operator MUST delete amount_2 or any error will occur
    else {
      delete onSubmitTempLeadFilter.history_filter.amount_2;
    }

    // handle custom fields that have no field name
    if (!!onSubmitTempLeadFilter.custom_fields) {
      const invalidFields = onSubmitTempLeadFilter.custom_fields.filter((item: any) => item.id === "");
      if (invalidFields.length > 0) {
        appToast("Invalid Custom Field Filter! Must specify a field name");
        return;
      }
    }

    if (!!onSubmitTempLeadFilter.custom_fields) {
      const invalidFields = onSubmitTempLeadFilter.custom_fields.filter((item: any) => item.value?.length === 0);
      if (invalidFields.length > 0) {
        appToast("Invalid Custom Field Filter! Must specify a value");
        return;
      }
    }

    setLeadSystemFilter(onSubmitTempLeadFilter);
    setShowFiltersModal(!showFiltersModal);
    setLeadSystemOperators(tempLeadSystemOperators);
  };

  const filterOptionsArray = Object.keys(tempLeadFilter).filter(
    (k) =>
      k !== "history_filter" &&
      k !== "call_result_filter" &&
      k !== "rep_activity_filter" &&
      !(k === "reps" && repVersion) &&
      (!showLeadSourceToUser?.showLeadSourceToUser ? k !== "lead_sources" && k !== "lead_creation_source" : true),
  );

  console.log("filters: ", leadSystemFilter);
  console.log("temp filters: ", tempLeadFilter);

  console.log(
    NEXT_SCHEDULED_EVENT_OPTIONS.filter((item: any) =>
      tempLeadFilter?.next_scheduled_event?.NextScheduledEventTypes.includes(item.value),
    ),
  );

  const error =
    errorCallResultOptions ||
    errorDispositionTypeOptions ||
    errorPDStatus ||
    customFieldsError ||
    errorReps ||
    industriesError ||
    stateError ||
    tzError;

  if (error) {
    return (
      <div>
        <AppErrorText>An error occurred while loading the filters. Please refresh the page and try again.</AppErrorText>
      </div>
    );
  }

  return (
    <EditCardDiv>
      <TitleDiv>
        <TitleText>Apply Filters</TitleText>
        <CloseButton>
          <NewAppButton
            // small
            // borderless
            onClick={(e) => {
              e.stopPropagation();

              // reset the unsaved temp filters to the current filters
              setTempLeadFilter(leadSystemFilter);

              // hide the modal
              setShowFiltersModal(!showFiltersModal);
            }}
          >
            <img src={newCloseModal} alt="Close" />
          </NewAppButton>
        </CloseButton>
      </TitleDiv>
      <ScrollDiv>
        {filterOptionsArray
          ?.filter(
            (key: string) =>
              ![
                "next_scheduled_event",
                "custom_fields",
                "zip_codes",
                "missed_event",
                "team",
                "sourced_by_user",
                "notes",
                "created_date",
                "claimed_date",
                "scheduled_event_type",
              ].includes(key),
          )
          ?.map((key: string) => {
            const optionsArray = mapOptionsToKey(key);
            return (
              <SectionContainerDiv key={key}>
                <SectionRow>
                  <SectionTitle>
                    <AppText style={{ borderBottom: "none" }}>
                      {mapLabelToKey(key)} &nbsp;
                      {!!leadSources && !!leadSources.length && (
                        <span style={{ fontWeight: 600 }}>({leadSources?.length})</span>
                      )}
                    </AppText>
                  </SectionTitle>
                  <div>
                    <NewAppButton
                      // small
                      // borderless
                      onClick={() =>
                        expanded?.includes(key)
                          ? setExpanded(expanded.filter((item) => item !== key))
                          : setExpanded([...expanded, key])
                      }
                    >
                      {expanded?.includes(key) ? (
                        <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                      ) : (
                        <img src={showMoreArrow} alt="Expand" />
                      )}
                    </NewAppButton>
                  </div>
                </SectionRow>
                {expanded?.includes(key) &&
                  (key === "industries" ? (
                    <PopOutDiv style={{ zIndex: 3 }}>
                      {returnOperatorFromKey(key) && (
                        <RadioSection>
                          {filterOperationArray?.map((item: any) => {
                            const operator = returnOperatorFromKey(key) as keyof typeof tempLeadSystemOperators;
                            return (
                              <RadioDiv>
                                <PhoenixRadio
                                  selected={tempLeadSystemOperators[operator] === item.value}
                                  onClick={async () => {
                                    setTempLeadSystemOperators({ ...tempLeadSystemOperators, [operator]: item.value });
                                  }}
                                />
                                <AppText fontSize={12} fontWeight={500}>
                                  {item.label}
                                </AppText>
                              </RadioDiv>
                            );
                          })}
                        </RadioSection>
                      )}
                      {(!returnOperatorFromKey(key) ||
                        tempLeadSystemOperators[returnOperatorFromKey(key) as keyof typeof tempLeadSystemOperators] !==
                          LeadFilterOperator.EmptyField) && (
                        <AppMultiSelect
                          maxHeight={200}
                          name={key}
                          options={mapOptionsToKey(key)
                            ?.map((item: any) => ({ label: item, value: item }))
                            // sort alphabetically
                            ?.sort((a: OptionItem, b: OptionItem) =>
                              a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                            )}
                          value={tempLeadFilter.industries?.map((item: any) => ({ label: item, value: item }))}
                          onChange={(e: any) => {
                            !!e.length
                              ? handleMultiSelectChange(
                                  "industries",
                                  e?.map((item: any) => item.value),
                                )
                              : resetIndustries();
                          }}
                        />
                      )}
                    </PopOutDiv>
                  ) : key === "sub_industry" ? (
                    <PopOutDiv>
                      {returnOperatorFromKey(key) && (
                        <RadioSection>
                          {filterOperationArray?.map((item: any) => {
                            const operator = returnOperatorFromKey(key) as keyof typeof tempLeadSystemOperators;
                            return (
                              <RadioDiv>
                                <PhoenixRadio
                                  selected={tempLeadSystemOperators[operator] === item.value}
                                  onClick={async () => {
                                    setTempLeadSystemOperators({ ...tempLeadSystemOperators, [operator]: item.value });
                                  }}
                                />
                                <AppText fontSize={12} fontWeight={500}>
                                  {item.label}
                                </AppText>
                              </RadioDiv>
                            );
                          })}
                        </RadioSection>
                      )}
                      {(!returnOperatorFromKey(key) ||
                        tempLeadSystemOperators[returnOperatorFromKey(key) as keyof typeof tempLeadSystemOperators] !==
                          LeadFilterOperator.EmptyField) && (
                        <AppMultiSelect
                          maxHeight={200}
                          name="sub_industry"
                          options={optionsArray
                            ?.map((item: any) => ({
                              label: item,
                              value: item,
                            }))
                            // sort alphabetically
                            ?.sort((a: OptionItem, b: OptionItem) =>
                              a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                            )}
                          value={tempLeadFilter.sub_industry
                            ?.map((item: any) => ({ label: item, value: item }))
                            .sort((a: any, b: any) => (a?.label < b?.label ? -1 : 1))}
                          onChange={(e: any) => {
                            console.log(JSON.stringify(e));
                            !!e.length
                              ? handleMultiSelectChange(
                                  "sub_industry",
                                  e?.map((item: any) => item.value),
                                )
                              : resetSubindustries();
                          }}
                        />
                      )}
                    </PopOutDiv>
                  ) : key === "lead_creation_source" ? (
                    <PaddingAndScrollDiv>
                      {returnOperatorFromKey(key) && (
                        <RadioSection>
                          {filterOperationArray?.map((item: any) => {
                            const operator = returnOperatorFromKey(key) as keyof typeof tempLeadSystemOperators;
                            return (
                              <RadioDiv>
                                <PhoenixRadio
                                  selected={tempLeadSystemOperators[operator] === item.value}
                                  onClick={async () => {
                                    setTempLeadSystemOperators({ ...tempLeadSystemOperators, [operator]: item.value });
                                  }}
                                />
                                <AppText fontSize={12} fontWeight={500}>
                                  {item.label}
                                </AppText>
                              </RadioDiv>
                            );
                          })}
                        </RadioSection>
                      )}
                      {expanded?.includes(key) &&
                        (!returnOperatorFromKey(key) ||
                          tempLeadSystemOperators[
                            returnOperatorFromKey(key) as keyof typeof tempLeadSystemOperators
                          ] !== LeadFilterOperator.EmptyField) &&
                        optionsArray?.map((item: string, index: number) => {
                          return (
                            <FilterOptionsContainer>
                              <AppCheckbox
                                title={
                                  key === "lead_creation_source"
                                    ? leadCreationSrcOptionsArray[index]
                                    : formatFilterName(key, item)
                                }
                                checked={tempLeadFilter[key]?.includes(item)}
                                onClick={() => {
                                  handleCheckboxClick(key, item);
                                }}
                              />
                            </FilterOptionsContainer>
                          );
                        })}
                    </PaddingAndScrollDiv>
                  ) : (
                    <PaddingAndScrollDiv>
                      {returnOperatorFromKey(key) && (
                        <RadioSection>
                          {filterOperationArray?.map((item: any) => {
                            const operator = returnOperatorFromKey(key) as keyof typeof tempLeadSystemOperators;
                            return (
                              <RadioDiv>
                                <PhoenixRadio
                                  selected={tempLeadSystemOperators[operator] === item.value}
                                  onClick={async () => {
                                    setTempLeadSystemOperators({ ...tempLeadSystemOperators, [operator]: item.value });
                                  }}
                                />
                                <AppText fontSize={12} fontWeight={500}>
                                  {item.label}
                                </AppText>
                              </RadioDiv>
                            );
                          })}
                        </RadioSection>
                      )}
                      {expanded?.includes(key) &&
                        (!returnOperatorFromKey(key) ||
                          tempLeadSystemOperators[
                            returnOperatorFromKey(key) as keyof typeof tempLeadSystemOperators
                          ] !== LeadFilterOperator.EmptyField) &&
                        optionsArray?.map((item: string, index: number) => {
                          return (
                            <FilterOptionsContainer>
                              <AppCheckbox
                                title={key === "reps" ? repsOptionsArrayLabels[index] : formatFilterName(key, item)}
                                checked={tempLeadFilter[key]?.includes(item)}
                                onClick={() => {
                                  handleCheckboxClick(key, item);
                                }}
                              />
                            </FilterOptionsContainer>
                          );
                        })}
                    </PaddingAndScrollDiv>
                  ))}
              </SectionContainerDiv>
            );
          })}
        <SectionContainerDiv key={"lead_history"} style={{ maxHeight: 500 }}>
          <SectionRow>
            <SectionTitle>
              <AppText style={{ borderBottom: "none" }}>Lead History</AppText>
            </SectionTitle>
            <div>
              <NewAppButton
                // small
                // borderless
                onClick={() =>
                  expanded?.includes("lead_history")
                    ? setExpanded(expanded.filter((item) => item !== "lead_history"))
                    : setExpanded([...expanded, "lead_history"])
                }
              >
                {expanded?.includes("lead_history") ? (
                  <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                ) : (
                  <img src={showMoreArrow} alt="Expand" />
                )}
              </NewAppButton>
            </div>
          </SectionRow>
          {expanded?.includes("lead_history") && (
            <PaddingAndScrollDiv style={{ paddingBottom: "8px", maxHeight: 500 }}>
              <HorizontalDiv>
                <AppText style={{ marginBottom: "18px" }}>
                  Lead Received
                  {tempLeadFilter?.history_filter?.operator === "Between" ||
                  tempLeadFilter?.history_filter?.operator === "NotBetween"
                    ? ""
                    : " a"}
                </AppText>
                <div style={{ marginLeft: "36px", width: "177px" }}>
                  <AppSelect
                    textAlign="left"
                    name="lead_history_received"
                    options={callResultsTypeOptions
                      // sort alphabetically
                      ?.sort((a: OptionItem, b: OptionItem) =>
                        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                      )}
                    value={tempLeadFilter?.history_filter?.metric}
                    placeholder="Metric"
                    allowSelectPlaceholder={true}
                    onChange={(e: any) => {
                      setTempLeadFilter({
                        ...tempLeadFilter,
                        history_filter: {
                          ...tempLeadFilter.history_filter,
                          metric: !!e?.target?.value && e.target.value !== "0" ? e.target.value : undefined,
                        },
                      });
                    }}
                  />
                </div>
              </HorizontalDiv>
              <HorizontalDiv>
                <div style={{ width: "177px" }}>
                  <AppSelect
                    textAlign="left"
                    name="lead_history_operator"
                    options={leadHistoryOperators
                      // sort alphabetically
                      ?.sort((a: OptionItem, b: OptionItem) =>
                        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                      )}
                    value={tempLeadFilter.history_filter?.operator}
                    placeholder="Operator"
                    allowSelectPlaceholder={true}
                    onChange={(e: any) => {
                      setTempLeadFilter({
                        ...tempLeadFilter,
                        history_filter: {
                          ...tempLeadFilter.history_filter,
                          // @ts-ignore
                          operator: !!e?.target?.value && e.target.value !== "0" ? e.target.value : undefined,
                        },
                      });
                    }}
                  />
                </div>
                <div style={{ width: "40px", margin: "2px 16px 0px 16px" }}>
                  <AppInput
                    style={{ margin: "0px", paddingLeft: "6px", paddingRight: "6px" }}
                    name="lead_history_number"
                    type="number"
                    value={tempLeadFilter?.history_filter?.amount ?? undefined}
                    placeholder="0"
                    onChange={(e) => {
                      setTempLeadFilter({
                        ...tempLeadFilter,
                        history_filter: {
                          ...tempLeadFilter.history_filter,
                          // @ts-ignore
                          amount: e?.target?.value ? Math.abs(parseInt(e.target.value)) : undefined,
                        },
                      });
                    }}
                  />
                </div>
                <AppText style={{ marginBottom: "18px" }}>
                  {tempLeadFilter?.history_filter?.operator === "Between" ||
                  tempLeadFilter?.history_filter?.operator === "NotBetween"
                    ? "and"
                    : "times"}
                </AppText>
                {tempLeadFilter?.history_filter?.operator === "Between" ||
                tempLeadFilter?.history_filter?.operator === "NotBetween" ? (
                  <>
                    <div style={{ width: "40px", margin: "2px 16px 0px 16px" }}>
                      <AppInput
                        style={{ margin: "0px", paddingLeft: "6px", paddingRight: "6px" }}
                        name="lead_history_number"
                        type="number"
                        value={tempLeadFilter?.history_filter?.amount_2 ?? undefined}
                        placeholder="0"
                        onChange={(e) => {
                          setTempLeadFilter({
                            ...tempLeadFilter,
                            history_filter: {
                              ...tempLeadFilter.history_filter,
                              // @ts-ignore
                              amount_2: e?.target?.value ? Math.abs(parseInt(e.target.value)) : undefined,
                            },
                          });
                        }}
                      />
                    </div>

                    <AppText style={{ marginBottom: "18px" }}>times</AppText>
                  </>
                ) : null}
              </HorizontalDiv>
              <Horizontal>
                <AppText style={{ marginRight: "18px" }}>During</AppText>
                <TimeColumnDiv>
                  <div style={{ display: "flex", width: "100%" }}>
                    <TimeLabelText style={{ marginLeft: "15px" }}>Start Date</TimeLabelText>
                    <TimeLabelText style={{ marginLeft: "95px" }}>End Date</TimeLabelText>
                  </div>
                  <OutsideEvent onOutsideClick={() => setFocusedInput(focusedInput)}>
                    <ReactDatesWrapper fixedPosition>
                      <DateRangePicker
                        minimumNights={0}
                        showClearDates
                        isOutsideRange={() => false}
                        startDate={
                          tempLeadFilter?.history_filter?.lowerbound_date
                            ? moment(tempLeadFilter?.history_filter?.lowerbound_date)
                            : null
                        }
                        startDateId="system_lead_filter_start_date"
                        endDate={
                          tempLeadFilter?.history_filter?.upperbound_date
                            ? moment(tempLeadFilter?.history_filter?.upperbound_date)
                            : null
                        }
                        endDateId="system_lead_filter_end_date"
                        onDatesChange={({ startDate, endDate }) => {
                          // if (startDate && moment(startDate).isValid()) {
                          setTempLeadFilter((tempLeadFilter: any) => ({
                            ...tempLeadFilter,
                            history_filter: {
                              ...tempLeadFilter.history_filter,
                              lowerbound_date: startDate,
                            },
                          }));
                          // }
                          // if (endDate && moment(endDate).isValid()) {
                          setTempLeadFilter((tempLeadFilter: any) => ({
                            ...tempLeadFilter,
                            history_filter: {
                              ...tempLeadFilter.history_filter,
                              upperbound_date: endDate,
                            },
                          }));
                          // }
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                      />
                    </ReactDatesWrapper>
                  </OutsideEvent>
                </TimeColumnDiv>
              </Horizontal>
            </PaddingAndScrollDiv>
          )}
        </SectionContainerDiv>

        {!repVersion && (
          <SectionContainerDiv>
            <SectionRow>
              <SectionTitle>
                <AppText style={{ borderBottom: "none" }}>Rep Activity</AppText>
              </SectionTitle>
              <div>
                <NewAppButton
                  // small
                  // borderless
                  onClick={() =>
                    expanded?.includes("rep_activity")
                      ? setExpanded(expanded.filter((item) => item !== "rep_activity"))
                      : setExpanded([...expanded, "rep_activity"])
                  }
                >
                  {expanded?.includes("rep_activity") ? (
                    <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                  ) : (
                    <img src={showMoreArrow} alt="Expand" />
                  )}
                </NewAppButton>
              </div>
            </SectionRow>
            {expanded?.includes("rep_activity") && (
              <PaddingAndScrollDiv style={{ paddingBottom: "8px", maxHeight: 500 }}>
                <HorizontalDiv>
                  <AppText style={{ marginBottom: "18px" }}>Reps</AppText>
                  <div style={{ marginLeft: "36px", width: "177px" }}>
                    <AppMultiSelect
                      name="rep_activity_users"
                      options={
                        dataRepsActivities?.fetchRepsAsOptions
                          ?.slice()
                          // sort alphabetically
                          ?.sort((a: OptionItem, b: OptionItem) => (a?.label < b?.label ? -1 : 1)) || []
                      }
                      value={tempLeadFilter?.rep_activity_filter?.user_ids}
                      onChange={(e: any) => {
                        console.log("multi output: ", e);
                        setTempLeadFilter({
                          ...tempLeadFilter,
                          rep_activity_filter: {
                            ...tempLeadFilter.rep_activity_filter,
                            user_ids: e || [],
                          },
                        });
                      }}
                    />
                  </div>
                </HorizontalDiv>
                <Horizontal>
                  <AppText style={{ marginRight: "18px" }}>During</AppText>
                  <TimeColumnDiv>
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimeLabelText style={{ marginLeft: "15px" }}>Start Date</TimeLabelText>
                      <TimeLabelText style={{ marginLeft: "95px" }}>End Date</TimeLabelText>
                    </div>
                    <OutsideEvent onOutsideClick={() => setFocusedInput2(focusedInput2)}>
                      <ReactDatesWrapper fixedPosition>
                        <DateRangePicker
                          minimumNights={0}
                          showClearDates
                          isOutsideRange={() => false}
                          startDate={
                            tempLeadFilter?.rep_activity_filter?.rep_lowerbound_date
                              ? moment(tempLeadFilter?.rep_activity_filter?.rep_lowerbound_date)
                              : null
                          }
                          startDateId="system_lead_filter_rep_start_date"
                          endDate={
                            tempLeadFilter?.rep_activity_filter?.rep_upperbound_date
                              ? moment(tempLeadFilter?.rep_activity_filter?.rep_upperbound_date)
                              : null
                          }
                          endDateId="system_lead_filter_rep_end_date"
                          onDatesChange={({ startDate, endDate }) => {
                            // if (startDate && moment(startDate).isValid()) {
                            setTempLeadFilter((tempLeadFilter: any) => ({
                              ...tempLeadFilter,
                              rep_activity_filter: {
                                ...tempLeadFilter.rep_activity_filter,
                                rep_lowerbound_date: startDate,
                              },
                            }));
                            // }
                            // if (endDate && moment(endDate).isValid()) {
                            setTempLeadFilter((tempLeadFilter: any) => ({
                              ...tempLeadFilter,
                              rep_activity_filter: {
                                ...tempLeadFilter.rep_activity_filter,
                                rep_upperbound_date: endDate,
                              },
                            }));
                            // }
                          }}
                          focusedInput={focusedInput2}
                          onFocusChange={(focusedInput) => setFocusedInput2(focusedInput)}
                        />
                      </ReactDatesWrapper>
                    </OutsideEvent>
                  </TimeColumnDiv>
                </Horizontal>
              </PaddingAndScrollDiv>
            )}
          </SectionContainerDiv>
        )}
        <SectionContainerDiv key={"call_result"}>
          <SectionRow>
            <SectionTitle>
              <AppText style={{ borderBottom: "none" }}>Call Result</AppText>
            </SectionTitle>
            <div>
              <NewAppButton
                // small
                // borderless
                onClick={() =>
                  expanded?.includes("call_result")
                    ? setExpanded(expanded.filter((item) => item !== "call_result"))
                    : setExpanded([...expanded, "call_result"])
                }
              >
                {expanded?.includes("call_result") ? (
                  <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                ) : (
                  <img src={showMoreArrow} alt="Expand" />
                )}
              </NewAppButton>
            </div>
          </SectionRow>
          {expanded?.includes("call_result") && (
            <PaddingAndScrollDiv style={{ marginBottom: "8px" }}>
              <DropdownLabel>When</DropdownLabel>
              <AppSelect
                textAlign="left"
                name="call_result_type"
                options={
                  dataDispositionTypeOptions?.getDispositionTypeOptions
                    ?.slice()
                    ?.sort((a: any, b: any) => (a.label < b.label ? -1 : 1)) || []
                }
                value={tempLeadFilter?.call_result_filter?.call_result_type}
                placeholder="None"
                isPlaceholder={true}
                allowSelectPlaceholder
                onChange={(e: any) => {
                  if (e.target.value) {
                    setTempLeadFilter({
                      ...tempLeadFilter,
                      call_result_filter: {
                        ...tempLeadFilter.call_result_filter,
                        call_result_type: !!e?.target?.value && e.target.value !== "0" ? e.target.value : undefined,
                      },
                    });
                  }
                }}
              />
              <DropdownLabel>Call Result Type</DropdownLabel>
              <AppSelect
                textAlign="left"
                name="call_result_option"
                options={
                  dataCallResultOptions?.getCallResultOptions
                    .slice()
                    .sort((a: any, b: any) => (a.label < b.label ? -1 : 1)) || []
                }
                value={tempLeadFilter?.call_result_filter?.call_result_option?.[0]}
                placeholder="None"
                isPlaceholder={true}
                allowSelectPlaceholder
                onChange={(e: any) => {
                  if (e.target.value) {
                    setTempLeadFilter({
                      ...tempLeadFilter,
                      call_result_filter: {
                        ...tempLeadFilter.call_result_filter,
                        call_result_option: !!e?.target?.value && e.target.value !== "0" ? [e.target.value] : [],
                      },
                    });
                  }
                }}
              />
            </PaddingAndScrollDiv>
          )}
        </SectionContainerDiv>

        <SectionContainerDiv key={"next_scheduled_event"} style={{ maxHeight: 500 }}>
          <SectionRow>
            <SectionTitle>
              <AppText style={{ borderBottom: "none" }}>Next Scheduled Event</AppText>
            </SectionTitle>
            <div>
              <NewAppButton
                // small
                // borderless
                onClick={() =>
                  expanded?.includes("next_scheduled_event")
                    ? setExpanded(expanded.filter((item) => item !== "next_scheduled_event"))
                    : setExpanded([...expanded, "next_scheduled_event"])
                }
              >
                {expanded?.includes("next_scheduled_event") ? (
                  <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                ) : (
                  <img src={showMoreArrow} alt="Expand" />
                )}
              </NewAppButton>
            </div>
          </SectionRow>
          {expanded?.includes("next_scheduled_event") && (
            <PaddingAndScrollDiv style={{ paddingBottom: "8px", maxHeight: 500 }}>
              <ColumnDiv>
                <AppText style={{ marginBottom: "18px" }}>Event Type</AppText>
                <div>
                  <NewAppSelect
                    multi
                    textAlign="left"
                    name="NextScheduledEventTypes"
                    options={mapOptionsToKey("next_scheduled_event")
                      // sort alphabetically
                      ?.sort((a: OptionItem, b: OptionItem) =>
                        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                      )}
                    defaultValue={
                      NEXT_SCHEDULED_EVENT_OPTIONS.filter((item: any) => {
                        return tempLeadFilter?.next_scheduled_event?.NextScheduledEventTypes.includes(item.value);
                      }) as any
                    }
                    // allowSelectPlaceholder={true}
                    onChange={(e: any) => {
                      setTempLeadFilter({
                        ...tempLeadFilter,
                        next_scheduled_event: {
                          NextScheduledEventTypes: !!e.length ? e?.map((item: any) => item.value) : [],
                          NextScheduledEventDays: tempLeadFilter.next_scheduled_event?.NextScheduledEventDays,
                        },
                      });
                    }}
                  />
                </div>
              </ColumnDiv>
              <ColumnDiv>
                <AppText style={{ marginBottom: "18px" }}>Occurs Within</AppText>
                <div style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}>
                  <AppInput
                    displayNoContext
                    style={{ margin: "8px 0px", paddingLeft: "6px", paddingRight: "6px" }}
                    name="NextScheduledEventDays"
                    type="number"
                    defaultValue={tempLeadFilter?.next_scheduled_event?.NextScheduledEventDays}
                    onChange={(e: any) => {
                      setTempLeadFilter({
                        ...tempLeadFilter,
                        next_scheduled_event: {
                          NextScheduledEventDays: !!e?.target?.value ? e.target.value : undefined,
                          NextScheduledEventTypes: tempLeadFilter.next_scheduled_event?.NextScheduledEventTypes,
                        },
                      });
                    }}
                  />
                  <AppText>days</AppText>
                </div>
              </ColumnDiv>
            </PaddingAndScrollDiv>
          )}
        </SectionContainerDiv>
        <SectionContainerDiv key={"custom_fields"} style={{ maxHeight: 500 }}>
          <SectionRow>
            <SectionTitle>
              <AppText style={{ borderBottom: "none" }}>Custom Fields</AppText>
            </SectionTitle>
            <div>
              <NewAppButton
                // small
                // borderless
                onClick={() =>
                  expanded?.includes("custom_fields")
                    ? setExpanded(expanded.filter((item) => item !== "custom_fields"))
                    : setExpanded([...expanded, "custom_fields"])
                }
              >
                {expanded?.includes("custom_fields") ? (
                  <img src={showMoreArrow} style={{ transform: "rotate(180deg)" }} alt="Minimize" />
                ) : (
                  <img src={showMoreArrow} alt="Expand" />
                )}
              </NewAppButton>
            </div>
          </SectionRow>

          {expanded?.includes("custom_fields") && (
            <>
              <ColumnDiv
                style={{
                  paddingBottom: "32px",
                }}
              >
                {tempLeadFilter?.custom_fields?.map(
                  (item: { id: string; type: string; key: string; value: string }, index: number) => {
                    return (
                      <CustomFieldDiv>
                        <div style={{ width: "100%", display: "flex", alignItems: "center", marginBottom: "18px" }}>
                          <div
                            style={{
                              width: "392px",
                            }}
                          >
                            <NewAppSelect
                              noErrorNeeded
                              textAlign="left"
                              name="CustomField"
                              options={
                                (supportedCustomFields
                                  ?.map((item: any) => {
                                    return {
                                      label: item.key,
                                      value: item.id,
                                    };
                                  })
                                  // sort alphabetically
                                  ?.sort((a: OptionItem, b: OptionItem) => (a?.label < b?.label ? -1 : 1))
                                  // filter out the custom fields that are already selected
                                  ?.filter((item: any) => {
                                    return !tempLeadFilter?.custom_fields
                                      ?.map((item: any) => item.id)
                                      .includes(item.value);
                                  }) as any) ?? []
                              }
                              defaultValue={
                                tempLeadFilter?.custom_fields[index]?.id
                                  ? {
                                      label: tempLeadFilter?.custom_fields[index]?.key,
                                      value: tempLeadFilter?.custom_fields[index]?.id,
                                    }
                                  : {
                                      label: "Select a custom field",
                                      value: "",
                                    }
                              }
                              value={
                                tempLeadFilter?.custom_fields[index]?.id
                                  ? {
                                      label: tempLeadFilter?.custom_fields[index]?.key,
                                      value: tempLeadFilter?.custom_fields[index]?.id,
                                    }
                                  : {
                                      label: "Select a custom field",
                                      value: "",
                                    }
                              }
                              allowSelectPlaceholder={true}
                              onChange={(e: any) => {
                                // change the selected custom field at the index to whatever value is selected
                                const newSelectedCustomFields = tempLeadFilter.custom_fields?.map(
                                  // change the filed at the
                                  (currentFields: any, i: number) => {
                                    if (i === index) {
                                      return {
                                        id: e.value,
                                        key: e.label,
                                        value: [],
                                        type: supportedCustomFields.filter((item: any) => item.id === e.value)[0].type,
                                      };
                                    }
                                    return currentFields;
                                  },
                                );

                                setTempLeadFilter({
                                  ...tempLeadFilter,
                                  custom_fields: newSelectedCustomFields,
                                });
                              }}
                            />
                          </div>
                          <BsTrashFill
                            style={{ cursor: "pointer", marginLeft: 10 }}
                            color="red"
                            size={20}
                            onClick={() => {
                              const newSelectedCustomFields = tempLeadFilter.custom_fields.filter(
                                (currentFields: any, i: number) => {
                                  return currentFields.id !== item.id;
                                },
                              );
                              setTempLeadFilter({
                                ...tempLeadFilter,
                                custom_fields: newSelectedCustomFields,
                              });
                            }}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <AppMultiSelect
                            name="CustomFieldValue"
                            options={
                              // map the options for that id of the item
                              supportedCustomFields
                                ?.find((customField: any) => customField.id === item.id)
                                ?.options?.map((customFieldOption: any) => {
                                  return { label: customFieldOption, value: customFieldOption };
                                })
                                // sort alphabetically
                                ?.sort((a: OptionItem, b: OptionItem) => (a?.label < b?.label ? -1 : 1)) || []
                            }
                            value={
                              // selected values based on selected custom fields
                              tempLeadFilter?.custom_fields[index]?.value?.map((item: any) => {
                                return { label: item, value: item };
                              }) || []
                            }
                            // allowSelectPlaceholder={true}
                            onChange={(e: any) => {
                              // change the selected custom field at the index
                              const newSelectedCustomFields = tempLeadFilter.custom_fields?.map(
                                (customField: any, i: number) => {
                                  if (customField.id === item.id) {
                                    return {
                                      key: item.key,
                                      id: item.id,
                                      value: e?.map((item: any) => item.value),
                                      type: item.type,
                                    };
                                  }
                                  return customField;
                                },
                              );
                              setTempLeadFilter({
                                ...tempLeadFilter,
                                custom_fields: newSelectedCustomFields,
                              });
                            }}
                          />
                        </div>
                      </CustomFieldDiv>
                    );
                  },
                )}
              </ColumnDiv>
              <ColumnDivWithMargin>
                <NewAppButton
                  onClick={() => {
                    // add a custom field to the selected custom fields list with no field selected or value
                    const newCustomFields = tempLeadFilter.custom_fields ?? [];
                    newCustomFields.push({
                      id: "",
                      value: [],
                      key: "",
                      type: "",
                    });
                    setTempLeadFilter({
                      ...tempLeadFilter,
                      custom_fields: newCustomFields,
                    });
                  }}
                >
                  Add Custom Field
                </NewAppButton>
              </ColumnDivWithMargin>
            </>
          )}
        </SectionContainerDiv>
      </ScrollDiv>
      <SubmitDiv>
        <NewAppButton
          onClick={() => {
            resetLeadSystemFilters();
            // setTempLeadFilter(leadSystemFilter);
            // setSelectedSystem(selectedSystem);
            setShowFiltersModal(!showFiltersModal);
            resetLeadSystemOperators();
          }}
        >
          Reset Filters
        </NewAppButton>
        <NewAppButton
          variant={"primary"}
          onClick={onSubmit}
          disabled={
            JSON.stringify(tempLeadFilter) === JSON.stringify(leadSystemFilter) &&
            JSON.stringify(tempLeadSystemOperators) === JSON.stringify(leadSystemOperators)
          }
        >
          Apply Filters {currentFilterCount > 0 && `(${currentFilterCount})`}
        </NewAppButton>
      </SubmitDiv>
    </EditCardDiv>
  );
};

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
`;

const RadioSection = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 3px;
  margin-bottom: 16px;
`;

const CustomFieldDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const TimeColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: end;
  margin-bottom: 12px;
`;

const TimeLabelText = styled(AppText)`
  /* margin-top: 8px;
  font-size: 8px;
  font-weight: 600; */
  margin: 0px;
  margin-bottom: 5px;
  line-height: 1.3;
`;

const PopOutDiv = styled.div`
  z-index: 20;
  overflow: visible;
  background-color: ${theme.WHITE_COLOR};
  .Select-menu-outer {
    z-index: 1000;
  }
`;

const HorizontalDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center;
  width: 100%; */
`;

const ColumnDivWithMargin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center;
  width: 100%; */
  padding-top: 12px;
  padding-bottom: 12px;
`;

const DropdownLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const ScrollDiv = styled.div`
  max-height: calc(100vh - 156px);
  height: calc(100vh - 156px);
  overflow-y: auto;
`;

const SubmitDiv = styled.div`
  position: absolute;
  height: 80px;
  bottom: 20px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const PaddingAndScrollDiv = styled.div`
  /* padding-bottom: 20px; */
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 240px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const FilterOptionsContainer = styled.div``;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionContainerDiv = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  max-height: 300px;
  /* padding-bottom: 20px; */
  /* overflow: auto; */
`;

const EditCardDiv = styled.div`
  /* position: absolute; */
  /* left: 25px;
  top: 50px; */
  z-index: 15;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 7px;
  /* background: ${theme.NEUTRAL100}; */
  /* border-radius: 50%; */
  cursor: pointer;
  /* padding: 3px; */
  z-index: 5;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export { SystemLeadsFiltersComponent };
