import React, { useContext, useRef, useState } from "react";
import { AppText } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";
import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { file_text, more_vertical, play_circle, trash } from "../../../images/NewDesign";
import { ModalContext } from "../../../context";
import { CoachingNoteModal } from "../../modal/CoachingNoteModal";
import moment from "moment";
import { useClickOutside } from "../../../utils/hooks";
import { DeleteCoachNoteModal } from "../../../Components/modal";

interface CoachingNotesTabProps {
  coachingNotes: any;
}

export const CoachingNotesTab: React.FC<CoachingNotesTabProps> = ({ coachingNotes }) => {
  const { showDeleteCoachNoteModal, setShowCoachingNotesModal } = useContext(ModalContext);
  const [selectedCoachNote, setSelectedCoachNote] = useState<string>("");

  return (
    <>
      {showDeleteCoachNoteModal && <DeleteCoachNoteModal noteItemId={selectedCoachNote} />}
      <FlexDiv gap={24} direction="column" height="100%" padding="24px">
        <AppText fontSize={14} fontWeight={500} lineHeight={20}>
          Coaching Notes
        </AppText>
        <PhoenixAppButton buttonType="secondary" onClick={() => setShowCoachingNotesModal(true)}>
          Add Coaching Note
        </PhoenixAppButton>

        <NoteGrid gap={24} wrap="wrap">
          {coachingNotes?.map((note: any) => (
            <Note
              key={note.id}
              id={note.id}
              type="text"
              text={note.text}
              timestamp={note.start}
              setSelectedCoachNote={setSelectedCoachNote}
            />
          ))}
        </NoteGrid>
      </FlexDiv>
    </>
  );
};

interface NoteProps {
  id: string;
  type: "text" | "audio";
  text?: string;
  timestamp: number;
  setSelectedCoachNote: (note: any) => void;
}

const Note: React.FC<NoteProps> = ({ id, type, text, timestamp, setSelectedCoachNote }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <NoteContainer>
      {showMenu && <NoteActionPopup setShowMenu={setShowMenu} />}
      <NoteHeader>
        <FlexDiv align="center" gap={8}>
          <PhoenixIcon
            svg={type === "text" ? file_text : play_circle}
            size={16}
            color={theme.icon.danger.default}
            hoverColor={theme.icon.danger.default}
            alt={type === "text" ? "text note" : "audio note"}
          />
          <AppText>
            {moment
              .utc(timestamp * 1000)
              .format("HH:mm:ss")
              .replace(/^00:/, "")}
          </AppText>
        </FlexDiv>

        <PhoenixIcon
          svg={more_vertical}
          size={16}
          variant="brand"
          pointer
          onClick={() => {
            setShowMenu(true);
            setSelectedCoachNote(id);
          }}
        />
      </NoteHeader>

      <NoteBody>{type === "text" ? <AppText>{text}</AppText> : <></>}</NoteBody>
    </NoteContainer>
  );
};

const NoteContainer = styled.div`
  position: relative;
  width: 336px;
  min-height: 160px;
  max-height: 160px;

  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;

  overflow: hidden;
`;

const NoteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 32px;
  padding: 0px 8px 0px 16px;

  background-color: ${theme.fill.brand.secondary};
`;

const NoteBody = styled.div`
  width: 100%;
  max-height: 128px;
  padding: 16px;

  overflow-y: auto;
`;

const NoteGrid = styled(FlexDiv)`
  max-height: 580px;
  overflow-y: auto;
`;

interface NoteActionPopupProps {
  setShowMenu: (showMenu: boolean) => void;
}

const NoteActionPopup: React.FC<NoteActionPopupProps> = ({ setShowMenu }) => {
  const { setShowDeleteCoachNoteModal } = useContext(ModalContext);
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, () => setShowMenu(false));
  return (
    <PopupContainer ref={containerRef}>
      <MenuItem
        onClick={() => {
          setShowMenu(false);
          setShowDeleteCoachNoteModal(true);
        }}
      >
        <PhoenixIcon svg={trash} size={16} variant="danger" pointer />
        Delete
      </MenuItem>
    </PopupContainer>
  );
};

const PopupContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 24px;

  background-color: ${theme.fill.neutral.primary};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 4px;

  animation: ${theme.popup} 0.2s ease;
  overflow: hidden;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 152px;
  height: 32px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.text.neutral.primary};

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${theme.fill.brand.secondary};
  }
`;
