import React, { useContext } from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../UI";
import { ModalContext } from "../../context";
import { Modal } from "./Modal";
import { theme } from "../../utils/theme";
import { copyTextToClipboard } from "../../utils/misc";

interface DashboardShareViewModalProps {
  url: string;
}

export const DashboardShareViewModal: React.FC<DashboardShareViewModalProps> = ({ url }) => {
  const { showDashboardShareViewModal, setShowDashboardShareViewModal } = useContext(ModalContext);

  return (
    <Modal
      open={showDashboardShareViewModal}
      onClose={() => {
        setShowDashboardShareViewModal(false);
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <AppText fontSize={16} fontWeight={600} lineHeight={22}>
          Your view is now shared at:
        </AppText>
        <AppText
          fontSize={12}
          fontWeight={500}
          color={theme.PRIMARY500}
          style={{ cursor: "pointer", borderBottom: "none" }}
          onClick={() => copyTextToClipboard(url)}
        >
          {url}
        </AppText>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 406px;
  height: 216px;

  padding: 12px;
`;
