import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import * as React from "react";
import { useContext, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import styled from "styled-components";
import { restAPI } from "../../../apollo";
import { ModalContext } from "../../../context/ModalContext";
import { hubspotLogo, iconArrowBackNew, pandaDocLogo, salesforceLogo } from "../../../images";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { BACKEND_URL } from "../../../utils/variables";
import { OutsideEvent } from "../../Dumb";
import { IntegrationCardV2 } from "../../Smart";
import { AppErrorText, AppSidebarCard, AppText, Loading, SkeletonBlock } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import {
  DisconnectIntegrationModal,
  ErrorLogReportModal,
  IntegrationMapRulesV2,
  IntegrationMapSyncPicklistV2,
  Modal,
} from "../../modal/";
import { IntegrationReportingComponent } from "./IntegrationReportingComponent";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./integration/integration-mapping-table.css";

import { IIntegrations } from "../../../types";
import FieldMappingPage from "./integration/FieldMappingPage";

import { CaretDownIcon, MenuSelectContainer, SelectTitle } from "../../UI/Phoenix/PhoenixMenuSelect";
import IntegrationSettingsPage from "./integration/IntegrationSettingsPage";

import { IntegrationItem } from "../../../types";
import { PhoenixMultiSelect } from "../../UI/Phoenix";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
  togglePageTitle?: () => void;
}

const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      hubspot_last_sync_date
      Reps {
        id
        full_name
        id
        first_name
        last_name
        revenue_per_day
        email
      }
      send_error_log_email
      error_log_email_address
    }
  }
`;

const ADD_EMAIL_TO_ERROR_REPORTING = gql`
  mutation AddEmailToErrorReporting($emails: [String], $active: Boolean) {
    addEmailToErrorReporting(emails: $emails, active: $active)
  }
`;

export interface ExpectedIntegrationMappingsResponse {
  fetchIntegrationMappings: {
    id?: string;
    opsiq_object?: string;
    opsiq_field?: string;
    opsiq_field_label?: string;
    opsiq_field_type?: string;
    external_object?: string;
    external_field?: string;
    external_field_label?: string;
    sync_rule?: string;
    trigger_events?: string;
    mapping_type?: string;
    integration_type?: string;
    organization_id?: string;
    created_at?: string;
    updated_at?: string;
    status?: string;
    external_template_id?: string;
    inspect?: string;
    is_custom_object?: boolean;
  }[];
}

const FETCH_INTEGRATION_MAPPINGS = gql`
  query fetchIntegrationMappings($integration_type: INTEGRATION_TYPE!, $template_id: String) {
    fetchIntegrationMappings(integration_type: $integration_type, template_id: $template_id) {
      id
      opsiq_object
      opsiq_field
      opsiq_field_label
      opsiq_field_type
      external_object
      external_field
      external_field_label
      sync_rule
      trigger_events
      mapping_type
      integration_type
      organization_id
      created_at
      updated_at
      status
      external_template_id
      inspect
      is_custom_object
    }
  }
`;

export const CHECK_INTEGRATION_STATUS = gql`
  query checkIntegrationStatus {
    checkIntegrationStatus
  }
`;

export const FETCH_PANDADOC_TEMPLATE_ADMIN = gql`
  query fetchAllPandaDocTemplateForAdmin {
    fetchAllPandaDocTemplateForAdmin {
      template_id
      template_name
      active
      organization_id
    }
  }
`;

export const FETCH_PANDADOC_TEMPLATE_REP = gql`
  query fetchAllPandaDocTemplateForRep {
    fetchAllPandaDocTemplateForRep {
      template_id
      template_name
      active
      organization_id
    }
  }
`;

export const UPDATE_TEMPLATE_ACTIVE_STATUS = gql`
  mutation updateTemplateActiveStatus($active: Boolean!, $template_id: String!) {
    updateTemplateActiveStatus(active: $active, template_id: $template_id) {
      template_id
      template_name
      organization_id
      active
    }
  }
`;

export const SYNC_PANDADOC_TEMPLATE = gql`
  mutation syncPandaDocTemplate {
    syncPandaDocTemplate {
      template_id
      template_name
      active
      organization_id
    }
  }
`;

const integrationsList: IntegrationItem[] = [
  {
    name: "SalesForce",
    url: BACKEND_URL + "/salesforce/redirect",
    logo: salesforceLogo,
    description: "Sync lead and sales data with your Salesforce account",
  },
  {
    name: "HubSpot",
    url: BACKEND_URL + "/hubspot/redirect",
    logo: hubspotLogo,
    description: "Import leads and sync data with HubSpot",
  },
  {
    name: "PandaDoc",
    url: BACKEND_URL + "/pandadoc/redirect",
    logo: pandaDocLogo,
    description: "Integrate sales contracts with PandaDoc",
  },
];

interface CreateOptionsProps {
  drillInPage: () => void;
  configureOption: IIntegrations;
  togglePageTitle?: () => void;
  goToManageTemplates?: () => void;
  goToIntegrationReporting?: () => void;
  goToSettingsPage?: () => void;
  goToFieldMapping?: () => void;
}

interface ManageTemplatesProps {
  backToIntegrations: () => void;
}

const ManageTemplatesComponent: React.FC<ManageTemplatesProps> = ({ backToIntegrations }) => {
  const { data: dataVisibleTemplates, loading: loadingVisibleTemplates, error: errorVisibleTemplates } = useQuery(
    FETCH_PANDADOC_TEMPLATE_ADMIN,
  );

  const [updateTemplateActiveStatus] = useMutation(UPDATE_TEMPLATE_ACTIVE_STATUS, {
    async onCompleted({ updateTemplateActiveStatus }) {
      console.log("updateTemplateActiveStatus: ", updateTemplateActiveStatus);
      appToast("Updated template visibility");
      if (!updateTemplateActiveStatus) {
        return;
      }
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateTemplateActiveStatus GraphQL Error: ${message}`,
      });
      console.log("Error in updateTemplateActiveStatus: ", message);
    },
    refetchQueries: ["fetchAllPandaDocTemplateForAdmin"],
  });

  const [syncPandaDocTemplate, { loading: loadingReSync }] = useMutation(SYNC_PANDADOC_TEMPLATE, {
    async onCompleted({ syncPandaDocTemplate }) {
      console.log("syncPandaDocTemplate: ", syncPandaDocTemplate);
      if (!syncPandaDocTemplate) {
        return;
      }
      appToast(`Re-sync with PandaDoc was successful`);
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `syncPandaDocTemplate GraphQL Error: ${message}`,
      });
      console.log("Error in syncPandaDocTemplate: ", message);
    },
    refetchQueries: ["fetchAllPandaDocTemplateForAdmin"],
  });

  return (
    <TopAlignDiv>
      <SpaceBetweenDiv>
        <BackButton
          // borderless
          onClick={() => {
            backToIntegrations();
          }}
        >
          <img style={{ marginRight: "8px" }} src={iconArrowBackNew} alt="Back" />
          Back
        </BackButton>
        <div style={{ width: "200px" }}>
          {loadingReSync ? (
            <Loading />
          ) : (
            <NewAppButton variant={"primary"} height={40} onClick={async () => await syncPandaDocTemplate()}>
              Re-sync Templates
            </NewAppButton>
          )}
        </div>
      </SpaceBetweenDiv>
      <AppSidebarCard title="Manage Templates">
        <ManageTemplatesTable>
          <TableHeadingText>Name of Template</TableHeadingText>
          <TableHeadingText>Visible in Sellfire</TableHeadingText>
        </ManageTemplatesTable>
        {loadingVisibleTemplates ? (
          <SkeletonBlock height={40} width={"100%"} borderRadius={6} />
        ) : errorVisibleTemplates ? (
          <AppErrorText>Error loading templates data</AppErrorText>
        ) : (
          !!dataVisibleTemplates?.fetchAllPandaDocTemplateForAdmin &&
          dataVisibleTemplates.fetchAllPandaDocTemplateForAdmin
            .slice()
            .sort((a: any, b: any) =>
              `${a?.template_name} ${a?.template_id}`
                .toLocaleLowerCase()
                .localeCompare(`${b?.template_name} ${b?.template_id})`.toLocaleLowerCase()),
            )
            ?.map((item: any) => (
              <ManageTemplatesTableData id={item.template_id}>
                <TallTableText>{item.template_name}</TallTableText>
                <div style={{ padding: "9px 16px" }}>
                  <Switch
                    onChange={async (checked: boolean) =>
                      await updateTemplateActiveStatus({
                        variables: { template_id: item.template_id, active: checked },
                      })
                    }
                    checked={item.active}
                    onColor={theme.PRIMARY500}
                    offColor={theme.NEUTRAL200}
                    height={32}
                    width={54}
                    handleDiameter={24}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </div>
              </ManageTemplatesTableData>
            ))
        )}
      </AppSidebarCard>
    </TopAlignDiv>
  );
};

const CreateIntegrationCardOptions: React.FC<CreateOptionsProps> = ({
  configureOption,
  goToManageTemplates,
  goToIntegrationReporting,
  goToSettingsPage,
  goToFieldMapping,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const { hubspotBackfill, leadIntegrationRelatedRecords } = useFlags();

  return (
    <OutsideEvent onOutsideClick={() => setIsClicked(false)}>
      <ContainerDiv>
        <MenuSelectContainer
          height={32}
          width={128}
          onClick={() => setIsClicked(!isClicked)}
          style={{
            border: "none",
            backgroundColor: theme.NEUTRAL100,
          }}
        >
          <SelectTitle>More Actions</SelectTitle>
          <CaretDownIcon />
        </MenuSelectContainer>

        {isClicked && (
          <OptionsDiv>
            <Option
              onClick={() => {
                goToFieldMapping && goToFieldMapping();
              }}
            >
              Configure Field Mapping
            </Option>
            {(configureOption === IIntegrations.Salesforce || configureOption === IIntegrations.HubSpot) &&
              leadIntegrationRelatedRecords && (
                <Option
                  onClick={() => {
                    goToSettingsPage && goToSettingsPage();
                  }}
                >
                  Settings
                </Option>
              )}
            {configureOption === IIntegrations.PandaDoc && (
              <Option
                onClick={() => {
                  goToManageTemplates && goToManageTemplates();
                }}
              >
                Manage Templates
              </Option>
            )}
            {configureOption === "HubSpot" && !!hubspotBackfill && (
              <Option
                onClick={() => {
                  goToManageTemplates && goToManageTemplates();
                }}
              >
                Import Missing Leads
              </Option>
            )}
            <Option
              onClick={() => {
                goToIntegrationReporting && goToIntegrationReporting();
              }}
            >
              Integration Reports
            </Option>
          </OptionsDiv>
        )}
      </ContainerDiv>
    </OutsideEvent>
  );
};

const StepIntegrationV2: React.FC<DisappearingDivProps> = ({ togglePageTitle }) => {
  const [subpage, setSubpage] = useState<"fieldMapping" | "manageTemplates" | "integrationReporting" | "settingsPage">(
    "fieldMapping",
  );

  const [emails, setEmails] = useState(
    // string aray of emails
    [] as string[],
  );

  const {
    // deprecated
    integrationErrorLogModal,
    selectedIntegration,
    selectedIntegrationLabel,
    setSelectedIntegration,
    showIntegrationMapRulesModal,
    integrationMapPicklistModal,
    setTemplateId,
    mapStateData,
    templateId,
    showDisconnectIntegrationModal,
    selectedIntegrationDisconnect,
  } = useContext(ModalContext);

  const [showIntegrationSubpage, setShowIntegrationSubpage] = useState(false);
  const [confirmHubspotSync, setConfirmHubspotSync] = useState(false);
  const [hubspotModalStep, setHubspotModalStep] = useState(1);

  const { data: dataOrg, loading: loadingOrg, error: errorOrg } = useQuery(FETCH_ORGANIZATION, {
    fetchPolicy: "network-only",
    onCompleted(organization) {
      if (!organization) {
        return;
      }
      setEnableEmailToErrorReporting(organization?.fetchOrganization?.send_error_log_email);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [addEmailToErrorReporting, { loading: loadingAddEmailToErrorReporting }] = useMutation(
    ADD_EMAIL_TO_ERROR_REPORTING,
    {
      onCompleted({ addEmailToErrorReporting }) {
        if (!addEmailToErrorReporting) {
          appToast("Error: Something went wrong!");
          return;
        }
        appToast("Integration Erorr Reporting Settings Updated");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `addEmailToErrorReporting GraphQL Error: ${message}`,
        });

        console.log("Error in addEmailToErrorReporting: ", message);
      },
    },
  );

  const {
    data: dataVisibleTemplatesRep,
    loading: loadingVisibleTemplatesRep,
    error: errorVisibleTemplatesRep,
  } = useQuery(FETCH_PANDADOC_TEMPLATE_REP, {
    fetchPolicy: "network-only",
  });

  const {
    data: dataMappings,
    loading: loadingMappings,
    error: errorMappings,
    refetch: refetchMappings,
  } = useQuery<ExpectedIntegrationMappingsResponse>(FETCH_INTEGRATION_MAPPINGS, {
    skip: !selectedIntegration,
    variables: {
      integration_type: selectedIntegration,
      template_id: templateId ? templateId : undefined,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: dataStatus, loading: loadingStatus, error: errorStatus } = useQuery(CHECK_INTEGRATION_STATUS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const backToIntegrationsTopPage = () => {
    setSelectedIntegration(undefined);
    setShowIntegrationSubpage(false);
    !!togglePageTitle && togglePageTitle();
  };

  const syncHubspotContacts = async () => {
    try {
      const api_call = await restAPI
        .post(`${BACKEND_URL}/hubspot/syncContactsViaQueue`)
        .then((response) => {
          if (!!response?.data) {
            console.log("response sucessful", response);
            appToast("Hubspot Sync successful!");
            setHubspotModalStep(1);
            setConfirmHubspotSync(false);
          }
        })
        .catch((error) => console.log("error here: ", JSON.stringify(error)));

      return api_call;
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const [enableEmailToErrorReporting, setEnableEmailToErrorReporting] = useState(
    dataOrg?.fetchOrganization?.send_error_log_email || false,
  );

  const RenderHubspotSync = () => {
    return (
      <>
        <Modal
          open={confirmHubspotSync}
          onClose={() => {
            setHubspotModalStep(1);
            setConfirmHubspotSync(false);
          }}
        >
          <ModalDiv>
            {hubspotModalStep === 1 && (
              <>
                <AppText fontSize={16} style={{ fontWeight: 600 }}>
                  Import Missing Hubspot Contacts
                </AppText>
                <AppText style={{ width: 240 }}>
                  This action will import all Contacts in Hubspot that don't exist in Sellfire
                </AppText>
              </>
            )}
            {hubspotModalStep === 2 && (
              <>
                <AppText fontSize={16} style={{ fontWeight: 600, width: 282 }}>
                  Are you sure you would like to import your missing HubSpot Contacts into Sellfire?
                </AppText>
                <AppText style={{ width: 240 }}>You cannot undo this action</AppText>
              </>
            )}
            <ButtonDiv>
              {hubspotModalStep === 1 && (
                <NewAppButton variant="primary" size="lg" onClick={() => setHubspotModalStep(2)}>
                  Import
                </NewAppButton>
              )}
              {hubspotModalStep === 2 && (
                <>
                  <NewAppButton
                    variant="primary"
                    size="lg"
                    onClick={() => {
                      syncHubspotContacts();
                    }}
                  >
                    Import
                  </NewAppButton>
                  <NewAppButton
                    variant="attention"
                    size="lg"
                    onClick={() => {
                      setHubspotModalStep(1);
                      setConfirmHubspotSync(false);
                    }}
                  >
                    Cancel
                  </NewAppButton>
                </>
              )}
            </ButtonDiv>
          </ModalDiv>
        </Modal>
        <TopAlignDiv>
          <SpaceBetweenDiv>
            <BackButton
              // borderless
              onClick={backToIntegrationsTopPage}
            >
              <img style={{ marginRight: "8px" }} src={iconArrowBackNew} alt="Back" />
              Back to Connected apps
            </BackButton>
          </SpaceBetweenDiv>
          <div style={{ maxWidth: "600px", display: "flex", flexDirection: "column", gap: 24 }}>
            <AppText>
              Syncing HubSpot Contacts will import your contacts in HubSpot to Sellfire. This is only for new contacts.
              Existing HubSpot Contacts that already exist in Sellfire will be Ignored.
            </AppText>
            <AppText fontSize={12} style={{ color: theme.NEUTRAL300 }}>
              Last Synced at: {moment(dataOrg?.fetchOrganization?.hubspot_last_sync_date).format("YYYY-MM-DD hh:mm a")}
            </AppText>
            <NewAppButton variant="secondary" onClick={() => setConfirmHubspotSync(true)}>
              Sync Hubspot Contacts
            </NewAppButton>
          </div>
        </TopAlignDiv>
      </>
    );
  };

  const IntegrationGridLoader = () => {
    const integrationCardSize = 257;
    return (
      <>
        <SkeletonBlock height={integrationCardSize} width={integrationCardSize} borderRadius={6} />
        <SkeletonBlock height={integrationCardSize} width={integrationCardSize} borderRadius={6} />
        <SkeletonBlock height={integrationCardSize} width={integrationCardSize} borderRadius={6} />
      </>
    );
  };

  const error = errorStatus || errorOrg || errorMappings || errorVisibleTemplatesRep;

  if (error) {
    return <AppErrorText>Error loading integration page.</AppErrorText>;
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Integrations step of Admin Onboarding"}>
      {/* deprecated */}
      {integrationErrorLogModal && !!selectedIntegration && <ErrorLogReportModal />}
      {showIntegrationMapRulesModal && <IntegrationMapRulesV2 />}
      {integrationMapPicklistModal && <IntegrationMapSyncPicklistV2 integration_mapping_id={mapStateData.id} />}

      {/* Disconnect modal for integrations */}
      {showDisconnectIntegrationModal && !!selectedIntegrationDisconnect && <DisconnectIntegrationModal />}

      {showIntegrationSubpage ? (
        <>
          {subpage === "integrationReporting" ? (
            <IntegrationReportingComponent backToIntegrations={backToIntegrationsTopPage} />
          ) : subpage === "manageTemplates" ? (
            <>
              {selectedIntegration === IIntegrations.PandaDoc ? (
                <ManageTemplatesComponent backToIntegrations={backToIntegrationsTopPage} />
              ) : selectedIntegration === IIntegrations.HubSpot ? (
                <RenderHubspotSync />
              ) : (
                <AppErrorText>Error loading integration manage templates screen</AppErrorText>
              )}
            </>
          ) : subpage === "fieldMapping" ? (
            <>
              {!!selectedIntegration ? (
                <FieldMappingPage
                  dataMappings={dataMappings ?? []}
                  errorMappings={errorMappings}
                  loadingMappings={loadingMappings}
                  selectedIntegration={selectedIntegration}
                  setSelectedIntegration={setSelectedIntegration}
                  setTemplateId={setTemplateId}
                  showIntegrationSubpage={showIntegrationSubpage}
                  setShowIntegrationSubpage={setShowIntegrationSubpage}
                  togglePageTitle={togglePageTitle}
                  dataVisibleTemplatesRep={dataVisibleTemplatesRep}
                />
              ) : (
                <AppErrorText>Error loading integration Mapping Table</AppErrorText>
              )}
            </>
          ) : (
            subpage === "settingsPage" && (
              <>
                <IntegrationSettingsPage
                  integration={(selectedIntegration as IIntegrations) ?? IIntegrations.Salesforce}
                  integrationLabel={selectedIntegrationLabel}
                  integrationLogo={
                    integrationsList.find((item) => item.name === selectedIntegration)?.logo ?? salesforceLogo
                  }
                  goBack={backToIntegrationsTopPage}
                />
              </>
            )
          )}
        </>
      ) : (
        <TopAlignDiv>
          <BoxesGrid>
            {loadingStatus ? (
              <IntegrationGridLoader />
            ) : errorStatus ? (
              <AppErrorText>Error loading integrations</AppErrorText>
            ) : (
              integrationsList?.map((item) => (
                <IntegrationCardV2
                  integration={item}
                  isConnected={!!dataStatus?.checkIntegrationStatus[item.name]}
                  optionsButton={
                    <CreateIntegrationCardOptions
                      drillInPage={() => setShowIntegrationSubpage(true)}
                      configureOption={item.name as IIntegrations}
                      togglePageTitle={togglePageTitle}
                      goToManageTemplates={() => {
                        setSubpage("manageTemplates");
                        setSelectedIntegration(item.name as IIntegrations);
                        setShowIntegrationSubpage(true);
                        !!togglePageTitle && togglePageTitle();
                      }}
                      goToIntegrationReporting={() => {
                        setSubpage("integrationReporting");
                        setSelectedIntegration(item.name as IIntegrations);
                        setShowIntegrationSubpage(true);
                        !!togglePageTitle && togglePageTitle();
                      }}
                      goToSettingsPage={() => {
                        setSubpage("settingsPage");
                        setSelectedIntegration(item.name as IIntegrations);
                        setShowIntegrationSubpage(true);
                        !!togglePageTitle && togglePageTitle();
                      }}
                      goToFieldMapping={() => {
                        setSubpage("fieldMapping");
                        setSelectedIntegration(item.name as IIntegrations);
                        setShowIntegrationSubpage(true);
                        !!togglePageTitle && togglePageTitle();
                      }}
                    />
                  }
                />
              ))
            )}
          </BoxesGrid>
          <EmailDiv>
            {loadingOrg ? (
              <SkeletonBlock
                height={20}
                width={"100%"}
                borderRadius={6}
                style={{
                  marginBottom: "24px",
                  marginTop: "24px",
                }}
              />
            ) : errorOrg ? (
              <AppErrorText>Error loading reps</AppErrorText>
            ) : (
              <EmailsSwitchDiv>
                <Switch
                  onChange={(checked) => {
                    addEmailToErrorReporting({
                      variables: {
                        active: checked,
                      },
                    });

                    setEnableEmailToErrorReporting(checked);
                  }}
                  onColor={theme.PRIMARY500}
                  offColor={theme.NEUTRAL200}
                  checked={enableEmailToErrorReporting}
                  height={16}
                  width={32}
                  handleDiameter={12}
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
                <RadioText>Send integration email report updates </RadioText>
              </EmailsSwitchDiv>
            )}
            <>
              {loadingOrg ? (
                <SkeletonBlock height={40} width={"100%"} borderRadius={6} />
              ) : errorOrg ? (
                <AppErrorText>Error loading reps</AppErrorText>
              ) : (
                !!dataOrg &&
                !!dataOrg?.fetchOrganization &&
                !!dataOrg.fetchOrganization?.Reps && (
                  <div style={{ maxWidth: "732px" }}>
                    <PhoenixMultiSelect
                      creatableOptions
                      name="emails"
                      isClearable
                      isMulti
                      onChange={(value: any) => {
                        setEmails(value?.map((item: any) => item.value));

                        addEmailToErrorReporting({
                          variables: {
                            emails: value?.map((item: any) => item.value),
                          },
                        });
                        console.log(emails);
                      }}
                      placeholder="Enter email addresses..."
                      options={dataOrg?.fetchOrganization?.Reps?.map((item: any) => ({
                        label: `${item.email} (${item.first_name} ${item.last_name})`,
                        value: item.email,
                      }))}
                      defaultValue={dataOrg?.fetchOrganization?.Reps.filter((item: any) =>
                        dataOrg?.fetchOrganization?.error_log_email_address?.includes(item.email),
                      )?.map((item: any) => ({
                        label: `${item.email} (${item.first_name} ${item.last_name})`,
                        value: item.email,
                      }))}
                    />
                  </div>
                )
              )}
            </>
          </EmailDiv>
        </TopAlignDiv>
      )}
    </Sentry.ErrorBoundary>
  );
};

const EmailDiv = styled.div`
  padding: 12px;
  min-width: 500px;
  background-color: ${theme.WHITE_COLOR};
  width: fit-content;
`;
const EmailsSwitchDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 24px 0px;
`;

const BoxesGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 250px 250px 250px;
`;

const TableText = styled(AppText)`
  font-size: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 11px;
  width: 100%;
  letter-spacing: 0.2px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TallTableText = styled(TableText)`
  padding-top: 16px;
`;

interface CampaignTableProps {
  selectedIntegration?: string;
}

const CampaignTable = styled.div<CampaignTableProps>`
  display: grid;
  width: calc(100%);
  overflow-x: hidden;

  grid-template-columns: ${(props) =>
    props.selectedIntegration === "PandaDoc" ? `1fr 1fr 1fr 80px;` : `130px 1fr 150px 1fr 1fr 146px 80px 80px 80px;`};
  grid-template-rows: 40px;
  align-items: stretch;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  border-top: 1px solid ${theme.NEUTRAL200};
  margin-top: -1px;
  background-color: ${theme.WHITE_COLOR};
  :last-child {
    border-bottom: none;
  }
`;

const ManageTemplatesTable = styled(CampaignTable)`
  grid-template-columns: 1fr 1fr;
`;

const ManageTemplatesTableData = styled(ManageTemplatesTable)`
  grid-template-rows: 50px;
`;

const TableHeadingText = styled(AppText)`
  font-weight: 600;
  padding: 11px 16px;
  width: 100%;
  height: 100%;
  vertical-align: center;
  background-color: ${theme.NEUTRAL200};
  border-right: 1px solid ${theme.NEUTRAL200};
  :last-child {
    border-right: none;
  }
`;

const BackButton = styled(NewAppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 245px;
`;

const OptionsDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 48px;
  width: 171px;
  background-color: ${theme.WHITE_COLOR};
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15); */
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 3;
`;

const Option = styled.div`
  display: flex;

  align-items: center;
  height: 40px;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.2px;
  padding-left: 12px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
  }
`;

const RadioText = styled(AppText)`
  font-size: 12px;
  line-height: 21px;
  font-weight: 500;
`;

const ContainerDiv = styled.div`
  min-width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  break-inside: avoid;
  height: 50px;
  margin-bottom: 20px;
`;

const TopAlignDiv = styled.div`
  min-height: 400px;
  width: 100%;
  padding: 24px;
`;

const ModalDiv = styled.div`
  padding: 48px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  justify-content: space-between;
  align-items: center;
  height: 314px;
  width: 400px;
  text-align: center;
`;
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`;

const MultiTextSelect = styled(CreatableSelect)`
  font-size: 13px;
  border-radius: 2.9px;
  text-align: center;
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  .css-yk16xz-control {
    border: none;
  }
`;

export { StepIntegrationV2 };
