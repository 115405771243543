import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import gql from "graphql-tag";
import * as React from "react";
import { useContext, useState } from "react";
import styled from "styled-components";
import { loggedInUser } from "../../apollo/cache";
import { MRR_LABEL } from "../../apollo/query";
import { ModalContext } from "../../context";
import { MixpanelActions } from "../../services/mixpanel";
import { OptionItem } from "../../types";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { AppErrorText, AppText, Loading } from "../UI";
import { PhoenixAppButton, PhoenixMultiSelect } from "../UI/Phoenix";
import { Modal } from "./Modal";

interface RepObject {
  id?: string;
  first_name?: string;
  last_name?: string;
}
interface ExpectedFetchRepsFromOrg {
  fetchOrganization?: {
    Reps?: RepObject[];
  };
}

interface Props {
  claim?: boolean;
  resetSelectedLeads: () => void;
}

const FETCH_REPS_FROM_ORG = gql`
  query fetchOrganization {
    fetchOrganization {
      Reps {
        id
        first_name
        last_name
      }
    }
  }
`;

const BULK_TRANSFER_LEADS = gql`
  mutation bulkTransferLead(
    $lead_ids: [String!]!
    $dest_user_id: String!
    $set_own: Boolean
    $ignore_future_event_warning: Boolean
  ) {
    bulkTransferLead(
      lead_ids: $lead_ids
      dest_user_id: $dest_user_id
      set_own: $set_own
      ignore_future_event_warning: $ignore_future_event_warning
    )
  }
`;

const BULK_FAVORITE_LEADS = gql`
  mutation bulkStarLeads($lead_ids: [String!]!) {
    bulkStarLeads(lead_ids: $lead_ids)
  }
`;

const BULK_ADD_TO_CUSTOM_QUEUE = gql`
  mutation bulkAddLeadsCustomQueue($lead_ids: [String!]!) {
    bulkAddLeadsCustomQueue(lead_ids: $lead_ids)
  }
`;

const BULK_UNASSIGN_LEADS = gql`
  mutation bulkUnassignLead($lead_ids: [String!]!, $ignore_future_event_warning: Boolean) {
    bulkUnassignLead(lead_ids: $lead_ids, ignore_future_event_warning: $ignore_future_event_warning)
  }
`;

// helper functions

const getCancelButtonText = (isUnassignOn: string) => {
  if (isUnassignOn === "Reassign") {
    return "Cancel";
  } else {
    return "No, Cancel";
  }
};

const BulkReassignLeadsModalV2: React.FC<Props> = ({ claim = false, resetSelectedLeads }) => {
  const {
    reassignLeadModal,
    setReassignLeadModal,
    selectedLeadToReassign,
    setSelectedLeadToReassign,
    isUnassignOn,
  } = useContext(ModalContext);

  const [step, setStep] = useState<number>(0);

  const [newRep, setNewRep] = useState<OptionItem>({
    label: "",
    value: "",
  });

  const { data: dataOptions, loading: loadingOptions, error: errorOptions } = useQuery<ExpectedFetchRepsFromOrg>(
    FETCH_REPS_FROM_ORG,
    {
      variables: {
        org_id: loggedInUser().organization_id,
      },
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [bulkTransferLead, { loading: loadingBulkReassign }] = useMutation(BULK_TRANSFER_LEADS, {
    async onCompleted({ bulkTransferLead }) {
      console.log("bulkTransferLead: ", bulkTransferLead);
      if (!bulkTransferLead) {
        appToast("Error reassigning leads. Something went wrong.");
        return;
      }
      // hide modal
      setReassignLeadModal(false);
      // reset selected modal data
      setSelectedLeadToReassign({ lead_id: "", source_user_id: "", lead_id_list: [] });

      if (!!bulkTransferLead.length) {
        appToast(`Warning: ${bulkTransferLead.length} conflicts were experienced during bulk transfer`);
      } else {
        appToast("Success! All leads reassigned sucessfully");
      }
      resetSelectedLeads();
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `bulkTransferLead GraphQL Error: ${message}`,
      });

      if (!!message) {
        console.log("Error in bulkTransferLead: ", message);
        message === "There are future events for some of these leads" && setStep(2);
      }
    },
    refetchQueries: ["filterLeads", "fetchFilteredLeadCount"],
  });

  const [bulkUnassignLead, { loading: loadingBulkUnassign }] = useMutation(BULK_UNASSIGN_LEADS, {
    async onCompleted({ bulkUnassignLead }) {
      console.log("bulkUnassignLead: ", bulkUnassignLead);
      if (!bulkUnassignLead) {
        appToast("Error unassigning leads. Something went wrong.");
        return;
      }

      resetSelectedLeads();
      setReassignLeadModal(false);
      setSelectedLeadToReassign({ lead_id: "", source_user_id: "", lead_id_list: [] });
      appToast("Success! Leads unassigned sucessfully");
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `bulkUnassignLead GraphQL Error: ${message}`,
      });

      if (!!message) {
        console.log("Error in bulkUnassignLead: ", message);
        message === "There are future events for some of these leads" && setStep(2);
      }
    },
    refetchQueries: ["filterLeads", "fetchFilteredLeadCount", "fetchNotifications"],
  });

  const [bulkFavoriteLead, { loading: loadingFavoriteLead }] = useMutation(BULK_FAVORITE_LEADS, {
    async onCompleted({ bulkStarLeads }) {
      console.log("bulkStarLeads: ", bulkStarLeads);
      if (!bulkStarLeads) {
        appToast("Error reassigning leads. Something went wrong.");
        return;
      }
      setReassignLeadModal(false);
      setSelectedLeadToReassign({ lead_id: "", source_user_id: "", lead_id_list: [] });
      appToast(`Success!`);
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `bulkStarLead GraphQL Error: ${message}`,
      });
      console.log("Error in bulkStarLead: ", message);
    },

    refetchQueries: ["filterLeads", "fetchFilteredLeadCount"],
  });

  const [bulkAddLeadsCustomQueue, { loading: loadingCustomQueue }] = useMutation(BULK_ADD_TO_CUSTOM_QUEUE, {
    async onCompleted({ bulkAddLeadsCustomQueue }) {
      console.log("bulkAddLeadsCustomQueue: ", bulkAddLeadsCustomQueue);
      if (!bulkAddLeadsCustomQueue) {
        appToast("Error reassigning leads. Something went wrong.");
        return;
      }
      setReassignLeadModal(false);
      setSelectedLeadToReassign({ lead_id: "", source_user_id: "", lead_id_list: [] });
      appToast(`Success!`);
    },
    onError({ message }) {
      appToast(`${message}`);
      Sentry.captureEvent({
        message: `bulkAddLeadsCustomQueue GraphQL Error: ${message}`,
      });
      console.log("Error in bulkAddLeadsCustomQueue: ", message);
    },
    refetchQueries: ["filterLeads", "fetchFilteredLeadCount"],
  });

  if (loadingOptions)
    return (
      <Modal open={reassignLeadModal} onClose={() => setReassignLeadModal(false)}>
        <EditCardDiv>
          <MinHeightDiv>
            <Loading />
          </MinHeightDiv>
        </EditCardDiv>
      </Modal>
    );

  if (errorOptions)
    return (
      <Modal open={reassignLeadModal} onClose={() => setReassignLeadModal(false)}>
        <EditCardDiv>
          <AppErrorText>Error loading options</AppErrorText>
        </EditCardDiv>
      </Modal>
    );

  const repOptions =
    loggedInUser().role === "AE" || loggedInUser().role === "SDR"
      ? dataOptions?.fetchOrganization?.Reps?.filter((item: any) => item.id === loggedInUser().id)?.map(
          (item: any) => ({
            label: `${item.first_name} ${item.last_name}`,
            value: item.id,
          }),
        )
      : dataOptions?.fetchOrganization?.Reps?.map((item: any) => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item.id,
        }));

  const renderConfirmButton = () => {
    switch (isUnassignOn) {
      case "Reassign":
        return (
          <PhoenixAppButton
            disabled={loadingBulkReassign}
            variant="brand"
            buttonType="secondary"
            onClick={async () => {
              if (loggedInUser().role === "AE" || loggedInUser().role === "SDR") {
                await bulkTransferLead({
                  variables: {
                    lead_ids: selectedLeadToReassign.lead_id_list,
                    dest_user_id: loggedInUser().id,
                    set_own: true,
                    ignore_future_event_warning: step === 2 ? true : undefined,
                  },
                });
                MixpanelActions.track("System View", {
                  type: claim ? `Claim` : `Reassign`,
                  quantity: selectedLeadToReassign.lead_id_list.length,
                });
              } else {
                await bulkTransferLead({
                  variables: {
                    lead_ids: selectedLeadToReassign.lead_id_list,
                    dest_user_id: newRep.value,
                    set_own: true,
                    ignore_future_event_warning: step === 2 ? true : undefined,
                  },
                });
                MixpanelActions.track("System View", {
                  type: claim ? `Claim` : `Reassign`,
                  quantity: selectedLeadToReassign.lead_id_list.length,
                });
              }
            }}
          >
            Continue
            {/* {step === 0 ? (claim ? `Claim Lead` : "Reassign Lead") : claim ? `Yes, Claim Lead` : "Yes, Reassign Lead"}
            {`${selectedLeadToReassign.lead_id_list.length > 1 ? "s" : ""} `} */}
          </PhoenixAppButton>
        );
      case "Unassign":
        return (
          <PhoenixAppButton
            disabled={loadingBulkReassign}
            variant="brand"
            buttonType="secondary"
            onClick={async () => {
              await bulkUnassignLead({
                variables: {
                  lead_ids: selectedLeadToReassign.lead_id_list,
                  ignore_future_event_warning: step === 2 ? true : undefined,
                },
              });
            }}
          >
            Yes, Continue
          </PhoenixAppButton>
        );
      case "Favorite":
        return (
          <PhoenixAppButton
            variant="brand"
            buttonType="secondary"
            disabled={loadingFavoriteLead}
            onClick={async () => {
              await bulkFavoriteLead({
                variables: {
                  lead_ids: selectedLeadToReassign.lead_id_list,
                  ignore_future_event_warning: step === 2 ? true : undefined,
                },
              }).then(() => resetSelectedLeads());
            }}
          >
            Yes, Continue
          </PhoenixAppButton>
        );
      case "CustomQueue":
        return (
          <PhoenixAppButton
            variant="brand"
            buttonType="secondary"
            disabled={loadingCustomQueue}
            onClick={async () => {
              await bulkAddLeadsCustomQueue({
                variables: {
                  lead_ids: selectedLeadToReassign.lead_id_list,
                },
              }).then(() => resetSelectedLeads());
              MixpanelActions.track("System View", {
                type: "Add to Custom Queue",
                quantity: selectedLeadToReassign.lead_id_list.length,
              });
              // }
            }}
          >
            Yes, Continue
          </PhoenixAppButton>
        );
      default:
        return <div />;
    }
  };

  // state dependent helper functions
  const renderTitle = () => {
    switch (isUnassignOn) {
      case "Reassign":
        if (claim) {
          return `Claim Leads`;
        }
        return `Reassign Leads`;
      case "Unassign":
        return `Unassign Leads`;
      case "Favorite":
        return `Favorite Leads`;
      case "CustomQueue":
        return `Add Leads to Custom Queue`;
      default:
        return <div />;
    }
  };

  const renderActionText = () => {
    const num = selectedLeadToReassign.lead_id_list.length;
    switch (isUnassignOn) {
      case "Reassign":
        if (loggedInUser().role !== "AE" && loggedInUser().role !== "SDR") {
          if (claim) {
            return `Select your name in the list below`;
          }
          return `Select a new rep.`;
        }
        return `Are you sure you want to claim ${num > 1 ? " these leads" : " this lead"}?`;
      case "Unassign":
        return `Are you sure you want to unassign ${num > 1 ? " these leads" : " this lead"}?`;
      case "Favorite":
        return `Are you sure you want to favorite ${num > 1 ? " these leads" : " this lead"}?`;
      case "CustomQueue":
        return `Are you sure you want to add ${num > 1 ? " these leads" : " this lead"} to your custom queue?`;
      default:
        return ``;
    }
  };

  const renderActionTextFinal = () => {
    const num = selectedLeadToReassign.lead_id_list.length;
    switch (isUnassignOn) {
      case "Reassign":
        if (claim) {
          return `Are you sure you want to claim these leads?`;
        }
        return `Are you sure you want to reassign ${num > 1 ? " these leads" : " this lead"}?`;
      case "Unassign":
        return `Are you sure you want to unassign ${num > 1 ? " these leads" : " this lead"}?`;
      case "Favorite":
        return `Are you sure you want to favorite ${num > 1 ? " these leads" : " this lead"}?`;
      case "CustomQueue":
        return `Are you sure you want to add ${num > 1 ? " these leads" : " this lead"} to your custom queue?`;
      default:
        return <div />;
    }
  };

  return (
    <Modal open={reassignLeadModal} onClose={() => setReassignLeadModal(false)} bigCloseButton>
      <EditCardDiv>
        {step === 0 && (
          <TitleDiv>
            <AppText fontSize={16} fontWeight={600} color={theme.text.neutral.primary}>
              {renderTitle()}
            </AppText>
          </TitleDiv>
        )}

        <Main>
          {step === 0 && (
            <>
              {isUnassignOn !== "Reassign" && (
                <AppText
                  fontSize={14}
                  fontWeight={600}
                  style={{
                    textAlign: "center",
                  }}
                >
                  You have selected {selectedLeadToReassign.lead_id_list.length} lead
                  {`${selectedLeadToReassign.lead_id_list.length > 1 ? "s" : ""} `}
                </AppText>
              )}
              <AppText
                fontSize={14}
                fontWeight={400}
                color={theme.text.neutral.primary}
                style={{
                  textAlign: "center",
                }}
              >
                {renderActionText()}
              </AppText>
              {isUnassignOn === "Unassign" && (
                <AppText
                  fontSize={14}
                  fontWeight={400}
                  color={theme.text.danger.primary}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Unassigning {selectedLeadToReassign.lead_id_list.length > 1 ? "these leads" : "this lead"} will reset
                  the sales cycle
                </AppText>
              )}
              {isUnassignOn === "Reassign" && loggedInUser().role !== "AE" && loggedInUser().role !== "SDR" && (
                <PhoenixMultiSelect
                  isMulti={false}
                  name="rep"
                  width={360}
                  value={newRep}
                  options={
                    repOptions?.sort((a: OptionItem, b: OptionItem) =>
                      a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : 1,
                    ) ?? []
                  }
                  onChange={(e: any) =>
                    !!e?.value ? setNewRep({ label: e?.label, value: e?.value }) : setNewRep({ label: "", value: "" })
                  }
                />
              )}
            </>
          )}
          {step === 1 && <ModalWarningTitle>{renderActionTextFinal()}</ModalWarningTitle>}
          {step === 2 && (
            <>
              <AppText
                fontSize={14}
                fontWeight={600}
                style={{
                  textAlign: "center",
                }}
                color={theme.text.danger.primary}
              >
                There are future events for
                {selectedLeadToReassign.lead_id_list.length > 1 ? ` some of these leads` : ` this lead`} which will be
                deleted.
              </AppText>
              <AppText
                fontSize={14}
                fontWeight={400}
                color={theme.text.neutral.primary}
                style={{
                  textAlign: "center",
                }}
              >
                Are you sure you want to perform this action?
              </AppText>
            </>
          )}
        </Main>
        <SubmitDiv>
          <PhoenixAppButton
            variant="danger-outline"
            buttonType="secondary"
            onClick={() => {
              setReassignLeadModal(!reassignLeadModal);
            }}
          >
            {getCancelButtonText(isUnassignOn ?? "")}
          </PhoenixAppButton>
          {renderConfirmButton()}
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const MinHeightDiv = styled.div`
  height: 400px;
`;

const SubmitDiv = styled.div`
  border-top: 1px solid ${theme.border.neutral.secondary};
  padding-top: 16px;
  padding: 16px 40px 16px 40px;

  display: flex;
  justify-content: space-between;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 40px 40px 40px;
  height: 100%;
  align-items: center;
  min-height: 100px;
  justify-content: center;
  align-items: center;
`;

const ModalWarningTitle = styled(AppText)`
  max-width: 336px;
  text-align: center;
  font-weight: 600;
  margin: "51px auto 4px auto";
  font-size: 14;
`;

const TitleDiv = styled.div`
  position: relative;
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 476px;
`;

export { BulkReassignLeadsModalV2 };
