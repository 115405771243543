import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useState } from "react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { trash } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { AppErrorText, AppText, SkeletonBlock } from "../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";
import { Modal, UpdateAdmin } from "../../modal/";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

const DEFAULT_NEW_ADMIN = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  visible: false,
};

const FETCH_ADMINS = gql`
  query {
    fetchAdmins {
      id
      email
      first_name
      last_name
      full_name
      role
      phone_number
    }
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($user_id: String!) {
    deleteUser(user_id: $user_id) {
      id
    }
  }
`;

const StepAdmins: React.FC<DisappearingDivProps> = ({ step, isOnboarding }) => {
  const [modalUser, setModalUser] = useState(DEFAULT_NEW_ADMIN);

  const history = useHistory();

  const [deleteUser, { loading: loadingDeleteUser, error: errorDeleteUser }] = useMutation(DELETE_USER, {
    onCompleted({ deleteUser }) {
      console.log("Add admin: ", deleteUser);
      if (!deleteUser) {
        return;
      }
      appToast("User Deleted");
      setShowConfirmDeleteAdminModal(false);
    },
    onError({ message }) {
      console.log("Error in addAdmin: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `deleteUser GraphQL Error: ${message}`,
      });
    },
    update(cache, { data: { deleteUser } }) {
      cache.modify({
        fields: {
          fetchAdmins(existingAdmins, { readField }) {
            return existingAdmins.filter((ref: any) => deleteUser.id !== readField("id", ref));
          },
        },
      });
    },
  });

  const { data, loading: loadingQuery, error: errorQuery } = useQuery(FETCH_ADMINS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  // confirm modal for deleting admin user
  const [showConfirmDeleteAdminModal, setShowConfirmDeleteAdminModal] = useState<boolean>(false);

  // admin user is deleted by id
  const [adminUserIdToDelete, setAdminUserIdToDelete] = useState<string>("");

  const ConfirmDeleteAdminModal = () => {
    return (
      <Modal
        open={showConfirmDeleteAdminModal}
        bigCloseButton
        onClose={() => {
          setShowConfirmDeleteAdminModal(false);
        }}
      >
        <DeleteModalContent>
          <AppText fontSize={18} fontWeight={600}>
            Are you sure you want to delete this enablement user?
          </AppText>
          <DeleteConfirmationButtonRow>
            <PhoenixAppButton
              buttonType="secondary"
              variant="danger-outline"
              onClick={() => {
                setShowConfirmDeleteAdminModal(false);
              }}
            >
              Cancel
            </PhoenixAppButton>
            <PhoenixAppButton
              buttonType="secondary"
              variant="brand"
              disabled={loadingQuery || loadingDeleteUser}
              onClick={async () => {
                await deleteUser({
                  variables: {
                    user_id: adminUserIdToDelete,
                  },
                });
              }}
            >
              Delete
            </PhoenixAppButton>
          </DeleteConfirmationButtonRow>
        </DeleteModalContent>
      </Modal>
    );
  };

  if (errorQuery) {
    return <AppErrorText> Error loading enablement users</AppErrorText>;
  }

  return (
    <DisappearingDiv>
      {showConfirmDeleteAdminModal && <ConfirmDeleteAdminModal />}
      {modalUser.visible && <UpdateAdmin close={() => setModalUser(DEFAULT_NEW_ADMIN)} {...modalUser} />}
      {loadingQuery ? (
        <SkeletonBlock height={"100%"} width={"100%"} borderRadius={6} />
      ) : !data.fetchAdmins.length ? (
        <EmptyStateDiv>
          <EmptyStateText>Add A New Enablement User</EmptyStateText>
          <CenterDiv>
            <AiFillPlusCircle
              style={{ cursor: "pointer" }}
              size={48}
              color={theme.SUCCESS500}
              onClick={() => setModalUser({ ...DEFAULT_NEW_ADMIN, visible: true })}
            />
          </CenterDiv>
        </EmptyStateDiv>
      ) : (
        <div>
          <TopAlignDiv>
            <CampaignDiv>
              <AppText> </AppText>
              <CampaignTable style={{ borderBottom: `solid 1px ${theme.NEUTRAL500}`, alignItems: "flex-end" }}>
                <TableHeadingText>Name</TableHeadingText>
                <TableHeadingText>Email</TableHeadingText>
              </CampaignTable>
              <AppText> </AppText>
            </CampaignDiv>
            <TableDataDiv>
              {data.fetchAdmins?.map((item: any, index: number) => {
                return (
                  <CampaignDiv key={item.id}>
                    <OrderNumberText>{data.fetchAdmins.indexOf(item) + 1}</OrderNumberText>
                    <CampaignTable>
                      <TableSubheadingText>{item.full_name}</TableSubheadingText>
                      <TableText>{item.email}</TableText>
                    </CampaignTable>
                    <ButtonsRow>
                      <EditButton
                        buttonType="secondary"
                        variant="brand-outline"
                        onClick={() => setModalUser({ ...item, visible: true })}
                      >
                        Edit
                      </EditButton>
                      <InvisibleButton
                        type="submit"
                        onClick={() => {
                          if (!item.id) {
                            appToast("Admin user not found");
                            return;
                          }
                          // show confirm modal
                          setShowConfirmDeleteAdminModal(true);
                          // set admin user id to delete
                          setAdminUserIdToDelete(item.id);
                        }}
                      >
                        <PhoenixIcon
                          svg={trash}
                          style={{ cursor: "pointer", lineHeight: 1 }}
                          size={22}
                          color={theme.NEUTRAL500}
                          hoverColor={theme.NEUTRAL500}
                        />
                      </InvisibleButton>
                    </ButtonsRow>
                  </CampaignDiv>
                );
              })}
            </TableDataDiv>
            <PhoenixAppButton
              buttonType="secondary"
              variant="brand"
              onClick={() => setModalUser({ ...DEFAULT_NEW_ADMIN, visible: true })}
            >
              Add New Enablement User
            </PhoenixAppButton>
          </TopAlignDiv>
        </div>
      )}
      <CenterDiv>
        {isOnboarding === true && (
          <ContinueButton
            buttonType="secondary"
            variant="brand"
            onClick={() => {
              history.push(`/onboarding?step=${step + 1}`);
            }}
          >
            Continue
          </ContinueButton>
        )}
      </CenterDiv>
    </DisappearingDiv>
  );
};

const DeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 40px;
`;

const DeleteConfirmationButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const EmptyStateText = styled(AppText)`
  font-size: 14px;
  margin-bottom: 23px;
`;

const EmptyStateDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;

const TableText = styled(AppText)`
  font-size: 13px;
`;

const TableSubheadingText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
`;

const ButtonsRow = styled.div`
  margin-right: 24px;
  display: flex;
  justify-content: flex-end;
`;

const EditButton = styled(PhoenixAppButton)`
  box-sizing: border-box;
  height: 28px;
  width: 124px;
  border-radius: 2px;
  border: solid 2px ${theme.NEUTRAL100};
  font-size: 12px;
  font-weight: 500;
  margin-left: 10.5px;
  margin-right: 18px;
`;

const InvisibleButton = styled.button`
  height: 30px;
  width: 25px;
  border: none;
  vertical-align: bottom;
  background: transparent;
  :focus {
    border: none;
    outline: 0;
  }
`;

const TableHeadingText = styled(AppText)`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8.5px;
`;

const OrderNumberText = styled(TableHeadingText)`
  margin-bottom: 0;
`;

const CampaignTable = styled.div`
  display: grid;
  width: 966px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
  align-items: center;
`;

const CampaignDiv = styled.div`
  display: grid;
  grid-template-columns: 30px 2fr 1fr;
  grid-template-rows: 50px;
  align-items: center;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  min-width: 1150px;
`;

const TableDataDiv = styled.div`
  min-height: 400px;
  overflow: auto;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContinueButton = styled(PhoenixAppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

export { StepAdmins };
