import { useQuery } from "@apollo/client";
import React, { Dispatch, SetStateAction, useContext } from "react";
import styled from "styled-components";
import { CallContext } from "../../context";
import { theme } from "../../utils/theme";
import { FETCH_LEAD } from "./Call";
import { CallResultV2, InactiveRightSidebar } from "./CallSegments";

const DashboardSidebarRight: React.FC = () => {
  const { callLeadId, callState, callOptionStackPeek, isNonCallActivity } = useContext(CallContext);

  const { data } = useQuery(FETCH_LEAD, {
    fetchPolicy: "no-cache",
    variables: { id: callLeadId },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  return (
    <SidebarContainer callState={callState || isNonCallActivity} callOptionStackPeek={callOptionStackPeek()}>
      {callState || isNonCallActivity ? (
        <>
          <CallResultV2
            isInHeldPhase={data?.fetchLead?.phase === "HeldPhase"}
            lead_primary_email={data?.fetchLead?.primary_email ?? ""}
            lead_full_name={`${data?.fetchLead?.first_name} ${data?.fetchLead?.last_name}`}
            scheduled_event_behavior={data?.fetchLead?.sequence_step?.actions?.[0]?.scheduled_event_behavior}
          />
        </>
      ) : (
        <InactiveRightSidebar />
      )}
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div<{ callState: boolean | undefined; callOptionStackPeek: string }>`
  margin-left: auto;
  padding: ${(props) => (props.callOptionStackPeek === "custom make sale" ? "24px 0px" : props.callState ? "24px" : 0)};
  padding-top: 18px;
  border-radius: 8px;
  width: 480px;
  max-height: 100vh;
  background-color: ${(props) => (props.callState ? theme.WHITE_COLOR : "none")};
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export { DashboardSidebarRight };
