import React, { useState, useMemo, useEffect, useContext } from "react";
import ReactFlow, { Controls, ControlButton, useReactFlow, useNodesState, useEdgesState } from "react-flow-renderer";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import * as Sentry from "@sentry/react";
import { gql, useMutation } from "@apollo/client";
import { PhoenixIcon } from "../Phoenix";
import { minimize, minus, plus } from "../../../images/NewDesign";
import { ButtonEdge, EndSequence, SequenceStep } from "./CustomNodes";
import { getNodeDepth, handleDiagramGeneration, updateNodePositions } from "../../../utils/sequences";
import { ConditionNode } from "./CustomNodes/ConditionNode";
import { DispositionNode } from "./CustomNodes/DispositionNode";
import { cloneDeep } from "lodash";
import { ModalContext } from "../../../context";
import { UserStatusContext } from "../../../context";

const UPDATE_STEP_COUNT = gql`
  mutation updateSequenceStepCount($sequenceInput: SequenceInput!) {
    createOrUpdateSequence(SequenceInput: $sequenceInput) {
      id
      steps_count
    }
  }
`;

interface SequenceCanvasProps {
  initialSteps: any[];
  active: boolean;
  sequenceData: any;
}

export const SequenceCanvas: React.FC<SequenceCanvasProps> = ({ initialSteps, active, sequenceData }) => {
  const customNodes = useMemo(
    () => ({
      sequenceStep: SequenceStep,
      endSequence: EndSequence,
      conditionNode: ConditionNode,
      dispositionNode: DispositionNode,
    }),
    [],
  );
  const customEdges = useMemo(() => ({ buttonEdge: ButtonEdge }), []);

  const [nodeData, _] = useState(handleDiagramGeneration(initialSteps, active));
  const [nodes, setNodes, __] = useNodesState(nodeData.nodes);
  const [edges, setEdges, ___] = useEdgesState(nodeData.edges);
  const [nodePositionsLoaded, setNodePositionsLoaded] = useState(false);

  const { updateStepTrigger, setUpdateStepTrigger } = useContext(ModalContext);

  useEffect(() => {
    // setTimeout ensures the dom elements render before we run the inner code
    setTimeout(() => {
      const nodeDataCopy = cloneDeep(nodeData);

      nodeDataCopy.nodes = nodeDataCopy.nodes?.map((node) => {
        const domEle = document.getElementById(node.id);
        if (node.data?.conditions?.length >= 9 && domEle) {
          return {
            ...node,
            domEleHeight: domEle.offsetHeight + 98,
          };
        }
        return {
          ...node,
          domEleHeight: domEle?.offsetHeight,
        };
      });

      const nodeDataWithPositions = updateNodePositions(nodeDataCopy.nodes, nodeDataCopy.edges);

      setNodes(nodeDataWithPositions.nodes);
      setEdges(nodeDataWithPositions.edges);
      setNodePositionsLoaded(true);
    }, 0);
  }, []);

  useEffect(() => {
    // updateStepTrigger is set to true when creating a new step or deleting a step
    if (updateStepTrigger) {
      const newStepCount = calculateRowCount(nodes, edges);

      updateSequenceStepCount({
        variables: {
          sequenceInput: {
            sequence_id: sequenceData?.id,
            name: sequenceData?.name,
            priority: sequenceData?.priority,
            steps_count: newStepCount,
          },
        },
      });

      setUpdateStepTrigger(false);
    }
  }, [updateStepTrigger]);

  const [updateSequenceStepCount, { loading: loadingStartOrPauseSequence }] = useMutation(UPDATE_STEP_COUNT, {
    async onCompleted({ createOrUpdateSequence }) {
      console.log("updateStepCount:", createOrUpdateSequence);
    },
    onError({ message }) {
      Sentry.captureEvent({
        message: `updateStepCount GraphQL Error: ${message}`,
      });
      console.log(`updateStepCount GraphQL Error: ${message}`);
    },
  });

  return (
    <FlowContainer nodePositionsLoaded={nodePositionsLoaded}>
      <ReactFlow nodes={nodes} edges={edges} nodeTypes={customNodes} edgeTypes={customEdges} fitView>
        <CustomControls nodes={nodes} />
      </ReactFlow>
    </FlowContainer>
  );
};

interface CustomControlsProps {
  nodes: any[];
}

const CustomControls: React.FC<CustomControlsProps> = ({ nodes }) => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();

  useEffect(() => {
    fitView();
  }, [nodes]);

  return (
    <Controls showInteractive={false} showZoom={false} showFitView={false}>
      <ControlButton onClick={() => zoomIn()}>
        <PhoenixIcon size="small" svg={plus} variant="white" hoverColor="white" pointer />
      </ControlButton>
      <ControlButton onClick={() => zoomOut()}>
        <PhoenixIcon size="small" svg={minus} variant="white" hoverColor="white" pointer />
      </ControlButton>
      <ControlButton onClick={() => fitView()} style={{ borderBottom: "none", backgroundColor: theme.PRIMARY500 }}>
        <PhoenixIcon size="small" svg={minimize} variant="white" hoverColor="white" pointer />
      </ControlButton>
    </Controls>
  );
};

interface FlowContainerProps {
  nodePositionsLoaded: boolean;
}

const FlowContainer = styled.div<FlowContainerProps>`
  width: 100%;
  height: 75vh;

  .react-flow {
    opacity: ${(props) => (props.nodePositionsLoaded ? 1 : 0)};
    transition: opacity 0.4s ease;
  }

  .react-flow__controls {
    bottom: 32px;
    right: 20px;
    left: unset;

    border: 1px solid ${theme.PRIMARY300};
    border-radius: 8px;
    overflow: hidden;
  }
  .react-flow__controls-button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 8px;

    background-color: ${theme.PRIMARY900};
    border-bottom: 1px solid ${theme.PRIMARY800};
  }
  .react-flow__controls-button svg {
    max-width: 16px;
    max-height: 16px;
  }

  /* We are not allowed to hide the attribution unless we purchase the pro version of react-flow.
    z-index of '7' still shows the attribution without interference with modal z-index  */
  .react-flow__attribution {
    z-index: 7;
  }

  // hide top/bottom handles
  .react-flow__handle-top {
    top: 0px;
    opacity: 0;
  }
  .react-flow__handle-bottom {
    bottom: 0px;
    opacity: 0;
  }
`;

/** Uses getNodeDepth algo to return count of rows in a sequence */
const calculateRowCount = (nodes: any[], edges: any[]) => {
  const filteredEdgeList = cloneDeep(edges)
    .filter((ele: any) => !ele.target.includes("end"))
    ?.map((edge: any) => {
      // modify edges to filter out dispositionNode's and conditionNode's
      const sourceEdge = edges.find((ele) => ele.target === edge.source);
      const sourceEdgeSource = edges.find((ele) => ele.target === sourceEdge?.source);
      if (sourceEdge?.source.includes("condition")) {
        edge.source = sourceEdge.source;
      }
      if (!!sourceEdgeSource && sourceEdgeSource?.source.includes("condition")) {
        edge.source = sourceEdgeSource.source;
      }
      return edge;
    });
  const rankList = getNodeDepth(
    nodes.filter(
      (ele) =>
        !ele.id.includes("end") && !ele?.data?.origin_id?.includes("condition") && !ele?.data?.conditions?.length,
    ),
    filteredEdgeList,
  );

  const depthList = rankList?.map((ele) => ele.depth);

  return Math.max(...depthList) + 1;
};
