import styled from "styled-components";
import * as React from "react";
import { useContext, useState } from "react";
import { AppText, NewAppButton } from "../UI";
import { theme } from "../../utils/theme";
import { BACKEND_URL } from "../../utils/variables";
import { restAPI } from "../../apollo";
import { useMutation, gql } from "@apollo/client";
import { appToast } from "../../utils/toast";
import { getIntegrationLabel } from "../../utils/format";
import { IntegrationItem } from "../../types";
import { ModalContext } from "src/context/ModalContext";
import { DisconnectIntegrationModal } from "../modal";

interface IntegrationCardProps {
  integration: IntegrationItem;
  isConnected: boolean;
  optionsButton?: any;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({ integration, isConnected, optionsButton }) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    showDisconnectIntegrationModal,
    setShowDisconnectIntegrationModal,
    selectedIntegrationDisconnect,
    setSelectedIntegrationDisconnect,
  } = useContext(ModalContext);

  const getGmailLink = async () => {
    try {
      const api_call = await restAPI
        .get(`${BACKEND_URL}/gmail/getGmailUrl`)
        .then((response) => {
          if (!!response?.data) {
            const loc = response.data.split("OK. Redirecting to ").join("");
            window.open(loc, "_blank");
          }
        })
        .catch((error) => console.log("error here: ", JSON.stringify(error)));
      return api_call;
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const sendHubSpotCode = async () => {
    try {
      const api_call = await restAPI
        .get(`${BACKEND_URL}/hubspot/redirect/`)
        .then((response) => {
          if (!!response?.data) {
            const loc = response.data.split("OK. Redirecting to ").join("");
            window.open(loc, "_blank");
          }
        })
        .catch((error) => console.log("error here: ", JSON.stringify(error)));

      return api_call;
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const renderConnectButton = (item: any) => {
    const organization_id = localStorage.getItem("organization_id");
    switch (item.name) {
      case "Gmail":
        return (
          <NewAppButton
            size="md"
            variant={!!isConnected ? "confirm" : "secondary"}
            hoverVariant={!!isConnected ? "attention" : "secondary"}
            onClick={async () => await getGmailLink()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {!!isConnected ? (isHovered ? "Disconnect" : "Connected") : "Connect"}
          </NewAppButton>
        );
      case "HubSpot":
        return (
          <NewAppButton
            size="md"
            variant={!!isConnected ? "confirm" : "secondary"}
            hoverVariant={!!isConnected ? "attention" : "secondary"}
            onClick={
              !!isConnected
                ? () => {
                    setSelectedIntegrationDisconnect(item.name);
                    setShowDisconnectIntegrationModal(true);
                  }
                : async () => await sendHubSpotCode()
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {!!isConnected ? (isHovered ? "Disconnect" : "Connected") : "Connect"}
          </NewAppButton>
        );
      default:
        return (
          //   <a href={!!isConnected ? "" : item.url} target="_blank">
          <NewAppButton
            size="md"
            variant={!!isConnected ? "confirm" : "secondary"}
            hoverVariant={!!isConnected ? "attention" : "secondary"}
            onClick={
              !!isConnected
                ? () => {
                    setSelectedIntegrationDisconnect(item.name);
                    setShowDisconnectIntegrationModal(true);
                  }
                : () => window.open(`${item.url}?organization_id=${organization_id}`)
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {!!isConnected ? (isHovered ? "Disconnect" : "Connected") : "Connect"}
          </NewAppButton>
          //   </a>
        );
    }
  };

  return (
    <div
      style={{
        width: 257,
        borderRadius: 8,
        padding: 16,
        border: `1px solid ${theme.NEUTRAL200}`,
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        gap: 8,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img
          src={integration.logo}
          width="32px"
          alt={`${getIntegrationLabel(integration.name)} logo`}
          style={{ display: "flex", flexDirection: "row" }}
        />
        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          {renderConnectButton(integration)}
        </div>
      </div>
      <AppText fontSize={16} style={{ fontWeight: 600 }}>
        {getIntegrationLabel(integration.name)}
      </AppText>
      <AppText fontSize={14} style={{ color: theme.NEUTRAL300 }}>
        {integration.description}
      </AppText>
      {optionsButton}
    </div>
  );
};

export { IntegrationCard };
