import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI";
import DynamicDropdown from "./DynamicDropdown";
import { PhoenixInput } from "src/Components/UI/Phoenix";
import { hasValue } from "src/utils/misc";
import { useOpenOnMoreFilter } from "src/utils/hooks";

type NotesFilterProps = {
  isMoreFilter?: boolean;
  onChange?: (value: any) => void;
  removeFilter?: () => void;
  value?: string;
};

const NotesFilter = ({ isMoreFilter, onChange, removeFilter, value }: NotesFilterProps) => {
  const [localValue, setLocalValue] = useState(value || "");
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  useOpenOnMoreFilter(value, setIsOpen, isMoreFilter);

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value || "");
      hasChangesRef.current = false;
    }
  }, [value, isOpen]);

  useEffect(() => {
    hasChangesRef.current = localValue !== value;
  }, [localValue, value]);

  const valueText = useMemo(() => {
    return localValue ? `Note contains "${localValue}"`.trim() : "";
  }, [localValue]);

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (!newIsOpen && hasChangesRef.current) {
        onChange?.(localValue);
        hasChangesRef.current = false;
      }
      setIsOpen(newIsOpen);
    },
    [localValue, onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue("");
    hasChangesRef.current = true;
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    hasChangesRef.current = true;
  }, []);

  return (
    <DynamicDropdown<string>
      label="Notes"
      onClear={handleClear}
      onRemove={hasValue(localValue) ? removeFilter : undefined}
      isOpen={isOpen}
      setIsOpen={handleIsOpenChange}
      value={localValue}
      renderHeader={
        <AppText
          fontSize={12}
          color={theme.PRIMARY500}
          style={{ maxWidth: "180px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {valueText}
        </AppText>
      }
    >
      <PhoenixInput
        name="notes"
        value={localValue}
        placeholder="Search notes"
        onChange={handleChange}
        displayNoContextText
      />
    </DynamicDropdown>
  );
};

export default NotesFilter;
