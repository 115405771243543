import styled from "styled-components";
import * as React from "react";
import { AppButton, AppText, AppInput, AppSelect } from "../UI";
import { theme } from "../../utils/theme";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { NewAppButton } from "../UI/NewAppButton";
import { Modal } from "./Modal";
import { InputField } from "../Field";
interface DisappearingDivProps {
  onCancel: any;
  onConfirm: any;
  blinds: any;
  setBlinds: any;
  text: string;
}

const optionsReason = [
  { value: "", label: "Select" },
  { value: "Bad Vertical", label: "Bad Vertical" },
  { value: "Not a Good Time to Call", label: "Not a Good Time to Call" },
  { value: "Missing Key Data", label: "Missing Key Data" },
  { value: "Bad Lead History", label: "Bad Lead History" },
  { value: "Other", label: "Other" },
];

const ReleaseLeadModal: React.FC<DisappearingDivProps> = ({ onCancel, onConfirm, blinds, setBlinds, text }) => {
  const [reason, setReason] = useState("");
  const [customReason, setCustomReason] = useState("");

  return (
    <Sentry.ErrorBoundary fallback={"An error has in skip lead modal"}>
      <Modal
        open={blinds}
        onClose={() => {
          setBlinds(false);
          onCancel();
        }}
      >
        <ModalContent>
          {/* <TitleDiv>
            <TitleText>Release Lead</TitleText>
          </TitleDiv> */}
          <PaddingDiv>
            <ConfirmText style={{ fontWeight: 600 }}>Release Lead?</ConfirmText>
            <ConfirmText>
              Are you sure you'd like to release <span style={{ fontWeight: 500 }}>{text}</span>? It will be removed
              from your queue and made available for re-assignment to other reps.
            </ConfirmText>
            {/* <LabelText>
              Why are you releasing this lead? <span style={{ color: "red" }}>*</span>
            </LabelText>
            <CustomSelect
              value={reason}
              onChange={(e: any) => {
                setReason(e.target.value);
              }}
              options={optionsReason}
              allowSelectPlaceholder={false}
            />
            {reason === "Other" && (
              <>
                <LabelText>
                  Please elaborate here: <span style={{ color: "red" }}>*</span>
                </LabelText>
                <CustomInput value={customReason} onChange={(e: any) => setCustomReason(e.target.value)} />
              </>
            )} */}
          </PaddingDiv>
          <SubmitDiv>
            <SkipButton onClick={onCancel}>Cancel</SkipButton>
            <ConfirmButton
              variant={"primary"}
              disabled={!((!!reason && reason !== "Other") || (reason === "Other" && !!customReason.trim()))}
              onClick={() => onConfirm(reason === "Other" ? customReason : reason)}
            >
              Release Lead
            </ConfirmButton>
          </SubmitDiv>
        </ModalContent>
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

const SubmitDiv = styled.div`
  padding: 16px 24px;
  border-top: 1px solid ${theme.NEUTRAL200};
  background-color: ${theme.NEUTRAL100};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.NEUTRAL100};
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalContent = styled.div`
  width: 432px;
  height: 260px;
`;

const CustomSelect = styled(AppSelect)`
  text-align: left;
  border-color: ${theme.NEUTRAL100};
  :focus {
    border-color: ${theme.BLACK_COLOR};
  }
`;

const PaddingDiv = styled.div`
  padding: 48px;
`;

const ConfirmText = styled(AppText)`
  font-size: 14px;
  text-align: center;
  /* margin-bottom: 20px; */
  width: 100%;
`;

const LabelText = styled(AppText)`
  padding: 3px;
  text-align: left;
`;

const ConfirmButton = styled(NewAppButton)`
  /* margin-bottom: 16px;
  margin-top: 8px; */
  height: 48px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const SkipButton = styled(NewAppButton)`
  height: 48px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
`;

const CardTitle = styled.div`
  min-width: 100%;
  max-height: 20px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: ${theme.NEUTRAL100};
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const PopupContainerDiv = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 9;
`;

export { ReleaseLeadModal };
