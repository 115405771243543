import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { ModalContext } from "../../context";
import { theme } from "../../utils/theme";
import { AppText } from "../UI";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const DELETE_CUSTOM_OBJECT_ROW_ASSOCIATION = gql`
  mutation deleteLeadCustomObjectRowAssociation($lead_id: String!, $row_id: String!) {
    deleteLeadCustomObjectRowAssociation(lead_id: $lead_id, row_id: $row_id) {
      id
    }
  }
`;

interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
  lead_id: string;
  row_id: string;
  business_name?: string;
}

const DeleteLeadCustomObjectRelationModal: React.FC<DisappearingDivProps> = ({
  blinds,
  close,
  lead_id,
  row_id,
  business_name,
}) => {
  const [deleteLeadCustomObjectRowAssociation] = useMutation(DELETE_CUSTOM_OBJECT_ROW_ASSOCIATION, {
    onCompleted: () => {
      close();
    },
    onError: () => {
      console.log("error");
    },
    refetchQueries: ["fetchLeadsFromCustomObjectRow"],
  });
  return (
    <Modal open={blinds} onClose={() => close()}>
      <ModalContent>
        <HeaderDiv>
          <DeleteText fontWeight={600}>Disassociate {business_name || "Lead"} From Record</DeleteText>
        </HeaderDiv>
        <BodyDiv>
          <DeleteText>Are you sure you want to disassociate this record? This action cannot be undone.</DeleteText>
        </BodyDiv>
        <SubmitDiv>
          <SubmitFlexBetweenDiv>
            <PhoenixAppButton
              buttonType="secondary"
              type="button"
              uppercase={true}
              onClick={close}
              style={{ fontSize: "10px" }}
              height={40}
              variant="danger-outline"
            >
              No, Cancel
            </PhoenixAppButton>

            <PhoenixAppButton
              buttonType="secondary"
              type="button"
              uppercase={true}
              height={40}
              style={{ fontSize: "10px" }}
              onClick={async () => {
                await deleteLeadCustomObjectRowAssociation({ variables: { lead_id: lead_id, row_id: row_id } });
              }}
            >
              Yes, Continue
            </PhoenixAppButton>
          </SubmitFlexBetweenDiv>
        </SubmitDiv>
      </ModalContent>
    </Modal>
  );
};

const SubmitFlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
`;

const ModalContent = styled.div`
  width: 456px;
  height: 326px;
`;
const DeleteText = styled(AppText)`
  width: 100%;
  font-size: 14px;
  text-align: center;
`;

const SubmitDiv = styled.div`
  position: absolute;
  bottom: 0px;
  height: 80px;
  width: 100%;
  gap: 12px;
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const HeaderDiv = styled.div`
  position: relative;
  padding: 10px 40px;
  overflow: auto;
  width: 100%;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--border-neutral-secondary, #e1e2e7);
  background: var(--fill-neutral-primary, #fff);
  margin-top: 10px;
`;

const BodyDiv = styled.div`
  position: relative;
  padding: 40px;
  overflow: auto;
  width: 100%;
  height: 186px;
  margin-bottom: 88px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { DeleteLeadCustomObjectRelationModal };
