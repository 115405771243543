import styled from "styled-components";
import * as React from "react";

import { useContext } from "react";
import { AppText, AppErrorText, AppMultiSelect, SkeletonBlock } from "../UI";

import { useMutation, useQuery, gql, ApolloQueryResult, useLazyQuery } from "@apollo/client";

import { ModalContext } from "../../context";
import { appToast } from "../../utils/toast";

import { Modal } from "./Modal";
import { PhoenixAppButton } from "../UI/Phoenix";

const CLEAR_CUSTOM_QUEUE = gql`
  mutation Mutation {
    deleteAllCustomQueueItems
  }
`;

interface Props {
  clearAndRefetchCustomQueue: () => void;
}

const ClearCustomQueueModal: React.FC<Props> = ({ clearAndRefetchCustomQueue }) => {
  const { showClearCustomQueueModal, setShowClearCustomQueueModal } = useContext(ModalContext);

  const [clearCustomQueue, { data, loading: clearCustomQueueLoading, error: clearCustomQueueError }] = useMutation(
    CLEAR_CUSTOM_QUEUE,
    {
      onCompleted: () => {
        appToast("Queue Cleared");
        clearAndRefetchCustomQueue();
        setShowClearCustomQueueModal(false);
      },
      onError: () => {
        appToast("Error clearing queue");
      },
    },
  );

  const loading = clearCustomQueueLoading;

  const error = clearCustomQueueError;

  if (loading)
    return (
      <Modal open={showClearCustomQueueModal} onClose={() => setShowClearCustomQueueModal(false)} bigCloseButton>
        <Main>
          <SkeletonBlock height={400} width={"100%"} borderRadius={6} />
        </Main>
      </Modal>
    );

  if (error)
    return (
      <Modal open={showClearCustomQueueModal} onClose={() => setShowClearCustomQueueModal(false)} bigCloseButton>
        <Main>
          <AppErrorText>Error clearing custom queue</AppErrorText>
        </Main>
      </Modal>
    );

  return (
    <Modal open={showClearCustomQueueModal} onClose={() => setShowClearCustomQueueModal(false)} bigCloseButton>
      <Main>
        <TitleDiv>
          <TitleText>Clear Queue</TitleText>
        </TitleDiv>
        <FlexDiv>
          <AppText fontSize={14}>Are you sure you want to remove all of the leads from your custom queue?</AppText>
        </FlexDiv>
        <SubmitDiv>
          <PhoenixAppButton
            variant="danger-outline"
            buttonType="secondary"
            onClick={() => {
              setShowClearCustomQueueModal(false);
            }}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            onClick={() => {
              clearCustomQueue();
            }}
          >
            Yes, Clear Queue
          </PhoenixAppButton>
        </SubmitDiv>
      </Main>
    </Modal>
  );
};

const SubmitDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

const FlexDiv = styled.div`
  padding: 24px;
  height: 100%;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
  position: relative;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Main = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  width: 400px;
  height: 240px;
`;

export { ClearCustomQueueModal };
