import styled from "styled-components";
import * as React from "react";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";
import { Modal } from "./Modal";
import { PhoenixAppButton } from "../UI/Phoenix";
interface DisappearingDivProps {
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  loadingDelete: boolean;
  deleteFunction: () => void;
}

const DeleteRoutingRuleModal: React.FC<DisappearingDivProps> = ({
  blinds,
  setBlinds,
  loadingDelete,
  deleteFunction,
}) => {
  return (
    <Modal open={blinds} onClose={() => setBlinds(false)} bigCloseButton popupAnimation>
      <Main>
        <ContentDiv>
          <AppText fontSize={16} fontWeight={600} lineHeight={22}>
            Delete Rule
          </AppText>
          <AppText fontSize={14} fontWeight={400} lineHeight={20} textAlign="center">
            Are you sure you want to delete this rule? You cannot undo this action.
          </AppText>
        </ContentDiv>
        <ButtonsDiv>
          <PhoenixAppButton buttonType="secondary" variant="danger-outline" onClick={() => setBlinds(false)}>
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            variant="brand"
            buttonType="secondary"
            disabled={loadingDelete}
            onClick={() => {
              setBlinds(false);
              deleteFunction();
            }}
          >
            Yes, Delete it
          </PhoenixAppButton>
        </ButtonsDiv>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  width: 476px;
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.border.neutral.secondary};
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  padding: 40px 90px 100px 90px;
`;

export { DeleteRoutingRuleModal };
