import styled from "styled-components";
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { AppButton, AppText, AppModalPriorityPopup, Loading, AppErrorText, AppSidebarCard, NewAppSelect } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { theme } from "../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../Field";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";
import { Modal } from "./Modal";
import { ModalContext } from "../../context";
import Switch from "react-switch";
import { appToast } from "../../utils/toast";
import * as Sentry from "@sentry/react";
import { FETCH_PIPELINE_VALUES } from "../Segments";
import { loggedInUser } from "../../apollo/cache";
import { DEFAULT_MRR_LABEL } from "../../utils/variables";
import { updateLocalOrg, updateLocalUser } from "../Smart";

interface DisappearingDivProps {}

interface MyFormikProps {
  mrr: number;
  mrrLabel: string;
  mrrRequiredOnSale: boolean;
}

const editEmailSchema = Yup.object().shape({
  mrr: Yup.number().required("MRR Default Value is required"),
  mrrLabel: Yup.string(),
  mrrRequiredOnSale: Yup.boolean(),
});

const UPDATE_ORG_MRR = gql`
  mutation updateOrgMRR($mrr: Int!, $mrrRequiredOnSale: Boolean!, $mrrLabel: String!) {
    updateOrgMRR(mrr: $mrr, mrr_required_on_sale: $mrrRequiredOnSale, mrr_label: $mrrLabel) {
      id
      mrr_label
      mrr
      mrr_required_on_sale
    }
  }
`;

const PipelineMetricDefaultValueModal: React.FC<DisappearingDivProps> = () => {
  const { pipelineMetricDefaultValueModal, setPipelineMetricDefaultValueModal } = useContext(ModalContext);

  const { data: dataPipelineValues, loading: loadingPipelineValues, error: errorPipelineValues } = useQuery(
    FETCH_PIPELINE_VALUES,
    {
      fetchPolicy: "network-only",
      variables: { org_id: loggedInUser().organization_id },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [updateOrgMRR, { loading: loadingOrgMRR }] = useMutation(UPDATE_ORG_MRR, {
    onCompleted({ updateOrgMRR }) {
      console.log("updateOrgMRR: ", updateOrgMRR);
      appToast("Updated Default MRR Values");
      setPipelineMetricDefaultValueModal(false);
      updateLocalOrg({ mrr_label: updateOrgMRR?.mrr_label });
      updateLocalUser(updateOrgMRR?.mrr_label);
    },
    onError({ message }) {
      console.log("Error in updateOrgMRR: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateOrgMRR GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchOrganization"],
  });

  if (loadingPipelineValues)
    return (
      <Modal open={pipelineMetricDefaultValueModal} onClose={() => setPipelineMetricDefaultValueModal(false)}>
        <ContentDiv>
          <Loading />
        </ContentDiv>
      </Modal>
    );
  if (errorPipelineValues)
    return (
      <Modal open={pipelineMetricDefaultValueModal} onClose={() => setPipelineMetricDefaultValueModal(false)}>
        <ContentDiv>
          <AppErrorText>Error loading default values</AppErrorText>
        </ContentDiv>
      </Modal>
    );

  return (
    <Modal open={pipelineMetricDefaultValueModal} onClose={() => setPipelineMetricDefaultValueModal(false)}>
      <ContentDiv>
        <Formik
          enableReinitialize={true}
          initialValues={{
            mrr: Number(dataPipelineValues?.fetchOrganization?.mrr),
            mrrLabel: dataPipelineValues?.fetchOrganization?.mrr_label,
            mrrRequiredOnSale: dataPipelineValues?.fetchOrganization?.mrr_required_on_sale,
          }}
          validationSchema={editEmailSchema}
          onSubmit={async (values) => {
            await updateOrgMRR({
              variables: {
                mrr: Number(values.mrr),
                mrrRequiredOnSale: values.mrrRequiredOnSale,
                mrrLabel: values.mrrLabel,
              },
            });
          }}
        >
          {({ submitForm, isSubmitting, values, errors, setFieldValue }: FormikProps<MyFormikProps>) => {
            return (
              <>
                <TitleText>Default Revenue Forecast Settings</TitleText>
                <div style={{ width: "336px" }}>
                  <NewAppSelect
                    options={[
                      { label: "MRR", value: "MRR" },
                      { label: "MRR + One-Time Fees", value: "MRR + One-Time Fees" },
                      { label: "Revenue Value", value: "Revenue Value" },
                    ]}
                    name="mrrLabel"
                    title="Default Revenue Forecast Metric"
                    value={values.mrrLabel}
                    onChange={async (e: any) => {
                      setFieldValue(`mrrLabel`, e.value);
                    }}
                  />
                </div>
                <InputField name="mrr" title="Default Revenue Forecast Value" type="number" width={336} />
                <VerticalDiv>
                  <SwitchLabel>Sale Phase Value</SwitchLabel>
                  <ButtonsDiv onClick={() => setFieldValue("mrrRequiredOnSale", true)}>
                    <RadioBorder selected={values.mrrRequiredOnSale === true}>
                      <RadioButton selected={values.mrrRequiredOnSale === true} />
                    </RadioBorder>
                    <RadioText>{`Have Reps enter a value in the ${
                      values.mrrLabel || DEFAULT_MRR_LABEL
                    } field in the Make Sale flow.`}</RadioText>
                  </ButtonsDiv>

                  <ButtonsDiv onClick={() => setFieldValue("mrrRequiredOnSale", false)}>
                    <RadioBorder selected={values.mrrRequiredOnSale === false}>
                      <RadioButton selected={values.mrrRequiredOnSale === false} />
                    </RadioBorder>
                    <RadioText>{`Automatically populate the MRR + One-Time Fees Reps associate with product selections in the Make Sale flow in the ${
                      values.mrrLabel || DEFAULT_MRR_LABEL
                    } field.`}</RadioText>
                  </ButtonsDiv>
                  {/* <Switch
                    name="mrrRequiredOnSale"
                    onChange={(checked: boolean) => {
                      setFieldValue(`mrrRequiredOnSale`, checked);
                    }}
                    checked={values.mrrRequiredOnSale}
                    onColor={theme.PRIMARY500}
                    offColor={theme.NEUTRAL200}
                    height={19}
                    width={36}
                    handleDiameter={15}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  /> */}
                </VerticalDiv>
                <ButtonsContainer>
                  <ButtonsDiv>
                    <NewAppButton
                      height={48}
                      variant="attention"
                      onClick={() => setPipelineMetricDefaultValueModal(false)}
                    >
                      Cancel
                    </NewAppButton>
                    <NewAppButton
                      height={48}
                      style={{ marginLeft: 10 }}
                      variant="primary"
                      type="submit"
                      onClick={submitForm}
                      disabled={isSubmitting}
                    >
                      SAVE CHANGES
                    </NewAppButton>
                  </ButtonsDiv>
                </ButtonsContainer>
              </>
            );
          }}
        </Formik>
      </ContentDiv>
    </Modal>
  );
};

const HorizontalDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
`;

const VerticalDiv = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  margin-top: 4px;
  margin-bottom: 15px;
`;

const SwitchLabel = styled(AppText)`
  /* font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-right: 16px; */
  font-size: 12px;
  margin-right: 16px;
  font-weight: 600;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

const ButtonsDiv = styled.div`
  /* display: grid;
  grid-template-columns: 188px 188px;
  grid-template-rows: 48px;
  grid-gap: 16px; */
  display: flex;
  align-items: center;
  width: 336px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${theme.BLACK_COLOR};
  text-align: center;
  margin-bottom: 16px;
  margin-top: 48px;
`;

const SummaryText = styled(AppText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.BLACK_COLOR};
  text-align: center;
  margin-bottom: 48px;
  max-width: 336px;
`;

const ContentDiv = styled.div`
  width: 640px;
  /* height: 512px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 100%; */
  align-items: center;
`;

interface SelectedProp {
  selected: boolean;
}

const RadioBorder = styled.div<SelectedProp>`
  border: 1px solid ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
  border-radius: 50%;
  margin-right: 8px;
  height: fit-content;
  width: fit-content;
`;

const RadioButton = styled.div<SelectedProp>`
  width: 18px;
  height: 18px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${(props) => (props.selected ? theme.PRIMARY500 : theme.NEUTRAL200)};
`;

const RadioText = styled(AppText)`
  font-size: 11px;
  line-height: 15px;
  opacity: 0.75;
`;

export { PipelineMetricDefaultValueModal };
