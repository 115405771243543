import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { FieldArray, Formik, FormikProps } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Switch from "react-switch";
import styled from "styled-components";
import * as Yup from "yup";
import { iconNewMoveRow, iconNewPlus } from "../../images";
import { trash } from "../../images/NewDesign";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { InputField } from "../Field";
import { PhoenixInputField } from "../Field/Phoenix";
import { AppText, FlexDiv, Loading, NewAppSelect } from "../UI";
import { PhoenixAppButton, PhoenixEyeToggle, PhoenixIcon, PhoenixMultiSelect } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { OptionItem } from "../../types";
import { MixpanelActions } from "src/services/mixpanel";

interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
  id?: string;
  custom_object_id?: string;
  type?: string;
  keyName?: string;
  options?: string[];
  allow_reps_to_edit?: boolean;
  visible?: boolean;
  show_on_lead_preview?: boolean;
  lead_system_field_key?: string;
  mappable_lead_keys?: { label: string; value: string }[];
  is_custom_object: boolean;
  is_name_field?: boolean;
  custom_object_name?: string;
}

const ADD_CUSTOM_FIELD = gql`
  mutation AddCustomField(
    $type: CustomFieldType
    $visible: Boolean!
    $key: String!
    $allow_reps_to_edit: Boolean!
    $options: [String!]
  ) {
    addCustomField(
      type: $type
      visible: $visible
      key: $key
      allow_reps_to_edit: $allow_reps_to_edit
      options: $options
    ) {
      id
      type
      key
      value
      options
      allow_reps_to_edit
    }
  }
`;

const UPDATE_CUSTOM_FIELD = gql`
  mutation UpdateOneCustomField(
    $customFieldId: String!
    $key: String
    $type: CustomFieldType
    $allowRepsToEdit: Boolean
    $visible: Boolean
    $options: [String]
  ) {
    updateOneCustomField(
      custom_field_id: $customFieldId
      key: $key
      type: $type
      allow_reps_to_edit: $allowRepsToEdit
      visible: $visible
      options: $options
    ) {
      id
      key
      type
      options
      allow_reps_to_edit
    }
  }
`;

const ADD_ONE_CUSTOM_OBJECT_FIELD = gql`
  mutation createOneCustomObjectField($data: CustomObjectDataInput!) {
    createOneCustomObjectField(data: $data) {
      id
      custom_object_id
      name
      type
      list_option
      allow_reps_to_edit
      visible
      show_on_lead_preview
    }
  }
`;

const UPDATE_ONE_CUSTOM_OBJECT_FIELD = gql`
  mutation updateOneCustomObjectField($id: String!, $data: CustomObjectDataInput!) {
    updateOneCustomObjectField(custom_object_field_id: $id, data: $data) {
      id
      custom_object_id
      name
      type
      list_option
      allow_reps_to_edit
      visible
      show_on_lead_preview
    }
  }
`;

interface MyFormikProps {
  id: string;
  type: string;
  key: string;
  options: string[];
  allow_reps_to_edit: boolean;
  visible: boolean;
  show_on_lead_preview: boolean;
  lead_system_field_key: string;
  is_name_field: boolean;
}

const UpdateCustomFieldV2: React.FC<DisappearingDivProps> = ({
  blinds,
  close,
  id,
  custom_object_id,
  keyName,
  is_custom_object,
  custom_object_name,
  ...propsField
}) => {
  const { multiTextField } = useFlags();
  const formikRef = useRef<FormikProps<MyFormikProps>>(null);

  const optionTypeRaw = [
    {
      label: "Select input type",
      value: "",
    },
    {
      label: "Text Field",
      value: "Text",
    },
    {
      label: "Big Text",
      value: "BigText",
    },
    {
      label: "Dropdown",
      value: "Dropdown",
    },
    {
      label: "Multi Dropdown",
      value: "MultiDropdown",
    },
    {
      label: "Numeric Value",
      value: "Number",
    },
    {
      label: "Rate",
      value: "Rate",
    },
    {
      label: "Percentage",
      value: "Percentage",
    },
    {
      label: "True/False",
      value: "Boolean",
    },
    {
      label: "Date/Time",
      value: "DateTime",
    },
    {
      label: "Date",
      value: "Date",
    },
  ];

  const optionsType = multiTextField ? [...optionTypeRaw, { label: "Multi Text", value: "MultiText" }] : optionTypeRaw;

  const LEAD_SYSTEM_FIELD_OPTIONS = [{ label: "None", value: "" }, ...(propsField.mappable_lead_keys || [])];

  if (is_custom_object) {
    optionsType.push({ label: "Reference", value: "Reference" });
  }

  const customFieldsSchema = Yup.object().shape({
    id: Yup.string(),
    key: Yup.string().required("Field required!"),
    type: Yup.string().required("Please select a valid type!"),
    allow_reps_to_edit: Yup.boolean(),
    visible: Yup.boolean(),
    show_on_lead_preview: Yup.boolean(),
    options: Yup.array(Yup.string()),
  });

  const [updateOneCustomField, { loading: updateLoading, error: errorLoading }] = useMutation(UPDATE_CUSTOM_FIELD, {
    onCompleted({ updateOneCustomField }) {
      console.log("Update field: ", updateOneCustomField);
      // window.location.reload(false);
      appToast("Custom field updated successfully!");
      if (!updateOneCustomField) {
        return;
      }
      close();
    },
    onError({ message }) {
      console.log("Error in updateOneCustomField: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateOneCustomField GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["FetchCustomFields"],
  });

  const [addCustomField, { loading: addLoading, error: addError }] = useMutation(ADD_CUSTOM_FIELD, {
    onCompleted({ addCustomField }) {
      console.log("Add custom field: ", addCustomField);
      appToast("Custom field added successfully!");
      // window.location.reload(false);
      if (!addCustomField) {
        appToast("Error: Something went wrong!");
        return;
      }
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addCustomField GraphQL Error: ${message}`,
      });
      console.log("Error in addCustomField: ", message);
    },
    refetchQueries: ["FetchCustomFields"],
  });

  const [addOneCustomObjectField, { loading: addObjectLoading, error: addObjectError }] = useMutation(
    ADD_ONE_CUSTOM_OBJECT_FIELD,
    {
      onCompleted({ createOneCustomObjectField }) {
        appToast("Custom object field added successfully!");
        // window.location.reload(false);
        if (!createOneCustomObjectField) {
          appToast("Error: Something went wrong!");
          return;
        }
        const values = formikRef.current?.values;
        MixpanelActions.track("Custom Field Created", {
          parent_object_name: custom_object_name,
          field_name: values?.key,
          data_type: values?.type,
        });
        close();
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `createOneCustomObjectField GraphQL Error: ${message}`,
        });
      },
      refetchQueries: ["fetchOneCustomObject"],
    },
  );

  const [updateOneCustomObjectField, { loading: updateObjectLoading, error: updateObjectError }] = useMutation(
    UPDATE_ONE_CUSTOM_OBJECT_FIELD,
    {
      onCompleted({ updateOneCustomObjectField }) {
        appToast("Custom object field updated successfully!");
        // window.location.reload(false);
        if (!updateOneCustomObjectField) {
          appToast("Error: Something went wrong!");
          return;
        }
        const values = formikRef.current?.values;
        MixpanelActions.track("Custom Field Edited", {
          parent_object_name: custom_object_name,
          field_name: values?.key,
        });
        close();
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `updateOneCustomObjectField GraphQL Error: ${message}`,
        });
      },
      refetchQueries: ["fetchOneCustomObject"],
    },
  );

  const onDragEnd = (result: any, setFieldValue: any, values: any) => {
    console.log("Result: ", result);
    if (!result.destination) {
      return;
    }

    const items = Array.from(values.options);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFieldValue("options", items);
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        id: id || "",
        key: keyName || "",
        type: propsField.type || "",
        visible: propsField.visible ?? true,
        show_on_lead_preview: propsField.show_on_lead_preview ?? false,
        allow_reps_to_edit: propsField.allow_reps_to_edit ?? false,
        options: propsField.options || [],
        lead_system_field_key: propsField.lead_system_field_key ?? "",
        is_name_field: propsField.is_name_field ?? false,
      }}
      validationSchema={customFieldsSchema}
      onSubmit={async ({
        id,
        type,
        visible,
        show_on_lead_preview,
        key,
        allow_reps_to_edit,
        options,
        lead_system_field_key,
      }) => {
        console.log("values", lead_system_field_key);
        if (is_custom_object && custom_object_id) {
          if (id) {
            await updateOneCustomObjectField({
              variables: {
                id: id,
                data: {
                  name: key,
                  type: type,
                  list_option: options,
                  custom_object_id,
                  lead_system_field_key,
                  allow_reps_to_edit,
                  visible,
                  show_on_lead_preview,
                },
              },
            });
          } else {
            await addOneCustomObjectField({
              variables: {
                data: {
                  name: key,
                  type: type,
                  list_option: options,
                  custom_object_id,
                  lead_system_field_key,
                  allow_reps_to_edit,
                  visible,
                  show_on_lead_preview,
                },
              },
            });
          }
        } else {
          if (id) {
            await updateOneCustomField({
              variables: {
                customFieldId: id,
                key: key,
                type: type,
                allowRepsToEdit: allow_reps_to_edit,
                visible: visible,
                options: options,
              },
            });
          } else {
            await addCustomField({
              variables: {
                type: type,
                visible: visible,
                key: key,
                allow_reps_to_edit: allow_reps_to_edit,
                options: options,
              },
            });
          }
        }
      }}
    >
      {({ submitForm, isSubmitting, values, isValid, dirty, setFieldValue }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={blinds} onClose={close} bigCloseButton>
            <ModalContent>
              <TitleDiv>
                <PopupTitle>{id ? `Edit ` : `Add `}Custom Field</PopupTitle>
              </TitleDiv>
              <ScrollingDiv>
                <WidthContainer>
                  <PhoenixMultiSelect
                    name="type"
                    isMulti={false}
                    isClearable={false}
                    titleText="Input Type"
                    value={optionsType.find((option) => option.value === values.type)}
                    options={optionsType}
                    onChange={(value: any) => setFieldValue("type", value.value)}
                    menuPortal
                  />
                  <PhoenixInputField
                    name="key"
                    {...(values.is_name_field ? { titleText: "Record Name" } : { titleText: "Field Name" })}
                    disabled={!!id && !is_custom_object}
                  />
                </WidthContainer>
                {(values.type === "Dropdown" || values.type === "MultiDropdown") && (
                  <DragDropContext onDragEnd={(result) => onDragEnd(result, setFieldValue, values)}>
                    <Droppable droppableId="droppable-lead-custom">
                      {(provided, snapshot) => (
                        <FieldArray
                          name="options"
                          render={({ push, insert, remove }) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {values.options &&
                                values.options.length > 0 &&
                                values.options?.map((option, index) => (
                                  <Draggable key={index} draggableId={index.toString()} index={index}>
                                    {(provided) => {
                                      return (
                                        <DropdownOptionContainer
                                          key={index}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            position: "inherit", // You can consider using fixed positioning
                                          }}
                                        >
                                          <OptionTaskContainer>
                                            <PhoenixIcon svg={iconNewMoveRow} size={12} color={theme.NEUTRAL200} />
                                          </OptionTaskContainer>
                                          <DropdownOption name={`options.${index}`} />
                                          <OptionTaskContainer>
                                            <PhoenixIcon
                                              variant="danger"
                                              svg={trash}
                                              size={16}
                                              pointer
                                              onClick={() => remove(index)}
                                            />
                                          </OptionTaskContainer>
                                        </DropdownOptionContainer>
                                      );
                                    }}
                                  </Draggable>
                                ))}
                              {provided.placeholder}

                              <CenterDiv
                                style={{ cursor: "pointer", height: "45px", paddingBottom: "15px", maxWidth: "385px" }}
                                onClick={() => push("")}
                              >
                                <PhoenixIcon svg={iconNewPlus} size={16} color={theme.PRIMARY600} />
                                <AppText variant="primary" uppercase>
                                  Add Option{" "}
                                </AppText>
                              </CenterDiv>
                            </div>
                          )}
                        />
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <WidthContainer>
                  <FlexDiv direction="column" gap={16}>
                    <FlexDiv gap={8} align="center">
                      <Switch
                        checked={values.allow_reps_to_edit}
                        name="allow_reps_to_edit"
                        onChange={(check) => {
                          setFieldValue("allow_reps_to_edit", check);
                        }}
                        onColor={theme.PRIMARY500}
                        height={16}
                        width={32}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        handleDiameter={12}
                      />
                      <AppText fontSize={12} fontWeight={500}>
                        Allow Reps to Edit
                      </AppText>
                    </FlexDiv>
                    {is_custom_object && !values.is_name_field && (
                      <FlexDiv gap={8} align="center">
                        <Switch
                          checked={values.show_on_lead_preview}
                          name="show_on_lead_preview"
                          onChange={(check) => {
                            setFieldValue("show_on_lead_preview", check);
                          }}
                          onColor={theme.PRIMARY500}
                          height={16}
                          width={32}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          handleDiameter={12}
                          disabled={values.visible === false}
                        />
                        <AppText fontSize={12} fontWeight={500}>
                          Show on Record Preview
                        </AppText>
                      </FlexDiv>
                    )}
                    <div>
                      <PhoenixEyeToggle
                        visible={values.visible}
                        visibleText="Visibility On"
                        notVisibleText="Visibility Off"
                        onChange={() => {
                          setFieldValue("visible", !values.visible);
                          if (values.visible === true) {
                            setFieldValue("show_on_lead_preview", false);
                          }
                        }}
                      />
                    </div>
                    {is_custom_object && values.type === "Reference" && (
                      <div>
                        <PopupInputLabel>Lead Foreign Key</PopupInputLabel>

                        <PhoenixMultiSelect
                          isMulti={false}
                          isClearable={false}
                          value={LEAD_SYSTEM_FIELD_OPTIONS.find(
                            (option) => option.value === values.lead_system_field_key,
                          )}
                          name="lead_system_field_key"
                          options={LEAD_SYSTEM_FIELD_OPTIONS}
                          maxMenuHeight={200}
                          onChange={(e: OptionItem) => {
                            const value = e?.value;

                            setFieldValue(`lead_system_field_key`, !!value ? value : "");
                          }}
                        />
                      </div>
                    )}
                  </FlexDiv>
                </WidthContainer>
              </ScrollingDiv>
              <SubmitDiv>
                <PhoenixAppButton buttonType="secondary" variant="danger-outline" onClick={close} uppercase={true}>
                  Cancel
                </PhoenixAppButton>
                {isSubmitting ? (
                  <Loading />
                ) : (
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="brand"
                    onClick={submitForm}
                    uppercase={true}
                    disabled={!isValid}
                  >
                    {id ? "Update" : "Create"}
                  </PhoenixAppButton>
                )}
              </SubmitDiv>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

const WidthContainer = styled.div`
  width: 100%;
`;

const ModalContent = styled.div`
  width: 456px;
  min-height: 398px;
`;
const SubmitDiv = styled.div`
  bottom: 0px;
  height: 72px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionTaskContainer = styled.div`
  height: 40px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DropdownOptionContainer = styled.div`
  z-index: 3;
  width: 200px;
  overflow: visible;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`;

const DropdownOption = styled(InputField)`
  margin: 0;
  text-align: left;
  border-radius: 2px;
  width: 160px;
`;

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const ScrollingDiv = styled.div`
  position: relative;
  padding: 40px;
  overflow: auto;
  width: 100%;
  min-height: 200px;
  max-height: 536px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
`;

export { UpdateCustomFieldV2 };
