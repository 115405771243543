import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { loggedInUser } from "../../apollo/cache";
import { ModalContext } from "../../context";
import { ai, alert_circle, calendar, clipboard, dollar_sign, info, stop_watch } from "../../images/NewDesign";
import { MixpanelActions } from "../../services/mixpanel";
import { CallType } from "../../types";
import {
  calculateSpeakerTimeline,
  generateSentimentDataPoints,
  getCallScoreDescription,
  getScoreColor,
} from "../../utils/misc";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { FETCH_CONFERENCE_POLL_INTERVAL } from "../../utils/variables";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { AIProcessingCall } from "../Segments/CallReportV2Segments/AIProcessingCall";
import { SummaryTab } from "../Segments/CallReportV2Segments/SummaryTab";
import { TranscriptTab } from "../Segments/CallReportV2Segments/TranscriptTab";
import { LeadCardV2 } from "../Segments/DashboardSideBarSegments";
import { AppText, FlexDiv, Loading } from "../UI";
import { PhoenixAppButton, PhoenixIcon, PhoenixProgressBar } from "../UI/Phoenix";
import { CallScoreMoreInfoModal, SaveToFolderModal } from "../modal";
import { CallFeedbackModal } from "../modal/CallFeedbackModal";
import { CallReport } from "./CallReports";
import { SoundbitesTab } from "../Segments/CallReportV2Segments/SoundbitesTab";
import { CreateSoundbiteModal } from "../modal/CreateSoundbiteModal";
import { AnalysisTab } from "../Segments/CallReportV2Segments/AnalysisTab";
import { CoachingNotesTab } from "../Segments/CallReportV2Segments/CoachingNotesTab";
import { CoachingNoteModal } from "../modal/CoachingNoteModal";

const FETCH_CONFERENCE = gql`
  query fetchConference($conference_id: String!) {
    fetchConference(conference_id: $conference_id) {
      id
      salesperson
      users {
        id
      }
      lead {
        id
        first_name
        last_name
        full_name
        business_name
        city
        state
        channel
        current_lead_type
        industry
        lead_source
        call_notes {
          id
          notes
          created_at
        }
      }
      call_result {
        id
        label
        associated_action
      }
      recording_url_computed
      duration
      is_call_live
      start_time

      first_user_to_explore_id
      waveform_data_url_computed

      symbol_ai_data
      symbol {
        id
        status
      }
    }
  }
`;

const FETCH_CALL_NOTES = gql`
  query fetchCallNotes($conference_id: String!) {
    fetchConference(conference_id: $conference_id) {
      id
      coaching_notes {
        id
        text
        start
        end
      }
      coaching_notes_sorted {
        id
        text
        start
        end
      }
    }
  }
`;

const ADD_COACHING_NOTE = gql`
  mutation addCoachingNote($conference_id: String!, $text: String!, $start: Int!, $end: Int!, $note_id: String) {
    addCoachingNote(conference_id: $conference_id, text: $text, start: $start, end: $end, note_id: $note_id) {
      id
      text
      start
      end
    }
  }
`;

const UPDATE_COACHING_NOTE = gql`
  mutation UpdateOneNoteItem($noteItemId: String!, $text: String!, $start: Int, $end: Int) {
    updateOneNoteItem(note_item_id: $noteItemId, text: $text, start: $start, end: $end) {
      id
      text
      start
      end
    }
  }
`;

const DELETE_COACHING_NOTE = gql`
  mutation DeleteOneNoteItem($noteItemId: String!) {
    deleteOneNoteItem(note_item_id: $noteItemId) {
      id
    }
  }
`;

interface ParamTypes {
  conference_id: string;
  from_system_view?: string;
}

type Tracker = {
  id: string;
  name: string;
  matches: {
    type: string;
    value: string;
    insightRefs: any[];
    messageRefs: {
      id: string;
      text: string;
      offset: number;
    }[];
  }[];
};

type CallScoreCriteria = {
  name: string;
  score: number;
  summary: string;
  feedback: {
    negative: {
      summary: string;
    };
    positive: {
      summary: string;
    };
  };
  criteriaId: string;
};

const CallReportV2: React.FC = () => {
  const { conference_id } = useParams<ParamTypes>();

  // used for mixpanel tracking (OPS-7383)
  const [pageLoaded, setPageLoaded] = useState(false);
  const [peakDataLoading, setPeakDataLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState<
    "summary" | "transcript" | "soundbites" | "analysis" | "coachingNotes"
  >("summary");

  const [showCallScoreMoreInfo, setShowCallScoreMoreInfo] = useState<{
    title: string;
    score: number;
    description: string;
  } | null>(null);

  const { data, loading, error, refetch, startPolling, stopPolling, called } = useQuery(FETCH_CONFERENCE, {
    variables: { conference_id: conference_id },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
    onCompleted() {
      setPageLoaded(true);
      console.log("fetchConf data: ", data);
    },
  });

  const { data: callNotesData } = useQuery(FETCH_CALL_NOTES, {
    variables: { conference_id: conference_id },
    fetchPolicy: "network-only",
  });

  const aiProcessing = useMemo(
    () => data?.fetchConference?.symbol?.status === "IN_PROGRESS" || !data?.fetchConference?.symbol?.status,
    [data],
  );
  const callScoreProcessing = useMemo(
    () =>
      data?.fetchConference?.symbol_ai_data?.call_score?.status === "processing" ||
      data?.fetchConference?.symbol_ai_data?.call_score === null ||
      !data?.fetchConference?.symbol_ai_data,
    [data],
  );

  /**
   * Formats AI and call data for UI display.
   */
  const aiData = useMemo(() => {
    if (!data?.fetchConference) return null;

    const symbol = data.fetchConference?.symbol_ai_data;

    const [repSentimentDataPoints, leadSentimentDataPoints] = generateSentimentDataPoints(data);

    const repName = data.fetchConference?.salesperson?.split(" ")?.[0];

    // repSpeakerId and leadSpeakerId are hardcoded to assume this is a 2-person call
    // TODO: update logic to consider more than 2 speakers
    const repSpeakerId = symbol?.transcript?.messages?.find((m: any) => m?.from?.name?.includes("Speaker 1"))?.from?.id;
    const leadSpeakerId = symbol?.transcript?.messages?.find((m: any) => !m?.from?.name?.includes("Speaker 1"))?.from
      ?.id;

    // Calculate total talk time for each speaker
    const speakerTimes = symbol?.transcript?.messages.reduce((acc: any, message: any) => {
      const speakerName = message.from.name;
      const duration = message.duration;
      if (acc[speakerName]) {
        acc[speakerName] += duration;
      } else {
        acc[speakerName] = duration;
      }
      return acc;
    }, {});

    let totalDuration: any;
    let talkTime;
    if (!!speakerTimes) {
      // Calculate total duration of talk time
      totalDuration = Object.values(speakerTimes).reduce((acc: any, duration: any) => acc + duration, 0);
      // Calculate talk time percentage for each speaker
      talkTime = Object.entries(speakerTimes).reduce((acc: any, [speakerName, duration]: any[]) => {
        const percentage = (duration / totalDuration) * 100;
        acc[speakerName] = percentage < 1 ? Math.ceil(percentage) : Math.floor(percentage);
        return acc;
      }, {});
    }

    return {
      summary: symbol?.summary?.summary ?? [],
      nextSteps: symbol?.action_items?.actionItems ?? [],
      formattedTranscript: symbol?.transcript?.messages?.map((m: any) => {
        return {
          id: m.id,
          text: m.text || "",
          speaker: m.from.name || "NA",
          timestamp: moment.utc(m.timeOffset * 1000).format("mm:ss"),
        };
      }),
      repSentimentDataPoints,
      leadSentimentDataPoints,
      avgRepSentiment:
        repSentimentDataPoints.reduce((acc, point) => acc + point.score, 0) / repSentimentDataPoints.length,
      avgLeadSentiment:
        leadSentimentDataPoints.reduce((acc, point) => acc + point.score, 0) / leadSentimentDataPoints.length,
      repSpeakingTimeline: calculateSpeakerTimeline(
        symbol?.transcript?.messages,
        data.fetchConference.duration,
        repSpeakerId,
      ),
      leadSpeakingTimeline: calculateSpeakerTimeline(
        symbol?.transcript?.messages,
        data.fetchConference.duration,
        leadSpeakerId,
      ),
      talkTime,
      trackers:
        symbol?.trackers?.map((t: Tracker) => ({
          name: t.name,
          messageRefs: t.matches?.flatMap((m) => m.messageRefs || []),
        })) ?? [],
      callScore: {
        overall: symbol?.call_score?.score,
        communicationAndEngagement: symbol?.call_score?.criteria?.find(
          (c: CallScoreCriteria) => c.name === "Communication And Engagement",
        ),
        forwardMotion: symbol?.call_score?.criteria?.find((c: CallScoreCriteria) => c.name === "Forward Motion"),
        questionHandling: symbol?.call_score?.criteria?.find((c: CallScoreCriteria) => c.name === "Question Handling"),
        salesProcess: symbol?.call_score?.criteria?.find((c: CallScoreCriteria) => c.name === "Sales Process"),
      },
      topics: symbol?.topics || [],
    };
  }, [data]);

  const callData = useMemo(() => {
    if (!data?.fetchConference) return null;

    return {
      callDisposition: data.fetchConference.call_result?.label,
      callType: data.fetchConference.call_result?.associated_action,
    };
  }, [data]);

  const {
    setShowCallFeedbackModal,
    showCallFeedbackModal,
    saveCallToLibraryModal,
    setSaveCallToLibraryModal,
    currentConferenceID,
    showSoundbiteModal,
    setShowSoundbiteModal,
    setShowCoachingNotesModal,
    showCoachingNotesModal,
  } = useContext(ModalContext);

  useEffect(() => {
    console.log("data: ", data);
    if (!called || !data) {
      return;
    }
    if (!!data.fetchConference.is_call_live) {
      startPolling(FETCH_CONFERENCE_POLL_INTERVAL);
    } else {
      stopPolling();
    }
    return () => {
      stopPolling();
      console.log("fetch conference stop polling");
    };
  }, [data]);

  // mixpanel tracking (OPS-7383)

  // ops-7383 - track call report viewed
  useEffect(() => {
    // loaded page state is necessary for is_call_live to be defined
    // conference query is polled so we need to make sure we only track the initial load
    if (!pageLoaded) {
      return;
    }

    MixpanelActions.track("Call Report", {
      type: "call report viewed",
      user_role: loggedInUser()?.role || "N/A",
      is_call_live: data?.fetchConference?.is_call_live,
      duration: data?.fetchConference?.is_call_live ? undefined : data?.fetchConference?.duration,
      first_view: !data?.fetchConference?.first_user_to_explore_id,
    });
  }, [pageLoaded]);

  const [peakData, setPeakData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (!data?.fetchConference?.waveform_data_url_computed) {
        setPeakData(undefined);
        return;
      }

      setPeakDataLoading(true);
      try {
        const response = await fetch(data.fetchConference.waveform_data_url_computed);
        const json = await response.json();
        setPeakData(json.data);
      } catch (error) {
        console.error("Failed to fetch peak data:", error);
        setPeakData(undefined);
      } finally {
        setPeakDataLoading(false);
      }
    };

    fetchData();
  }, [data]);

  const [addCoachingNote, { loading: addCoachingNoteLoading }] = useMutation(ADD_COACHING_NOTE, {
    onCompleted({ addCoachingNote }) {
      console.log("addCoachingNote: ", addCoachingNote);
      if (!addCoachingNote) {
        return;
      }
      MixpanelActions.track("Coaching Note", { type: "add" });

      setShowCoachingNotesModal(false);

      appToast("Coaching note added!");
    },
    onError({ message }) {
      console.log("Error in addCoachingNote: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `addCoachingNote GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchCallNotes"],
  });

  const [editCoachingNote] = useMutation(UPDATE_COACHING_NOTE, {
    onCompleted({ coachingNote }) {
      console.log("editCoachingNote: ", coachingNote);
      if (!coachingNote) {
        return;
      }
    },
    onError({ message }) {
      console.log("Error in editCoachingNote: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `editCoachingNote GraphQL Error: ${message}`,
      });
    },
  });
  const [deleteCoachingNote] = useMutation(DELETE_COACHING_NOTE, {
    onCompleted({ coachingNote }) {
      console.log("deleteCoachingNote: ", coachingNote);

      if (!coachingNote) {
        return;
      }
    },
    onError({ message }) {
      console.log("Error in deleteCoachingNote: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `deleteCoachingNote GraphQL Error: ${message}`,
      });
    },
  });

  if (loading || peakDataLoading) return <Loading />;
  if (error) return <p>Error Loading Call Report</p>;

  const {
    latest_call_result,
    is_call_live,
    lead,
    users,
    recording_url_computed,
    salesperson,
    start_time,
  } = data.fetchConference;

  const { coaching_notes, coaching_notes_sorted } = callNotesData?.fetchConference || {};

  const formattedCoachingNotes = coaching_notes_sorted?.map(
    (note: { id: string; text: string; start: number; end: number }) => {
      return {
        id: note.id,
        start: note.start,
        end: note.end,
        data: {
          note: note.text,
        },
      };
    },
  );

  if (!!is_call_live) {
    // temp
    return <CallReport />;
  }

  return (
    <CallReportContainer>
      {showCallFeedbackModal && (
        <CallFeedbackModal
          blinds={showCallFeedbackModal}
          setBlinds={setShowCallFeedbackModal}
          role="manager"
          conference_id={conference_id}
        />
      )}
      {saveCallToLibraryModal && (
        <SaveToFolderModal
          blinds={saveCallToLibraryModal}
          setBlinds={setSaveCallToLibraryModal}
          conferenceID={currentConferenceID}
        />
      )}
      {!!showCallScoreMoreInfo && (
        <CallScoreMoreInfoModal
          blinds={!!showCallScoreMoreInfo}
          setBlinds={setShowCallScoreMoreInfo}
          showCallScoreMoreInfo={showCallScoreMoreInfo}
        />
      )}
      {showSoundbiteModal && (
        <CreateSoundbiteModal
          blinds={showSoundbiteModal}
          setBlinds={setShowSoundbiteModal}
          duration={data?.fetchConference?.duration}
        />
      )}
      {showCoachingNotesModal && (
        <CoachingNoteModal
          conference_id={conference_id}
          addCoachingNote={addCoachingNote}
          duration={data?.fetchConference?.duration}
          addCoachingNoteLoading={addCoachingNoteLoading}
        />
      )}
      <MainArea>
        <SideCard>
          <LeadCardV2
            hideActionButtons
            expandOnRender
            parentType={"leadDetails"}
            leadData={lead}
            maxHeight="92vh"
            hideExpandButton
          />
          <Filler />
        </SideCard>

        <FlexDiv direction="column" gap={24} style={{ width: "100%" }}>
          <Header>
            <FlexDiv direction="column" gap={8}>
              <HeaderInfoText>Time of Call</HeaderInfoText>
              <FlexDiv gap={8} align="center">
                <PhoenixIcon
                  svg={calendar}
                  size={16}
                  color={theme.icon.brand.default}
                  hoverColor={theme.icon.brand.default}
                />
                <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                  {moment(data?.fetchConference?.start_time).format("MM/DD/YYYY")}
                </AppText>
                <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.text.neutral.secondary}>
                  {moment(data?.fetchConference?.start_time).format("h:mm A")}
                </AppText>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv direction="column" gap={8}>
              <HeaderInfoText>Duration</HeaderInfoText>
              <FlexDiv gap={8} align="center">
                <PhoenixIcon
                  svg={stop_watch}
                  size={16}
                  color={theme.icon.brand.default}
                  hoverColor={theme.icon.brand.default}
                />
                <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                  {moment
                    .utc(data?.fetchConference?.duration * 1000)
                    .format(data?.fetchConference?.duration >= 3600 ? "HH:mm:ss" : "mm:ss")}
                </AppText>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv direction="column" gap={8} width={140}>
              <HeaderInfoText>Call Type</HeaderInfoText>
              <CallResultPill result={callData?.callType} icon={getCallTypeIcon(callData?.callType)} />
            </FlexDiv>
            <FlexDiv direction="column" gap={8} width={140}>
              <HeaderInfoText>Call Disposition</HeaderInfoText>
              <CallResultPill result={callData?.callDisposition} />
            </FlexDiv>
          </Header>

          <Body>
            <BodyNav>
              <NavItemFiller width="24px" />
              <NavItem selected={selectedTab === "summary"} onClick={() => setSelectedTab("summary")}>
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  lineHeight={16}
                  color={selectedTab === "summary" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                  letterSpacing={1}
                  uppercase
                  noWrap
                >
                  Summary
                </AppText>
              </NavItem>
              <NavItem selected={selectedTab === "transcript"} onClick={() => setSelectedTab("transcript")}>
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  lineHeight={16}
                  color={selectedTab === "transcript" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                  letterSpacing={1}
                  uppercase
                  noWrap
                >
                  Transcript
                </AppText>
              </NavItem>
              {/* <NavItem selected={selectedTab === "soundbites"} onClick={() => setSelectedTab("soundbites")}>
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  lineHeight={16}
                  color={selectedTab === "soundbites" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                  letterSpacing={1}
                  uppercase
                  noWrap
                >
                  Soundbites
                </AppText>
              </NavItem> */}
              <NavItem selected={selectedTab === "analysis"} onClick={() => setSelectedTab("analysis")}>
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  lineHeight={16}
                  color={selectedTab === "analysis" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                  letterSpacing={1}
                  uppercase
                  noWrap
                >
                  Analysis
                </AppText>
              </NavItem>
              <NavItem selected={selectedTab === "coachingNotes"} onClick={() => setSelectedTab("coachingNotes")}>
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  lineHeight={16}
                  color={selectedTab === "coachingNotes" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                  letterSpacing={1}
                  uppercase
                  noWrap
                >
                  Coaching Notes
                </AppText>
              </NavItem>
              <NavItemFiller />
            </BodyNav>

            {selectedTab === "summary" && (
              <SummaryTab
                aiProcessing={aiProcessing}
                aiData={aiData}
                refetch_call_report={refetch}
                deleteCoachingNote={deleteCoachingNote}
                addCoachingNote={addCoachingNote}
                editCoachingNote={editCoachingNote}
                is_call_live={is_call_live}
                start_time={start_time}
                salesperson={salesperson}
                recording_url={recording_url_computed}
                peakData={peakData}
                conference_id={conference_id}
                lead={lead}
                formattedCoachingNotes={formattedCoachingNotes}
                conferenceData={data?.fetchConference}
              />
            )}

            {selectedTab === "transcript" && (
              <TranscriptTab aiProcessing={aiProcessing} aiData={aiData} conferenceData={data?.fetchConference} />
            )}

            {selectedTab === "soundbites" && (
              <SoundbitesTab
                aiProcessing={aiProcessing}
                refetch_call_report={refetch}
                deleteCoachingNote={deleteCoachingNote}
                addCoachingNote={addCoachingNote}
                editCoachingNote={editCoachingNote}
                is_call_live={is_call_live}
                start_time={start_time}
                salesperson={salesperson}
                recording_url={recording_url_computed}
                peakData={peakData}
                conference_id={conference_id}
                lead={lead}
                formattedCoachingNotes={formattedCoachingNotes}
                conferenceData={data?.fetchConference}
              />
            )}

            {selectedTab === "analysis" && <AnalysisTab aiProcessing={aiProcessing} aiData={aiData} />}

            {selectedTab === "coachingNotes" && <CoachingNotesTab coachingNotes={coaching_notes || []} />}
          </Body>
        </FlexDiv>

        <RightSection>
          <PhoenixStyledTooltip
            id="call-score-tooltip"
            place="left"
            width={320}
            padding={16}
            getContent={(dataTip) => (
              <>
                <AppText
                  fontSize={14}
                  lineHeight={20}
                  fontWeight={500}
                  color={theme.text.neutral.inverse}
                  style={{ paddingBottom: "8px" }}
                >
                  {dataTip?.split("|")?.[0]}
                </AppText>
                <AppText
                  fontSize={12}
                  lineHeight={18}
                  fontWeight={400}
                  color={theme.text.neutral.inverse}
                  textAlign="left"
                >
                  {dataTip?.split("|")?.[1]}
                </AppText>
              </>
            )}
          />
          {/* <ScoreContainer>
            <FlexDiv gap={8}>
              <PhoenixIcon svg={ai} size={16} color={theme.icon.brand.default} hoverColor={theme.icon.brand.default} />
              <div>
                <AppText fontSize={14} fontWeight={500} lineHeight={16}>
                  Sellfire Call Score
                </AppText>
                <AppText fontSize={10} fontWeight={400} lineHeight={18} color={theme.text.brand.primary}>
                  Powered by AI
                </AppText>
              </div>
              <AppText
                fontSize={16}
                fontWeight={500}
                lineHeight={18}
                color={aiProcessing ? theme.text.neutral.disabled : theme.text.success.primary}
                style={{ marginLeft: "auto" }}
              >
                {aiProcessing ? 0 : aiData?.callScore?.overall || 0}
              </AppText>
            </FlexDiv>

            {!callScoreProcessing ? (
              <FlexDiv direction="column" gap={8}>
                <CallScoreCard
                  title="Communication & Engagement"
                  score={aiData?.callScore?.communicationAndEngagement?.score || 0}
                  textWidth="120px"
                  description={getCallScoreDescription("Communication & Engagement")}
                  onClick={() =>
                    setShowCallScoreMoreInfo({
                      title: "Communication & Engagement",
                      score: aiData?.callScore?.communicationAndEngagement?.score || 0,
                      description: aiData?.callScore?.communicationAndEngagement?.summary,
                    })
                  }
                />
                <CallScoreCard
                  title="Forward Motion"
                  score={aiData?.callScore?.forwardMotion?.score || 0}
                  textWidth="60px"
                  description={getCallScoreDescription("Forward Motion")}
                  onClick={() =>
                    setShowCallScoreMoreInfo({
                      title: "Forward Motion",
                      score: aiData?.callScore?.forwardMotion?.score || 0,
                      description: aiData?.callScore?.forwardMotion?.summary,
                    })
                  }
                />
                <CallScoreCard
                  title="Question Handling"
                  score={aiData?.callScore?.questionHandling?.score || 0}
                  textWidth="60px"
                  description={getCallScoreDescription("Question Handling")}
                  onClick={() =>
                    setShowCallScoreMoreInfo({
                      title: "Question Handling",
                      score: aiData?.callScore?.questionHandling?.score || 0,
                      description: aiData?.callScore?.questionHandling?.summary,
                    })
                  }
                />
                <CallScoreCard
                  title="Sale Process"
                  score={aiData?.callScore?.salesProcess?.score || 0}
                  textWidth="60px"
                  description={getCallScoreDescription("Sale Process")}
                  onClick={() =>
                    setShowCallScoreMoreInfo({
                      title: "Sale Process",
                      score: aiData?.callScore?.salesProcess?.score || 0,
                      description: aiData?.callScore?.salesProcess?.summary,
                    })
                  }
                />
              </FlexDiv>
            ) : (
              <FlexDiv justify="center" style={{ height: "312px", paddingBottom: "40px" }}>
                <AIProcessingCall width={200} />
              </FlexDiv>
            )}
          </ScoreContainer> */}

          {/* <ScoreContainer>
            <FlexDiv gap={16} direction="column">
              <FlexDiv gap={8} align="center">
                <PhoenixIcon svg={clipboard} size={16} variant="brand" hoverColor={theme.icon.brand.default} />
                <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                  Manager Score
                </AppText>
              </FlexDiv>

              <PhoenixAppButton
                buttonType="tertiary"
                uppercase
                buttonTextFontSize={10}
                borderRadius={4}
                width={120}
                style={{ letterSpacing: "1px" }}
              >
                Complete
              </PhoenixAppButton>
            </FlexDiv>
          </ScoreContainer> */}
        </RightSection>
      </MainArea>
    </CallReportContainer>
  );
};

const CallReportContainer = styled.div`
  position: relative;

  width: calc(100vw - 72px);
  height: 100vh;
  min-width: 1300px;

  padding: 24px;

  background: ${theme.surface.brand.secondary};
`;

const MainArea = styled.div`
  display: flex;
  gap: 40px;
`;

const SideCard = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 424px;
  height: 92vh;

  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background: ${theme.fill.neutral.primary};

  overflow: hidden;
`;

const Filler = styled.div`
  flex: 1 1 auto;
  width: 100%;
  background: ${theme.surface.brand.primary};
`;

const HeaderInfoText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const Header = styled.div`
  display: flex;
  gap: 120px;
  align-items: center;

  padding: 0px 24px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background: ${theme.fill.neutral.primary};

  overflow: hidden;
`;

const BodyNav = styled.div`
  display: flex;

  width: 100%;
  padding-top: 16px;
`;

const BodyContent = styled.div`
  max-height: calc(100vh - 206px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const NavItemFiller = styled.div<{ width?: string }>`
  background-color: ${theme.WHITE_COLOR};
  border-bottom: 1px solid ${theme.border.neutral.primary};

  width: ${(props) => props.width ?? "100%"};
  height: 32px;
`;

const NavItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;

  height: 32px;
  padding: 8px 16px;

  background-color: ${theme.WHITE_COLOR};
  border-bottom: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? theme.border.brand.primary : theme.border.neutral.primary)};

  cursor: pointer;

  transition: border-bottom 0.1s ease-in-out;

  & > p {
    transition: color 0.1s ease-in-out;
    user-select: none;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 74px;
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 240px;

  padding: 16px;
  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background: ${theme.fill.neutral.primary};
`;

interface CallScoreCardProps {
  title: string;
  score: number;
  description: string;
  textWidth?: string;
  onClick?: () => void;
}

const CallScoreCard: React.FC<CallScoreCardProps> = ({ title, score, textWidth, description, onClick }) => {
  return (
    <CallScoreCardContainer onClick={onClick}>
      <FlexDiv justify="space-between">
        <AppText fontSize={12} fontWeight={400} lineHeight={18} style={{ width: textWidth }}>
          {title}
        </AppText>

        <div>
          <PhoenixIcon
            svg={info}
            size={16}
            variant="neutral"
            style={{ float: "right" }}
            data-tip={`${title}|${description}`}
            data-for="call-score-tooltip"
          />
          <AppText fontSize={12} fontWeight={600} lineHeight={18}>
            {score}/100
          </AppText>
        </div>
      </FlexDiv>
      <PhoenixProgressBar step={score} maxStep={100} height={4} color={getScoreColor(score)} />
    </CallScoreCardContainer>
  );
};

const CallScoreCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 208px;

  padding: 12px;
  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
  background: ${theme.fill.neutral.primary};

  :hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  }

  transition: box-shadow 0.1s ease-in-out;
  cursor: pointer;
`;

interface CallResultPillProps {
  result: string;
  icon?: string;
}

const CallResultPill: React.FC<CallResultPillProps> = ({ icon, result }) => {
  const isSale = result?.toLowerCase() === "sale";
  const color = isSale ? theme.text.neutral.inverse : theme.text.brand.primary;
  const backgroundColor = isSale ? theme.fill.brand.primary : theme.fill.brand.secondary;
  return (
    <PillContainer backgroundColor={backgroundColor}>
      {icon && <PhoenixIcon svg={icon} size={12} color={color} hoverColor={color} />}
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={color}>
        {result || "N/A"}
      </AppText>
    </PillContainer>
  );
};

const PillContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: fit-content;
  min-height: 24px;
  height: fit-content;

  padding: 4px 8px;

  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
`;

export { CallReportV2 };

const getCallTypeIcon = (callType: CallType) => {
  switch (callType) {
    case "Transfer Demo":
    case "Schedule Follow Up Demo/Schedule Callback":
    case "Schedule Follow Up Demo":
    case "Schedule Demo":
    case "Schedule Decision Call":
    case "Schedule Callback":
    case "Reschedule Demo/Schedule Callback":
    case "Reschedule Demo":
      return clipboard;
    case "Make Sale":
      return dollar_sign;
    default:
      return alert_circle;
  }
};
