import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton, PhoenixMultiSelect, PhoenixInput } from "../../UI/Phoenix";
import { FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { SaleConfigPage } from "../../../utils/misc";
import { cloneDeep } from "lodash";
import { OptionItem } from "../../../types";
import { v4 as uuidv4 } from "uuid";

interface ChoosePageModalProps {
  selectedFieldID: string;
  pageState: {
    pages: SaleConfigPage[];
    setPages: Dispatch<SetStateAction<SaleConfigPage[]>>;
    selectedPage: SaleConfigPage | undefined;
    setSelectedPage: Dispatch<SetStateAction<SaleConfigPage | undefined>>;
  };
}

export const ChoosePageModal: React.FC<ChoosePageModalProps> = ({
  selectedFieldID,
  pageState: { pages, setPages, selectedPage, setSelectedPage },
}) => {
  const { showChoosePageModal, setShowChoosePageModal, showChoosePageModalState } = useContext(ModalContext);

  const [chosenPage, setChosenPage] = useState<OptionItem | undefined>(undefined);
  const [newPageName, setNewPageName] = useState<string>("");

  const duplicateName = useMemo(() => pages.some((p) => p.title === newPageName), [newPageName]);

  const handleMovePage = () => {
    const newSelectedPage = cloneDeep(selectedPage);
    const selectedField = newSelectedPage?.sections?.find((section) => section.id === selectedFieldID);
    const newPages = cloneDeep(pages);
    const chosenPageIndex = newPages?.findIndex((p) => p.id === chosenPage?.value);

    // if moving field to the same page, do nothing
    if (newPages[chosenPageIndex].id === newSelectedPage?.id) {
      setShowChoosePageModal(false);
      return;
    }

    // filter field from current page
    if (newSelectedPage && selectedField) {
      newSelectedPage.sections = newSelectedPage?.sections?.filter((section) => section.id !== selectedFieldID) || [];
      setSelectedPage(newSelectedPage);
    }

    // add field to chosen page
    if (newPages && chosenPageIndex !== -1 && selectedField) {
      newPages[chosenPageIndex].sections = [selectedField, ...newPages[chosenPageIndex].sections];
      setPages(newPages);
    }

    setShowChoosePageModal(false);
  };

  const handleNewPage = () => {
    const newPages = cloneDeep(pages);
    const newSelectedPage = cloneDeep(selectedPage);
    const selectedField = newSelectedPage?.sections?.find((section) => section.id === selectedFieldID);

    // filter field from current page
    if (newSelectedPage && selectedField) {
      newSelectedPage.sections = newSelectedPage?.sections?.filter((section) => section.id !== selectedFieldID) || [];
    }

    // create new page and add field to it
    if (selectedField) {
      newPages.push({
        id: uuidv4(),
        order: pages.length,
        title: newPageName,
        sections: [selectedField],
      });
    }

    setSelectedPage(newSelectedPage);
    setPages(newPages);
    setShowChoosePageModal(false);
  };

  return (
    <Modal
      open={showChoosePageModal}
      onClose={() => {
        setShowChoosePageModal(false);
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            {showChoosePageModalState === "new" ? "Create a New Page" : "Choose a Page"}
          </AppText>

          <FlexDiv direction="column" gap={8}>
            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
              {showChoosePageModalState === "new" ? "Name" : "Page"}
            </AppText>
            {showChoosePageModalState === "new" ? (
              <PhoenixInput
                value={newPageName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPageName(e.target.value)}
                placeholder="A New Page"
                width={396}
                displayNoContextText
              />
            ) : (
              <PhoenixMultiSelect
                name="choose-page"
                isMulti={false}
                isClearable={false}
                value={chosenPage}
                options={pages?.map((p) => ({ label: p.title, value: p.id })) || []}
                onChange={(e: OptionItem) => setChosenPage(e)}
                placeholder="Choose Page"
                width={396}
                marginBottom={false}
              />
            )}
          </FlexDiv>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => setShowChoosePageModal(false)}
          >
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              if (showChoosePageModalState === "new") {
                handleNewPage();
              } else {
                handleMovePage();
              }
            }}
            disabled={showChoosePageModalState === "new" ? !newPageName.length || duplicateName : !chosenPage}
          >
            Continue
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  padding: 40px 40px 74px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
