import React, { useContext } from "react";
import styled from "styled-components";
import { AppText } from "../UI";
import { ModalContext } from "../../context";
import { Modal } from ".";
import { PhoenixAppButton } from "../UI/Phoenix";
import { theme } from "../../utils/theme";
import { gql, useMutation } from "@apollo/client";
import { errorToast, successToast } from "../../utils/toast";

const DELETE_PRODUCT = gql`
  mutation deleteProductAdmin($productId: String!) {
    deleteProductAdmin(product_id: $productId) {
      message
      status
      status_boolean
    }
  }
`;

interface ManageDeleteProductModalProps {
  selectedProductID: string;
}

export const ManageDeleteProductModal: React.FC<ManageDeleteProductModalProps> = ({ selectedProductID }) => {
  const { showManageDeleteProductModal, setShowManageDeleteProductModal } = useContext(ModalContext);

  const [deleteProduct, { loading: loadingDeleteProduct }] = useMutation(DELETE_PRODUCT, {
    onCompleted: () => {
      successToast("Product Deleted");
      setShowManageDeleteProductModal(false);
    },
    onError: (error) => {
      errorToast(error.message);
      console.log(error);
    },
    refetchQueries: ["fetchProducts"],
  });

  return (
    <Modal
      open={showManageDeleteProductModal}
      onClose={() => {
        setShowManageDeleteProductModal(false);
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Delete Product
          </AppText>
          <AppText fontSize={14}>Are you sure you want to delete this product? You cannot undo this action.</AppText>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              setShowManageDeleteProductModal(false);
            }}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              deleteProduct({
                variables: {
                  productId: selectedProductID,
                },
              });
            }}
            disabled={loadingDeleteProduct}
          >
            Yes, Delete It
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  padding: 40px 70px 90px 70px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
